import _, { cloneDeep } from 'lodash';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DocumentTitle } from '../../../components/shared/DocumentTitle';
import { Loader } from '../../../components/shared/Loader';
import { useCloseAlertMutation } from '../../../components/weekly-recap/useCloseAlertMutation';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useLoading } from '../../../contexts/LoadingContext';
import { useSignalRContext } from '../../../contexts/SignalRContext';
import usePerformanceNavigation from '../../../hooks/usePerformanceNavigation';
import { IEntityLease } from '../../../interfaces/IEntityLease';
import { IValidation } from '../../../interfaces/IError';
import { ILookupModel } from '../../../interfaces/ILookup';
import { IOrganizationSettingModel } from '../../../interfaces/IOrganization';
import {
    IReportModel,
    IReportBaseModel,
    IReportUserModel,
    IReportSaveModel,
    IUpdateAlertUserModel,
    IExportSettings,
} from '../../../interfaces/Report/IReport';
import {
    ITimelineReportInputModel,
    IReportSectionDetailModel,
    IWeeklyRecapModel,
    IMonthlyRecapModel,
} from '../../../interfaces/Report/ITimelineReport';
import CheckmateApiClient from '../../../services/apiClient';
import { useObtainEntityLeaseMutation } from '../../../shared/react-query-hooks/mutations/useObtainEntityLeaseMutation';
import { useReleaseEntityLeaseMutation } from '../../../shared/react-query-hooks/mutations/useReleaseEntityLeaseMutation';
import { useGetDbAppSettings } from '../../../shared/react-query-hooks/useGetDbAppSettings';
import { useGetNoteCategoryTypes } from '../../../shared/react-query-hooks/useGetNoteCategoryTypes';
import { useGetOrganizationSettings } from '../../../shared/react-query-hooks/useGetOrganizationSettings';
import { useGetIsEntityLeaseAvailable } from '../../../shared/react-query-hooks/userGetIsEntityLeaseAvailable';
import { useZoneUsers } from '../../../shared/react-query-hooks/useZoneUsers';
import { UnsavedChangesBlocker } from '../../../shared/UnsavedChangesBlocker';
import ValidateUtils from '../../../shared/validations';
import CheckmateSelectHelper from '../../../utilities/CheckmateSelectHelper';
import {
    TimelineReportSections,
    DataScopesEnum,
    EntityTypes,
    DatabaseAppSettingsEnum,
    AlertTypesEnum,
    AlertSubTypesEnum,
    ReportUserTypesEnum,
    ReportStatusTypesEnum,
    MyDocumentsTypesEnum,
} from '../../../utilities/Constants';
import { DisplayMessages } from '../../../utilities/DisplayMessages';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { IOpenChatActModel, SignalRMethods } from '../../../utilities/SignalR';
import UIHelper from '../../../utilities/UIHelper';
import { MonthlyRecapReportRenderer } from '../timeline/monthly-recap/MonthlyRecapReportRenderer';
import { WeeklyRecapReportRenderer } from '../timeline/weekly-recap/WeeklyRecapReportRenderer';
import { CollabReportPageActionsType, CollabReportPageActions } from './CollabReportPageActions';
import { CollabReportPageHeader } from './CollabReportPageHeader';
import { CollabReportDisplayMode } from './enums/CollabReportDisplayMode';
import { CollabReportUnsavedChangesReason } from './enums/CollabReportUnsavedChangesReason';
import { useCollabReportModal, CollabModalTypes } from './modals/CollabModalContext';
import { IChatModalData } from './modals/ReportChatModal';
import { ISaveReportModalData } from './modals/SaveReportModal';
import { IKpiReportInputModel, IKpiReportModel } from '../kpi-report/KpiReport';
import { KpiReportRenderer } from '../kpi-report/KpiReportRenderer';
import { PageWithAffixedHeader } from '../../../shared/PageWithAffixedHeader';
import { CaseEasyUpdate } from '../../case/CaseEasyUpdate';
import { ICaseModel } from '../../../interfaces/ICase';

const _apiClient = new CheckmateApiClient();

const LeaseDuration = 60 * 60 * 8 * 1000;

export interface ICollabReportState {
    easyUpdateAttemptedCaseList: string[];
    easyUpdateMode: boolean;
    easyUpdateCaseGuid?: string | undefined;
    noteEditAttemptedGuidList: string[];
    orgSettings: IOrganizationSettingModel[];
    selectAllSections: TimelineReportSections[];
}

export interface ICollabReportViewProps<T, I> {
    data: T;
    collabReportState: ICollabReportState;
    isPreviewMode: boolean;
    isReportReadonly: boolean;
    inputModel: I;
    reportGuid?: string;
    title: string;
    onEventTriggered: (event: string, data?: any) => void;

    onSectionCheckedChange: (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => void;
    show?: boolean;
    setCollabState: (setter: (prevState: ICollabReportState) => ICollabReportState) => void;
}

export interface IControllerProps<T, I> {
    collabReportUrl: string;
    data: T | undefined;
    exportSettings: IExportSettings;
    getReportData?: () => Promise<void>;
    inputModel: I;
    onCollabReportLoaded?: (data: T | undefined) => void;
    onEasyUpdateComplete?: (updatedCase: ICaseModel) => void;
    onInputModelChange: (newInputModel: I) => void;
    onShowCommentsModal: (show: boolean, section: number) => void;
    onShowSettingsModal: (show: boolean) => void;
    onReportStatusChange: (status: ILookupModel) => void;
    pageTitle: string;
    reportTypeId: number;
    sections?: ILookupModel[];
    timelineReportTypeId?: number;
}

function CollabReportController<T, I extends ITimelineReportInputModel | IKpiReportInputModel>(
    props: IControllerProps<T, I>
) {
    const { user } = useAuthContext();
    const { guid: reportGuid } = useParams();
    const loading = useLoading();
    const navigate = useNavigate();
    const { alertHub } = useSignalRContext();
    const closeChatActMutation = useCloseAlertMutation();
    const navigationType = usePerformanceNavigation();
    const modal = useCollabReportModal();

    const zoneUsers = useZoneUsers(DataScopesEnum.National.Value);
    const orgSettings = useGetOrganizationSettings();
    const noteCategoryTypes = useGetNoteCategoryTypes();
    const appSettings = useGetDbAppSettings();

    const obtainEntityLeaseMutation = useObtainEntityLeaseMutation();
    const releaseEntityLeaseMutation = useReleaseEntityLeaseMutation();
    const getIsEntityLeaseAvailable = useGetIsEntityLeaseAvailable(
        EntityTypes.Report,
        reportGuid,
        !!navigationType && !!reportGuid
    );

    const [collabState, setCollabState] = useState<ICollabReportState>({
        easyUpdateAttemptedCaseList: [],
        easyUpdateMode: false,
        noteEditAttemptedGuidList: [],
        orgSettings: [],
        selectAllSections: [],
    });

    const [originalReportState, setOriginalReportState] = useState<{
        collaborationReport: IReportModel;
        inputModel: I;
    }>({ inputModel: props.inputModel } as {
        collaborationReport: IReportModel;
        inputModel: I;
    });

    const [validation, setValidation] = useState<IValidation>({});
    const [collaborationReport, setCollaborationReport] = useState<IReportModel>();
    const [isPending, setIsPending] = useState<boolean>(false);
    const [forceHideEditButton, setForceHideEditButton] = useState<boolean>(false);
    const [displayMode, setDisplayMode] = useState<CollabReportDisplayMode>(
        !reportGuid ? CollabReportDisplayMode.Create : CollabReportDisplayMode.Readonly
    );
    const [existingLease, setExistingLease] = useState<IEntityLease | undefined>();
    const existingLeaseRef = useRef<IEntityLease | undefined>(); // used to store the existingLease so it's available when unmounting

    const reportDifferences =
        !_.isEmpty(originalReportState?.collaborationReport) && !_.isEmpty(collaborationReport)
            ? UIHelper.difference(
                  _.cloneDeep(originalReportState?.collaborationReport),
                  _.cloneDeep(collaborationReport)
              )
            : {};

    const inputModelDifferences = UIHelper.difference(
        _.cloneDeep(originalReportState?.inputModel),
        _.cloneDeep(props.inputModel)
    );

    const hasUnsavedChanges =
        !!reportGuid && (!_.isEmpty(reportDifferences) || !_.isEmpty(inputModelDifferences));

    const getCollaborationReport = async (reportGuid: string) => {
        const response = await _apiClient.getCollaborationReport(reportGuid);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }

        if (response.httpResponse.status === 403) {
            navigate(LocalRoutes.AccessDenied);
            return;
        }

        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsPending(false);
            return;
        }

        return response.payload;
    };

    const loadCollabReport = async () => {
        const validation: IValidation = {};
        setDisplayMode(CollabReportDisplayMode.Readonly);

        setIsPending(true);

        if (!orgSettings.isError || noteCategoryTypes.isError || zoneUsers.isError) {
            validation.model = [DisplayMessages.UnexpectedError];
        }

        let collaborationReportCopy = cloneDeep(collaborationReport);
        let inputModelCopy = cloneDeep(props.inputModel);
        let reportDataCopy = cloneDeep(props.data);

        if (reportGuid) {
            const serverCollabReport = await getCollaborationReport(reportGuid);
            if (serverCollabReport?.baseDetails.guid) {
                collaborationReportCopy = serverCollabReport;

                if (serverCollabReport.reportData?.reportParameterJson) {
                    inputModelCopy = JSON.parse(serverCollabReport.reportData?.reportParameterJson);
                    inputModelCopy.entityGuid = reportGuid;
                    //todo:aj - verify after feature is implemented.
                    inputModelCopy.saveReport = true;
                    if (serverCollabReport.reportData?.data) {
                        reportDataCopy = JSON.parse(serverCollabReport.reportData?.data);
                    }
                    props.onShowSettingsModal(false);
                }
            }
        }

        // todo jec: handle error?
        // validation.model = [DisplayMessages.UnexpectedError];

        setCollaborationReport(collaborationReportCopy);
        if (collaborationReportCopy) {
            setOriginalReportState({
                collaborationReport: collaborationReportCopy,
                inputModel: inputModelCopy,
            });
        }

        props.onInputModelChange(inputModelCopy);

        if (props.onCollabReportLoaded) {
            props.onCollabReportLoaded(reportDataCopy);
        }

        setIsPending(false);
    };

    const getSectionsAfterSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        sections: IReportSectionDetailModel[] = []
    ) => {
        const sectionsCopy = cloneDeep(sections);
        const isChecked = event.target.checked;
        const found = sectionsCopy.find((x) => x.section === section);

        if (found) {
            found.excludeInReport = !isChecked;
        } else if (isChecked) {
            sectionsCopy?.push({
                section,
                sectionName: section.toString(),
            });
        }
        return sectionsCopy?.filter((detail) => !detail.excludeInReport);
    };

    const getLeaseDuration = (): number => {
        const leaseDurationSetting = appSettings.data?.find(
            (setting) => setting.name === DatabaseAppSettingsEnum.CollabReportLeaseDuration
        )?.value;

        const durationAsNumber = parseInt(leaseDurationSetting ?? '');

        if (isNaN(durationAsNumber)) {
            return LeaseDuration;
        }

        return durationAsNumber;
    };

    const enableCollab =
        appSettings.data?.find(
            (setting) => setting.name === DatabaseAppSettingsEnum.DisableCollabReportFeature
        )?.value !== 'true' && !appSettings.isLoading;

    const getPageActionsDisplayType = () => {
        if (displayMode === CollabReportDisplayMode.Create && props.data) {
            return CollabReportPageActionsType.CollabReportCreateActions;
        }

        if (displayMode === CollabReportDisplayMode.Edit && collaborationReport) {
            return CollabReportPageActionsType.CollabReportNonReadOnlyActions;
        }

        if (!props.data) {
            return CollabReportPageActionsType.SettingsOnly;
        }

        return CollabReportPageActionsType.CollabReportReadOnlyActions;
    };

    const saveReport = async (
        report: IReportBaseModel,
        collaborators: IReportUserModel[],
        readers: IReportUserModel[],
        redirectOnSave?: boolean
    ) => {
        setIsPending(true);

        const request: IReportSaveModel = {
            baseDetails: { ...report, type: { id: props.reportTypeId } },
            entityLeaseGuid: existingLeaseRef.current?.guid,
            users: collaborators,
            readers,
            reportParameterJson: JSON.stringify(props.inputModel),
        };

        const response = await _apiClient.saveCollaborationReport(request);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsPending(false);
            return;
        } else {
            setIsPending(false);
            const newReportState = response.payload!;
            setCollaborationReport(newReportState);
            setOriginalReportState({
                collaborationReport: newReportState,
                inputModel: props.inputModel,
            });
            setDisplayMode(CollabReportDisplayMode.Readonly);

            if (redirectOnSave && props.collabReportUrl) {
                navigate(
                    props.collabReportUrl.replace(':guid', response.payload?.baseDetails.guid ?? '')
                );
            }

            return response.payload;
        }
    };

    const cancelLease = (readonly: boolean) => {
        if (readonly || !existingLeaseRef.current?.guid) return;

        if (existingLeaseRef.current?.guid) {
            releaseEntityLeaseMutation
                .mutateAsync(existingLeaseRef.current?.guid)
                .then(() => {
                    existingLeaseRef.current = undefined;
                    setExistingLease(undefined);
                    setForceHideEditButton(false);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleReportViewEventTriggered = useCallback(
        (event: string, data?: any) => {
            switch (event) {
                case 'commentsModalStateChange':
                    props.onShowCommentsModal(data.open, data.section);
                    return;
                case 'inputModelChange':
                    props.onInputModelChange(data.inputModel);
                    return;
            }
        },
        [props.data]
    );

    const handleCancelClick = () => {
        if (hasUnsavedChanges) {
            modal.openModal<{ reason: CollabReportUnsavedChangesReason }, unknown>(
                CollabModalTypes.UnsavedChangesDetected,
                undefined,
                () => {
                    loadCollabReport();
                }
            );
        } else {
            setDisplayMode(CollabReportDisplayMode.Readonly);
        }

        if (existingLeaseRef.current?.guid) {
            cancelLease(false);
        }
    };

    const handleCloseChatActs = () => {
        if (collaborationReport) {
            const collaborationReportCopy = cloneDeep(collaborationReport);
            collaborationReportCopy.baseDetails.loggedInUserHasOpenChatAct = false;
            setCollaborationReport(collaborationReportCopy);
        }
    };

    const handleFailedObtainedLease = (currentLease: IEntityLease) => {
        modal.openModal(CollabModalTypes.FailedToObtainLease, { currentLease });
    };

    const handleChatClick = () => {
        modal.openModal<IChatModalData, () => void>(CollabModalTypes.Chat, {
            alertGuid: collaborationReport?.baseDetails.alertGuid ?? '',
            showCloseChatActButton: collaborationReport?.baseDetails.loggedInUserHasOpenChatAct,
            onCloseChatActs: handleCloseChatActs,
            actionTargets:
                zoneUsers.data
                    ?.filter((reportUser) =>
                        collaborationReport?.collaborators
                            ?.map((collaborator) => collaborator.userGuid)
                            .includes(reportUser.guid)
                    )
                    .filter((reportUser) => reportUser.guid !== user.guid) ?? [],
            reportGuid: reportGuid ?? '',
            user,
        });
    };

    const handleCloseReviewActClick = async () => {
        if (!collaborationReport?.baseDetails.alertGuid) {
            setValidation((prev) => ({ ...prev, model: ['Failed to close Review Act'] }));
        }

        const model = {
            alertGuid: collaborationReport?.baseDetails.alertGuid,
            alertType: AlertTypesEnum.Act,
            alertSubType: AlertSubTypesEnum.Review,
            parentEntityGuid: reportGuid,
            parentEntityType: EntityTypes.Report,
        } as IUpdateAlertUserModel;

        loading.showLoading();

        await closeChatActMutation
            .mutateAsync(model)
            .then(() => {
                if (!collaborationReport) return;
                const collaborationReportCopy = cloneDeep(collaborationReport);
                collaborationReportCopy.baseDetails.loggedInUserHasOpenReviewAct = false;
                setCollaborationReport(collaborationReportCopy);
            })
            .catch(() => {
                setValidation((prev) => ({ ...prev, act: ['Failed to close Review Act'] }));
            })
            .finally(() => {
                loading.hideLoading();
            });
    };

    const handleCommentsClick = () => {
        props.onShowCommentsModal(true, 0);
    };

    const handleEditClick = async (onSuccess?: () => void) => {
        if (!reportGuid) return;

        const durationInMs = getLeaseDuration();

        obtainEntityLeaseMutation
            .mutateAsync({
                entityTypeId: EntityTypes.Report,
                entityGuid: reportGuid!,
                durationInMs,
            })
            .then((result) => {
                if (result?.success) {
                    setDisplayMode(CollabReportDisplayMode.Edit);
                    setExistingLease(result.currentLease);

                    if (typeof onSuccess === 'function') {
                        onSuccess();
                    }
                } else {
                    // setShowFailedToObtainLeaseDialog(result?.currentLease);
                }
            })
            .catch(() => {
                // todo: handle error
            });
    };

    const handleExportError = () => {
        // todo: any error handling
    };

    const handleHistoryClick = () => {
        modal.openModal(CollabModalTypes.History, { reportGuid });
    };

    const handlePreviewClick = () => {
        const newMode = isPreviewMode
            ? CollabReportDisplayMode.Readonly
            : CollabReportDisplayMode.Preview;

        setDisplayMode(newMode);
    };

    const handleRefreshClick = () => {
        if (props.getReportData) {
            props.getReportData();
        }
    };

    const handleSaveAsClick = () => {
        const handleSaveAsModalClose = () => {
            cancelLease(saveModalIsReadonly);
            modal.closeModal(CollabModalTypes.Save);
        };

        const config: ISaveReportModalData = {
            alertGuid: collaborationReport?.baseDetails.alertGuid ?? '',
            availableUsers: CheckmateSelectHelper.getUserOptions(zoneUsers.data ?? []),
            closeButtonText: displayMode === CollabReportDisplayMode.Create ? 'Cancel' : 'Close',
            disableReaders: userIsReader,
            executeSaveReport: saveReport,
            leaseDuration: getLeaseDuration(),
            onClose: handleSaveAsModalClose,
            onFailedToObtainLease: handleFailedObtainedLease,
            onLeaseObtained: (lease) => {
                setExistingLease(lease);
                setForceHideEditButton(true);
            },
            showEditButton: !existingLease && !isPublished,
            readonly: saveModalIsReadonly,
            report: collaborationReport?.baseDetails,
            reportCollaborators:
                collaborationReport?.collaborators ??
                (reportGuid
                    ? []
                    : [
                          {
                              reportGuid,
                              reportUserTypeId: ReportUserTypesEnum.Collaborator,
                              userGuid: user.guid,
                          } as IReportUserModel,
                      ]),
            reportReaders: collaborationReport?.readers ?? [],
            title: reportGuid ? 'Collab' : 'Save Report',
        };

        modal.openModal<ISaveReportModalData, () => void>(CollabModalTypes.Save, config);
    };

    const handleSaveClick = async () => {
        if (collaborationReport) {
            saveReport(
                collaborationReport.baseDetails,
                collaborationReport.collaborators ?? [],
                [],
                false
            )
                .then((payload) => {
                    if (payload) {
                        setCollaborationReport(payload);
                        setOriginalReportState({
                            collaborationReport: payload,
                            inputModel: props.inputModel,
                        });
                        setDisplayMode(CollabReportDisplayMode.Readonly);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setValidation((prev) => ({ ...prev, model: [error] }));
                });
        }
    };

    const handleSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const newSectionDetails = getSectionsAfterSectionCheckedChange(
            event,
            section,
            props.inputModel.reportSectionDetails ?? []
        );

        props.onInputModelChange({
            ...props.inputModel,
            reportSectionDetails: newSectionDetails,
        });
    };

    const handleSettingsClick = () => {
        props.onShowSettingsModal(true);
    };

    const bindSignalrEvents = () => {
        const handleSendNewOpenChatActReceived = (model: IOpenChatActModel) => {
            // console.log(`******* ${SignalRMethods.SendOpenChatActStatus} *******`, model);

            if (model.entityGuid === reportGuid && collaborationReport) {
                const collaborationReportCopy = cloneDeep(collaborationReport);
                collaborationReportCopy.baseDetails.loggedInUserHasOpenChatAct =
                    model.loggedInUserHasOpenChatAct;
                setCollaborationReport(collaborationReportCopy);
            }
        };

        if (alertHub !== undefined) {
            alertHub?.on(SignalRMethods.SendOpenChatActStatus, handleSendNewOpenChatActReceived);
            console.log(
                `SignalR - Alert Hub => Listening for ${SignalRMethods.SendOpenChatActStatus}`
            );
        }
    };

    const unbindSignalrEvents = () => {
        alertHub?.off(SignalRMethods.SendOpenChatActStatus);
        console.log(
            `SignalR - Alert Hub => Stopped listening for ${SignalRMethods.SendOpenChatActStatus}`
        );
    };

    const easyUpdateDone = async (updatedCase: ICaseModel) => {
        if (props.onEasyUpdateComplete) {
            props.onEasyUpdateComplete(updatedCase);
        }

        setCollabState((prev) => ({
            ...prev,
            easyUpdateMode: false,
            easyUpdateCaseGuid: undefined,
        }));
    };

    useEffect(() => {
        bindSignalrEvents();
        return unbindSignalrEvents;
    }, [alertHub]);

    useEffect(() => {
        if (reportGuid) {
            loadCollabReport();
        }
    }, [reportGuid]);

    useEffect(() => {
        props.onReportStatusChange(
            collaborationReport?.baseDetails.status ?? { id: ReportStatusTypesEnum.Draft }
        );
    }, [collaborationReport?.baseDetails.status]);

    useEffect(() => {
        existingLeaseRef.current = existingLease;
    }, [JSON.stringify(existingLease)]);

    useEffect(() => {
        return () => {
            if (existingLeaseRef.current) {
                const belongsToCurrentUser =
                    existingLeaseRef.current?.lesseeUser?.guid === user.guid;

                if (belongsToCurrentUser) {
                    releaseEntityLeaseMutation
                        .mutateAsync(existingLeaseRef.current?.guid)
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        };
    }, [existingLeaseRef.current]);

    const isDraftStatus =
        collaborationReport?.baseDetails.status?.id === ReportStatusTypesEnum.Draft;
    const isPublished =
        collaborationReport?.baseDetails.status?.id === ReportStatusTypesEnum.Published;
    const isFinalDraftStatus =
        collaborationReport?.baseDetails.status?.id === ReportStatusTypesEnum.FinalDraft;

    const isPreviewMode = displayMode === CollabReportDisplayMode.Preview || isPublished;
    const isReadonlyMode = displayMode === CollabReportDisplayMode.Readonly || isPreviewMode;

    const lastModifiedByCurrentUser =
        collaborationReport?.baseDetails.modifiedBy?.guid === user.guid;

    const saveModalIsReadonly =
        isReadonlyMode || (!!reportGuid && !isDraftStatus && !lastModifiedByCurrentUser);

    const userIsReader = !!collaborationReport?.readers?.find(
        (reader) => reader.userGuid === user.guid
    );

    let ReportRenderer;

    switch (props.reportTypeId) {
        case MyDocumentsTypesEnum.WeeklyRecap.Value:
            ReportRenderer = WeeklyRecapReportRenderer<
                IWeeklyRecapModel,
                ITimelineReportInputModel
            >;
            break;
        case MyDocumentsTypesEnum.MonthlyRecap.Value:
            ReportRenderer = MonthlyRecapReportRenderer<
                IMonthlyRecapModel,
                ITimelineReportInputModel
            >;
            break;
        case MyDocumentsTypesEnum.KpiReport.Value:
            ReportRenderer = KpiReportRenderer<IKpiReportModel, IKpiReportInputModel>;
            break;
        default:
            ReportRenderer = () => <div>No report renderer found for {props.reportTypeId}</div>;
    }

    if (userIsReader && !isPublished) {
        navigate(LocalRoutes.AccessDenied);
    }

    const isLoading =
        isPending ||
        orgSettings.isLoading ||
        zoneUsers.isLoading ||
        noteCategoryTypes.isLoading ||
        getIsEntityLeaseAvailable.isLoading ||
        appSettings.isLoading;

    return (
        <>
            <CaseEasyUpdate
                user={user}
                caseGuid={collabState.easyUpdateCaseGuid}
                onComplete={easyUpdateDone}
                onCancel={() => {
                    setCollabState((prev) => ({
                        ...prev,
                        easyUpdateMode: false,
                        easyUpdateCaseGuid: undefined,
                    }));
                }}
                hidden={!collabState.easyUpdateMode}
            />
            <UnsavedChangesBlocker when={hasUnsavedChanges} />
            <DocumentTitle title={props.pageTitle} />
            {isLoading && <Loader />}
            {!collabState.easyUpdateMode && (
                <PageWithAffixedHeader
                    headerComponent={
                        <>
                            <CollabReportPageHeader
                                title={props.pageTitle}
                                actionsComponent={
                                    <CollabReportPageActions
                                        enableCollab={enableCollab}
                                        exportSettings={props.exportSettings}
                                        forceHideEditButton={forceHideEditButton}
                                        hasChatActs={
                                            collaborationReport?.baseDetails
                                                .loggedInUserHasOpenChatAct ?? false
                                        }
                                        hasUnsavedChanges={hasUnsavedChanges}
                                        isClosingReviewAct={closeChatActMutation.isLoading}
                                        isPreviewMode={isPreviewMode}
                                        onCancelClick={handleCancelClick}
                                        onChatClick={handleChatClick}
                                        onCloseReviewActClick={handleCloseReviewActClick}
                                        onCommentsClick={handleCommentsClick}
                                        onEditClick={handleEditClick}
                                        onExportError={handleExportError}
                                        onHistoryClick={handleHistoryClick}
                                        onPreviewClick={handlePreviewClick}
                                        onRefreshClick={handleRefreshClick}
                                        onSaveAsClick={handleSaveAsClick}
                                        onSaveClick={handleSaveClick}
                                        onSettingsClick={handleSettingsClick}
                                        pageActionsDisplayType={getPageActionsDisplayType()}
                                        reportStatus={collaborationReport?.baseDetails.status}
                                        reportTypeId={props.reportTypeId}
                                        userIsReader={userIsReader}
                                    />
                                }
                                inputModel={props.inputModel}
                                isCollabReport={!!reportGuid}
                                isInADraftStatus={isDraftStatus || isFinalDraftStatus}
                                report={collaborationReport?.baseDetails}
                            />
                            {validation.model && (
                                <div>
                                    <span className="text-danger">{validation.model}</span>
                                </div>
                            )}
                        </>
                    }
                >
                    <>
                        <ReportRenderer
                            collabReportState={collabState}
                            data={props.data as any}
                            inputModel={props.inputModel as any}
                            isPreviewMode={isPreviewMode}
                            isReportReadonly={isReadonlyMode}
                            onEventTriggered={handleReportViewEventTriggered}
                            onSectionCheckedChange={handleSectionCheckedChange}
                            reportGuid={reportGuid}
                            setCollabState={setCollabState}
                            title={props.pageTitle}
                        />
                    </>
                </PageWithAffixedHeader>
            )}
        </>
    );
}

export default CollabReportController;
