import { AlertSubTypesEnum, AlertTypesEnum, EntityTypes } from '../../../../utilities/Constants';
import {
    IEntityConversationCreateModel,
    IEntityConversationModel,
    IUpdateAlertUserModel,
} from '../../../../interfaces/Report/IReport';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ChatMessage } from '../../../../components/weekly-recap/ChatMessage';
import CheckmateSelect from '../../../../components/shared/CheckmateSelect';
import { ConfirmationDialog } from '../../../../components/shared/ConfirmationDialog';
import { ICollabModalProps } from './CollabModalContext';
import { IMultiSelectOptions } from '../../../../interfaces/ILookup';
import { IUserModel } from '../../../../interfaces/IUser';
import { IValidation } from '../../../../interfaces/IError';
import { ResizableDialog } from '../../../../components/shared/ResizableDialog';
import { SignalRMethods } from '../../../../utilities/SignalR';
import Sort from '../../../../stores/Sort';
import { Stack } from 'react-bootstrap';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { useCloseAlertMutation } from '../../../../components/weekly-recap/useCloseAlertMutation';
import { useCreateEntityConversationMutation } from '../../../../shared/react-query-hooks/mutations/useCreateEntityConversationMutation';
import { useGetEntityConversations } from '../../../../shared/react-query-hooks/useGetEntityConversations';
import { useLoading } from '../../../../contexts/LoadingContext';
import { useSignalRContext } from '../../../../contexts/SignalRContext';

export interface IChatModalData {
    alertGuid?: string;
    actionTargets: IUserModel[];
    showCloseChatActButton: boolean | undefined;
    onCloseChatActs: () => void;
    reportGuid: string;
    user: IUserModel;
}

export const ReportChatModal: React.FC<ICollabModalProps<IChatModalData, () => void>> = (props) => {
    if (!props.open || !props.data) return null;

    const { alertHub } = useSignalRContext();

    const entityConversations = useGetEntityConversations(
        EntityTypes.Report,
        props.data.reportGuid
    );

    const loading = useLoading();

    const createMutation = useCreateEntityConversationMutation();
    const closeChatActMutation = useCloseAlertMutation();

    const [actionTargetGuids, setActionTargetGuids] = useState<string[]>([]);
    const [localMessages, setLocalMessages] = useState<IEntityConversationModel[]>([]);
    const [localHasOpenChatAct, setLocalHasOpenChatAct] = useState<boolean>(
        props.data.showCloseChatActButton ?? false
    );
    const [messageText, setMessageText] = useState<string>('');
    const [isSending, setIsSending] = useState<boolean>(false);
    const [optimisticSavedGuids, setOptimisticSavedGuids] = useState<string[]>([]);
    const [saveErrorGuids, setSaveErrorGuids] = useState<string[]>([]);
    const [showChatActConfirmationDialog, setShowChatActConfirmationDialog] =
        useState<boolean>(false);
    const [validation, setValidation] = useState<IValidation>({});

    const handleActionTargetsChange = (optionsList: IMultiSelectOptions[]) => {
        setActionTargetGuids(optionsList.map((opt: IMultiSelectOptions) => opt.guid ?? ''));
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessageText(e.target.value);
    };

    const handleSendClick = () => {
        const tempGuid = uniqueId();
        const newMessage: IEntityConversationModel = {
            actionTargets:
                props.data?.actionTargets.filter((user) =>
                    actionTargetGuids.includes(user.guid ?? '')
                ) ?? [],
            author: props.data?.user ?? ({} as IUserModel),
            dateUTC: new Date().toUTCString(),
            entityGuid: props.data?.reportGuid ?? '',
            entityTypeId: EntityTypes.Report,
            guid: tempGuid,
            text: messageText,
        };

        setOptimisticSavedGuids((prev) => [...prev, tempGuid]);

        const createModel: IEntityConversationCreateModel = {
            entityTypeId: EntityTypes.Report,

            entityGuid: props.data?.reportGuid ?? '',
            actionTargets:
                props.data?.actionTargets
                    .filter((user) => actionTargetGuids.includes(user.guid ?? ''))
                    .map((at) => at.guid!) ?? [],
            message: messageText,
            timestampUTC: new Date().toUTCString(),
        };

        setIsSending(true);
        setMessageText('');
        setActionTargetGuids([]);

        const onSendSuccess = (newConvo: IEntityConversationModel | undefined) => {
            if (newConvo) {
                setIsSending(false);

                setLocalMessages((prev) => {
                    const newConvos = [...prev];
                    const foundIdx = prev.findIndex((msg) => msg.guid === tempGuid);

                    if (foundIdx > -1) {
                        newConvos.splice(foundIdx, 1, newConvo);
                    }

                    return newConvos;
                });
            }
        };

        createMutation
            .mutateAsync(createModel)
            .then(onSendSuccess)
            .catch((error) => {
                console.log(error);
                setSaveErrorGuids((prev) => [...prev, newMessage?.guid ?? '']);
            })
            .finally(() => {
                setOptimisticSavedGuids((prev) => prev.filter((guid) => guid !== tempGuid));
                setIsSending(false);
            });

        setLocalMessages((prev) => [...prev, newMessage]);
    };

    const handleChatConfirmationsDialogSubmit = async () => {
        if (!props.data?.alertGuid) {
            setValidation((prev) => ({ ...prev, act: ['Failed to close Chat Act'] }));
        }

        const model = {
            alertGuid: props.data?.alertGuid,
            alertType: AlertTypesEnum.Act,
            alertSubType: AlertSubTypesEnum.Chat,
            parentEntityGuid: props.data?.reportGuid,
            parentEntityType: EntityTypes.Report,
        } as IUpdateAlertUserModel;

        loading.showLoading();

        await closeChatActMutation
            .mutateAsync(model)
            .then(() => {
                setShowChatActConfirmationDialog(false);
                setLocalHasOpenChatAct(false);
                props.onClose();
                props.data?.onCloseChatActs();
            })
            .catch(() => {
                setValidation((prev) => ({ ...prev, act: ['Failed to close Chat Act'] }));
                setShowChatActConfirmationDialog(false);
            })
            .finally(() => {
                loading.hideLoading();
            });
    };

    const handleChatActClick = () => {
        setValidation((prev) => ({ ...prev, act: [] }));
        setShowChatActConfirmationDialog(true);
    };

    // todo jec: pass this in from caller
    const leftFooterComponent = (
        <Stack direction="horizontal" gap={2}>
            <button className="btn btn-no-bg font-orange" onClick={handleChatActClick}>
                <i className="fal fa-check" />
                &nbsp;Chat Act
            </button>
            {(validation.act?.length ?? 0) > 0 && (
                <Stack
                    direction="horizontal"
                    gap={1}
                    className="text-danger"
                    style={{ fontSize: '0.9em' }}
                >
                    <i className="fal fa-exclamation-triangle" />
                    <span>{validation.act}</span>
                </Stack>
            )}
        </Stack>
    );

    useEffect(() => {
        if (entityConversations.isSuccess) {
            setLocalMessages(entityConversations.data ?? []);
        }
    }, [entityConversations.isSuccess, entityConversations.data]);

    useEffect(() => {
        entityConversations.refetch();
    }, []);

    const bindSignalrEvents = () => {
        const handleSendChatMessageReceived = (convo: IEntityConversationModel) => {
            // console.log(`******* ${SignalRMethods.SendChatMessage} *******`, convo);

            if (convo.entityGuid === props.data?.reportGuid) {
                setLocalMessages((prev) => [...prev, convo]);
            }
        };

        if (alertHub !== undefined) {
            console.log(`SignalR - Alert Hub => Listening for ${SignalRMethods.SendChatMessage}`);
            alertHub?.on(SignalRMethods.SendChatMessage, handleSendChatMessageReceived);
        }
    };

    const unbindSignalrEvents = () => {
        console.log(
            `SignalR - Alert Hub => Stopped listening for ${SignalRMethods.SendChatMessage}`
        );
        alertHub?.off(SignalRMethods.SendChatMessage);
    };

    useEffect(() => {
        bindSignalrEvents();
        return unbindSignalrEvents;
    }, [alertHub]);

    const actionTargetOptions = useMemo(
        () =>
            props.data?.actionTargets
                .filter((user) => user.guid !== props.data?.user.guid)
                .map(
                    (user) =>
                        ({
                            label: `${user.profile?.firstName} ${user.profile?.lastName}`,
                            value: user.guid,
                            guid: user.guid,
                        } as IMultiSelectOptions)
                )
                .sort((a, b) => (a.label < b.label ? -1 : 1)),
        [props.data?.actionTargets, props.data?.user]
    );

    return (
        <>
            <ResizableDialog
                isShowingModal
                title="Chat"
                defaultHeight={500}
                defaultWidth={500}
                minWidth={350}
                bodyComponent={
                    <ChatBodyComponent
                        availableActionTargets={actionTargetOptions ?? []}
                        conversations={localMessages}
                        currentUserGuid={props.data.user.guid!}
                        isSending={isSending}
                        isLoading={entityConversations.isLoading}
                        isLoadingError={entityConversations.isError}
                        loadingError={entityConversations.error}
                        message={messageText}
                        onChangeActionTargets={handleActionTargetsChange}
                        onMessageChange={handleMessageChange}
                        onSendClick={handleSendClick}
                        optimisticSavedGuids={optimisticSavedGuids}
                        saveErrorGuids={saveErrorGuids}
                        selectedActionTargetIds={actionTargetGuids}
                        validation={{}}
                    />
                }
                cancelText="Close"
                handleClose={props.onClose}
                closeButtonClassName="btn btn-default"
                leftFooterComponent={
                    localHasOpenChatAct && props.data.alertGuid && leftFooterComponent
                }
                draggable
            />
            <ConfirmationDialog
                bodyComponent={<div>Are you sure you want to close all of your Chat Acts?</div>}
                cancelText="Cancel"
                onSubmit={handleChatConfirmationsDialogSubmit}
                onCancel={() => setShowChatActConfirmationDialog(false)}
                onClose={() => setShowChatActConfirmationDialog(false)}
                open={showChatActConfirmationDialog}
                submitText="Yes"
                title="Close Your Chat Acts"
            />
        </>
    );
};

interface IChatBodyComponentProps {
    availableActionTargets: IMultiSelectOptions[];
    conversations: IEntityConversationModel[];
    currentUserGuid: string;
    isLoading: boolean;
    isLoadingError: boolean;
    isSending: boolean;
    loadingError: unknown;
    message: string;
    onMessageChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSendClick: () => void;
    onChangeActionTargets: (optionsList: any) => void;
    optimisticSavedGuids: string[];
    saveErrorGuids: string[];
    selectedActionTargetIds: string[];
    validation?: IValidation;
}

function ChatBodyComponent(props: IChatBodyComponentProps) {
    const messagesEndRef = useRef<HTMLDivElement>(null);

    const selectedActionTargets = props.availableActionTargets.filter((actionTarget) =>
        props.selectedActionTargetIds.includes(actionTarget.guid ?? '')
    );

    const scrollToBottom = (behavior = 'smooth') => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: behavior as ScrollBehavior });
        }
    };

    useEffect(() => {
        if (!props.isLoading) {
            scrollToBottom('instant');
        }
    }, [JSON.stringify(props.conversations), props.isLoading]);

    const messagesStyle: React.CSSProperties = {
        borderBottom: 'none',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        padding: '10px',
    };

    const disableSendButton = props.message.length === 0;
    const sendButtonClass = classNames('fa-lg fal fa-share-all', {
        'font-orange': !disableSendButton,
    });

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'auto',
                padding: '2px',
            }}
        >
            <div className="mt-2 mb-1">
                <CheckmateSelect
                    isDisabled={props.isLoading}
                    isMulti
                    onChange={props.onChangeActionTargets}
                    options={props.availableActionTargets}
                    value={selectedActionTargets}
                />
            </div>
            <div className="border-gray" style={messagesStyle}>
                {props.isLoading && <>Loading messages...</>}
                {props.isLoadingError && (
                    <span className="text-danger">Failed to load messages.</span>
                )}
                {props.conversations
                    .sort(Sort.compareDate('dateUTC', undefined, 'asc'))
                    .map((item: IEntityConversationModel, i: number) => (
                        <ChatMessage
                            key={i}
                            currentUserGuid={props.currentUserGuid}
                            message={item}
                            temporary={props.optimisticSavedGuids.includes(item?.guid ?? '')}
                            actionTargets={props.availableActionTargets}
                            hasError={props.saveErrorGuids.includes(item?.guid ?? '')}
                        />
                    ))}
                <div ref={messagesEndRef} />
            </div>
            <div className="border-gray" style={{ display: 'flex' }}>
                <textarea
                    className="form-control fill-light-gray only-right-border"
                    disabled={props.isLoading}
                    maxLength={30000}
                    onChange={props.onMessageChange}
                    placeholder="type text here..."
                    rows={4}
                    value={props.message}
                    style={{ opacity: props.isLoading ? 0.6 : 1 }}
                />
                <button
                    className="btn btn-no-bg"
                    onClick={props.onSendClick}
                    disabled={disableSendButton}
                    style={{ width: 50 }}
                >
                    <i className={sendButtonClass} />
                </button>
            </div>
            <div className="text-danger">{props.validation?.actionTargets}</div>
        </div>
    );
}
