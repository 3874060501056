import { Accordion } from 'react-bootstrap';
import { CheckmateDialog } from '../../../components/shared/dialog';
import { ILookupModel } from '../../../interfaces/ILookup';
import { ResizableDialog } from '../../../components/shared/ResizableDialog';
import { cloneDeep } from 'lodash';
import { useState } from 'react';

export interface ISectionCommentEntry {
    section: number;
    comment: string | undefined;
}

export interface ICommentsDialogProps {
    defaultSection: number | null;
    disabled?: boolean;
    onSaveClick: (sectionDetails: ISectionCommentEntry[]) => void;
    open: boolean;
    sectionCommentMap: ISectionCommentEntry[];
    sections: ILookupModel[];
}

export function CommentsDialog(props: ICommentsDialogProps) {
    if (!props.open) return null;

    const [localReportSectionMapping, setLocalReportSectionMapping] = useState<
        ISectionCommentEntry[]
    >(props.sectionCommentMap);

    const [showClearConfirmation, setShowClearConfirmation] = useState<boolean>(false);

    const handleCommentsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        reportSection: ILookupModel
    ) => {
        const { value: comment } = event.target;
        const localReportSectionDetailsCopy = cloneDeep(localReportSectionMapping) ?? [];

        const match = localReportSectionDetailsCopy.find(
            (sectionDetail) => sectionDetail.section === reportSection.id
        );

        if (match) {
            match.comment = comment;
        } else {
            localReportSectionDetailsCopy.push({
                section: reportSection.id,
                comment: comment,
            });
        }

        setLocalReportSectionMapping(localReportSectionDetailsCopy);
    };

    const handleSaveClick = () => {
        props.onSaveClick(localReportSectionMapping);
    };

    const handleClearClick = () => {
        setShowClearConfirmation(true);
    };

    const handleClearConfirmationClick = () => {
        setShowClearConfirmation(false);

        const mapCopy = cloneDeep(localReportSectionMapping) ?? [];
        mapCopy.forEach((section) => {
            section.comment = '';
        });
        setLocalReportSectionMapping(mapCopy);
    };

    const getCommentText = (reportSection: number) => {
        const sectionMatch = localReportSectionMapping.find((x) => x.section === reportSection);
        if (sectionMatch) return sectionMatch.comment;

        return '';
    };

    return (
        <>
            <ResizableDialog
                isShowingModal={props.open}
                title="Comments"
                defaultWidth={500}
                minHeight={300}
                minWidth={350}
                bodyComponent={
                    <Accordion defaultActiveKey={props.defaultSection?.toString()}>
                        {props.sections.map((section: ILookupModel) => {
                            return (
                                <Accordion.Item key={section.id} eventKey={section.id.toString()}>
                                    <Accordion.Header>
                                        {section.displayName}
                                        {getCommentText(section.id) && (
                                            <i className="fal fa-comment-lines ps-2" />
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0 pb-3">
                                        <textarea
                                            className="form-control"
                                            value={getCommentText(section.id)}
                                            rows={5}
                                            onChange={(
                                                e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => {
                                                handleCommentsChange(e, section);
                                            }}
                                            disabled={props.disabled}
                                        />
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                    </Accordion>
                }
                confirmText={'Clear'}
                handleConfirm={props.disabled ? undefined : handleClearClick}
                confirmButtonClassName="btn btn-default float-end"
                cancelText={props.disabled ? 'Close' : 'Done'}
                handleClose={handleSaveClick}
                closeButtonClassName={
                    props.disabled ? 'btn btn-default float-end' : 'btn btn-orange float-end'
                }
                draggable
            />
            <CheckmateDialog
                isShowingModal={showClearConfirmation || false}
                body="Are you sure you want to clear all comments?"
                handleClose={() => {
                    setShowClearConfirmation(false);
                }}
                handleConfirm={handleClearConfirmationClick}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end"
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />
        </>
    );
}
