import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    AssertionDescriptors,
    CaseDetailOperation,
    CaseFileStaticItems,
    CustomLogicAssertionDescriptors,
    DataScopesEnum,
    DisplayItemTypeEnum,
    EntityTypes,
    FavoriteTypesEnum,
    LookupDataEnums,
    NotePurposeTypesEnum,
    NoteStatusTypes,
    NoteTypes,
    OrganizationTypesEnum,
    SortColumns,
    SortDirections,
    TutorialTopics,
    UserRightsEnum,
} from '../../utilities/Constants';
import {
    ICaseAssertionModel,
    ICaseModel,
    ICaseSearchModel,
    ICaseStatusLookupModel,
} from '../../interfaces/ICase';
import {
    ICaseExpertModel,
    ICaseExpertViewDetailModel,
    IViewCaseExpertModelWithDefaultEdit,
} from '../../interfaces/ICaseExpert';
import {
    ILookupModel,
    IMultiSelectOptions,
    IStaticItemLookupModel,
} from '../../interfaces/ILookup';
import { INoteCategoryTypeModel, INoteModel } from '../../interfaces/INote';
import { Link, NavigateFunction } from 'react-router-dom';
import { cloneDeep, uniqueId } from 'lodash';

import ApiClient from '../../services/apiClient';
import Authorization from '../../stores/Authorization';
import CaseDataCustomLogicHelper from '../../utilities/CaseDataCustomLogicHelper';
import { CaseDepositionListWrapper } from '../../components/case/static-sections/CaseDepositionListWrapper';
import { CaseExpertEditor } from '../../components/case/static-sections/case-experts/CaseExpertEditor';
import { CaseExpertsList } from '../../components/case/static-sections/case-experts/CaseExpertsList';
import CaseFileCheckmateSelectHelper from '../../utilities/CaseFileCheckmateSelectHelper';
import CaseHelper from '../../utilities/CaseHelper';
import { CaseName } from '../../components/case/CaseName';
import { CheckmateDialog } from '../../components/shared/dialog';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import CheckmateSelectHelper from '../../utilities/CheckmateSelectHelper';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { Favorite } from '../../components/shared/Favorite';
import { Help } from '../../components/shared/Help';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IOrganizationSettingModel } from '../../interfaces/IOrganization';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Modal } from 'react-bootstrap';
import { NoteEditor } from '../../components/notes/NoteEditor';
import NoteHelper from '../../utilities/NoteHelper';
import { NoteList } from '../../components/notes/NoteList';
import Sort from '../../stores/Sort';
import UIHelper from '../../utilities/UIHelper';
import ValidateUtils from '../../shared/validations';
import { WholeNumberInput } from '../../shared/WholeNumberInput';

const _apiClient = new ApiClient();
const PageSize = 10;

export interface ICaseEasyUpdateProps {
    additionalHeaderText?: string;
    caseGuid?: string;
    documentTitle?: string;
    excludeNotes?: boolean;
    hidden?: boolean;
    onCancel?: (hadChanges: boolean) => void;
    onComplete?: (updatedCase: ICaseModel) => void;
    navigate?: NavigateFunction;
    refreshIdentifier?: string;
    user: IUserModel;
}

interface ICaseEasyUpdateState {
    addNewCase: boolean;
    allDefenseCounselsForZone: ILookupModel[];
    allUsersInZone: IUserModel[];
    assertionDescriptors: IAssertionDescriptorModel[];
    caseFieldsAll: IMultiSelectOptions[];
    caseGuid?: string;
    caseManagers: ILookupModel[];
    caseSearchResults: ICaseModel[];
    caseSearchString?: string;
    caseStatuses: ICaseStatusLookupModel[];
    currentCase: ICaseModel;
    currentCaseExpert?: IViewCaseExpertModelWithDefaultEdit | null;
    currentFavoriteDetailsString: string;
    currentNarrativeField?: IMultiSelectOptions;
    currentNarrativeFieldText?: string;
    currentNote?: INoteModel;
    currentPageNumber: number;
    depositionCategories: ILookupModel[];
    depositionStatusTypes: ILookupModel[];
    easyUpdateFieldsSelected: IMultiSelectOptions[];
    forceNoteEditorInReadOnlyMode?: boolean;
    hasChanges: boolean;
    noteAdded: boolean;
    noteCategoryTypes: INoteCategoryTypeModel[];
    openNoteEditor: boolean;
    organizationSettings: IOrganizationSettingModel[];
    originalCase: ICaseModel;
    pendingResponse: boolean;
    selectedNoteCategories: IMultiSelectOptions[];
    selectedQueryFavoriteId?: number;
    showCaseSearchResults: boolean;
    showConfirmRemoveDialog: boolean;
    showDepoSection: boolean;
    showExpertsSection: boolean;
    showNotesSection: boolean;
    showQueryFavoritesDialog?: boolean;
    showSuccessDialog: boolean;
    source?: string;
    staticItems: IStaticItemLookupModel[];
    systemGeneratedIds: string[];
    totalCaseCount: number;
    userDefaultSettingsJson?: string;
    validation: IValidation;
}

export class CaseEasyUpdate extends React.Component<ICaseEasyUpdateProps, ICaseEasyUpdateState> {
    constructor(props: ICaseEasyUpdateProps) {
        super(props);

        this.state = {
            addNewCase: false,
            allDefenseCounselsForZone: [],
            allUsersInZone: [],
            assertionDescriptors: [],
            systemGeneratedIds: [],
            caseFieldsAll: [],
            caseManagers: [],
            caseSearchResults: [],
            caseStatuses: [],
            currentCase: {
                caseAssertions: [],
            },
            currentFavoriteDetailsString: '',
            currentPageNumber: 0,
            depositionCategories: [],
            depositionStatusTypes: [],
            easyUpdateFieldsSelected: [],
            hasChanges: false,
            noteAdded: false,
            noteCategoryTypes: [],
            openNoteEditor: false,
            organizationSettings: [],
            originalCase: {},
            pendingResponse: false,
            selectedNoteCategories: [],
            showCaseSearchResults: false,
            showConfirmRemoveDialog: false,
            showDepoSection: false,
            showExpertsSection: false,
            showNotesSection: false,
            showSuccessDialog: false,
            staticItems: [],
            totalCaseCount: 0,
            validation: {},
        };

        this.onSearchableSingleSelectChange = this.onSearchableSingleSelectChange.bind(this);
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, this.props.user)) {
            window.location.assign(LocalRoutes.AccessDenied);
        }
        this.loadInitialData();
    }

    componentDidUpdate(prevProps: ICaseEasyUpdateProps) {
        if (
            prevProps.refreshIdentifier !== this.props.refreshIdentifier &&
            this.props.refreshIdentifier
        ) {
            this.resetAllState();
        } else {
            if (prevProps.caseGuid !== this.props.caseGuid) {
                if (this.props.caseGuid) {
                    this.loadCaseData(this.props.caseGuid);
                } else {
                    this.setState({
                        originalCase: {},
                        currentCase: { caseAssertions: [] },
                        caseGuid: this.props.caseGuid,
                    });
                }
            }

            if (prevProps.excludeNotes !== this.props.excludeNotes) {
                const caseFieldsAll = cloneDeep(this.state.caseFieldsAll);
                const easyUpdateFieldsSelected = cloneDeep(this.state.easyUpdateFieldsSelected);

                const caseFieldsNotesSectionIndex = caseFieldsAll.findIndex(
                    (x) => x.id === CaseFileStaticItems.NotesSection.Id
                );

                const easyUpdateFieldsHasNotesSectionIndex = easyUpdateFieldsSelected.findIndex(
                    (x) => x.id === CaseFileStaticItems.NotesSection.Id
                );

                if (this.props.excludeNotes) {
                    if (caseFieldsNotesSectionIndex > -1) {
                        caseFieldsAll.splice(caseFieldsNotesSectionIndex, 1);
                    }

                    if (easyUpdateFieldsHasNotesSectionIndex > -1) {
                        easyUpdateFieldsSelected.splice(easyUpdateFieldsHasNotesSectionIndex, 1);
                    }
                } else if (caseFieldsNotesSectionIndex === -1) {
                    const caseNotesField = {
                        id: CaseFileStaticItems.NotesSection.Id,
                        label: CaseHelper.getCaseFileStaticItemDisplayName(
                            this.state.staticItems,
                            CaseFileStaticItems.NotesSection.Id
                        ),
                        value: UIHelper.getName(
                            this.state.staticItems,
                            CaseFileStaticItems.NotesSection.Id
                        ),
                    };

                    caseFieldsAll.push(caseNotesField);
                }

                this.setState({
                    caseFieldsAll,
                    easyUpdateFieldsSelected,
                });
            }
        }

        if (prevProps.hidden !== this.props.hidden) {
            this.setState({ validation: {} });
        }
    }

    resetAllState = () => {
        this.setState(
            {
                pendingResponse: false,
                caseFieldsAll: [],
                easyUpdateFieldsSelected: [],
                caseStatuses: [],
                caseManagers: [],
                allUsersInZone: [],
                allDefenseCounselsForZone: [],
                assertionDescriptors: [],
                validation: {},
                showCaseSearchResults: false,
                addNewCase: false,
                caseSearchResults: [],
                totalCaseCount: 0,
                currentPageNumber: 0,
                originalCase: {},
                currentCase: { caseAssertions: [] },
                showNotesSection: false,
                openNoteEditor: false,
                noteAdded: false,
                noteCategoryTypes: [],
                selectedNoteCategories: [],
                showConfirmRemoveDialog: false,
                showSuccessDialog: false,
                organizationSettings: [],
                depositionCategories: [],
                depositionStatusTypes: [],
                showDepoSection: false,
                showExpertsSection: false,
                currentFavoriteDetailsString: '',
                staticItems: [],
            },
            this.loadInitialData
        );
    };

    loadInitialData = async () => {
        this.setState({
            pendingResponse: true,
        });

        const caseStatuses = await this.fetchCaseStatuses();
        if (!caseStatuses) return;

        const caseManagers = await this.fetchCaseManagers();
        if (!caseManagers) return;

        const caseManagerDropdownValuesAll: ILookupModel[] = [];
        for (let i = 0; i < caseManagers.length; i++) {
            caseManagerDropdownValuesAll.push({
                displayName:
                    caseManagers[i].profile!.firstName +
                    ' ' +
                    caseManagers[i].profile!.lastName +
                    (caseManagers[i].activeZoneDataScope &&
                    caseManagers[i].activeZoneDataScope!.id === DataScopesEnum.LocalBasic.Value
                        ? ' (Local)'
                        : ''),
                name: caseManagers[i].profile!.firstName + ' ' + caseManagers[i].profile!.lastName,
                guid: caseManagers[i].guid!,
                id: 0,
            });
        }

        const allUsersInZone = await this.fetchAllUsersInZone();
        if (!allUsersInZone) return;

        const defenseCounsels = await this.fetchAllDefenseCounselsForZone();
        if (!defenseCounsels) return;

        const caseFileStaticItems = await this.fetchCaseFileStaticItems();
        if (!caseFileStaticItems) return;

        const defenseCounselDropdownValuesAll: ILookupModel[] = [];
        for (let i = 0; i < defenseCounsels.length; i++) {
            defenseCounselDropdownValuesAll.push({
                displayName: defenseCounsels[i].name!,
                name: defenseCounsels[i].name!,
                id: 0,
                guid: defenseCounsels[i].guid!,
            });
        }

        const assertionDescriptors = await this.fetchAssertionDescriptors();
        if (!assertionDescriptors) return;

        // Build Query Criteria List
        let queryCriteriaList: IMultiSelectOptions[] = [];

        if (
            Authorization.userHasRight(UserRightsEnum.EditCaseFile, this.props.user) &&
            caseFileStaticItems.length > 0
        ) {
            const staticItemFilter = (x: IStaticItemLookupModel) =>
                x.id !== CaseFileStaticItems.NotesSection.Id &&
                x.id !== CaseFileStaticItems.DepositionSection.Id &&
                x.id !== CaseFileStaticItems.ExpertsSection.Id &&
                x.id !== CaseFileStaticItems.DefenseCounselSection.Id &&
                x.displayItemTypeId != DisplayItemTypeEnum.ReadOnlySingleField;

            queryCriteriaList = caseFileStaticItems
                .filter(staticItemFilter)
                .map((item: ILookupModel) => ({
                    id: item.id,
                    label: item.alternateDisplayName || item.displayName || item.name || '',
                    value: item.name || '',
                }));
        }

        if (!this.props.excludeNotes) {
            queryCriteriaList.push({
                id: CaseFileStaticItems.NotesSection.Id,
                label: CaseHelper.getCaseFileStaticItemDisplayName(
                    caseFileStaticItems,
                    CaseFileStaticItems.NotesSection.Id
                ),
                value: UIHelper.getName(caseFileStaticItems, CaseFileStaticItems.NotesSection.Id),
            });
        }

        if (
            Authorization.userHasRight(UserRightsEnum.AddDeposition, this.props.user) ||
            Authorization.userHasRight(UserRightsEnum.EditDeposition, this.props.user) ||
            Authorization.userHasRight(UserRightsEnum.DeleteDeposition, this.props.user)
        ) {
            queryCriteriaList.push({
                id: CaseFileStaticItems.DepositionSection.Id,
                label: CaseHelper.getCaseFileStaticItemDisplayName(
                    caseFileStaticItems,
                    CaseFileStaticItems.DepositionSection.Id
                ),
                value: UIHelper.getName(
                    caseFileStaticItems,
                    CaseFileStaticItems.DepositionSection.Id
                ),
            });
        }

        if (Authorization.userHasRight(UserRightsEnum.ViewExpert, this.props.user)) {
            queryCriteriaList.push({
                id: CaseFileStaticItems.ExpertsSection.Id,
                label: UIHelper.getDisplayName(
                    caseFileStaticItems,
                    CaseFileStaticItems.ExpertsSection.Id
                ),
                value: UIHelper.getName(caseFileStaticItems, CaseFileStaticItems.ExpertsSection.Id),
            });
        }

        assertionDescriptors
            .filter((x) => x.usageType && x.usageType.id === AssertionDescriptorUsageTypesEnum.Case)
            .forEach((item: IAssertionDescriptorModel) => {
                let exclude = false;
                const match = CustomLogicAssertionDescriptors.find(
                    (x) => x.Name.toUpperCase() == item.name!.toUpperCase()
                );

                if (match && match.ReadOnly) {
                    exclude = true;
                }

                if (!exclude) {
                    queryCriteriaList.push({
                        id: 0,
                        label: item.alternateDisplayName || item.displayName!,
                        value: item.name!,
                        guid: item.guid,
                    });
                }
            });

        queryCriteriaList = queryCriteriaList.sort(Sort.compareValues('label'));
        const noteCategoryTypes = await this.loadNoteCategoryTypes();
        if (!noteCategoryTypes) return;

        const depositionCategories = await this.loadDepositionCategories();
        if (!depositionCategories) return;

        const depositionStatusTypes = await this.loadDepositionStatuses();
        if (!depositionStatusTypes) return;

        this.setState(
            {
                caseFieldsAll: queryCriteriaList,
                caseStatuses,
                caseManagers: caseManagerDropdownValuesAll,
                allUsersInZone,
                allDefenseCounselsForZone: defenseCounselDropdownValuesAll,
                assertionDescriptors,
                noteCategoryTypes,
                depositionCategories,
                depositionStatusTypes,
                pendingResponse: false,
                staticItems: caseFileStaticItems,
            },
            this.getOrganizationSettings
        );
    };

    loadCaseData = async (caseGuid: string) => {
        this.setState({
            pendingResponse: true,
        });

        let caseDetails: ICaseModel = {};
        let originalCaseDetails: ICaseModel = {};
        const caseAPIResponse = await this.getCaseDetailsById(caseGuid);
        if (!caseAPIResponse) {
            const validation: IValidation = {};
            validation.model = [DisplayMessages.UnexpectedError];
            this.setState({ pendingResponse: false, validation: validation });
        }

        CaseDataCustomLogicHelper.Run(caseAPIResponse!, this.state.assertionDescriptors);

        originalCaseDetails = JSON.parse(JSON.stringify(caseAPIResponse));
        caseDetails = JSON.parse(JSON.stringify(caseAPIResponse));

        // CHECK-1732 - remove the fields that were previously system generated
        const filteredSelectedFields = this.state.easyUpdateFieldsSelected.filter(
            (field) => !this.state.systemGeneratedIds.includes(field.guid ?? '')
        );

        const updatedEasyUpdateFieldsSelected = this.getUpdatedEasyUpdateFields(
            caseDetails,
            filteredSelectedFields
        );

        this.setState({
            originalCase: originalCaseDetails,
            currentCase: caseDetails,
            caseGuid: caseGuid,
            easyUpdateFieldsSelected: updatedEasyUpdateFieldsSelected,
            pendingResponse: false,
        });
    };

    loadDepositionCategories = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.DepositionCategories);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    loadDepositionStatuses = async () => {
        const response = await _apiClient.getLookupData(LookupDataEnums.DepositionStatusTypes);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchCaseFileStaticItems = async () => {
        const response = await _apiClient.getCaseFileStaticItems();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            return;
        }
        return response.payload;
    };

    refreshCaseCount = async () => {
        this.setState({ pendingResponse: true });
        const res = await _apiClient.getCaseCount({ searchText: this.state.caseSearchString });
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (res.payload) {
            this.setState({ totalCaseCount: res.payload.data, pendingResponse: false });
        }
    };

    getCaseDetailsById = async (id: string) => {
        const response = await _apiClient.getCaseById(
            id,
            '?op=' + CaseDetailOperation.EasyUpdate.Value.toString()
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }

        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchCaseStatuses = async () => {
        const response = await _apiClient.getCaseStatusLookupData();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchCaseManagers = async () => {
        const response = await _apiClient.getCaseManagersInZone();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAllUsersInZone = async () => {
        const response = await _apiClient.getUsersInZone();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAllDefenseCounselsForZone = async () => {
        const response = await _apiClient.getOrganizationInZone(OrganizationTypesEnum.Firm.Value);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    fetchAssertionDescriptors = async () => {
        const response = await _apiClient.getAssertionDescriptors(
            AssertionDescriptorUsageTypesEnum.Case,
            undefined,
            true
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    loadNoteCategoryTypes = async () => {
        const response = await _apiClient.getNoteCategoryTypes(LookupDataEnums.CaseNoteCategories);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    getOrganizationSettings = async () => {
        this.setState({ pendingResponse: true, validation: {} });
        const response = await _apiClient.getOrganizationSettings();
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        if (response.payload) {
            this.setState({ organizationSettings: response.payload, pendingResponse: false });
        }
    };

    handleEasyUpdateFieldsSelected = (optionsList: any) => {
        const previousFieldsSelected: IMultiSelectOptions[] = JSON.parse(
            JSON.stringify(this.state.easyUpdateFieldsSelected)
        );
        const currentFieldsSelected: IMultiSelectOptions[] = optionsList;
        const itemDeleted = previousFieldsSelected.length > currentFieldsSelected.length;

        const currentCase = this.state.currentCase;

        // For all the fields that are removed in the current iteration, set value from Original case details
        previousFieldsSelected.forEach((prevField) => {
            const isFieldInCurrent = currentFieldsSelected.some(
                (x) =>
                    x.guid === prevField.guid ||
                    x.value.toUpperCase() === prevField.value.toUpperCase()
            );

            if (!isFieldInCurrent) {
                this.refreshCaseFieldValue(currentCase, prevField);
            }
        });

        const easyUpdateFieldsSelected = itemDeleted
            ? currentFieldsSelected
            : this.getUpdatedEasyUpdateFields(currentCase, currentFieldsSelected);

        // For UI Reasons, always make case Notes the last item in the list
        const notesSectionId = CaseFileStaticItems.NotesSection.Id;

        if (easyUpdateFieldsSelected.some((x) => x.id === notesSectionId)) {
            const index = easyUpdateFieldsSelected.findIndex((x) => x.id === notesSectionId);
            easyUpdateFieldsSelected.push(easyUpdateFieldsSelected.splice(index, 1)[0]);
        }

        this.setState({
            easyUpdateFieldsSelected,
            currentCase,
        });
    };

    getAllEasyUpdateFieldsForMultiSelect = () => {
        // Do not allow case Notes for a new case.
        let fieldsList: IMultiSelectOptions[] = cloneDeep(this.state.caseFieldsAll);

        if (this.state.addNewCase) {
            const fieldsToRemove = [
                CaseFileStaticItems.NotesSection.Id,
                CaseFileStaticItems.DepositionSection.Id,
                CaseFileStaticItems.ExpertsSection.Id,
            ];

            fieldsList = fieldsList.filter((field) => !fieldsToRemove.includes(field.id));
        }

        return fieldsList;
    };

    handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>,
        item?: IMultiSelectOptions
    ) => {
        const value = event.target.value;

        if (item) item.validationError = '';

        switch (event.target.name) {
            case 'caseNameOrNumberSearch':
                this.setState({ caseSearchString: value, validation: {} });
                break;
            case 'noteContent':
                if (this.state.currentNote) {
                    const currentNote = this.state.currentNote;
                    currentNote.content = value;
                    this.setState({ currentNote: currentNote, validation: {} });
                }
                break;
            default:
                if (item) {
                    const currentCase = cloneDeep(this.state.currentCase);
                    if (
                        item.guid &&
                        this.state.assertionDescriptors.filter((x) => x.guid == item.guid).length >
                            0
                    ) {
                        const assertionDescriptorMatch = this.state.assertionDescriptors.filter(
                            (x) => x.guid == item.guid
                        )[0];
                        if (currentCase.caseAssertions) {
                            const caseAssertionMatch = currentCase.caseAssertions.find(
                                (x) => x.assertionDescriptor!.guid == assertionDescriptorMatch.guid
                            );
                            if (caseAssertionMatch) {
                                caseAssertionMatch.userOverride = true;
                                if (
                                    assertionDescriptorMatch.assertionDescriptorValues &&
                                    assertionDescriptorMatch.assertionDescriptorValues.length > 0
                                ) {
                                    caseAssertionMatch.assertionDescriptorValue =
                                        value &&
                                        assertionDescriptorMatch.assertionDescriptorValues.find(
                                            (x) => x.guid === value
                                        )
                                            ? JSON.parse(
                                                  JSON.stringify(
                                                      assertionDescriptorMatch.assertionDescriptorValues.find(
                                                          (x) => x.guid === value
                                                      )
                                                  )
                                              )
                                            : undefined;
                                } else {
                                    caseAssertionMatch.text = value;
                                }
                            } else if (value) {
                                const newCaseAssertion: ICaseAssertionModel = {
                                    assertionDescriptor: {
                                        guid: assertionDescriptorMatch.guid,
                                        name: assertionDescriptorMatch.name,
                                    },
                                    userOverride: true,
                                };
                                if (
                                    assertionDescriptorMatch.assertionDescriptorValues &&
                                    assertionDescriptorMatch.assertionDescriptorValues.length > 0
                                ) {
                                    newCaseAssertion.assertionDescriptorValue =
                                        assertionDescriptorMatch.assertionDescriptorValues.find(
                                            (x) => x.guid === value
                                        )
                                            ? JSON.parse(
                                                  JSON.stringify(
                                                      assertionDescriptorMatch.assertionDescriptorValues.find(
                                                          (x) => x.guid === value
                                                      )
                                                  )
                                              )
                                            : undefined;
                                } else {
                                    newCaseAssertion.text = value;
                                }
                                currentCase.caseAssertions.push(newCaseAssertion);
                            }
                        }
                    } else {
                        switch (item.id) {
                            case CaseFileStaticItems.CaseNumber.Id:
                                currentCase.caseNumber = value;
                                break;
                            case CaseFileStaticItems.UniqueCaseID.Id:
                                currentCase.uniqueCaseId = value;
                                break;
                            case CaseFileStaticItems.LocalCounsel.Id:
                                // If first ever time adding any Defense Counsel; just add
                                if (!currentCase.defenseCounsels) {
                                    if (value)
                                        currentCase.defenseCounsels = [
                                            { guid: value, priority: 1 },
                                        ];
                                    break;
                                }
                                // If no Local Counsel, Add
                                if (
                                    currentCase.defenseCounsels.filter((x) => x.priority == 1)
                                        .length === 0
                                ) {
                                    if (value)
                                        currentCase.defenseCounsels.push({
                                            guid: value,
                                            priority: 1,
                                        });
                                    break;
                                }
                                // Update previous value of Local Counsel either to a different value or remove all together
                                currentCase.defenseCounsels.filter((x) => x.priority == 1)[0].guid =
                                    value ? value : undefined; // If Value = "", that means the Local Counsel is being removed. Hence set guid = undefined
                                break;
                            case CaseFileStaticItems.CasePriority.Id:
                                currentCase.priority = value == 'true' ? true : undefined;
                                break;
                        }
                    }

                    const updatedEasyUpdateFieldsSelected = this.getUpdatedEasyUpdateFields(
                        currentCase,
                        this.state.easyUpdateFieldsSelected
                    );

                    CaseDataCustomLogicHelper.Run(currentCase, this.state.assertionDescriptors);
                    this.setState({
                        currentCase,
                        easyUpdateFieldsSelected: updatedEasyUpdateFieldsSelected,
                        validation: {},
                    });
                }

                return;
        }
    };

    getUpdatedEasyUpdateFields(
        currentCase: ICaseModel,
        easyUpdateFieldsSelected: IMultiSelectOptions[]
    ): IMultiSelectOptions[] {
        const additionalFields = CaseHelper.getAdditionalCaseAssertionFields(
            currentCase,
            this.state.assertionDescriptors
        ).filter((f: any) => f.assertionDescriptor.name !== 'StatusDocumented');

        const autoPopulatedGuids = additionalFields
            .filter((field) => field.assertionDescriptor?.guid)
            .map((field) => field.assertionDescriptor?.guid ?? '');

        // Resolution Agreement Date
        if (additionalFields.length > 0) {
            for (let i = 0; i < additionalFields.length; i++) {
                if (
                    !easyUpdateFieldsSelected.some(
                        (x) =>
                            x.guid?.toUpperCase() ===
                            additionalFields[i].assertionDescriptor!.guid!.toUpperCase()
                    )
                ) {
                    easyUpdateFieldsSelected.push({
                        id: 0,
                        label: additionalFields[i].assertionDescriptor!.displayName!,
                        value: additionalFields[i].assertionDescriptor!.name!,
                        guid: additionalFields[i].assertionDescriptor!.guid,
                    });
                }

                if (
                    !easyUpdateFieldsSelected.some(
                        (x) => x.id === CaseFileStaticItems.CaseStatus.Id
                    )
                ) {
                    const tempCaseStatusGuid = uniqueId();

                    easyUpdateFieldsSelected.push({
                        id: CaseFileStaticItems.CaseStatus.Id,
                        label: CaseHelper.getCaseFileStaticItemDisplayName(
                            this.state.staticItems,
                            CaseFileStaticItems.CaseStatus.Id
                        ),
                        value: UIHelper.getName(
                            this.state.staticItems,
                            CaseFileStaticItems.CaseStatus.Id
                        ),
                        guid: tempCaseStatusGuid,
                    });

                    autoPopulatedGuids.push(tempCaseStatusGuid);
                }
            }
        }

        this.setState({ systemGeneratedIds: autoPopulatedGuids });

        const ADGroups: string[][] = [
            // AGE
            [
                AssertionDescriptors.CalculatedAgeRange.Guid.toUpperCase(),
                AssertionDescriptors.EstimatedAge.Guid.toUpperCase(),
                AssertionDescriptors.DOB.Guid.toUpperCase(),
                AssertionDescriptors.DateOfDeath.Guid.toUpperCase(),
                AssertionDescriptors.LivingDeceased.Guid.toUpperCase(),
                AssertionDescriptors.CalculatedExactAge.Guid.toUpperCase(),
            ],
            // SMOKING
            [
                AssertionDescriptors.SmokingStartDate.Guid.toUpperCase(),
                AssertionDescriptors.SmokingEndDate.Guid.toUpperCase(),
                AssertionDescriptors.SmokingHistoryPackYear.Guid.toUpperCase(),
                AssertionDescriptors.SmokingHistoryPacksPerDay.Guid.toUpperCase(),
                AssertionDescriptors.IsSmoker.Guid.toUpperCase(),
            ],
            // NON PARTY SHARES
            [
                AssertionDescriptors.NumberOfNonPartyShares.Guid.toUpperCase(),
                AssertionDescriptors.NonPartySharesExact.Guid.toUpperCase(),
            ],
            // DISEASE
            [AssertionDescriptors.Diagnosis.Guid.toUpperCase()],
        ];

        ADGroups.forEach((adGroup) => {
            CaseHelper.handleGroupFieldsForEasyUpdate(
                currentCase,
                easyUpdateFieldsSelected,
                adGroup,
                this.state.assertionDescriptors
            );
        });

        return easyUpdateFieldsSelected;
    }

    clearSearchFilter = () => {
        this.setState({
            caseSearchString: '',
            currentCase: { caseAssertions: [] },
            validation: {},
        });
    };

    clearAll = () => {
        this.setState({
            caseSearchString: '',
            easyUpdateFieldsSelected: JSON.parse(JSON.stringify([])),
            addNewCase: false,
            source: '',
            validation: {},
            showNotesSection: false,
            showDepoSection: false,
            selectedQueryFavoriteId: 0,
        });
    };

    runSearch = async (newSearch?: boolean) => {
        const validation: IValidation = {};

        if (!this.state.caseSearchString || this.state.caseSearchString.trim() == '') {
            validation.caseNameOrNumberSearch = ['Enter a Case Number or Case Name. '];
            this.setState({ validation: validation });
            return;
        } else {
            validation.caseNameOrNumberSearch = [''];
        }

        if (newSearch) await this.refreshCaseCount();

        this.setState({ pendingResponse: true });

        const caseSearchParameters: ICaseSearchModel = {
            searchText: this.state.caseSearchString,
            queryModel: {
                pageNumber: this.state.currentPageNumber,
                pageSize: PageSize,
                sortDirection: SortDirections.Asc,
                sortBy: SortColumns.CaseName,
            },
        };

        const res = await _apiClient.getCases(caseSearchParameters);
        if (res.httpResponse.status == 401) {
            window.location.reload();
            return false;
        }
        if (res.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(res.errors, res.errorMessage),
                pendingResponse: false,
            });
            return false;
        }

        this.setState({
            showCaseSearchResults: true,
            caseSearchResults: res.payload!,
            validation: validation,
            pendingResponse: false,
        });
        return true;
    };

    fetchLoggedInUserDefaultOrganization = async () => {
        const response = await _apiClient.getDefaultUserOrganizationFromServer();
        if (!response) return;
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            return;
        }
        return response.payload;
    };

    addNewCase = async () => {
        const defaultOrganization = await this.fetchLoggedInUserDefaultOrganization();
        const currentCase: ICaseModel = { organization: defaultOrganization, caseAssertions: [] };
        const easyUpdateFieldsSelected: IMultiSelectOptions[] = JSON.parse(
            JSON.stringify(this.state.easyUpdateFieldsSelected)
        );

        if (
            easyUpdateFieldsSelected.filter((x) => x.id === CaseFileStaticItems.NotesSection.Id)
                .length > 0
        ) {
            easyUpdateFieldsSelected.splice(
                easyUpdateFieldsSelected.findIndex(
                    (x) => x.id === CaseFileStaticItems.NotesSection.Id
                ),
                1
            );
        }

        if (
            easyUpdateFieldsSelected.filter(
                (x) => x.id === CaseFileStaticItems.DepositionSection.Id
            ).length > 0
        ) {
            easyUpdateFieldsSelected.splice(
                easyUpdateFieldsSelected.findIndex(
                    (x) => x.id === CaseFileStaticItems.DepositionSection.Id
                ),
                1
            );
        }

        // Automatically add required fields to the easyUpdateFieldsSelected List
        for (let i = 0; i < this.state.caseFieldsAll.length; i++) {
            const item = this.state.caseFieldsAll[i];
            if (
                item.id === CaseFileStaticItems.CaseStatus.Id ||
                item.id === CaseFileStaticItems.CaseNumber.Id ||
                (item.guid || '').toUpperCase() ==
                    AssertionDescriptors.InjuredPartyFirstName.Guid ||
                (item.guid || '').toUpperCase() == AssertionDescriptors.InjuredPartyLastName.Guid
            )
                if (
                    easyUpdateFieldsSelected.filter(
                        (x) => x.value.toUpperCase() == item.value.toUpperCase()
                    ).length === 0
                )
                    easyUpdateFieldsSelected.push(item);

            if (
                (item.guid || '').toUpperCase() ==
                    AssertionDescriptors.InjuredPartyFirstName.Guid ||
                (item.guid || '').toUpperCase() == AssertionDescriptors.InjuredPartyLastName.Guid
            ) {
                currentCase.caseAssertions!.push({
                    assertionDescriptor: { guid: item.guid, name: item.value },
                });
            }
        }

        this.setState({
            addNewCase: true,
            currentCase: currentCase,
            easyUpdateFieldsSelected: easyUpdateFieldsSelected,
            validation: {},
            showNotesSection: false,
            showDepoSection: false,
        });
    };

    loadLoggedInUserDefaultOrganization = async () => {
        const response = await _apiClient.getDefaultUserOrganizationFromServer();
        if (!response) return;
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }
        return response.payload;
    };

    getValue = (item: IMultiSelectOptions): any => {
        if (
            item.guid &&
            this.state.assertionDescriptors.filter((x) => x.guid == item.guid).length > 0
        ) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.filter(
                (x) => x.guid == item.guid
            )[0];
            if (
                assertionDescriptorMatch.valueDisplayType?.id ===
                AssertionDescriptorValueDisplayTypes.MultiSelect.Value
            ) {
                return CaseHelper.getMultiSelectSelectedValues(
                    assertionDescriptorMatch,
                    this.state.currentCase
                );
            } else {
                if (this.state.currentCase.caseAssertions) {
                    const caseAssertionMatch = this.state.currentCase.caseAssertions.find(
                        (x) => x.assertionDescriptor!.guid == assertionDescriptorMatch.guid
                    );
                    if (caseAssertionMatch) {
                        switch (assertionDescriptorMatch.valueSourceType!.id) {
                            case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                            case AssertionDescriptorValueSourceTypeEnum.Implied.Value:
                                return CaseFileCheckmateSelectHelper.getCheckmateSelectValue(
                                    caseAssertionMatch
                                );
                            case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                                return CheckmateSelectHelper.getBooleanValueFromString(
                                    caseAssertionMatch.text
                                );
                            default:
                                return caseAssertionMatch.text ? caseAssertionMatch.text : '';
                        }
                    }
                }
            }
        } else {
            switch (item.id) {
                case CaseFileStaticItems.CaseNumber.Id:
                    return this.state.currentCase.caseNumber
                        ? this.state.currentCase.caseNumber
                        : '';
                case CaseFileStaticItems.UniqueCaseID.Id:
                    return this.state.currentCase.uniqueCaseId
                        ? this.state.currentCase.uniqueCaseId
                        : '';
                case CaseFileStaticItems.CaseStatus.Id:
                    if (this.state.currentCase.caseStatus) {
                        if (this.state.currentCase.caseStatus.status) {
                            let filter = this.state.caseStatuses.filter(
                                (x) =>
                                    x.status &&
                                    x.status.id == this.state.currentCase.caseStatus!.status.id
                            );
                            if (this.state.currentCase.caseStatus.subStatus)
                                filter = filter.filter(
                                    (x) =>
                                        x.subStatus &&
                                        x.subStatus.id ==
                                            this.state.currentCase.caseStatus!.subStatus.id
                                );
                            if (filter.length > 0) {
                                return CaseFileCheckmateSelectHelper.getCaseStatusValue(
                                    this.state.caseStatuses,
                                    filter[0].id!
                                );
                            }
                        }
                    }
                    return undefined;
                case CaseFileStaticItems.CaseManager.Id: {
                    const a1Guid =
                        this.state.currentCase.caseManagerUser &&
                        this.state.currentCase.caseManagerUser.guid
                            ? this.state.currentCase.caseManagerUser.guid
                            : '';
                    return CheckmateSelectHelper.getSelectedUserValue(
                        this.state.allUsersInZone,
                        a1Guid
                    );
                }
                case CaseFileStaticItems.AlternateCaseManager.Id: {
                    const a2Guid =
                        this.state.currentCase.alternateCaseManagerUser &&
                        this.state.currentCase.alternateCaseManagerUser.guid
                            ? this.state.currentCase.alternateCaseManagerUser.guid
                            : '';
                    return CheckmateSelectHelper.getSelectedUserValue(
                        this.state.allUsersInZone,
                        a2Guid
                    );
                }
                case CaseFileStaticItems.LocalCounsel.Id:
                    if (this.state.currentCase.defenseCounsels) {
                        const localCounsel = this.state.currentCase.defenseCounsels.filter(
                            (x) => x.priority == 1
                        );
                        if (localCounsel.length > 0)
                            return CheckmateSelectHelper.getSelectedOrgValue(
                                this.state.allDefenseCounselsForZone,
                                localCounsel[0].guid!
                            );
                    }
                    break;
                case CaseFileStaticItems.CasePriority.Id:
                    return CheckmateSelectHelper.getBooleanValue(this.state.currentCase.priority);
            }
        }

        return '';
    };

    onSearchableSingleSelectChange(
        optionsList: any,
        assertionDescriptorGuid?: string,
        staticItemId?: number,
        isBoolean?: boolean
    ) {
        let currentCase = this.state.currentCase;
        const selectedItem: IMultiSelectOptions = JSON.parse(JSON.stringify(optionsList));

        if (assertionDescriptorGuid) {
            currentCase =
                CaseFileCheckmateSelectHelper.updateCaseOnSelectedAssertionDescriptorValueChange(
                    currentCase,
                    assertionDescriptorGuid,
                    isBoolean || false,
                    selectedItem,
                    this.state.assertionDescriptors
                );
        } else {
            switch (staticItemId) {
                case CaseFileStaticItems.CaseStatus.Id:
                    if (selectedItem && selectedItem.id) {
                        const match = this.state.caseStatuses.find((x) => x.id == selectedItem.id);
                        currentCase.caseStatus = JSON.parse(JSON.stringify(match));
                    } else currentCase.caseStatus = undefined;
                    break;
                case CaseFileStaticItems.CaseManager.Id:
                    if (selectedItem && selectedItem.guid) {
                        const match = this.state.allUsersInZone.find(
                            (x) => x.guid == selectedItem.guid
                        );
                        currentCase.caseManagerUser = JSON.parse(JSON.stringify(match));
                    } else currentCase.caseManagerUser = undefined;
                    break;
                case CaseFileStaticItems.AlternateCaseManager.Id:
                    if (selectedItem && selectedItem.guid) {
                        const match = this.state.allUsersInZone.find(
                            (x) => x.guid == selectedItem.guid
                        );
                        currentCase.alternateCaseManagerUser = JSON.parse(JSON.stringify(match));
                    } else currentCase.alternateCaseManagerUser = undefined;
                    break;
                case CaseFileStaticItems.LocalCounsel.Id:
                    // If first ever time adding any Defense Counsel; just add
                    if (!currentCase.defenseCounsels) {
                        if (selectedItem && selectedItem.guid)
                            currentCase.defenseCounsels = [
                                { guid: selectedItem.guid, priority: 1 },
                            ];
                        break;
                    }
                    // If no Local Counsel, Add
                    if (currentCase.defenseCounsels.filter((x) => x.priority == 1).length === 0) {
                        if (selectedItem.guid)
                            currentCase.defenseCounsels.push({
                                guid: selectedItem.guid,
                                priority: 1,
                            });
                        break;
                    }
                    // Update previous value of Local Counsel either to a different value or remove all together
                    currentCase.defenseCounsels.filter((x) => x.priority == 1)[0].guid =
                        selectedItem && selectedItem.guid ? selectedItem.guid : undefined; // If no selectedItem, that means the Local Counsel is being removed. Hence set guid = undefined
                    break;
                case CaseFileStaticItems.CasePriority.Id:
                    currentCase.priority = selectedItem && selectedItem.id === 1;
                    break;
                default:
                    break;
            }
        }

        const easyUpdateFieldsSelected = this.state.easyUpdateFieldsSelected;
        const updatedEasyUpdateFieldsSelected = this.getUpdatedEasyUpdateFields(
            currentCase,
            easyUpdateFieldsSelected
        );
        CaseDataCustomLogicHelper.Run(currentCase, this.state.assertionDescriptors);
        this.setState({
            currentCase: currentCase,
            easyUpdateFieldsSelected: updatedEasyUpdateFieldsSelected,
            validation: {},
        });
    }

    handleMultiSelectValuesChanged = (optionsList: any, ad?: IAssertionDescriptorModel) => {
        const currentCase = this.state.currentCase;
        if (currentCase) {
            if (!currentCase.caseAssertions) currentCase.caseAssertions = [];

            currentCase.caseAssertions = currentCase.caseAssertions.filter(
                (x) => x.assertionDescriptor!.guid != ad!.guid
            );

            if (optionsList.length === 0) {
                currentCase.caseAssertions.push({
                    assertionDescriptor: JSON.parse(JSON.stringify(ad)),
                });
            } else {
                for (let i = 0; i < optionsList.length; i++) {
                    currentCase.caseAssertions.push({
                        assertionDescriptor: JSON.parse(JSON.stringify(ad)),
                        assertionDescriptorValue: JSON.parse(
                            JSON.stringify(
                                ad!.assertionDescriptorValues!.filter(
                                    (x) => x.guid == optionsList[i].guid!
                                )[0]
                            )
                        ),
                    });
                }
            }

            this.setState({ currentCase: currentCase });
        }
    };

    getCriteriaHelpText = (item: IMultiSelectOptions) => {
        const helpText = CaseHelper.getAssertionDescriptorFieldHelpText(
            this.state.assertionDescriptors,
            item.value,
            item.guid
        );
        if (helpText)
            return <Help type={ApplicationHelpType.Info} title={item.label} helpText={helpText} />;
        else return null;
    };

    buildCaseFieldValues = (item: IMultiSelectOptions): any => {
        const content = [];

        let caseAssertion: ICaseAssertionModel = { assertionDescriptor: { guid: item.guid } };
        if (
            this.state.currentCase.caseAssertions &&
            this.state.currentCase.caseAssertions.filter(
                (x) => x.assertionDescriptor && x.assertionDescriptor.guid === item.guid
            ).length > 0
        )
            caseAssertion = this.state.currentCase.caseAssertions.filter(
                (x) => x.assertionDescriptor && x.assertionDescriptor.guid === item.guid
            )[0];

        const setStyleAsSystemAutoPopulated = CaseHelper.setStyleAsSystemAutoPopulated(
            caseAssertion,
            this.state.currentCase
        );
        const additionalStyleClass = setStyleAsSystemAutoPopulated ? ' font-green' : '';
        const readOnly = CaseHelper.isFieldReadOnly(item.guid ?? '', this.state.currentCase);

        if (
            item.guid &&
            this.state.assertionDescriptors.filter(
                (x) => x.guid && x.guid.toUpperCase() === item.guid!.toUpperCase()
            ).length > 0
        ) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                (x) => x.guid != undefined && x.guid.toUpperCase() === item.guid!.toUpperCase()
            );
            if (assertionDescriptorMatch) {
                if (readOnly) {
                    const value = this.getValue(item);
                    let text = '';
                    if (
                        assertionDescriptorMatch.valueSourceType!.id ===
                        AssertionDescriptorValueSourceTypeEnum.Selection.Value
                    ) {
                        if (value) {
                            if (assertionDescriptorMatch.assertionDescriptorValues) {
                                const advMatch =
                                    assertionDescriptorMatch.assertionDescriptorValues.find(
                                        (x) => x.guid === value.guid
                                    );
                                if (advMatch) text = advMatch.text || '';
                                else {
                                    console.log(
                                        'Unexpected Error - No ADV Match for AD.Guid = ' +
                                            assertionDescriptorMatch.guid +
                                            '; ADV Guid = ' +
                                            JSON.stringify(value)
                                    );
                                }
                            }
                        }
                    } else {
                        text = value;
                    }
                    content.push(
                        <span>
                            <input
                                type="text"
                                className={'form-control' + additionalStyleClass}
                                style={{ width: '42%' }}
                                value={text}
                                readOnly={true}
                            ></input>
                            <span className="text-danger">{item.validationError}</span>
                        </span>
                    );
                } else {
                    switch (assertionDescriptorMatch.valueSourceType!.id) {
                        case AssertionDescriptorValueSourceTypeEnum.Selection.Value:
                            if (
                                assertionDescriptorMatch.assertionDescriptorValues &&
                                assertionDescriptorMatch.assertionDescriptorValues.length > 0
                            ) {
                                content.push(
                                    <>
                                        {assertionDescriptorMatch.valueDisplayType &&
                                        assertionDescriptorMatch.valueDisplayType.id ==
                                            AssertionDescriptorValueDisplayTypes.MultiSelect
                                                .Value ? (
                                            <CheckmateSelect
                                                options={CaseHelper.getMultiSelectOptions(
                                                    assertionDescriptorMatch
                                                )}
                                                value={this.getValue(item)}
                                                systemPopulated={setStyleAsSystemAutoPopulated}
                                                placeholder="Select"
                                                isMulti={true}
                                                onChange={(e: any) => {
                                                    this.handleMultiSelectValuesChanged(
                                                        e,
                                                        assertionDescriptorMatch
                                                    );
                                                }}
                                            />
                                        ) : (
                                            <CheckmateSelect
                                                options={CaseFileCheckmateSelectHelper.getOptionsForCheckmateSelect(
                                                    assertionDescriptorMatch.assertionDescriptorValues!
                                                )}
                                                value={this.getValue(item)}
                                                systemPopulated={setStyleAsSystemAutoPopulated}
                                                onChange={(e: any) => {
                                                    this.onSearchableSingleSelectChange(
                                                        e,
                                                        assertionDescriptorMatch!.guid!
                                                    );
                                                }}
                                            />
                                        )}
                                        <span className="text-danger">{item.validationError}</span>
                                    </>
                                );
                            }
                            break;
                        case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
                            const valueDisplayTypeId = assertionDescriptorMatch.valueDisplayType
                                ? assertionDescriptorMatch.valueDisplayType.id
                                : 0;
                            switch (valueDisplayTypeId) {
                                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                    content.push(
                                        <>
                                            <span>$&nbsp;</span>
                                            <WholeNumberInput
                                                className={'form-control'}
                                                name="numericValue"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChange(e, item);
                                                }}
                                                placeholder="Enter a valid $ amount"
                                                style={{ display: 'inline-block' }}
                                                value={this.getValue(item)}
                                            />
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </>
                                    );
                                    break;
                                case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                    content.push(
                                        <>
                                            <input
                                                type="date"
                                                className={
                                                    'form-control' +
                                                    additionalStyleClass +
                                                    (this.getValue(item) ? '' : ' unselectClass')
                                                }
                                                value={this.getValue(item)}
                                                name="date"
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChange(e, item);
                                                }}
                                            />
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </>
                                    );
                                    break;
                                case AssertionDescriptorValueDisplayTypes.Narrative.Value:
                                    content.push(
                                        <>
                                            <button
                                                className="btn btn-no-bg"
                                                onClick={() => this.handleNarrativeFieldEdit(item)}
                                            >
                                                <i className="fal fa-pen color-black" />
                                            </button>
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </>
                                    );
                                    break;
                                case AssertionDescriptorValueDisplayTypes.Number.Value:
                                    content.push(
                                        <>
                                            <input
                                                type="number"
                                                className={'form-control' + additionalStyleClass}
                                                style={{ width: '42%' }}
                                                value={this.getValue(item)}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChange(e, item);
                                                }}
                                            ></input>
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </>
                                    );
                                    break;
                                case AssertionDescriptorValueDisplayTypes.SSN.Value:
                                    content.push(
                                        <>
                                            <input
                                                type="text"
                                                className={
                                                    'form-control input-SSN ' + additionalStyleClass
                                                }
                                                maxLength={11}
                                                placeholder="XXX-XX-XXXX"
                                                value={this.getValue(item)}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChange(e, item);
                                                }}
                                            ></input>
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </>
                                    );
                                    break;
                                default:
                                    content.push(
                                        <>
                                            <input
                                                type="text"
                                                className={'form-control' + additionalStyleClass}
                                                style={{ width: '42%' }}
                                                value={this.getValue(item)}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    this.handleChange(e, item);
                                                }}
                                            ></input>
                                            <span className="text-danger">
                                                {item.validationError}
                                            </span>
                                        </>
                                    );
                                    break;
                            }
                            break;
                        }
                        case AssertionDescriptorValueSourceTypeEnum.Boolean.Value:
                            content.push(
                                <>
                                    <div className="w-25">
                                        <CheckmateSelect
                                            options={CheckmateSelectHelper.getBooleanOptions()}
                                            value={this.getValue(item)}
                                            systemPopulated={setStyleAsSystemAutoPopulated}
                                            onChange={(e: any) => {
                                                this.onSearchableSingleSelectChange(
                                                    e,
                                                    assertionDescriptorMatch!.guid!,
                                                    undefined,
                                                    true
                                                );
                                            }}
                                        />
                                    </div>
                                    <span className="text-danger">{item.validationError}</span>
                                </>
                            );
                            break;
                        default:
                            break;
                    }
                }
            } else {
                content.push(<span className="text-danger">Unexpected Error!</span>);
            }
        } else {
            switch (item.id) {
                case CaseFileStaticItems.CaseNumber.Id:
                case CaseFileStaticItems.UniqueCaseID.Id:
                    content.push(
                        <span>
                            <input
                                className="form-control"
                                style={{ width: '42%' }}
                                type="text"
                                name={item.value}
                                value={this.getValue(item)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.handleChange(e, item);
                                }}
                            ></input>
                            <span className="text-danger">{item.validationError}</span>
                        </span>
                    );
                    break;
                case CaseFileStaticItems.CaseStatus.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                options={CaseFileCheckmateSelectHelper.getCaseStatusOptionsForCheckmateSelect(
                                    this.state.caseStatuses
                                )}
                                value={this.getValue(item)}
                                systemPopulated={setStyleAsSystemAutoPopulated}
                                onChange={(e: any) => {
                                    this.onSearchableSingleSelectChange(e, undefined, item.id);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case CaseFileStaticItems.CaseManager.Id:
                case CaseFileStaticItems.AlternateCaseManager.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getUserOptions(
                                    this.state.allUsersInZone
                                )}
                                value={this.getValue(item)}
                                systemPopulated={setStyleAsSystemAutoPopulated}
                                onChange={(e: any) => {
                                    this.onSearchableSingleSelectChange(e, undefined, item.id);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case CaseFileStaticItems.LocalCounsel.Id:
                    content.push(
                        <>
                            <CheckmateSelect
                                options={CheckmateSelectHelper.getOrgOptions(
                                    this.state.allDefenseCounselsForZone
                                )}
                                value={this.getValue(item)}
                                systemPopulated={setStyleAsSystemAutoPopulated}
                                onChange={(e: any) => {
                                    this.onSearchableSingleSelectChange(e, undefined, item.id);
                                }}
                            />
                            <span className="text-danger">{item.validationError}</span>
                        </>
                    );
                    break;
                case CaseFileStaticItems.CasePriority.Id:
                    content.push(
                        <span>
                            <div className="w-25">
                                <CheckmateSelect
                                    options={CheckmateSelectHelper.getBooleanOptions()}
                                    value={this.getValue(item)}
                                    systemPopulated={setStyleAsSystemAutoPopulated}
                                    onChange={(e: any) => {
                                        this.onSearchableSingleSelectChange(
                                            e,
                                            undefined,
                                            item.id,
                                            true
                                        );
                                    }}
                                />
                            </div>
                            <span className="text-danger">{item.validationError}</span>
                        </span>
                    );
                    break;
                case CaseFileStaticItems.NotesSection.Id:
                    content.push(
                        <div>
                            <button
                                className="btn btn-no-bg text-gray"
                                onClick={this.handleOpenNotesSection}
                            >
                                <i className="fal fa-memo" />
                                &nbsp;{this.state.showNotesSection ? 'Close' : 'Open'}
                            </button>
                        </div>
                    );
                    break;
                case CaseFileStaticItems.DepositionSection.Id:
                    content.push(
                        <div>
                            <button
                                className="btn btn-no-bg text-gray"
                                onClick={this.handleOpenDepoSection}
                            >
                                <i className="fal fa-memo" />
                                &nbsp;{this.state.showDepoSection ? 'Close' : 'Open'}
                            </button>
                        </div>
                    );
                    break;
                case CaseFileStaticItems.ExpertsSection.Id:
                    content.push(
                        <div>
                            <button
                                className="btn btn-no-bg text-gray"
                                onClick={this.handleOpenExpertsSection}
                            >
                                <i className="fal fa-memo" />
                                &nbsp;{this.state.showExpertsSection ? 'Close' : 'Open'}
                            </button>
                        </div>
                    );
                    break;
            }
        }

        return content;
    };

    handleOpenNotesSection = () => {
        if (!this.state.showNotesSection && !this.state.currentCase.guid) {
            const validation: IValidation = {};
            validation.model = ['Select a case'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ showNotesSection: !this.state.showNotesSection });
    };

    handleOpenDepoSection = () => {
        if (!this.state.showDepoSection && !this.state.currentCase.guid) {
            const validation: IValidation = {};
            validation.model = ['Select a case'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ showDepoSection: !this.state.showDepoSection });
    };

    handleOpenExpertsSection = () => {
        if (!this.state.showExpertsSection && !this.state.currentCase.guid) {
            const validation: IValidation = {};
            validation.model = ['Select a case'];
            this.setState({ validation: validation });
            return;
        }

        this.setState({ showExpertsSection: !this.state.showExpertsSection });
    };

    handleAddNote = () => {
        const currentNote: INoteModel = {
            type: { id: NoteTypes.CaseNote },
            status: { id: NoteStatusTypes.Open },
            caseGuid: this.state.currentCase.guid,
            purpose: { id: NotePurposeTypesEnum.Info },
        };

        this.setState({
            openNoteEditor: true,
            currentNote: currentNote,
            forceNoteEditorInReadOnlyMode: false,
            hasChanges: true,
        });
    };

    handleViewEditNote = (noteGuidToEdit: string, readOnly?: boolean) => {
        if (!this.state.currentCase.notes) return;

        const currentNote = this.state.currentCase.notes.find((x) => x.guid == noteGuidToEdit);
        if (currentNote) {
            this.setState({
                currentNote: currentNote,
                openNoteEditor: true,
                forceNoteEditorInReadOnlyMode: readOnly,
            });
        }
    };

    handleNotesChanges = (notes: INoteModel[]) => {
        const caseItem = cloneDeep({ ...this.state.currentCase, notes });
        this.setState({ currentCase: caseItem, hasChanges: true });
    };

    handleCancelNoteEditor = () => {
        // Since we update the CurrentCase.Notes directly on change of any field in the NoteEditor, on Cancel, reset all changes
        const currentCase = this.state.currentCase;
        if (this.state.currentCase && this.state.originalCase && this.state.originalCase.notes) {
            currentCase.notes = JSON.parse(JSON.stringify(this.state.originalCase.notes));
        }

        this.setState({ currentNote: undefined, openNoteEditor: false, currentCase: currentCase });
    };

    onSaveNoteComplete = (noteItem: INoteModel) => {
        const caseModel = this.state.currentCase;

        let matchFound = false;
        let caseNotes = caseModel.notes || [];
        for (let i = 0; i < caseNotes.length; i++) {
            if (caseNotes[i].guid == noteItem.guid) {
                matchFound = true;
                caseNotes[i] = JSON.parse(JSON.stringify(noteItem));
                break;
            }
        }

        if (!matchFound) caseNotes.push(JSON.parse(JSON.stringify(noteItem)));

        caseNotes = caseNotes.sort(Sort.compareDate('modifiedDate', undefined, 'desc'));
        caseModel.notes = caseNotes;

        this.setState({
            pendingResponse: false,
            openNoteEditor: false,
            currentNote: {
                status: { id: NoteStatusTypes.Open },
                purpose: { id: NotePurposeTypesEnum.Info },
            },
            currentCase: caseModel,
            originalCase: cloneDeep(caseModel),
        });
    };

    handleCancelCaseSearch = () => {
        this.setState({
            showCaseSearchResults: false,
            caseSearchString: '',
            currentCase: { caseAssertions: [] },
            currentPageNumber: 0,
        });
    };

    selectCase = async (event: React.FormEvent<HTMLButtonElement>, caseGuid: string) => {
        this.setState({ pendingResponse: true });
        const caseAPIResponse = await this.getCaseDetailsById(caseGuid);
        const originalCase: ICaseModel = JSON.parse(JSON.stringify(caseAPIResponse));
        const easyUpdateFieldsSelected = this.getUpdatedEasyUpdateFields(
            caseAPIResponse!,
            this.state.easyUpdateFieldsSelected
        );
        this.setState({
            showCaseSearchResults: false,
            caseSearchString: '',
            currentCase: caseAPIResponse!,
            pendingResponse: false,
            originalCase: originalCase,
            easyUpdateFieldsSelected: easyUpdateFieldsSelected,
        });
    };

    saveCase = async () => {
        const currentCase = cloneDeep(this.state.currentCase);
        const validation = cloneDeep(this.state.validation);

        if (!this.state.addNewCase && !currentCase.guid) {
            validation.model = ['Please select an existing case to update (or) Add a new case'];
            this.setState({ validation: validation });
            return;
        }

        if (this.state.easyUpdateFieldsSelected.length === 0) {
            validation.model = ['Please select at least one field to update'];
            this.setState({ validation: validation });
            return;
        }

        let easyUpdateFieldsSelected: IMultiSelectOptions[] = JSON.parse(
            JSON.stringify(this.state.easyUpdateFieldsSelected)
        );

        // If user somehow deleted/removed the required date field for Resolved Cases, add it back.
        easyUpdateFieldsSelected = this.getUpdatedEasyUpdateFields(
            currentCase,
            easyUpdateFieldsSelected
        );

        // Validations
        if (this.state.addNewCase) {
            const validationErrors: string[] = [];

            if (!easyUpdateFieldsSelected.some((x) => x.id === CaseFileStaticItems.CaseNumber.Id)) {
                validationErrors.push('Case Number is required for a new case');
            }

            if (!easyUpdateFieldsSelected.some((x) => x.id === CaseFileStaticItems.CaseStatus.Id)) {
                validationErrors.push('Case Status is required for a new case');
            }

            if (!currentCase.caseAssertions || currentCase.caseAssertions.length === 0) {
                validationErrors.push(
                    'Injured Party First & Last Name are required for a new case'
                );
            } else if (
                !currentCase.caseAssertions.some(
                    (x) => x.assertionDescriptor?.name?.toUpperCase() == 'INJURED PARTY FIRST NAME'
                )
            ) {
                validationErrors.push('Injured Party First Name is required for a new case');
            } else if (
                !currentCase.caseAssertions.some(
                    (x) => x.assertionDescriptor?.name?.toUpperCase() == 'INJURED PARTY LAST NAME'
                )
            ) {
                validationErrors.push('Injured Party Last Name is required for a new case');
            }

            if (validationErrors.length > 0) {
                validation.model = validationErrors;
            }
        }

        for (let i = 0; i < easyUpdateFieldsSelected.length; i++) {
            const item = easyUpdateFieldsSelected[i];
            item.validationError = undefined;
            if (
                item.guid &&
                this.state.assertionDescriptors.filter((x) => x.guid == item.guid).length > 0
            ) {
                const assertionDescriptorMatch = this.state.assertionDescriptors.filter(
                    (x) => x.guid == item.guid
                )[0];
                if (this.state.currentCase.caseAssertions) {
                    const caseAssertionMatch = this.state.currentCase.caseAssertions.find(
                        (x) => x.assertionDescriptor!.guid == item.guid
                    );
                    if (caseAssertionMatch) {
                        if (
                            assertionDescriptorMatch.guid!.toUpperCase() ==
                                AssertionDescriptors.InjuredPartyFirstName.Guid &&
                            (!caseAssertionMatch.text || caseAssertionMatch.text.trim() == '')
                        ) {
                            item.validationError = 'Injured Party Last Name is required';
                        } else if (
                            assertionDescriptorMatch.guid!.toUpperCase() ==
                                AssertionDescriptors.InjuredPartyLastName.Guid &&
                            (!caseAssertionMatch.text || caseAssertionMatch.text.trim() == '')
                        ) {
                            item.validationError = 'Injured Party First Name is required';
                        } else if (
                            assertionDescriptorMatch.name!.includes('SSN') &&
                            caseAssertionMatch.text &&
                            !ValidateUtils.isValidSSN(caseAssertionMatch.text!)
                        ) {
                            item.validationError =
                                'Invalid ' +
                                assertionDescriptorMatch.displayName +
                                ".  Enter a valid SSN in the format XXX-XX-XXXX (Use 0's if you only have last 4 of the SSN i.e. 000 - 000 - 1234)";
                        } else if (assertionDescriptorMatch.valueDisplayType) {
                            switch (assertionDescriptorMatch.valueDisplayType.id) {
                                case AssertionDescriptorValueDisplayTypes.DateTime.Value:
                                    break;
                                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                    if (
                                        caseAssertionMatch.text &&
                                        isNaN(Number(caseAssertionMatch.text))
                                    )
                                        item.validationError = 'Enter a valid number';
                                    break;
                                default:
                            }
                        }
                    }
                }
            } else {
                switch (item.id) {
                    case CaseFileStaticItems.CaseNumber.Id:
                        if (
                            !this.state.currentCase.caseNumber ||
                            this.state.currentCase.caseNumber.trim() == ''
                        ) {
                            item.validationError = 'Case Number is required';
                        }
                        break;
                    case CaseFileStaticItems.CaseStatus.Id:
                        if (
                            !this.state.currentCase.caseStatus ||
                            !this.state.currentCase.caseStatus.status ||
                            this.state.currentCase.caseStatus.status.id == 0
                        ) {
                            // AR - TO DO - Status Cleanup - Also add Mapping validation
                            item.validationError = 'Status is required';
                        }
                        break;
                }
            }
        }

        if (easyUpdateFieldsSelected.filter((x) => x.validationError).length > 0) {
            this.setState({
                validation: validation,
                easyUpdateFieldsSelected: easyUpdateFieldsSelected,
            });
            return;
        }

        if (this.state.noteAdded) {
            currentCase.notes = [this.state.currentNote!];
        }

        this.setState({ pendingResponse: true, validation: {} });

        /*
        ASSUMPTIONS FOR THE EASY UPDATE
       - STATUS -  Required field in the Model. Hence latest value from the model will be updated in the DB
       - CASE NUMBER -  Required field in the Model. Hence latest value from the model will be updated in the DB
       - CASE MANAGER - For an existing case, if CaseManager object is NULL, its ignored. If CaseManagerUser object is NOT NULL but Guid = NULL, then its considered that the user is deleting the CaseManager Value
       - LOCAL COUNSEL - For an existing case, if DefenseCounsels list is empty or item with Priority 1 doesnt exist, then ignored. If item with Priority 1 exists but Guid = Guid.Empty, then its considered that the user is deleting the Local Counsel
       - NOTE: Only new notes can be added
        */

        // remove case experts and notes from patch
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { caseExperts, notes, ...restOfCase } = currentCase;

        CaseHelper.updateUserOverrideFieldForCaseAssertions(restOfCase);
        const response = await _apiClient.easyUpdateCase(restOfCase);
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
                pendingResponse: false,
            });
            return;
        }

        // We only need the updated case Details in case of Easy Update from case List. So that the case details can be sent back to the parent control to update the case List
        // No need to preserve the Fields in case of creating a new case.

        const updatedCase: ICaseModel = response.payload! ?? { caseAssertions: [] };

        if (typeof this.props.onComplete === 'function') {
            this.setState({
                showSuccessDialog: false,
            });

            this.props.onComplete(updatedCase);
        } else {
            this.setState({
                currentCase: updatedCase,
                noteAdded: false,
                currentNote: undefined,
                selectedNoteCategories: [],
                pendingResponse: false,
                showSuccessDialog: true,
                hasChanges: true,
            });
        }
    };

    handleCloseSuccessDialog = () => {
        this.setState(
            {
                showSuccessDialog: false,
            },
            () => {
                if (this.state.addNewCase && typeof this.props.navigate === 'function') {
                    this.props.navigate(LocalRoutes.CaseEasyUpdate);
                }
            }
        );
    };

    deleteField(e: React.MouseEvent<HTMLButtonElement>, item: IMultiSelectOptions) {
        // While deleting the case Field from the grid, remove that value from the list of selected fields. Also, set its value in the "currentCase" variable to the original value from the "originalCase" variable so that next time they select the same field, the UI displays the correct value
        const easyUpdateFieldsSelected: IMultiSelectOptions[] = JSON.parse(
            JSON.stringify(this.state.easyUpdateFieldsSelected)
        );
        if (easyUpdateFieldsSelected) {
            for (let i = 0; i < easyUpdateFieldsSelected.length; i++) {
                if (
                    item.guid == easyUpdateFieldsSelected[i].guid ||
                    item.value.toUpperCase() == easyUpdateFieldsSelected[i].value.toUpperCase()
                ) {
                    easyUpdateFieldsSelected.splice(i, 1);

                    const currentCase = this.state.currentCase;
                    if (currentCase) {
                        this.refreshCaseFieldValue(currentCase, item);
                    }

                    this.setState({
                        easyUpdateFieldsSelected: easyUpdateFieldsSelected,
                        currentCase: currentCase,
                    });
                    break;
                }
            }
        }
    }

    refreshCaseFieldValue(currentCase: ICaseModel, item: IMultiSelectOptions) {
        if (item.guid && this.state.assertionDescriptors.some((x) => x.guid === item.guid)) {
            const assertionDescriptorMatch = this.state.assertionDescriptors.find(
                (x) => x.guid === item.guid
            );
            if (currentCase.caseAssertions && assertionDescriptorMatch) {
                const currentCaseCaseAssertionMatch = currentCase.caseAssertions!.find(
                    (x) => x.assertionDescriptor!.guid === assertionDescriptorMatch.guid
                );
                if (currentCaseCaseAssertionMatch) {
                    currentCaseCaseAssertionMatch.assertionDescriptorValue = undefined;
                    currentCaseCaseAssertionMatch.text = undefined;
                    if (this.state.originalCase && this.state.originalCase.caseAssertions) {
                        const originalCaseCaseAssertionMatch =
                            this.state.originalCase.caseAssertions!.find(
                                (x) => x.assertionDescriptor!.guid == assertionDescriptorMatch.guid
                            );
                        if (originalCaseCaseAssertionMatch) {
                            currentCaseCaseAssertionMatch.assertionDescriptorValue =
                                originalCaseCaseAssertionMatch.assertionDescriptorValue;
                            currentCaseCaseAssertionMatch.text =
                                originalCaseCaseAssertionMatch.text;
                        }
                    }
                }
            }
        } else {
            switch (item.id) {
                case CaseFileStaticItems.CaseNumber.Id:
                    currentCase.caseNumber = this.state.originalCase.caseNumber;
                    break;
                case CaseFileStaticItems.UniqueCaseID.Id:
                    currentCase.uniqueCaseId = this.state.originalCase.uniqueCaseId;
                    break;
                case CaseFileStaticItems.CaseStatus.Id:
                    currentCase.caseStatus = this.state.originalCase.caseStatus
                        ? JSON.parse(JSON.stringify(this.state.originalCase.caseStatus))
                        : undefined;
                    break;
                case CaseFileStaticItems.CaseManager.Id:
                    currentCase.caseManagerUser = this.state.originalCase.caseManagerUser
                        ? JSON.parse(JSON.stringify(this.state.originalCase.caseManagerUser))
                        : undefined;
                    break;
                case CaseFileStaticItems.AlternateCaseManager.Id:
                    currentCase.alternateCaseManagerUser = this.state.originalCase
                        .alternateCaseManagerUser
                        ? JSON.parse(
                              JSON.stringify(this.state.originalCase.alternateCaseManagerUser)
                          )
                        : undefined;
                    break;
                case CaseFileStaticItems.LocalCounsel.Id:
                    currentCase.defenseCounsels = this.state.originalCase.defenseCounsels
                        ? JSON.parse(JSON.stringify(this.state.originalCase.defenseCounsels))
                        : undefined;
                    break;
                case CaseFileStaticItems.CasePriority.Id:
                    currentCase.priority = this.state.originalCase.priority;
                    break;
                case CaseFileStaticItems.NotesSection.Id:
                    this.setState({
                        noteAdded: false,
                        currentNote: {
                            status: { id: NoteStatusTypes.Open },
                            purpose: { id: NotePurposeTypesEnum.Info },
                        },
                    });
                    break;
            }
        }
    }

    handleNarrativeFieldEdit = (item: IMultiSelectOptions) => {
        this.setState({ currentNarrativeField: item });
    };

    handleCancelNarrativeFieldEdit = () => {
        this.setState({ currentNarrativeField: undefined });
    };

    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    handleClearNarrativeFieldEdit = () => {};

    handleSaveNarrativeFieldToCase = () => {
        this.setState({ currentNarrativeField: undefined });
    };

    displaySaveButton = () => {
        if (this.state.addNewCase) return true;

        const showSaveButton = this.state.easyUpdateFieldsSelected.some(
            (field) =>
                ![
                    CaseFileStaticItems.NotesSection.Id,
                    CaseFileStaticItems.ExpertsSection.Id,
                    CaseFileStaticItems.DepositionSection.Id,
                ].includes(field.id)
        );

        return showSaveButton;
    };

    nextPage = async () => {
        const nextPage = this.state.currentPageNumber + 1;
        this.setState({ currentPageNumber: nextPage }, () => {
            this.runSearch();
        });
    };

    previousPage = async () => {
        this.setState({ currentPageNumber: this.state.currentPageNumber - 1 }, () => {
            this.runSearch();
        });
    };

    applyFavoriteSelected = (settingJson?: string, isDefault?: boolean) => {
        if (settingJson) {
            let easyUpdateFieldsSelected: IMultiSelectOptions[] = JSON.parse(settingJson);

            const adsToRemove: string[] = [];
            for (let i = 0; i < easyUpdateFieldsSelected.length; i++) {
                const item = easyUpdateFieldsSelected[i];
                // Get the Latest Display Name since the Favorite might have been added with an old value
                if (item.guid) {
                    const match = this.state.assertionDescriptors.find(
                        (x) => x.guid!.toUpperCase() === item.guid!.toUpperCase()
                    );
                    if (match) {
                        item.label = match.alternateDisplayName || match.displayName || match.name!;
                    } else adsToRemove.push(item.guid);
                }
            }

            for (let index = 0; index < adsToRemove.length; index++) {
                const element = adsToRemove[index];
                easyUpdateFieldsSelected = easyUpdateFieldsSelected.filter(
                    (x) => x.guid != element
                );
            }

            if (this.state.addNewCase || this.props.excludeNotes)
                easyUpdateFieldsSelected = easyUpdateFieldsSelected.filter(
                    (x) => x.id != CaseFileStaticItems.NotesSection.Id
                );

            this.setState({
                easyUpdateFieldsSelected,
                userDefaultSettingsJson: isDefault
                    ? settingJson
                    : this.state.userDefaultSettingsJson,
            });
        }
    };

    handleDefaultFavoriteChange = (settingJson?: string) => {
        this.setState({ userDefaultSettingsJson: settingJson });
    };

    handleFavoriteSelected = (settingJson?: string) => {
        let selectedFilters = '';
        if (settingJson) {
            const easyUpdateFieldsSelected: IMultiSelectOptions[] = JSON.parse(settingJson);
            if (easyUpdateFieldsSelected.length > 0)
                easyUpdateFieldsSelected.map((item: IMultiSelectOptions) => {
                    const match = this.state.caseFieldsAll.find((x) => x.value === item.value);
                    if (match) selectedFilters += match.label + '\n';
                });
        }

        this.setState({ currentFavoriteDetailsString: selectedFilters });
    };

    selectLabel = (e: any, label: string) => {
        if (!e.value || e.value.length === 0) {
            return <span className="unselectClass css-1v99tuv">--{label}--</span>;
        }

        if (e.value.length === 1) {
            return <span className="css-1v99tuv">{e.value[0].label}</span>;
        } else {
            return <span className="css-1v99tuv">{e.value.length} selected</span>;
        }
    };

    handleExpertsChanges = (caseExperts: ICaseExpertModel[]) => {
        const caseCopy = { ...this.state.currentCase };
        caseCopy.caseExperts = caseExperts;

        this.setState({ currentCase: caseCopy, hasChanges: true });
    };

    handleBackClick = () => {
        if (typeof this.props.onCancel === 'function') {
            this.props.onCancel(this.state.hasChanges);
        }
    };

    handleSetCurrentCaseExpert = (caseExpert: ICaseExpertModel | null) => {
        this.setState({ currentCaseExpert: caseExpert });
    };

    handleCaseExpertEditorSave = (updatedCaseExpert: ICaseExpertViewDetailModel) => {
        const caseExpertsResultsCopy = cloneDeep(this.state.currentCase.caseExperts);
        const found = caseExpertsResultsCopy?.find((ce) => ce.guid === updatedCaseExpert.guid);

        if (found) {
            found.disciplines = updatedCaseExpert.expert.disciplines ?? [];
            found.leadDefendant = updatedCaseExpert.leadDefendant ?? '';
            found.coDefendants = updatedCaseExpert.coDefendants ?? '';
            found.expertReportStatusTypeId = updatedCaseExpert.expertReportStatusTypeId ?? 0;
            found.expertRetainedByTypeId = updatedCaseExpert.expertRetainedByTypeId ?? 0;
            found.purposeOfRetention = updatedCaseExpert.purposeOfRetention;
        }

        this.setState({
            currentCase: { ...this.state.currentCase, caseExperts: caseExpertsResultsCopy },
        });
    };

    buildEasyUpdateFieldsTableRows = () => {
        const rows: any = [];
        for (let i = 0; i < this.state.easyUpdateFieldsSelected.length; i++) {
            const item: IMultiSelectOptions = this.state.easyUpdateFieldsSelected[i];
            rows.push(
                <tr key={item.guid}>
                    <td>
                        {item!.label}
                        {this.getCriteriaHelpText(item)}
                    </td>
                    <td>{this.buildCaseFieldValues(item)}</td>
                    <td width="10%">
                        <button
                            className="btn btn-gray btn-icon float-end"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.deleteField(e, item);
                            }}
                        >
                            <i className="fal fa-times color-white" />
                        </button>
                    </td>
                </tr>
            );

            if (item.id === CaseFileStaticItems.NotesSection.Id && this.state.showNotesSection) {
                rows.push(
                    <tr key={item.guid}>
                        <td colSpan={3}>
                            <NoteList
                                user={this.props.user}
                                notes={this.state.currentCase.notes}
                                noteCategoryTypes={this.state.noteCategoryTypes || []}
                                handleViewEditNote={this.handleViewEditNote}
                                refreshParent={this.handleNotesChanges}
                                handleAddNote={this.handleAddNote}
                                allowView={Authorization.userHasRight(
                                    UserRightsEnum.ViewCaseNotes,
                                    this.props.user
                                )}
                                allowEdit={Authorization.userHasRight(
                                    UserRightsEnum.EditCaseNote,
                                    this.props.user
                                )}
                                allowDelete={Authorization.userHasRight(
                                    UserRightsEnum.DeleteCaseNote,
                                    this.props.user
                                )}
                                allowAddNew={Authorization.userHasRight(
                                    UserRightsEnum.AddCaseNote,
                                    this.props.user
                                )}
                                showExpertsColumn
                            />
                        </td>
                    </tr>
                );
            } else if (
                item.id === CaseFileStaticItems.DepositionSection.Id &&
                this.state.showDepoSection
            ) {
                rows.push(
                    <tr key={item.guid}>
                        <td colSpan={3}>
                            <CaseDepositionListWrapper
                                user={this.props.user}
                                caseGuid={this.state.currentCase.guid ?? ''}
                                usersInZone={this.state.allUsersInZone ?? []}
                                allDefenseCounselsForZone={
                                    this.state.allDefenseCounselsForZone ?? []
                                }
                                depositions={this.state.currentCase.depositions ?? []}
                                onDepositionsChange={() => this.setState({ hasChanges: true })}
                            />
                        </td>
                    </tr>
                );
            } else if (
                item.id === CaseFileStaticItems.ExpertsSection.Id &&
                this.state.showExpertsSection
            ) {
                rows.push(
                    <tr key={item.guid}>
                        <td colSpan={3}>
                            <CaseExpertsList
                                user={this.props.user}
                                caseExperts={this.state.currentCase.caseExperts ?? []}
                                caseGuid={this.state.currentCase.guid}
                                refreshParent={this.handleExpertsChanges}
                                onSetCurrentCaseExpert={this.handleSetCurrentCaseExpert}
                            />
                        </td>
                    </tr>
                );
            }
        }
        return rows;
    };

    render() {
        if (this.props.hidden) {
            return <div />;
        }

        if (this.state.currentNote && this.state.openNoteEditor) {
            const availableEntityItems = this.state.currentCase.caseExperts?.map(
                (ce) =>
                    ({
                        label: ce.name,
                        value: ce.guid,
                        guid: ce.guid,
                    } as IMultiSelectOptions)
            );
            return (
                <NoteEditor
                    forceNoteEditorInReadOnlyMode={this.state.forceNoteEditorInReadOnlyMode}
                    authorizedToEdit={NoteHelper.isUserAuthorizedToEditNote(
                        this.state.currentNote,
                        this.props.user,
                        Authorization.userHasRight(UserRightsEnum.ViewCaseNotes, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.EditCaseNote, this.props.user),
                        Authorization.userHasRight(UserRightsEnum.AddCaseNote, this.props.user)
                    )}
                    caseLocalCounselGuid={CaseHelper.getCaseLocalCounselGuid(
                        this.state.currentCase
                    )}
                    defaultAlertTarget={CaseHelper.getDefaultAlertTargetForCase(
                        this.props.user,
                        this.state.currentCase
                    )}
                    defaultWatchTargets={CaseHelper.getDefaultWatchTargetsForCase(
                        this.props.user,
                        this.state.currentCase
                    )}
                    caseTitle={CaseHelper.getCaseTitle(this.state.currentCase)}
                    user={this.props.user}
                    noteCategoryTypes={this.state.noteCategoryTypes}
                    currentNote={this.state.currentNote}
                    handleSaveComplete={this.onSaveNoteComplete}
                    handleCancel={this.handleCancelNoteEditor}
                    useNoteAssociationsModal
                    noteAssociationModalTitle="Add Related Case Expert(s)"
                    entityType={{ id: EntityTypes.CaseExpert }}
                    entityItems={availableEntityItems}
                />
            );
        }

        if (this.state.currentCaseExpert) {
            return (
                <CaseExpertEditor
                    caseExpertGuid={this.state.currentCaseExpert.guid}
                    caseGuid={this.state.currentCase.guid}
                    defaultIsEditMode={this.state.currentCaseExpert.defaultIsEditMode ?? false}
                    onBackClick={() => this.setState({ currentCaseExpert: null })}
                    onSave={this.handleCaseExpertEditorSave}
                    user={this.props.user}
                />
            );
        }

        return (
            <div>
                <DocumentTitle title={this.props.documentTitle ?? 'Easy Update'} />
                {this.state.pendingResponse && <Loader />}
                <div className="row">
                    <h1 className="col-sm-6 mb-0">
                        {this.state.addNewCase ? (
                            'Add New Case - Easy Update'
                        ) : (
                            <span style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    {this.state.currentCase.guid ? (
                                        <CaseName
                                            user={this.props.user}
                                            caseTitle={CaseHelper.getCaseTitle(
                                                this.state.currentCase
                                            )}
                                            openLinkInNewTab={true}
                                            additionalText=" - Easy Update"
                                        />
                                    ) : (
                                        'Easy Update'
                                    )}
                                </span>
                                <span
                                    className="font-size-xs"
                                    style={{ display: 'block', paddingTop: 5 }}
                                >
                                    {this.props.additionalHeaderText}
                                </span>
                            </span>
                        )}
                    </h1>
                    <div
                        className="col-sm-6"
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center',
                        }}
                    >
                        {!this.props.caseGuid && !this.state.addNewCase && (
                            <button className="btn-no-bg float-end " onClick={this.addNewCase}>
                                <span className="btn-green btn float-end btn-icon">
                                    <i className="fal fa-lg fa-plus color-white" />
                                </span>
                            </button>
                        )}
                        {this.props.caseGuid && this.props.onCancel && (
                            <button
                                className="btn btn-blue float-end"
                                onClick={this.handleBackClick}
                            >
                                Back
                            </button>
                        )}
                        <button className="btn btn-default float-end" onClick={this.clearAll}>
                            Clear
                        </button>
                        {this.displaySaveButton() && (
                            <button
                                className="btn btn-orange float-end text-gray"
                                onClick={this.saveCase}
                            >
                                Save
                            </button>
                        )}
                        <Favorite
                            user={this.props.user}
                            type={{ id: FavoriteTypesEnum.EasyUpdate }}
                            currentFavoriteSettingJson={
                                this.state.easyUpdateFieldsSelected.length > 0
                                    ? JSON.stringify(this.state.easyUpdateFieldsSelected)
                                    : ''
                            }
                            currentFavoriteDetailsString={this.state.currentFavoriteDetailsString}
                            handleFavoriteSelected={this.handleFavoriteSelected}
                            applyFavoriteSelected={this.applyFavoriteSelected}
                            handleDefaultFavoriteChange={this.handleDefaultFavoriteChange}
                        />
                        <Link
                            className="float-end no-padding-bottom"
                            target="_blank"
                            to={LocalRoutes.ViewSpecificPDF.replace(
                                ':id',
                                TutorialTopics.EasyUpdate.toString()
                            )}
                        >
                            <span className="btn btn-no-bg text-gray">
                                <i className={'fal fa-graduation-cap'} />
                                &nbsp;Tutorials
                            </span>
                        </Link>
                    </div>
                </div>
                <div>
                    <span className="text-danger">
                        {this.state.validation.model
                            ? this.state.validation.model.map((value: string, idx: number) => {
                                  return (
                                      <ul key={idx}>
                                          {value.split('|').map((s: string, idx: number) => {
                                              return <li key={idx}>{s}</li>;
                                          })}
                                      </ul>
                                  );
                              })
                            : null}
                    </span>
                </div>
                <div id="queryDetails" className="margin-top panel-body">
                    {this.state.addNewCase || this.props.caseGuid ? null : (
                        <div className="row mb-3">
                            <label className="text-gray text-lg col-sm-2">Case</label>
                            <div className="col-sm-3">
                                <input
                                    className="form-control full-width full-max-width"
                                    type="text"
                                    name="caseNameOrNumberSearch"
                                    placeholder="Search by Name or Number"
                                    value={this.state.caseSearchString}
                                    onChange={this.handleChange}
                                />
                                <span className="text-danger">
                                    {this.state.validation.caseNameOrNumberSearch}
                                </span>
                            </div>
                            <div
                                className="row col-sm-1"
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'row-reverse',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <button
                                    type="button"
                                    className="btn btn-gray btn-icon"
                                    style={{ marginRight: '5px', minHeight: '100%' }}
                                    onClick={this.clearSearchFilter}
                                >
                                    <i className="fal fa-times color-white" />
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-black btn-icon"
                                    style={{ minHeight: '100%' }}
                                    onClick={() => {
                                        this.runSearch(true);
                                    }}
                                >
                                    <i className="fal fa-search color-white" />
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-sm-2">
                            <label className="text-gray text-lg">Fields</label>
                        </div>
                        <div className="col-sm-10">
                            <CheckmateSelect
                                isMulti={true}
                                options={this.getAllEasyUpdateFieldsForMultiSelect()}
                                value={this.state.easyUpdateFieldsSelected}
                                onChange={this.handleEasyUpdateFieldsSelected}
                            />
                        </div>
                        <span className="text-danger">{this.state.validation.criteria}</span>
                    </div>
                    {this.state.easyUpdateFieldsSelected?.length > 0 && (
                        <div>
                            <table className="table margin-top-sm">
                                <thead>
                                    <tr>
                                        <th className="col-sm-3">Field</th>
                                        <th className="col-sm-4">Value(s)</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>{this.buildEasyUpdateFieldsTableRows()}</tbody>
                            </table>
                        </div>
                    )}
                </div>
                {this.state.showCaseSearchResults && (
                    <Modal
                        centered
                        size="lg"
                        show={this.state.showCaseSearchResults}
                        onHide={this.handleCancelCaseSearch}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Search Results </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.caseSearchResults.length > 0 ? (
                                <div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Case Name</th>
                                                <th>Status</th>
                                                <th>State</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.caseSearchResults.map(
                                                (caseItem: ICaseModel, i: number) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{caseItem.caseName}</td>
                                                            <td>
                                                                {caseItem.caseStatus!.displayName!}
                                                            </td>
                                                            <td>{caseItem.state}</td>
                                                            <td className="col-sm-2">
                                                                <button
                                                                    className="btn-no-bg text-gray"
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLButtonElement>
                                                                    ) => {
                                                                        this.selectCase(
                                                                            e,
                                                                            caseItem.guid!
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className="fal fa-check-square" />
                                                                    &nbsp;Select
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                    {this.state.currentPageNumber + 1 <
                                    Math.ceil(this.state.totalCaseCount / PageSize) ? (
                                        <button
                                            className="btn btn-orange float-end text-gray horizontal-margin"
                                            onClick={() =>
                                                this.setState(
                                                    {
                                                        currentPageNumber:
                                                            this.state.currentPageNumber + 1,
                                                    },
                                                    this.runSearch
                                                )
                                            }
                                        >
                                            Next
                                        </button>
                                    ) : null}
                                    {this.state.currentPageNumber > 0 ? (
                                        <button
                                            className="btn btn-orange float-end text-gray horizontal-margin"
                                            onClick={() =>
                                                this.setState(
                                                    {
                                                        currentPageNumber:
                                                            this.state.currentPageNumber - 1,
                                                    },
                                                    this.runSearch
                                                )
                                            }
                                        >
                                            Previous
                                        </button>
                                    ) : null}
                                </div>
                            ) : (
                                <span>No results found!</span>
                            )}
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleCancelCaseSearch}
                                >
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
                {this.state.currentNarrativeField && (
                    <Modal
                        centered
                        show={true}
                        onHide={this.handleCancelNarrativeFieldEdit}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title>{this.state.currentNarrativeField.label}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <span className="text-danger">{this.state.validation.model}</span>
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={this.getValue(this.state.currentNarrativeField)}
                                    name={this.state.currentNarrativeField.guid}
                                    rows={10}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                        this.handleChange(e, this.state.currentNarrativeField);
                                    }}
                                />
                            </div>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-orange float-end"
                                    onClick={this.handleSaveNarrativeFieldToCase}
                                >
                                    OK
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleCancelNarrativeFieldEdit}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-default float-end"
                                    onClick={this.handleClearNarrativeFieldEdit}
                                >
                                    Clear
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}

                <CheckmateDialog
                    isShowingModal={this.state.showSuccessDialog}
                    title="Easy Update"
                    body="The case was successfully saved."
                    handleClose={this.handleCloseSuccessDialog}
                    dialogClassName="user-profile-dialog"
                    cancelText="Ok"
                    closeButtonClassName="btn btn-black float-end"
                />
            </div>
        );
    }
}
