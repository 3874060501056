import * as React from 'react';

import { ApiRoutes } from '../../utilities/ApiRoutes';
import { Loader } from '../shared/Loader';

interface IRelativeReportViewerProps {
    handleLoaderExternally?: boolean;
    heightInPixels: number;
    hideLoadingOverlay?: boolean;
    onReady?: () => void;
    onRenderComplete?: () => void;
    refreshReport?: boolean;
    reportName: string;
    reportParameters?: any;
}

interface IRelativeReportViewerState {
    elementId?: string;
    loading?: boolean;
}
export default class RelativeReportViewer extends React.Component<
    IRelativeReportViewerProps,
    IRelativeReportViewerState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            elementId: this.props.reportName.replace('.trdp', ''),
        };
    }

    // The componentDidMount() method runs after the component output has been rendered to the DOM.
    componentDidMount() {
        $(`#${this.state.elementId}`).telerik_ReportViewer({
            serviceUrl: `/${ApiRoutes.Reports}`,
            reportSource: {
                report: this.props.reportName,
                parameters: this.props.reportParameters || {},
            },
            scale: 1.0,
            scaleMode: 'FIT_PAGE_WIDTH',
            viewMode: 'INTERACTIVE',
            printMode: 'SPECIFIC',
            sendEmail: { enabled: false },
            ready: () => {
                if (this.props.onReady) {
                    this.props.onReady();
                } else {
                    this.setState({ loading: true });
                }

                $('.trv-nav').hide();
                $('.trv-content').css('border', 'none');
                // Hack to use custom Checkmate Loader instead of inbuilt Telerik Loader
                const divs = $('.trv-error-pane');
                if (this.props.hideLoadingOverlay) {
                    divs.hide();
                } else {
                    divs.first().html('');
                }

                // Disable double click on the Report Viewer
                const kendoTouch = $('.trv-pages-area').data('kendoTouch');
                if (kendoTouch) {
                    kendoTouch.unbind('doubletap');
                }
            },
            renderingEnd: () => {
                $(`#${this.state.elementId}`).css(
                    'height',
                    this.props.heightInPixels.toString() + 'px'
                );
                $(`#${this.state.elementId}`).css('position', 'relative');
                $(`#${this.state.elementId}`).css('top', '0px');
                $(`#${this.state.elementId}`).css('z-index', '');

                if (this.props.onRenderComplete) {
                    this.props.onRenderComplete();
                } else {
                    this.setState({ loading: false });
                }
            },
        });
    }

    componentDidUpdate(prevProps: IRelativeReportViewerProps) {
        if (!prevProps.refreshReport && this.props.refreshReport) {
            this.refreshReport();
        }
    }

    refreshReport() {
        const reportViewer = $('#reportViewer1').data('telerik_ReportViewer');
        reportViewer.reportSource({
            report: this.props.reportName,
            parameters: this.props.reportParameters ? JSON.parse(this.props.reportParameters) : {},
        });
        reportViewer.refreshReport();
    }

    render() {
        return (
            <>
                {this.state.loading && !this.props.handleLoaderExternally && <Loader />}
                <div id={this.state.elementId} style={{ zIndex: -1 }} />
            </>
        );
    }
}
