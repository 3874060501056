import {
    ApplicationHelpType,
    DataScopesEnum,
    EntityTypes,
    NoteTypes,
    TimelineReportSections,
    UserRightsEnum,
} from '../../../../utilities/Constants';
import {
    IInventoryDetailModel,
    IMonthlyRecapModel,
    IReportSectionDetailModel,
    ISettlementRecapModel,
    ITimelineReportInputModel,
    IZoneNoteModel,
} from '../../../../interfaces/Report/ITimelineReport';

import Authorization from '../../../../stores/Authorization';
import Common from '../../../../stores/Common';
import { Help } from '../../../../components/shared/Help';
import { ICollabReportViewProps } from '../../collab-report/CollabReportController';
import { IKeyValuePairModel } from '../../../../interfaces/ILookup';
import { INoteModel } from '../../../../interfaces/INote';
import { IReportCaseDetailModel } from '../../../../interfaces/Report/IReportCaseDetailModel';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { PortfolioRisk } from '../../../../components/report/PortfolioRisk';
import RelativeReportViewer from '../../../../components/report/RelativeReportViewer';
import { SectionFooter } from '../SectionFooter';
import { SectionHeader } from '../../collab-report/SectionHeader';
import { Stack } from 'react-bootstrap';
import { TextWithLineBreaks } from '../../../../components/shared/TextWithLineBreaks';
import { cloneDeep } from 'lodash';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useState } from 'react';

export function MonthlyRecapReportRenderer<
    T extends IMonthlyRecapModel,
    I extends ITimelineReportInputModel
>(props: ICollabReportViewProps<T, I>) {
    const { user } = useAuthContext();

    const [mapRenderComplete, setMapRenderComplete] = useState<boolean | undefined>(undefined);
    const [diseasesRenderComplete, setDiseasesRenderComplete] = useState<boolean | undefined>(
        undefined
    );
    const getParentCaseGuidByNoteGuid = (
        noteGuid: string,
        section: TimelineReportSections
    ): string => {
        if (props.data) {
            switch (section) {
                case TimelineReportSections.TenderedCases:
                    return (
                        props.data.tenderedCases.find((caseItem) =>
                            caseItem.notes.some((note) => note.guid === noteGuid)
                        )?.caseGuid || ''
                    );
                case TimelineReportSections.RiskIssues:
                    return (
                        props.data.riskIssues.find((issue) =>
                            issue.notes.some((note) => note.guid === noteGuid)
                        )?.caseGuid || ''
                    );
                case TimelineReportSections.SignificantActivity:
                    return (
                        props.data.significantActivities.find((activity) =>
                            activity.notes.some((note) => note.guid === noteGuid)
                        )?.caseGuid || ''
                    );
                default:
                    return '';
            }
        }

        return '';
    };

    const getNoteGuidsByCaseAndSection = (
        caseGuid: string,
        section: TimelineReportSections
    ): string[] => {
        const returnValue: string[] = [];

        if (props.data) {
            switch (section) {
                case TimelineReportSections.TenderedCases: {
                    const tenderedCase = props.data.tenderedCases.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (tenderedCase?.notes) {
                        returnValue.push(...tenderedCase.notes.map((note) => note.guid));
                    }
                    break;
                }
                case TimelineReportSections.RiskIssues: {
                    const riskIssue = props.data.riskIssues.find((x) => x.caseGuid === caseGuid);
                    if (riskIssue?.notes) {
                        returnValue.push(...riskIssue.notes.map((note) => note.guid));
                    }
                    break;
                }
                case TimelineReportSections.SignificantActivity: {
                    const sigActivity = props.data.significantActivities.find(
                        (x) => x.caseGuid === caseGuid
                    );
                    if (sigActivity?.notes) {
                        returnValue.push(...sigActivity.notes.map((note) => note.guid));
                    }
                    break;
                }
                default:
            }
        }

        return returnValue;
    };

    const displaySection = (sectionNumber: number) =>
        (props.isPreviewMode &&
            props.inputModel?.reportSectionDetails?.some((x) => x.section === sectionNumber)) ||
        !props.isPreviewMode;

    const getCommentText = (reportSection: number) => {
        if (props.inputModel && props.inputModel.reportSectionDetails) {
            const sectionMatch = props.inputModel.reportSectionDetails.find(
                (x) => x.section === reportSection
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    const getAllGuidsBySectionForExport = (section: TimelineReportSections) => {
        const result: IReportSectionDetailModel = {
            section,
            sectionName: section.toString(),
            caseGuids: [],
            noteGuids: [],
        };

        if (props.data) {
            switch (section) {
                case TimelineReportSections.TenderedCases:
                    props.data.tenderedCases.forEach((tenderedCase) => {
                        result.caseGuids!.push(tenderedCase.caseGuid);
                        result.noteGuids!.push(...tenderedCase.notes.map((note) => note.guid));
                    });
                    break;
                case TimelineReportSections.RiskIssues:
                    props.data.riskIssues.forEach((issue) => {
                        result.caseGuids!.push(issue.caseGuid);
                        result.noteGuids!.push(...issue.notes.map((note) => note.guid));
                    });
                    break;
                case TimelineReportSections.SignificantActivity:
                    props.data.significantActivities.forEach((activity) => {
                        result.caseGuids!.push(activity.caseGuid);
                        result.noteGuids!.push(...activity.notes.map((note) => note.guid));
                    });
                    break;
                case TimelineReportSections.ZoneNotes:
                    result.noteGuids!.push(...props.data.zoneNotes.map((note) => note.noteGuid));
                    break;
                default:
            }
        }

        return result;
    };

    const getCheckedValue = (
        section: TimelineReportSections,
        entityType: EntityTypes,
        guid: string
    ) => {
        const inputModelCopy = cloneDeep(props.inputModel);
        if (inputModelCopy.reportSectionDetails) {
            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) {
                switch (entityType) {
                    case EntityTypes.Case:
                        if (sectionMatch.caseGuids)
                            return sectionMatch.caseGuids.some((x) => x === guid);
                        break;
                    case EntityTypes.Note:
                        if (sectionMatch.noteGuids)
                            return sectionMatch.noteGuids.some((x) => x === guid);
                        break;
                    default:
                }
            }
        }
        return false;
    };

    const previewFilter = (guid: string, section: number, entityType: EntityTypes) => {
        if (props.isPreviewMode) {
            return getCheckedValue(section, entityType, guid);
        }

        return true;
    };

    const buildReportSection = (item: IReportCaseDetailModel, section: TimelineReportSections) => {
        const rows: any[] = [];
        rows.push(
            <tr key={'section_' + section.toString()}>
                {!props.isPreviewMode && (
                    <td width={25} className={item.notes.length > 0 ? 'border-0' : ''}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={item.caseGuid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(e, section, EntityTypes.Case);
                            }}
                            checked={getCheckedValue(section, EntityTypes.Case, item.caseGuid)}
                            disabled={props.isReportReadonly}
                        />
                    </td>
                )}
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-2'}>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                    {item.priorServed && <>*</>}
                </td>
                {section === TimelineReportSections.InitialCaseEval && (
                    <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-1'}>
                        {Common.dateFormat(item.serviceDate)}
                    </td>
                )}
                {section === TimelineReportSections.TenderedCases && (
                    <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-1'}>
                        {item.status!.displayName ? item.status!.displayName : item.status!.name!}
                    </td>
                )}
                <td
                    className={
                        (item.notes.length > 0 ? 'border-0 ' : '') +
                        (section === TimelineReportSections.InitialCaseEval ||
                        section === TimelineReportSections.TenderedCases
                            ? 'col-sm-2'
                            : 'col-sm-3')
                    }
                >
                    {item.jurisdiction}
                </td>
                <td className={(item.notes.length > 0 ? 'border-0 ' : '') + 'col-sm-3'}>
                    {item.plaintiffsFirmDisplayName}
                </td>
                <td
                    className={
                        (item.notes.length > 0 ? 'border-0 ' : '') +
                        (section === TimelineReportSections.InitialCaseEval
                            ? 'col-sm-1'
                            : 'col-sm-2')
                    }
                >
                    {item.diagnosis}
                </td>
                <td className={item.notes.length > 0 ? 'border-0' : ''}>
                    {item.additionalCaseIdentifier}
                </td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, user) && (
                    <td
                        width={45}
                        className={(item.notes.length > 0 ? 'border-0 ' : '') + 'center'}
                    >
                        {!props.isReportReadonly && !props.isPreviewMode && (
                            <button
                                className="btn-no-bg"
                                onClick={() => {
                                    handleEasyUpdate(item.caseGuid);
                                }}
                            >
                                <i
                                    className={`fal fa-edit ${
                                        props.collabReportState.easyUpdateAttemptedCaseList.filter(
                                            (x) => x === item.caseGuid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        )}
                    </td>
                )}
            </tr>
        );

        let colSpan = 5;
        switch (section) {
            case TimelineReportSections.InitialCaseEval:
            case TimelineReportSections.TenderedCases:
                colSpan = 6;
                break;
            default:
        }

        if (item.notes) {
            buildNotesRows(item, section, colSpan).map((row: any) => {
                rows.push(row);
            });
        }
        return rows;
    };

    const buildNotesRows = (
        caseItem: IReportCaseDetailModel,
        section: TimelineReportSections,
        colSpan: number
    ) => {
        const rows: any[] = [];
        for (let i = 0; i < caseItem.notes.length; i++) {
            const currentNote: INoteModel = {
                guid: caseItem.notes[i].guid,
                type: { id: NoteTypes.CaseNote },
                status: { id: 0 },
                purpose: { id: 0 },
                caseGuid: caseItem.caseGuid,
                caseName: caseItem.caseName,
                qScore: caseItem.qScore,
                qProjection: caseItem.qProjection,
            };

            rows.push(
                <tr key={i}>
                    <td className={caseItem.notes.length - 1 === i ? '' : 'border-0'}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={caseItem.notes[i].guid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(e, section, EntityTypes.Note);
                            }}
                            checked={getCheckedValue(
                                section,
                                EntityTypes.Note,
                                caseItem.notes[i].guid
                            )}
                        />
                    </td>
                    <td
                        className={caseItem.notes.length - 1 === i ? '' : 'border-0'}
                        colSpan={colSpan}
                    >
                        {caseItem.notes[i].content && (
                            <TextWithLineBreaks text={caseItem.notes[i].content} />
                        )}
                    </td>
                    {Authorization.userHasRight(UserRightsEnum.EditCaseNote, user) &&
                        (Authorization.getUserDefaultOrganizationDataScope(user) !==
                            DataScopesEnum.LocalBasic.Value ||
                            caseItem.notes[i].allowLocalEdit) && (
                            <td
                                width={45}
                                className={
                                    (caseItem.notes.length - 1 === i ? '' : 'border-0 ') + 'center'
                                }
                            >
                                <button
                                    className="btn btn-no-bg"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        handleNoteEdit(e, currentNote);
                                    }}
                                >
                                    <i
                                        className={`fal fa-pen ${
                                            props.collabReportState.noteEditAttemptedGuidList.filter(
                                                (x) => x === currentNote.guid
                                            ).length > 0
                                                ? 'color-gray'
                                                : 'color-black'
                                        } `}
                                    />
                                </button>
                            </td>
                        )}
                </tr>
            );
        }

        return rows;
    };

    const buildZoneNoteRows = (item: IZoneNoteModel) => {
        const rows = [];

        rows.push(
            <tr key={item.noteGuid}>
                {!props.isPreviewMode && (
                    <td className="border-0">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={item.noteGuid}
                            checked={getCheckedValue(
                                TimelineReportSections.ZoneNotes,
                                EntityTypes.Note,
                                item.noteGuid
                            )}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(
                                    e,
                                    TimelineReportSections.ZoneNotes,
                                    EntityTypes.Note
                                );
                            }}
                        />
                    </td>
                )}
                <td className="col-sm-6 border-0">{item.topic}</td>
                <td className="border-0">{item.source}</td>
                {Authorization.userHasRight(UserRightsEnum.EditZoneNote, user) && (
                    <td className="border-0" width={45}>
                        <button
                            className="btn btn-no-bg float-end"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                handleNoteEdit(e, {
                                    guid: item.noteGuid,
                                    type: { id: NoteTypes.ZoneNote },
                                    status: { id: 0 },
                                    purpose: { id: 0 },
                                });
                            }}
                        >
                            <i
                                className={`fal fa-pen ${
                                    props.collabReportState.noteEditAttemptedGuidList.includes(
                                        item.noteGuid
                                    )
                                        ? 'color-gray'
                                        : 'color-black'
                                } `}
                            />
                        </button>
                    </td>
                )}
            </tr>
        );

        rows.push(
            <tr key={`${item.noteGuid}-content`}>
                <td />
                <td colSpan={5}>
                    {item.noteContent && <TextWithLineBreaks text={item.noteContent} />}
                </td>
            </tr>
        );

        return rows;
    };

    const handleEasyUpdate = (caseGuid: string, noteGuid?: string) => {
        const easyUpdateAttemptedCaseList = cloneDeep(
            props.collabReportState.easyUpdateAttemptedCaseList
        );
        
        const easyUpdateAttemptedNoteGuidList = cloneDeep(
            props.collabReportState.noteEditAttemptedGuidList
        );

        if (!easyUpdateAttemptedCaseList.some((x) => x === caseGuid)) {
            easyUpdateAttemptedCaseList.push(caseGuid);
        }

        if (noteGuid) {
            easyUpdateAttemptedNoteGuidList.push(noteGuid);
        }

        props.setCollabState((prev) => ({
            ...prev,
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
            noteEditAttemptedGuidList: easyUpdateAttemptedNoteGuidList,
        }));
    };

    const handleCommentsButtonClick = (section: number) => {
        props.onEventTriggered('commentsModalStateChange', { open: true, section });
    };

    const handleSelectAllCheckedChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const selectAllSectionsCopy = cloneDeep(props.collabReportState.selectAllSections);
        const inputModelCopy = cloneDeep(props.inputModel);

        if (!inputModelCopy.reportSectionDetails) inputModelCopy.reportSectionDetails = [];

        let sectionMatch = inputModelCopy.reportSectionDetails.find((x) => x.section === section);
        if (!sectionMatch) {
            sectionMatch = { section: section, sectionName: section.toString() };
            inputModelCopy.reportSectionDetails.push(sectionMatch);
        }

        if (event.target.checked) {
            if (!selectAllSectionsCopy.some((x) => x === section)) {
                selectAllSectionsCopy.push(section);
            }
            const allGuids = getAllGuidsBySectionForExport(section);
            sectionMatch.caseGuids = allGuids.caseGuids;
            sectionMatch.noteGuids = allGuids.noteGuids;
        } else {
            if (selectAllSectionsCopy.findIndex((x) => x === section) > -1)
                selectAllSectionsCopy.splice(
                    selectAllSectionsCopy.findIndex((x) => x === section),
                    1
                );
            sectionMatch.caseGuids = [];
            sectionMatch.noteGuids = [];
        }

        props.setCollabState((prev) => ({
            ...prev,
            selectAllSections: selectAllSectionsCopy,
            inputModel: inputModelCopy,
        }));
    };

    const isSelectAllChecked = (section: TimelineReportSections) => {
        return props.collabReportState.selectAllSections.includes(section);
    };

    const handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        entityType: EntityTypes
    ) => {
        const inputModelCopy = cloneDeep(props.inputModel);
        const selectAllSectionsCopy = cloneDeep(props.collabReportState.selectAllSections);

        if (event.target.checked) {
            if (!inputModelCopy.reportSectionDetails) inputModelCopy.reportSectionDetails = [];

            if (inputModelCopy.reportSectionDetails.findIndex((x) => x.section === section) === -1)
                inputModelCopy.reportSectionDetails.push({
                    section: section,
                    sectionName: section.toString(),
                });

            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (!sectionMatch) return;
            if (!sectionMatch.caseGuids) sectionMatch.caseGuids = [];
            if (!sectionMatch.noteGuids) sectionMatch.noteGuids = [];

            switch (entityType) {
                case EntityTypes.Case:
                    {
                        if (
                            sectionMatch.caseGuids?.findIndex((x) => x === event.target.name) === -1
                        )
                            sectionMatch.caseGuids?.push(event.target.name);

                        const noteGuidsToAddToSelectedList = getNoteGuidsByCaseAndSection(
                            event.target.name,
                            section
                        );
                        if (noteGuidsToAddToSelectedList.length > 0) {
                            for (let i = 0; i < noteGuidsToAddToSelectedList.length; i++) {
                                if (
                                    sectionMatch.noteGuids?.findIndex(
                                        (x) => x === noteGuidsToAddToSelectedList[i]
                                    ) === -1
                                )
                                    sectionMatch.noteGuids?.push(noteGuidsToAddToSelectedList[i]);
                            }
                        }
                    }
                    break;
                case EntityTypes.Note:
                    if (sectionMatch.noteGuids?.findIndex((x) => x === event.target.name) === -1) {
                        sectionMatch.noteGuids?.push(event.target.name);

                        // Find the parent;  if it is not checked, check it ( for all sections except other client requests)
                        const parentCaseGuid = getParentCaseGuidByNoteGuid(
                            event.target.name,
                            section
                        );
                        if (
                            parentCaseGuid &&
                            sectionMatch.caseGuids?.findIndex((x) => x === parentCaseGuid) === -1
                        )
                            sectionMatch.caseGuids?.push(parentCaseGuid);
                    }
                    break;
                default:
            }
        } else {
            if (selectAllSectionsCopy.findIndex((x) => x === section) > -1)
                selectAllSectionsCopy.splice(
                    selectAllSectionsCopy.findIndex((x) => x === section),
                    1
                );

            if (inputModelCopy.reportSectionDetails && inputModelCopy.reportSectionDetails) {
                const sectionMatch = inputModelCopy.reportSectionDetails.find(
                    (x) => x.section === section
                );
                if (sectionMatch) {
                    switch (entityType) {
                        case EntityTypes.Case:
                            if (
                                sectionMatch.caseGuids &&
                                sectionMatch.caseGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            ) {
                                sectionMatch.caseGuids.splice(
                                    sectionMatch.caseGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                                if (sectionMatch.noteGuids) {
                                    const noteGuidsToRemoveFromSelectedList =
                                        getNoteGuidsByCaseAndSection(event.target.name, section);
                                    if (noteGuidsToRemoveFromSelectedList.length > 0) {
                                        for (
                                            let i = 0;
                                            i < noteGuidsToRemoveFromSelectedList.length;
                                            i++
                                        ) {
                                            if (
                                                sectionMatch.noteGuids?.findIndex(
                                                    (x) =>
                                                        x === noteGuidsToRemoveFromSelectedList[i]
                                                ) > -1
                                            )
                                                sectionMatch.noteGuids?.splice(
                                                    sectionMatch.noteGuids?.findIndex(
                                                        (x) =>
                                                            x ===
                                                            noteGuidsToRemoveFromSelectedList[i]
                                                    ),
                                                    1
                                                );
                                        }
                                    }
                                }
                            }
                            break;
                        case EntityTypes.Note:
                            if (
                                sectionMatch.noteGuids &&
                                sectionMatch.noteGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            )
                                sectionMatch.noteGuids.splice(
                                    sectionMatch.noteGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                            break;
                        default:
                    }
                }
            }
        }

        props.setCollabState((prev) => ({
            ...prev,
            inputModel: inputModelCopy,
            selectAllSections: selectAllSectionsCopy,
        }));
    };

    const handleMapRenderComplete = () => {
        setMapRenderComplete(true);
    };

    const handleDiseasesRenderComplete = () => {
        setDiseasesRenderComplete(true);
    };

    const handleNoteEdit = (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => {
        if (!noteItem.guid) return;

        const noteEditAttemptedGuidList = [...props.collabReportState.noteEditAttemptedGuidList];
        if (noteEditAttemptedGuidList.filter((x) => x === noteItem.guid).length === 0) {
            noteEditAttemptedGuidList.push(noteItem.guid);
        }

        props.setCollabState((prev) => ({
            ...prev,
            openNoteEditor: true,
            currentNote: noteItem,
        }));
    };

    if (!props.data) {
        return null;
    }

    return (
        <div style={{ overflow: 'auto', paddingRight: '12px' }}>
            {displaySection(TimelineReportSections.CaseInventory) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.CaseInventory
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.CaseInventory}
                        title="Case Inventory"
                    />
                    {props.data.inventory.length > 0 && (
                        <table className="table thick-outer-border">
                            <thead>
                                <tr>
                                    {props.data.inventory.map((item: IKeyValuePairModel) => {
                                        return (
                                            <th className="align-top text-center" key={item.key}>
                                                {item.key!.split('<br/>').map((x, key) => {
                                                    return (
                                                        <span key={key}>
                                                            {x}
                                                            <br />
                                                        </span>
                                                    );
                                                })}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody className="align-top text-center">
                                <tr>
                                    {props.data.inventory.map((item: IKeyValuePairModel) => {
                                        return <td>{item.value}</td>;
                                    })}
                                </tr>
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.CaseInventory)}
                        rowCount={props.data.inventory.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.OpenActiveCasesStateMap) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.OpenActiveCasesStateMap
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.OpenActiveCasesStateMap}
                        title="Open - Active Cases by State"
                    />
                    <ChartPlaceholder
                        heightInPx={470}
                        text="Loading Data..."
                        show={!mapRenderComplete}
                    />
                    <RelativeReportViewer
                        reportName="OpenActiveCasesMap.trdp"
                        heightInPixels={470}
                        reportParameters={{ endDate: props.inputModel!.endDate }}
                        refreshReport={false}
                        onRenderComplete={handleMapRenderComplete}
                        handleLoaderExternally
                        hideLoadingOverlay
                    />
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.OpenActiveCasesStateMap)}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.CaseInventoryByState) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.CaseInventoryByState
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.CaseInventoryByState}
                        title="Case Inventory by State"
                    />
                    {props.data.inventoryByState.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="col-sm-2">State</th>
                                    <th className="col-sm-2">Open-Active</th>
                                    {props.inputModel.additionalSettings
                                        ?.caseInventoryByStateDisplayNumberOfPlaintiffs && (
                                        <th className="col-sm-2"># of Plaintiffs</th>
                                    )}
                                    <th className="col-sm-2">YTD New Cases </th>
                                    <th className="col-sm-2">YTD Dismissed Cases</th>
                                    <th className="col-sm-2">YTD Settled Cases</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.inventoryByState.map((item: IInventoryDetailModel) => {
                                    return (
                                        <tr>
                                            <td className="col-sm-2">{item.key}</td>
                                            <td className="col-sm-2">{item.openActive}</td>
                                            {props.inputModel.additionalSettings
                                                ?.caseInventoryByStateDisplayNumberOfPlaintiffs && (
                                                <td className="col-sm-2">
                                                    {item.numberOfPlaintiffs}
                                                </td>
                                            )}
                                            <td className="col-sm-2">{item.new}</td>
                                            <td className="col-sm-2">{item.dismissed}</td>
                                            <td className="col-sm-2">{item.settled}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.OpenActiveCasesStateMap)}
                        rowCount={props.data.inventoryByState.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.OpenActiveCasesByDisease) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.OpenActiveCasesByDisease
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.OpenActiveCasesByDisease}
                        title="Open - Active Cases by Disease"
                    />
                    <ChartPlaceholder
                        heightInPx={325}
                        text="Loading Data..."
                        show={!diseasesRenderComplete}
                    />
                    <RelativeReportViewer
                        reportName="OpenActiveCasesByDisease.trdp"
                        heightInPixels={325}
                        reportParameters={{
                            endDate: props.inputModel!.endDate,
                        }}
                        refreshReport={false}
                        onRenderComplete={handleDiseasesRenderComplete}
                        handleLoaderExternally
                        hideLoadingOverlay
                    />
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.OpenActiveCasesByDisease)}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.RiskProfile) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.RiskProfile
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.RiskProfile}
                        title="Portfolio Risk"
                    />
                    {props.data.riskProfile.length > 0 && (
                        <PortfolioRisk riskProfile={props.data.riskProfile} />
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.RiskProfile)}
                        rowCount={props.data.riskProfile.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.SettlementRecap) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.SettlementRecap
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.SettlementRecap}
                        title="Settlement Recap YTD"
                    />
                    {props.data.settlementRecap.length > 0 && (
                        <table className="table thick-outer-border">
                            <thead>
                                <tr>
                                    <th className="col-sm-2">State</th>
                                    <th className="col-sm-2">Meso</th>
                                    <th className="col-sm-2">Lung Cancer</th>
                                    <th className="col-sm-2">Other Disease</th>
                                    <th className="col-sm-2">YTD Total</th>
                                    {props.data.settlementRecap.some((x) => x.priorYearTotal) && (
                                        <th className="col-sm-2">
                                            {Common.Year(props.inputModel!.endDate!) - 1} Total
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.settlementRecap.map(
                                    (item: ISettlementRecapModel, index: number) => {
                                        return (
                                            <tr key={index}>
                                                <td className="col-sm-2">{item.key}</td>
                                                <td className="col-sm-2">
                                                    {item.diagnosis1
                                                        ? item.diagnosis1 +
                                                          (item.diagnosis1Count
                                                              ? ' | ' + item.diagnosis1Count
                                                              : '')
                                                        : ''}
                                                </td>
                                                <td className="col-sm-2">
                                                    {item.diagnosis2
                                                        ? item.diagnosis2 +
                                                          (item.diagnosis2Count
                                                              ? ' | ' + item.diagnosis2Count
                                                              : '')
                                                        : ''}
                                                </td>
                                                <td className="col-sm-2">
                                                    {item.diagnosis3
                                                        ? item.diagnosis3 +
                                                          (item.diagnosis3Count
                                                              ? ' | ' + item.diagnosis3Count
                                                              : '')
                                                        : ''}
                                                </td>
                                                <td className="col-sm-2">{item.total}</td>
                                                <td>{item.priorYearTotal ?? null}</td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.SettlementRecap)}
                        rowCount={props.data.settlementRecap.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.TenderedCases) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.TenderedCases
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.TenderedCases}
                        title="Tendered Cases"
                        helpComponent={
                            <Help
                                type={ApplicationHelpType.Info}
                                title="Tendered Cases"
                                additionalStyleClassNames="font-size-sm"
                                helpText="Cases that are Tendered-Pending as of the last date of the reporting period will be displayed along with the most recent case note, Category = Case.  \n\nCases with a Tendered-Accepted date during the reporting period will be displayed along with the most recent case note, Category = Case."
                            />
                        }
                    />
                    {props.data.tenderedCases.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="selectAll"
                                            checked={isSelectAllChecked(
                                                TimelineReportSections.TenderedCases
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleSelectAllCheckedChanged(
                                                    e,
                                                    TimelineReportSections.TenderedCases
                                                );
                                            }}
                                            disabled={props.isReportReadonly}
                                        />
                                    </th>
                                    <th className="col-sm-2">Case Name</th>
                                    <th>Status</th>
                                    <th className="col-sm-2">Jurisdiction</th>
                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                    <th className="col-sm-2">Disease</th>
                                    <th className="col-sm-2">
                                        {props.inputModel.additionalSettings
                                            ?.reportCaseDetailsLastColumnDisplayName ??
                                            'Last Column Header Name'}
                                    </th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.tenderedCases
                                    .filter((item) =>
                                        previewFilter(
                                            item.caseGuid,
                                            TimelineReportSections.TenderedCases,
                                            EntityTypes.Case
                                        )
                                    )
                                    .map((item: IReportCaseDetailModel) =>
                                        buildReportSection(
                                            item,
                                            TimelineReportSections.TenderedCases
                                        )
                                    )}
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.TenderedCases)}
                        rowCount={props.data.tenderedCases.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.RiskIssues) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.RiskIssues
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.RiskIssues}
                        title="Risk Issues"
                    />
                    {props.data.riskIssues.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="selectAll"
                                            checked={isSelectAllChecked(
                                                TimelineReportSections.RiskIssues
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleSelectAllCheckedChanged(
                                                    e,
                                                    TimelineReportSections.RiskIssues
                                                );
                                            }}
                                        />
                                    </th>
                                    <th className="col-sm-2">Case Name</th>
                                    <th className="col-sm-2">Jurisdiction</th>
                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                    <th className="col-sm-2">Disease</th>
                                    <th>
                                        {props.inputModel.additionalSettings
                                            ?.reportCaseDetailsLastColumnDisplayName ??
                                            'Last Column Header Name'}
                                    </th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.riskIssues
                                    .filter((item) =>
                                        previewFilter(
                                            item.caseGuid,
                                            TimelineReportSections.RiskIssues,
                                            EntityTypes.Case
                                        )
                                    )
                                    .map((item: IReportCaseDetailModel) =>
                                        buildReportSection(item, TimelineReportSections.RiskIssues)
                                    )}
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.RiskIssues)}
                        rowCount={props.data.riskIssues.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.SignificantActivity) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.SignificantActivity
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.SignificantActivity}
                        title="Significant Activity"
                    />
                    {props.data.significantActivities.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            name="selectAll"
                                            checked={isSelectAllChecked(
                                                TimelineReportSections.SignificantActivity
                                            )}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                handleSelectAllCheckedChanged(
                                                    e,
                                                    TimelineReportSections.SignificantActivity
                                                );
                                            }}
                                            disabled={props.isReportReadonly}
                                        />
                                    </th>
                                    <th className="col-sm-2">Case Name</th>
                                    <th className="col-sm-2">Jurisdiction</th>
                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                    <th className="col-sm-2">Disease</th>
                                    <th>
                                        {props.inputModel.additionalSettings
                                            ?.reportCaseDetailsLastColumnDisplayName ??
                                            'Last Column Header Name'}
                                    </th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.significantActivities
                                    .filter((item) =>
                                        previewFilter(
                                            item.caseGuid,
                                            TimelineReportSections.SignificantActivity,
                                            EntityTypes.Case
                                        )
                                    )
                                    .map((item: IReportCaseDetailModel) =>
                                        buildReportSection(
                                            item,
                                            TimelineReportSections.SignificantActivity
                                        )
                                    )}
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.SignificantActivity)}
                        rowCount={props.data.significantActivities.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.UpcomingTrialDates) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.UpcomingTrialDates
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.UpcomingTrialDates}
                        title="Upcoming Trial Dates"
                    />
                    {props.data.trialDates.length > 0 && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border-0" />
                                    <th className="col-sm-2">Case Name</th>
                                    <th className="col-sm-1">Trial Date</th>
                                    <th className="col-sm-3">Jurisdiction</th>
                                    <th className="col-sm-2">Plaintiffs Firm</th>
                                    <th className="col-sm-1">Disease</th>
                                    <th>
                                        {props.inputModel.additionalSettings
                                            ?.reportCaseDetailsLastColumnDisplayName ??
                                            'Last Column Header Name'}
                                    </th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.trialDates.map((item: IReportCaseDetailModel) => {
                                    return (
                                        <tr>
                                            <td width={25} className="border-0" />
                                            <td className="col-sm-2">
                                                <Link
                                                    target="_blank"
                                                    to={LocalRoutes.CaseFile.replace(
                                                        ':guid',
                                                        item.caseGuid
                                                    )}
                                                >
                                                    {item.caseName}
                                                </Link>
                                            </td>
                                            <td className="col-sm-1">
                                                {Common.dateFormat(item.trialDate)}
                                            </td>
                                            <td className="col-sm-3">{item.jurisdiction}</td>
                                            <td className="col-sm-2">
                                                {item.plaintiffsFirmDisplayName}
                                            </td>
                                            <td className="col-sm-1">{item.diagnosis}</td>
                                            <td>{item.additionalCaseIdentifier}</td>
                                            {Authorization.isAuthorizedToRoute(
                                                LocalRoutes.CaseEasyUpdate,
                                                user
                                            ) && (
                                                <td width={45} className="center">
                                                    {' '}
                                                    <button
                                                        className="btn-no-bg"
                                                        onClick={() => {
                                                            handleEasyUpdate(item.caseGuid);
                                                        }}
                                                    >
                                                        <i
                                                            className={`fal fa-pen ${
                                                                props.collabReportState.easyUpdateAttemptedCaseList.filter(
                                                                    (x) => x === item.caseGuid
                                                                ).length > 0
                                                                    ? 'color-gray'
                                                                    : 'color-black'
                                                            } `}
                                                        />
                                                    </button>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.UpcomingTrialDates)}
                        rowCount={props.data.trialDates.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.ZoneNotes) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.ZoneNotes
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.ZoneNotes}
                        title="Industry News"
                    />
                    {props.data.zoneNotes.length > 0 && (
                        <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="selectAll"
                                                checked={isSelectAllChecked(
                                                    TimelineReportSections.ZoneNotes
                                                )}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    handleSelectAllCheckedChanged(
                                                        e,
                                                        TimelineReportSections.ZoneNotes
                                                    );
                                                }}
                                                disabled={props.isReportReadonly}
                                            />
                                        </th>
                                        <th className="col-sm-6">Topic</th>
                                        <th>Source</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.zoneNotes.map((item: IZoneNoteModel) => {
                                        return buildZoneNoteRows(item);
                                    })}
                                </tbody>
                            </table>
                        </>
                    )}
                    <SectionFooter
                        text={getCommentText(TimelineReportSections.ZoneNotes)}
                        rowCount={props.data.zoneNotes.length}
                    />
                </div>
            )}
        </div>
    );
}

function ChartPlaceholder(props: { heightInPx: number; text: string; show?: boolean }) {
    if (!props.show) return null;

    const yMargin = 15;

    return (
        <Stack
            style={{
                alignItems: 'center',
                background: 'whitesmoke',
                border: '1px solid whitesmoke',
                height: props.heightInPx - 2 * yMargin,
                justifyContent: 'center',
                marginBottom: yMargin,
                marginTop: yMargin,
            }}
        >
            <span>{props.text}</span>
        </Stack>
    );
}
