import * as React from 'react';

import {
    ApplicationHelpType,
    AssertionDescriptorValueDisplayTypes,
} from '../../utilities/Constants';

import Common from '../../stores/Common';
import { Help } from '../shared/Help';
import { ICrossTabDataBaseReportModel } from '../../interfaces/Report/ITimelineReport';

interface IPortfolioRiskProps {
    riskProfile: ICrossTabDataBaseReportModel[];
    title?: string;
}

export class PortfolioRisk extends React.Component<IPortfolioRiskProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    buildCrossTabHeader(reportData: ICrossTabDataBaseReportModel[]) {
        const rows: any[] = [];

        const totalColumns = Math.max(
            ...reportData.map(function (o) {
                return o.columnSortOrder;
            })
        );
        const columns = [];
        columns.push(<th className="border-0"></th>);
        for (var j = 1; j < totalColumns + 1; j++) {
            const name = reportData.filter((x) => x.columnSortOrder === j)[0].columnDetail;
            const helpText = reportData.filter((x) => x.columnSortOrder === j)[0]
                .columnDetailHelpText;
            columns.push(
                <th className="border-0">
                    {name}
                    {helpText ? (
                        <Help type={ApplicationHelpType.Info} title={name} helpText={helpText} />
                    ) : null}
                </th>
            );
        }
        columns.push(<th className="border-0">Total</th>);

        rows.push(<tr>{columns}</tr>);
        return rows;
    }

    buildCrossTabRows(reportData: ICrossTabDataBaseReportModel[]) {
        const rows: any[] = [];

        const totalColumnCount = Math.max(
            ...reportData.map(function (o) {
                return o.columnSortOrder;
            })
        );
        const totalRowCount = Math.max(
            ...reportData.map(function (o) {
                return o.rowSortOrder;
            })
        );

        for (var i = 1; i < totalRowCount + 1; i++) {
            const columns = [];
            let columnTotalValue = 0;
            let columnTotalValue1 = 0;
            let totalValue1DisplayType = 0;
            columns.push(<td>{reportData.filter((x) => x.rowSortOrder === i)[0].rowDetail}</td>);
            for (var j = 1; j < totalColumnCount + 1; j++) {
                const value =
                    reportData.filter((x) => x.rowSortOrder === i && x.columnSortOrder === j)
                        .length > 0
                        ? reportData.filter(
                              (x) => x.rowSortOrder === i && x.columnSortOrder === j
                          )[0].value
                        : undefined;
                let text = '';
                if (value) {
                    columnTotalValue += value;
                    const value1 = reportData.filter(
                        (x) => x.rowSortOrder === i && x.columnSortOrder === j
                    )[0].value1;
                    text = value.toString();
                    if (value1) {
                        columnTotalValue1 += value1;
                        let valueDisplayType1 = 0;
                        if (
                            reportData.filter(
                                (x) => x.rowSortOrder === i && x.columnSortOrder === j
                            )[0].valueDisplayType1
                        ) {
                            valueDisplayType1 = reportData.filter(
                                (x) => x.rowSortOrder === i && x.columnSortOrder === j
                            )[0].valueDisplayType1;
                            totalValue1DisplayType = valueDisplayType1;
                        }
                        switch (valueDisplayType1) {
                            case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                                text += ' | ' + value1 + '%';
                                break;
                            case AssertionDescriptorValueDisplayTypes.Currency.Value:
                                text += ' | ' + Common.formatCurrency(value1.toString());
                                break;
                            default:
                                text += ' | ' + value1;
                        }
                    }
                }
                columns.push(<td>{text}</td>);
            }

            let columnTotalValueText = '';
            if (columnTotalValue) columnTotalValueText = columnTotalValue.toString();

            if (columnTotalValue1) {
                switch (totalValue1DisplayType) {
                    case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                        columnTotalValueText += ' | ' + columnTotalValue1 + '%';
                        break;
                    case AssertionDescriptorValueDisplayTypes.Currency.Value:
                        columnTotalValueText +=
                            ' | ' + Common.formatCurrency(columnTotalValue1.toString());
                        break;
                    default:
                        columnTotalValueText += ' | ' + columnTotalValue1;
                }
            }

            //Total Column
            columns.push(<td>{columnTotalValueText}</td>);

            const styleString = {
                color: reportData.filter((x) => x.rowSortOrder === i)[0].format,
            };
            rows.push(
                <tr key={i} style={styleString}>
                    {columns}
                </tr>
            );
        }

        // Total Row
        const totalColumns = [];
        totalColumns.push(<td>Total</td>);

        for (var j = 1; j < totalColumnCount + 1; j++) {
            let rowTotalValue = 0;
            let rowTotalValue1 = 0;
            let rowTotalValue1DisplayType = 0;
            const data = reportData.filter((x) => x.columnSortOrder === j);
            for (let x = 0; x < data.length; x++) {
                const item = data[x];
                if (item.value) rowTotalValue += item.value;
                if (item.value1) {
                    rowTotalValue1 += item.value1;
                    rowTotalValue1DisplayType = item.valueDisplayType1;
                }
            }
            let rowTotalValue1String = '';
            switch (rowTotalValue1DisplayType) {
                case AssertionDescriptorValueDisplayTypes.Percentage.Value:
                    rowTotalValue1String = rowTotalValue1.toString() + '%';
                    break;
                case AssertionDescriptorValueDisplayTypes.Currency.Value:
                    rowTotalValue1String = Common.formatCurrency(rowTotalValue1.toString()) || '';
                    break;
                default:
            }

            let rowTotalFullString = '';
            if (rowTotalValue) rowTotalFullString += rowTotalValue.toString();
            if (rowTotalValue1String) rowTotalFullString += ' | ' + rowTotalValue1String;

            totalColumns.push(<td>{rowTotalFullString}</td>);
        }
        totalColumns.push(<td></td>);
        rows.push(<tr key={100}>{totalColumns}</tr>);

        return rows;
    }

    render() {
        if (this.props.riskProfile.length == 0) return null;
        return (
            <div>
                {this.props.title ? (
                    <div className="mt-3">
                        <h4 className="d-inline-block">{this.props.title}</h4>
                        <Help
                            type={ApplicationHelpType.Info}
                            title={this.props.title}
                            helpText="Portfolio Risk is reflected as SQ Projection v. SQ Score"
                        />
                    </div>
                ) : null}

                <table className="table table-sm">
                    <thead>{this.buildCrossTabHeader(this.props.riskProfile)}</thead>
                    <tbody>{this.buildCrossTabRows(this.props.riskProfile)}</tbody>
                </table>
            </div>
        );
    }
}
