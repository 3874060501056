import { MyDocumentsTypesEnum, ReportTypeToExportUrlMap } from '../../../utilities/Constants';

import { ExportFileButton } from '../../../components/shared/ExportFileButton';
import { IExportSettings } from '../../../interfaces/Report/IReport';
import { LoadingButton } from '../../../shared/buttons/LoadingButton';
import { Stack } from 'react-bootstrap';
import classNames from 'classnames';

interface ICollabReportReadOnlyActionsProps {
    exportSettings: IExportSettings;
    forceReadonly?: boolean;
    hasChatActs?: boolean;
    hasCollabAct?: boolean;
    hasReviewAct?: boolean;
    hideCollabButton?: boolean;
    hideCloseReviewAct?: boolean;
    hidePreview?: boolean;
    hideHistoryButton: boolean;
    isClosingReviewAct?: boolean;
    isPublished?: boolean;
    onCloseReviewActClick: () => void;
    onCollabClick: () => void;
    onChatClick: () => void;
    onEditClick: () => void;
    onExportError?: () => void;
    onHistoryClick: () => void;
    onPreviewClick: () => void;
    previewText: string;
    reportTypeId: number;
}

export function CollabReportReadOnlyActions(props: ICollabReportReadOnlyActionsProps) {
    return (
        <Stack direction="horizontal" style={{ flexWrap: 'wrap' }}>
            {!props.hidePreview && !props.isPublished && (
                <button className="btn btn-no-bg text-gray" onClick={props.onPreviewClick}>
                    <i className="fal fa-magnifying-glass" />
                    &nbsp;{props.previewText}
                </button>
            )}
            {props.hasReviewAct && (
                <LoadingButton
                    buttonCssClass={classNames('btn btn-no-bg font-orange')}
                    icon="fal fa-check"
                    loading={props.isClosingReviewAct}
                    loadingText="Close Review Act"
                    onClick={props.onCloseReviewActClick}
                    text="Review Act"
                />
            )}
            {!props.hideCollabButton && (
                <button
                    className={classNames('btn btn-no-bg', {
                        'font-orange': props.hasCollabAct,
                        'text-gray': !props.hasCollabAct,
                    })}
                    onClick={props.onCollabClick}
                >
                    <i className="fal fa-users" />
                    &nbsp;Collab
                </button>
            )}
            {!props.hideHistoryButton && (
                <button className="btn btn-no-bg text-gray" onClick={props.onHistoryClick}>
                    <i className="fal fa-history" />
                    &nbsp;History
                </button>
            )}
            <ExportFileButton
                url={'/' + ReportTypeToExportUrlMap[props.reportTypeId]}
                reportType={{
                    id: MyDocumentsTypesEnum.WeeklyRecap.Value,
                    availableReportOptions: {
                        customOptions: props.exportSettings.options,
                    },
                }}
                reportParameters={props.exportSettings.parameters}
                onError={props.onExportError}
                icon="fa-file-pdf"
            />
            <button
                className={classNames('btn btn-no-bg', {
                    'font-orange': props.hasChatActs,
                    'text-gray': !props.hasChatActs,
                })}
                onClick={props.onChatClick}
            >
                <i className="fal fa-messages" />
                &nbsp;Chat
            </button>
            {!props.isPublished && !props.forceReadonly && (
                <button className="btn btn-no-bg text-gray" onClick={props.onEditClick}>
                    <i className="fal fa-pen" />
                    &nbsp;Edit
                </button>
            )}
        </Stack>
    );
}
