import { Button, Stack } from 'react-bootstrap';
import { MyDocumentsTypesEnum, ReportStatusTypesEnum } from '../../../utilities/Constants';

import { CollabReportCreateActions } from './CollabReportCreateActions';
import { CollabReportNonReadOnlyActions } from './CollabReportNonReadOnlyActions';
import { CollabReportReadOnlyActions } from './CollabReportReadOnlyActions';
import { IExportSettings } from '../../../interfaces/Report/IReport';
import { ILookupModel } from '../../../interfaces/ILookup';

export enum CollabReportPageActionsType {
    SettingsOnly,
    CollabReportReadOnlyActions,
    CollabReportNonReadOnlyActions,
    CollabReportCreateActions,
}

export interface ICollabReportPageActionsProps {
    enableCollab?: boolean;
    exportSettings: IExportSettings;
    forceHideEditButton: boolean;
    hasChatActs?: boolean;
    hasCollabAct?: boolean;
    hasReviewAct?: boolean;
    hasUnsavedChanges: boolean;
    isClosingReviewAct?: boolean;
    isPreviewMode: boolean;
    onCancelClick: () => void;
    onChatClick: () => void;
    onCloseReviewActClick: () => void;
    onCommentsClick: (section?: number) => void;
    onEditClick: () => void;
    onExportError?: () => void;
    onHistoryClick: () => void;
    onPreviewClick: () => void;
    onRefreshClick: () => void;
    onSaveClick: () => void;
    onSaveAsClick: () => void;
    onSettingsClick: () => void;
    pageActionsDisplayType: CollabReportPageActionsType;
    reportStatus: ILookupModel | undefined;
    reportTypeId: number;
    userIsReader: boolean;
}

export function CollabReportPageActions(props: ICollabReportPageActionsProps) {
    return (
        <div>
            <div>
                {props.pageActionsDisplayType === CollabReportPageActionsType.SettingsOnly ? (
                    <button
                        className="btn btn-no-bg float-end text-gray"
                        onClick={props.onSettingsClick}
                    >
                        <i className="fal fa-user-cog" />
                        &nbsp;Settings
                    </button>
                ) : (
                    <Stack direction="horizontal">
                        {props.pageActionsDisplayType ===
                            CollabReportPageActionsType.CollabReportReadOnlyActions && (
                            <CollabReportReadOnlyActions
                                exportSettings={props.exportSettings}
                                forceReadonly={props.forceHideEditButton}
                                hasChatActs={props.hasChatActs}
                                hidePreview={
                                    props.reportTypeId === MyDocumentsTypesEnum.KpiReport.Value
                                }
                                hasCollabAct={props.hasCollabAct}
                                hasReviewAct={props.hasReviewAct}
                                hideCollabButton={props.userIsReader || !props.enableCollab}
                                hideHistoryButton={props.userIsReader}
                                isClosingReviewAct={props.isClosingReviewAct}
                                isPublished={
                                    props.reportStatus?.id === ReportStatusTypesEnum.Published
                                }
                                onChatClick={props.onChatClick}
                                onCloseReviewActClick={props.onCloseReviewActClick}
                                onCollabClick={props.onSaveAsClick}
                                onHistoryClick={props.onHistoryClick}
                                onPreviewClick={props.onPreviewClick}
                                onEditClick={props.onEditClick}
                                onExportError={props.onExportError}
                                previewText={
                                    props.isPreviewMode
                                        ? props.reportStatus?.displayName ?? ''
                                        : 'Preview'
                                }
                                reportTypeId={props.reportTypeId}
                            />
                        )}

                        {props.pageActionsDisplayType ===
                            CollabReportPageActionsType.CollabReportNonReadOnlyActions && (
                            <CollabReportNonReadOnlyActions
                                exportSettings={props.exportSettings}
                                hasChatActs={props.hasChatActs}
                                hasUnsavedChanges={props.hasUnsavedChanges}
                                isPublished={
                                    props.reportStatus?.id === ReportStatusTypesEnum.Published
                                }
                                onCancelClick={props.onCancelClick}
                                onChatClick={props.onChatClick}
                                onCollabClick={props.onSaveAsClick}
                                onCommentsClick={() => props.onCommentsClick(0)}
                                onExportError={props.onExportError}
                                onHistoryClick={props.onHistoryClick}
                                onSaveClick={props.onSaveClick}
                                onSettingsClick={props.onSettingsClick}
                                reportTypeId={props.reportTypeId}
                            />
                        )}

                        {props.pageActionsDisplayType ===
                            CollabReportPageActionsType.CollabReportCreateActions && (
                            <CollabReportCreateActions
                                exportSettings={props.exportSettings}
                                hideSaveAsButton={!props.enableCollab}
                                onCommentsClick={props.onCommentsClick}
                                onExportError={props.onExportError}
                                onSaveAsClick={props.onSaveAsClick}
                                onSettingsClick={props.onSettingsClick}
                                reportTypeId={props.reportTypeId}
                            />
                        )}

                        <Button variant="primary" onClick={props.onRefreshClick}>
                            Refresh
                        </Button>
                    </Stack>
                )}
            </div>
        </div>
    );
}
