import * as React from 'react';

import {
    AssertionDescriptors,
    ExportOptions,
    MyDocumentsTypesEnum,
    PlaintiffsFirmDisplayMode,
    ReportCaseDetailsLastColumn,
    ReportStatusTypesEnum,
    TimelineReportSections,
    TimelineReportTypeEnum,
    WeeklyRecapReportSectionList,
} from '../../../../utilities/Constants';
import { CommentsDialog, ISectionCommentEntry } from '../CommentsDialog';
import { ICaseAssertionModel, ICaseModel } from '../../../../interfaces/ICase';
import {
    IReportSectionDetailModel,
    ITimelineReportInputModel,
    IWeeklyRecapModel,
} from '../../../../interfaces/Report/ITimelineReport';

import CheckmateApiClient from '../../../../services/apiClient';
import { CollabModalProvider } from '../../collab-report/modals/CollabModalContext';
import CollabReportController from '../../collab-report/CollabReportController';
import Common from '../../../../stores/Common';
import { ILookupModel } from '../../../../interfaces/ILookup';
import { IValidation } from '../../../../interfaces/IError';
import { Loader } from '../../../../components/shared/Loader';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { ReportSettingsModal } from '../ReportSettingsModal';
import ValidateUtils from '../../../../shared/validations';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';

const _apiClient = new CheckmateApiClient();

const getDefaultReportInputModel = (reportGuid: string | undefined) => {
    const inputModel: ITimelineReportInputModel = {
        type: TimelineReportTypeEnum.Weekly,
        entityGuid: reportGuid,
        reportSectionDetails: [],
    };

    WeeklyRecapReportSectionList.forEach((section: ILookupModel) => {
        const sectionDetail: IReportSectionDetailModel = {
            section: section.id,
            sectionName: section.name ?? '',
        };
        inputModel.reportSectionDetails!.push(sectionDetail);
    });

    inputModel.additionalSettings = {
        plaintiffsFirmDisplayMode: PlaintiffsFirmDisplayMode.Hierarchy,
        reportCaseDetailsLastColumn: ReportCaseDetailsLastColumn.ProductPremises,
        reportCaseDetailsLastColumnDisplayName: 'Product/Premises',
    };

    return inputModel;
};

export function WeeklyRecapReport() {
    const { guid: reportGuid } = useParams();

    const [data, setData] = React.useState<IWeeklyRecapModel>();
    const [inputModel, setInputModel] = React.useState<ITimelineReportInputModel>(
        getDefaultReportInputModel(reportGuid)
    );

    const [showSettingsModal, setShowSettingsModal] = React.useState<boolean>(false);
    const [commentsModal, setCommentsModal] = React.useState<{
        open: boolean;
        section: number;
    }>({ open: false, section: 0 });
    const [validation, setValidation] = React.useState<IValidation>({});
    const [shouldRunReport, setShouldRunReport] = React.useState<boolean>(false);
    const [reportStatusId, setReportStatusId] = React.useState<number | undefined>();
    const [isPending, setIsPending] = React.useState<boolean>(false);

    const getSectionsAfterSectionCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        sections: IReportSectionDetailModel[] = []
    ) => {
        const sectionsCopy = cloneDeep(sections);
        const isChecked = event.target.checked;
        const found = sectionsCopy.find((x) => x.section === section);

        if (found) {
            found.excludeInReport = !isChecked;
        } else if (isChecked) {
            sectionsCopy?.push({
                section,
                sectionName: section.toString(),
            });
        }
        return sectionsCopy?.filter((detail) => !detail.excludeInReport);
    };

    const handleCommentsDialogSaveClick = (fromDialog: ISectionCommentEntry[]) => {
        const reportSectionDetailsCopy = cloneDeep(inputModel.reportSectionDetails ?? []);

        reportSectionDetailsCopy?.forEach((details) => {
            const found = fromDialog.find(
                (searchDetail) => searchDetail.section === details.section
            );

            if (found) {
                details.comment = found.comment;
            }
        });

        const toAdd = fromDialog.filter((details) => {
            return !reportSectionDetailsCopy.map((fd) => fd.section).includes(details.section);
        });

        toAdd.forEach((item) => {
            const { section, comment } = item;
            reportSectionDetailsCopy.push({
                section,
                comment,
                excludeInReport: false,
            } as IReportSectionDetailModel);
        });

        setCommentsModal((prev) => ({ ...prev, open: false }));
        setInputModel((prev) => ({
            ...prev,
            reportSectionDetails: reportSectionDetailsCopy,
        }));
    };

    const handleInputModelChange = (newInputModel: any) => {
        setInputModel(newInputModel as ITimelineReportInputModel);
    };

    const handleShowSettingsModal = (show: boolean) => {
        setShowSettingsModal(show);
    };

    const handleShowCommentsModal = (show: boolean, section: number) => {
        setCommentsModal({ open: show, section });
    };

    const handleSettingModalSubmit = (model: ITimelineReportInputModel) => {
        setInputModel(model);
        setShouldRunReport(true);
    };

    const handleCollabReportLoaded = (data: IWeeklyRecapModel | undefined) => {
        setData(data);
    };

    const findAD = (caseAssertions: ICaseAssertionModel[] = [], adGuid: string) => {
        return caseAssertions?.find(
            (ad) => ad.assertionDescriptor?.guid?.toLowerCase() === adGuid.toLowerCase()
        );
    };

    const handleEasyUpdateComplete = (updatedCase: ICaseModel) => {
        if (!data) return;

        const reportData = cloneDeep(data);

        const jurisdictionAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.Jurisdiction.Guid
        );

        const plaintiffsFirmAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.PlainfirmFirmSecondary.Guid
        );

        const trialDateAD = findAD(updatedCase.caseAssertions, AssertionDescriptors.TrialDate.Guid);

        const demandAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.InitialDemand.Guid
        );

        const typeOfClaimAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.TypeOfClaim.Guid
        );

        const clientExposureStartDateAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.ClientExposureStartDate.Guid
        );

        const authorityRequestedAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.AuthorityRequested.Guid
        );

        const authorityRequestedDateAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.AuthorityRequestedDate.Guid
        );

        const serviceDateAD = findAD(
            updatedCase.caseAssertions,
            AssertionDescriptors.ServiceDate.Guid
        );

        function updateCaseInfo(model: IWeeklyRecapModel) {
            model.caseInfo.forEach((x) => {
                if (x.caseGuid === updatedCase.guid) {
                    x.caseName = updatedCase.caseName ?? '';
                    x.diagnosis = updatedCase.diagnosis;
                    x.additionalCaseIdentifier = typeOfClaimAD?.assertionDescriptorValue?.text;
                    x.jurisdiction = jurisdictionAD?.assertionDescriptorValue?.text;
                    x.plaintiffsFirmDisplayName = plaintiffsFirmAD?.assertionDescriptorValue?.text;
                }
            });
        }

        function updateInitialCaseEvaluations(model: IWeeklyRecapModel) {
            model.initialCaseEval.forEach((x) => {
                if (x.caseGuid === updatedCase.guid) {
                    x.caseName = updatedCase.caseName ?? '';
                    x.diagnosis = updatedCase.diagnosis;
                    x.additionalCaseIdentifier = typeOfClaimAD?.assertionDescriptorValue?.text;
                    x.jurisdiction = jurisdictionAD?.assertionDescriptorValue?.text;
                    x.plaintiffsFirmDisplayName = plaintiffsFirmAD?.assertionDescriptorValue?.text;
                    x.serviceDate = serviceDateAD?.text;
                }
            });
        }

        function updateOutStandingRequests(model: IWeeklyRecapModel) {
            model.clientRequests.forEach((x) => {
                if (x.caseGuid === updatedCase.guid) {
                    x.caseName = updatedCase.caseName ?? '';
                    x.diagnosis = updatedCase.diagnosis;
                    x.jurisdiction = jurisdictionAD?.assertionDescriptorValue?.text;
                    x.plaintiffsFirmDisplayName = plaintiffsFirmAD?.assertionDescriptorValue?.text;
                    x.trialDate = trialDateAD?.text;
                    x.demand = parseFloat(demandAD?.text ?? '');

                    x.authorityRequested = parseFloat(authorityRequestedAD?.text ?? '');
                    x.authorityRequestedDate = Common.dateFormat(authorityRequestedDateAD?.text);
                    x.clientExposureStartDate = Common.dateFormat(clientExposureStartDateAD?.text);
                }
            });
        }

        function updateUpcomingDepositions(model: IWeeklyRecapModel) {
            model.depositions.forEach((x) => {
                if (x.caseGuid === updatedCase.guid) {
                    x.caseName = updatedCase.caseName ?? '';
                    x.jurisdiction = jurisdictionAD?.assertionDescriptorValue?.text;

                    // todo jec: set the followinf values from depositions
                    // x.witness = ;
                    // x.status = ;
                    // x.date = ;
                }
            });
        }

        updateCaseInfo(reportData);
        updateInitialCaseEvaluations(reportData);
        updateOutStandingRequests(reportData);
        updateUpcomingDepositions(reportData);

        setData(reportData);
    };

    const handleReportStatusChange = (status: ILookupModel) => {
        setReportStatusId(status.id);
    };

    const getReportData = async () => {
        const validation: IValidation = {};

        if (!inputModel.startDate || !inputModel.endDate) {
            validation.settings = ['Enter Date Range'];
            setValidation(validation);
            return;
        }

        if (Common.dateDiffInDays(inputModel.endDate, inputModel.startDate) < 0) {
            validation.settings = ['From Date must be before To Date'];
            setValidation(validation);
            return;
        }

        if ((inputModel.reportSectionDetails?.length ?? 0) === 0) {
            validation.settings = ['Select at least 1 section'];
            setValidation(validation);
            return;
        }

        if (
            inputModel.reportSectionDetails?.some((element) =>
                [
                    TimelineReportSections.RiskIssues,
                    TimelineReportSections.SignificantActivity,
                    TimelineReportSections.UpcomingTrialDates,
                    TimelineReportSections.TenderedCases,
                ].includes(element.section)
            ) &&
            (!inputModel.additionalSettings ||
                !inputModel.additionalSettings.reportCaseDetailsLastColumnDisplayName)
        ) {
            validation.settings = ['Case Details - Last Column Header Name is required'];
            setValidation(validation);
            return;
        }

        setValidation(validation);
        setIsPending(true);

        const response = await _apiClient.getTimelineReportData<IWeeklyRecapModel>(inputModel);
        if (response.httpResponse.status === 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            setValidation(ValidateUtils.parseErrors(response.errors, response.errorMessage));
            setIsPending(false);
            return;
        }
        if (response.payload) {
            setShowSettingsModal(false);
            setData(response.payload);
        }
        setIsPending(false);
    };

    React.useEffect(() => {
        if (!reportGuid) {
            setShowSettingsModal(true);
            setData(undefined);
        }
    }, [reportGuid]);

    React.useEffect(() => {
        if (shouldRunReport) {
            getReportData();
            setShouldRunReport(false);
            setShowSettingsModal(false);
        }
    }, [shouldRunReport]);

    const { reportSectionDetails, startDate, endDate, entityGuid, saveReport, ...options } =
        inputModel;

    const exportParameters = {
        reportType: {
            id: MyDocumentsTypesEnum.WeeklyRecap.Value,
        },
        options,
        reportSectionDetails,
        date: {
            startDate,
            endDate,
        },
        entityGuid,
        saveReport,
    };

    const exportOptions = [
        ExportOptions.IncludeTactics,
        ExportOptions.IncludeAttachments,
        ExportOptions.IncludeStrategyNotes,
    ];

    const isDraftStatus = reportStatusId === ReportStatusTypesEnum.Draft;

    return (
        <>
            {isPending && <Loader />}
            <CollabModalProvider>
                <CollabReportController<IWeeklyRecapModel, ITimelineReportInputModel>
                    collabReportUrl={LocalRoutes.WeeklyRecapCollab}
                    data={data}
                    exportSettings={{ parameters: exportParameters, options: exportOptions }}
                    getReportData={getReportData}
                    inputModel={inputModel}
                    onCollabReportLoaded={handleCollabReportLoaded}
                    onEasyUpdateComplete={handleEasyUpdateComplete}
                    onInputModelChange={handleInputModelChange}
                    onReportStatusChange={handleReportStatusChange}
                    onShowCommentsModal={handleShowCommentsModal}
                    onShowSettingsModal={handleShowSettingsModal}
                    pageTitle="Weekly Recap"
                    reportTypeId={MyDocumentsTypesEnum.WeeklyRecap.Value}
                    sections={WeeklyRecapReportSectionList}
                    timelineReportTypeId={TimelineReportTypeEnum.Weekly}
                />
            </CollabModalProvider>
            <ReportSettingsModal
                disabled={!!reportGuid && !isDraftStatus}
                defaultReportInputModel={getDefaultReportInputModel(reportGuid)}
                initialInputModel={inputModel}
                readonly={false}
                open={showSettingsModal}
                onHide={() => setShowSettingsModal(false)}
                getSectionsAfterSectionCheckedChange={getSectionsAfterSectionCheckedChange}
                onSubmit={handleSettingModalSubmit}
                reportGuid={reportGuid}
                sections={WeeklyRecapReportSectionList}
                setValidation={setValidation}
                validation={validation}
            />
            <CommentsDialog
                defaultSection={commentsModal.section}
                disabled={!!reportGuid && !isDraftStatus}
                open={commentsModal.open}
                onSaveClick={handleCommentsDialogSaveClick}
                sectionCommentMap={
                    inputModel?.reportSectionDetails
                        ?.filter((detail) => !detail.excludeInReport)
                        .map((detail) => ({
                            section: detail.section,
                            comment: detail.comment,
                        })) ?? []
                }
                sections={WeeklyRecapReportSectionList}
            />
        </>
    );
}
