import * as React from 'react';

import { ApplicationHelpType } from '../../utilities/Constants';
import CheckmateSelect from '../../components/shared/CheckmateSelect';
import { Help } from '../../components/shared/Help';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IValidation } from '../../interfaces/IError';

export interface IFilterCriteriaProps {
    isRequired?: boolean;
    onQueryCriteriaSelected: (optionsList: any) => void;
    queryFilterCriteriaAll: IQueryFilterCriteria[];
    queryFilterCriteriaSelected: IQueryFilterCriteria[];
    validation: IValidation;
}

export function FilterCriteria(props: IFilterCriteriaProps) {
    return <FilterCriteriaSelector {...props} />;
}

function FilterCriteriaSelector(props: IFilterCriteriaProps) {
    return (
        <div className="row mb-2">
            <div className="col-sm-2">
                <label className="text-gray text-lg">Criteria{props.isRequired ? '*' : ''}</label>
                &nbsp;
                <Help
                    type={ApplicationHelpType.Info}
                    title="Criteria"
                    helpText="Selected criteria fields and values tell Checkmate the subset of cases to which the Trigger Rule will apply; once the Trigger Rules criteria are met, then Checkmate looks at the case to see if the Trigger Field condition and value has been or becomes met; when a case meets the Trigger Rule criteria AND the Trigger Rule is met, the user will receive a Trigger Alert"
                />
            </div>
            <div className="col-sm-10">
                <CheckmateSelect
                    isMulti
                    options={[...props.queryFilterCriteriaAll]}
                    value={props.queryFilterCriteriaSelected}
                    onChange={props.onQueryCriteriaSelected}
                />
                <span className="text-danger">{props.validation.criteria}</span>
            </div>
        </div>
    );
}
