import { ICollabModalProps } from './CollabModalContext';
import { IEntityLease } from '../../../../interfaces/IEntityLease';
import { Modal } from 'react-bootstrap';

export interface IFailedToObtainLeaseModalData {
    onOkButtonClick: () => void;
    currentLease: IEntityLease;
}

export function FailedToObtainLeaseModal(
    props: ICollabModalProps<IFailedToObtainLeaseModalData, unknown>
) {
    if (!props.open) return null;

    return (
        <Modal centered size="lg" show={props.open} backdrop={true}>
            <Modal.Header>
                <Modal.Title>Report is Read-only</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                `The report is read-only.\nIt is currently being edited by $
                {props.data?.currentLease?.lesseeUser?.profile?.firstName} $
                {props.data?.currentLease?.lesseeUser?.profile?.lastName}.`
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={props.data?.onOkButtonClick}>
                    Ok
                </button>
            </Modal.Footer>
        </Modal>
    );
}
