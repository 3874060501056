import { Route, Routes } from 'react-router-dom';

import { AccessDenied } from '../../pages/AccessDenied';
import { AccountLogin } from '../../pages/account/AccountLogin';
import AlertListWrapper from '../../pages/wrapper/AlertListWrapper';
import AssertionDescriptorDetailWrapper from '../../pages/wrapper/AssertionDescriptorDetailWrapper';
import { AssertionDescriptorList } from '../../pages/assertiondescriptor/AssertionDescriptorList';
import { Board } from '../../pages/board/Board';
import BoardCaseDetailWrapper from '../../pages/wrapper/BoardCaseDetailWrapper';
import { BoardViewDetail } from '../../pages/board/BoardViewDetail';
import CaseEasyUpdateWrapper from '../../pages/wrapper/CaseEasyUpdateWrapper';
import { CaseFile } from '../../pages/case/CaseFile';
import CaseListWrapper from '../../pages/wrapper/CaseListWrapper';
import CheckmatePDFViewer from '../../pages/CheckmatePDFViewer';
import EntityStrategyWrapper from '../../pages/wrapper/EntityStrategyWrapper';
import ExecutionPlanWrapper from '../../pages/wrapper/ExecutionPlanWrapper';
import { ExpertCreate } from '../../pages/experts/ExpertCreate';
import { ExpertDetail } from '../../pages/experts/ExpertDetail';
import { ExpertsList } from '../../pages/experts/ExpertsList';
import { Home } from '../../pages/Home';
import { Invitation } from '../../pages/user/Invitation';
import JurisdictionStrategyDetailWrapper from '../../pages/wrapper/JurisdictionStrategyDetailWrapper';
import { KpiReport } from '../../pages/report/kpi-report/KpiReport';
import { KpiReportLegacy } from '../../pages/report/kpi-report/KpiReportLegacy';
import KpiViewWrapper from '../../pages/wrapper/TrendsWrapper';
import { Loader } from '../shared/Loader';
import LocalCounselStrategyDetailWrapper from '../../pages/wrapper/LocalCounselStrategyDetailWrapper';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { Logout } from '../../pages/account/Logout';
import MeetingAgendaReportWrapper from '../../pages/wrapper/MeetingAgendaReportWrapper';
import { MonthlyRecapLegacy } from '../../pages/report/timeline/monthly-recap/MonthlyRecapLegacy';
import { MonthlyRecapReport } from '../../pages/report/timeline/monthly-recap/MonthlyRecapReport';
import MyDocuments from '../../pages/documents/MyDocuments';
import { NotFoundPage } from '../../pages/NotFoundPage';
import OrganizationDetailWrapper from '../../pages/wrapper/OrganizationDetailWrapper';
import { OrganizationExperienceDetail } from '../../pages/organization/OrganizationExperienceDetail';
import OrganizationExperienceDetailWrapper from '../../pages/wrapper/OrganizationExperienceDetailWrapper';
import { OrganizationExperienceList } from '../../pages/organization/OrganizationExperienceList';
import PackageSettlementDetailExportWrapper from '../../pages/wrapper/PackageSettlementDetailExportWrapper';
import PackageSettlementDetailWrapper from '../../pages/wrapper/PackageSettlementDetailWrapper';
import { PackageSettlementListWrapper } from '../../pages/case/packagesettlement/PackageSettlementList';
import PlaintiffsFirmStrategyDetailWrapper from '../../pages/wrapper/PlaintiffsFirmStrategyDetailWrapper';
import ProjectDetailWrapper from '../../pages/wrapper/ProductDetailWrapper';
import ProjectListWrapper from '../../pages/wrapper/ProjectListWrapper';
import PublicRoute from './public/PublicRoute';
import { Query2Wrapper } from '../../pages/query2/Query2Wrapper';
import { QueryLegacyWrapper } from '../../pages/query2/QueryLegacyWrapper';
import QueryWrapper from '../../pages/wrapper/QueryWrapper';
import RecoverAccount from '../../pages/account/RecoverAccount';
import RegisterWithInvitationWrapper from '../../pages/wrapper/RegisterWithInvitationWrapper';
import RegistrationOptionWrapper from '../../pages/wrapper/RegistrationOptionWrapper';
import { ReportListHome } from '../../pages/report/ReportListHome';
import { ResetPassword } from '../../pages/account/ResetPassword';
import SecureRoute from './secure/SecureRoute';
import SnapshotWrapper from '../../pages/wrapper/SnapshotWrapper';
import StrategicPlanWrapper from '../../pages/wrapper/StrategicPlanWrapper';
import { ThreatReport } from '../../pages/report/ThreatReport';
import { TriggerDetailsPage } from '../../pages/triggers/TriggerDetailsPage';
import { TriggersPage } from '../../pages/triggers/TriggersPage';
import Tutorials from '../../pages/Tutorials';
import TutorialsRoute from './tutorials/TutorialsRoute';
import UserDetailWrapper from '../../pages/wrapper/UserDetailWrapper';
import { UserList } from '../../pages/user/UserList';
import { UserReportList } from '../../pages/report/UserReportList';
import ViewExportReportWrapper from '../../pages/wrapper/ViewExportReportWrapper';
import { WeeklyRecapLegacy } from '../../pages/report/timeline/weekly-recap/WeeklyRecapLegacy';
import { WeeklyRecapReport } from '../../pages/report/timeline/weekly-recap/WeeklyRecapReport';
import WorkProductDetailWrapper from '../../pages/wrapper/WorkProductDetailWrapper';
import ZoneNotesWrapper from '../../pages/wrapper/ZoneNotesWrapper';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLoading } from '../../contexts/LoadingContext';

export function CheckmateRoutes() {
    const { logout, user } = useAuthContext();
    const { isLoading } = useLoading();

    const localUser = user || { active: false };

    return (
        <>
            <Routes>
                <Route path={LocalRoutes.Login} element={<AccountLogin />}>
                    <Route path={LocalRoutes.LoginAdvanced} element={<AccountLogin />}></Route>
                </Route>

                {/* SECURED ROUTES */}
                <Route path={LocalRoutes.Root} element={<SecureRoute />}>
                    <Route path={LocalRoutes.Root} element={<Home />} />
                    <Route path={LocalRoutes.Logout} element={<Logout onLogout={logout} />} />
                    <Route
                        path={LocalRoutes.OrganizationDetail}
                        element={<OrganizationDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.UserReportList}
                        element={<UserReportList user={localUser} />}
                    />
                    <Route path={LocalRoutes.Tutorials} element={<Tutorials />} />
                    <Route
                        path={LocalRoutes.PackageSettlementDetailExport}
                        element={<PackageSettlementDetailExportWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Cases}
                        element={<CaseListWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.CaseFile} element={<CaseFile />} />
                    <Route path={LocalRoutes.CaseFileNew} element={<CaseFile />} />
                    <Route
                        path={LocalRoutes.CaseEasyUpdate}
                        element={<CaseEasyUpdateWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.WorkProductDetail}
                        element={<WorkProductDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ReportTypeList}
                        element={<ReportListHome user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ThreatReport}
                        element={<ThreatReport user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ReportDetail}
                        element={<ViewExportReportWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.Query} element={<QueryWrapper user={localUser} />} />
                    <Route
                        path={LocalRoutes.QueryLegacy}
                        element={<QueryLegacyWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.Query2} element={<Query2Wrapper user={localUser} />} />
                    <Route
                        path={LocalRoutes.TriggerNew}
                        element={<TriggerDetailsPage user={localUser} isCreate />}
                    />
                    <Route
                        path={LocalRoutes.TriggerDetails}
                        element={<TriggerDetailsPage user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Triggers}
                        element={<TriggersPage user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ZoneNotes}
                        element={<ZoneNotesWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.WeeklyRecap} element={<WeeklyRecapReport />} />
                    <Route path={LocalRoutes.WeeklyRecapCollab} element={<WeeklyRecapReport />} />
                    <Route path={LocalRoutes.MonthlyRecap} element={<MonthlyRecapReport />} />
                    <Route path={LocalRoutes.MonthlyRecapCollab} element={<MonthlyRecapReport />} />
                    <Route path={LocalRoutes.KpiReport} element={<KpiReport />} />
                    <Route path={LocalRoutes.KpiReportCollab} element={<KpiReport />} />

                    <Route
                        path={LocalRoutes.MonthlyRecapLegacy}
                        element={<MonthlyRecapLegacy user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.WeeklyRecapLegacy}
                        element={<WeeklyRecapLegacy user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.KpiReportLegacy}
                        element={<KpiReportLegacy user={localUser} />}
                    />

                    <Route
                        path={LocalRoutes.MeetingAgenda}
                        element={<MeetingAgendaReportWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Trends}
                        element={<KpiViewWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.Board} element={<Board user={localUser} />} />
                    <Route
                        path={LocalRoutes.BoardCaseDetail}
                        element={<BoardCaseDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.BoardViewDetail}
                        element={<BoardViewDetail user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.PackageSettlements}
                        element={<PackageSettlementListWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.PackageSettlementDetails}
                        element={<PackageSettlementDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.PackageSettlementNew}
                        element={<PackageSettlementDetailWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.Experts} element={<ExpertsList user={localUser} />} />
                    <Route
                        path={LocalRoutes.ExpertCreate}
                        element={<ExpertCreate user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ExpertDetail}
                        element={<ExpertDetail user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.StrategicPlan}
                        element={<StrategicPlanWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.StrategyExecutionPlan}
                        element={<ExecutionPlanWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Strategy}
                        element={<EntityStrategyWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.PlaintiffFirmStrategyDetail}
                        element={<PlaintiffsFirmStrategyDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.JurisdictionStrategyDetail}
                        element={<JurisdictionStrategyDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.LocalCounselStrategyDetail}
                        element={<LocalCounselStrategyDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.PlaintiffFirmStrategyNew}
                        element={<PlaintiffsFirmStrategyDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.JurisdictionStrategyNew}
                        element={<JurisdictionStrategyDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.LocalCounselStrategyNew}
                        element={<LocalCounselStrategyDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Projects}
                        element={<ProjectListWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ProjectDetail}
                        element={<ProjectDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ProjectNew}
                        element={<ProjectDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Snapshot}
                        element={<SnapshotWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Alerts}
                        element={<AlertListWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.MyDocuments} element={<MyDocuments />} />
                    <Route path={LocalRoutes.AccessDenied} element={<AccessDenied />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>

                {/* ADMIN ROUTES */}
                <Route path={'/admin'} element={<SecureRoute />}>
                    <Route
                        path={LocalRoutes.CreateInvitation}
                        element={<Invitation user={user ?? { active: false }} />}
                    />
                    <Route
                        path={LocalRoutes.AssertionDescriptors}
                        element={<AssertionDescriptorList user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.AssertionDescriptorNew}
                        element={<AssertionDescriptorDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.AssertionDescriptorDetail}
                        element={<AssertionDescriptorDetailWrapper user={localUser} />}
                    />
                    <Route path={LocalRoutes.Users} element={<UserList user={localUser} />} />
                    <Route
                        path={LocalRoutes.UserDetail}
                        element={<UserDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.Zones}
                        element={<OrganizationExperienceList user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ZoneDetail}
                        element={<OrganizationExperienceDetailWrapper user={localUser} />}
                    />
                    <Route
                        path={LocalRoutes.ZoneNew}
                        element={<OrganizationExperienceDetail user={localUser} />}
                    />
                </Route>

                {/* TUTORIAL ROUTES */}
                <Route path={LocalRoutes.Tutorials} element={<TutorialsRoute />}>
                    <Route path={`${LocalRoutes.Tutorials}${LocalRoutes.ViewPDF}`}>
                        <Route
                            path={`${LocalRoutes.Tutorials}${LocalRoutes.ViewPDF}/:id`}
                            element={<CheckmatePDFViewer />}
                        />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Route>

                {/* PUBLIC ROUTES */}
                <Route path={LocalRoutes.Root} element={<PublicRoute />}>
                    <Route path={LocalRoutes.ForgotPassword} element={<RecoverAccount />} />
                    <Route path={LocalRoutes.ResetPassword} element={<ResetPassword />} />
                    <Route
                        path={LocalRoutes.AcceptInvitation}
                        element={<RegistrationOptionWrapper />}
                    />
                    <Route
                        path={LocalRoutes.RegisterWithInvitation}
                        element={<RegisterWithInvitationWrapper />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Route>

                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            {isLoading && <Loader />}
        </>
    );
}
