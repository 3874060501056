import * as React from 'react';

import { DepositionsTable } from './DepositionsTable';
import { IReportDepositionModel } from '../../../../../interfaces/Report/ITimelineReport';
import { IWeeklyRecapSectionProps } from './IWeeklyRecapSectionProps';
import { SectionFooter } from '../../SectionFooter';
import { SectionHeader } from '../../../collab-report/SectionHeader';
import { TimelineReportSections } from '../../../../../utilities/Constants';

export interface IUpcomingDepositionsSectionProps extends IWeeklyRecapSectionProps {
    data: IReportDepositionModel[];
    onEasyUpdate: (caseGuid: string) => void;
}

export function UpcomingDepositionsSection(props: IUpcomingDepositionsSectionProps) {
    return (
        <div className="pb-2">
            <SectionHeader
                checked={props.checked}
                isPreviewMode={props.isPreviewMode}
                onCommentsButtonClick={props.onCommentsButtonClick}
                onSectionCheckedChange={props.onSectionCheckedChange}
                readonly={props.readonly}
                section={TimelineReportSections.Depositions}
                title="Upcoming Depositions"
            />

            <DepositionsTable
                depositions={props.data}
                easyUpdateAttemptedGuids={props.previouslyViewedGuids}
                isReadOnly={props.readonly}
                user={props.user}
                onEasyUpdateClick={(caseGuid) => props.onEasyUpdate(caseGuid)}
            />

            <SectionFooter text={props.sectionComment} rowCount={props.data.length} />
        </div>
    );
}
