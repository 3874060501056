import * as React from 'react';

import { Button, Modal, Stack } from 'react-bootstrap';
import {
    KpiReportCommentReportParameterNames,
    TimelineReportSections,
} from '../../../utilities/Constants';
import _, { cloneDeep } from 'lodash';

import { CheckmateDialog } from '../../../components/shared/dialog';
import { IKpiReportInputModel } from './KpiReport';
import { IValidation } from '../../../interfaces/IError';
import UIHelper from '../../../utilities/UIHelper';

export interface IKpiReportCommentsModalProps {
    disabled?: boolean;
    onHide: () => void;
    onSubmit: (inputModel: IKpiReportInputModel, refreshOnClose: boolean) => void;
    open: boolean;
    reportInputModel: IKpiReportInputModel;
}

export function KpiReportCommentsModal(props: IKpiReportCommentsModalProps) {
    if (!props.open) return null;

    const [validation, setValidation] = React.useState<IValidation>({});
    const [showClearConfirmation, setShowClearConfirmation] = React.useState<boolean>(false);
    const [localInputModel, setLocalInputModel] = React.useState<IKpiReportInputModel>(
        props.reportInputModel
    );
    const handleCommentsChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
        section: TimelineReportSections,
        displayName: string
    ) => {
        const inputModelCopy = cloneDeep(localInputModel);

        if (!inputModelCopy) {
            return;
        }

        if (!inputModelCopy.reportSectionDetails) {
            inputModelCopy.reportSectionDetails = [];
        }

        const { value } = event.target;
        const validation: IValidation = {};

        const configMatch = KpiReportCommentReportParameterNames.find((x) => x.section === section);
        const maxLines = configMatch ? configMatch.maxLines : 0;
        const maxLength = configMatch ? configMatch.maxLength : 0;

        const valueLines = value.split(/\r\n|\r|\n/).length;

        if (valueLines > maxLines) {
            validation.model = [`${displayName} cannot be longer than ${maxLines} line(s)`];
            setValidation(validation);
            return;
        } else {
            setValidation(validation);
        }

        const valueLength = value.length;
        if (valueLength > maxLength) {
            validation.model = [`${displayName} cannot be longer than ${maxLength} characters`];
            setValidation(validation);
            return;
        } else {
            setValidation(validation);
        }

        const match = inputModelCopy.reportSectionDetails.find((x) => x.section === section);
        if (match) {
            match.comment = value;
        } else {
            inputModelCopy.reportSectionDetails.push({
                section: section,
                sectionName: section.toString(),
                comment: event.target.value,
            });
        }

        switch (section) {
            case TimelineReportSections.DatasourceFootnote:
                inputModelCopy.comments_DataSourceFootnote = value;
                break;
            case TimelineReportSections.OpenClaims:
                inputModelCopy.comments_OpenActiveCases = value;
                break;
            case TimelineReportSections.SettlementSpend:
                inputModelCopy.comments_SettlementSpend = value;
                break;
            case TimelineReportSections.DefenseSpend:
                inputModelCopy.comments_DefenseSpend = value;
                break;
            case TimelineReportSections.AvgSettlementTrends:
                inputModelCopy.comments_AvgSettlementTrends = value;
                break;
            default:
                break;
        }
        setLocalInputModel(inputModelCopy);
    };

    const handleSubmitClick = () => {
        const differences =
            !_.isEmpty(props.reportInputModel) && !_.isEmpty(Object.keys(localInputModel))
                ? UIHelper.difference(
                      _.cloneDeep(localInputModel),
                      _.cloneDeep(props.reportInputModel)
                  )
                : {};

        const hasChanges = !_.isEmpty(differences);

        props.onSubmit(localInputModel, hasChanges);
    };

    const handleClearClick = () => {
        setShowClearConfirmation(true);
    };

    const handleClearConfirmationClick = () => {
        const inputModelCopy = cloneDeep(localInputModel);

        inputModelCopy.reportSectionDetails?.forEach((secDetails) => {
            secDetails.comment = '';
        });

        setLocalInputModel(inputModelCopy);
        setShowClearConfirmation(false);
    };

    const getCommentText = (section: TimelineReportSections) => {
        const sectionMatch = localInputModel.reportSectionDetails?.find(
            (x) => x.section === section
        );
        return sectionMatch?.comment ?? '';
    };

    return (
        <>
            <Modal centered show onHide={props.onHide} backdrop={false}>
                <Modal.Header>
                    <Modal.Title>Comments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {validation.model && (
                        <div className="margin-top-med">
                            <span className="text-danger">{validation.model}</span>
                        </div>
                    )}
                    <Stack gap={2}>
                        {KpiReportCommentReportParameterNames.map((comment: any) => {
                            const text = getCommentText(comment.section) ?? '';
                            const remainingChars = comment.maxLength - text.length;

                            return (
                                <div className="form-group" key={comment.section}>
                                    <label className="control-label">{comment.displayName}</label>
                                    <span className="text-gray">
                                        &nbsp;&nbsp;(
                                        {remainingChars + ' characters remaining'})
                                    </span>
                                    <textarea
                                        className="form-control"
                                        disabled={props.disabled}
                                        value={text}
                                        maxLength={comment.maxLength}
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                            handleCommentsChange(
                                                e,
                                                comment.section,
                                                comment.displayName
                                            );
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmitClick}>
                        Done
                    </Button>
                    <Button variant="default" onClick={handleClearClick}>
                        Clear
                    </Button>
                </Modal.Footer>
            </Modal>
            <CheckmateDialog
                isShowingModal={showClearConfirmation || false}
                body="Are you sure you want to clear all comments?"
                handleClose={() => {
                    setShowClearConfirmation(false);
                }}
                handleConfirm={handleClearConfirmationClick}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end"
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />
        </>
    );
}
