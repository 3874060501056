import { ICollabModalProps } from './CollabModalContext';
import { Modal } from 'react-bootstrap';

export interface IAlreadyEditingModalData {
    onReadOnlyClick: () => void;
    onEditButtonClick: () => void;
}

export function AlreadyEditingModal(props: ICollabModalProps<IAlreadyEditingModalData, unknown>) {
    if (!props.open) return null;

    return (
        <Modal centered size="lg" show={props.open} backdrop={true}>
            <Modal.Header>
                <Modal.Title>Report is Read-only</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                You were previously editing this report in another session and didn't save.
                <br />
                Do you want to open this report in read-only mode or begin editing this report here?
                <br />
                All unsaved changes made in your previous session will be permanently lost if you
                choose to edit this report here
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-default" onClick={props.data?.onReadOnlyClick}>
                    Open as Read-only
                </button>
                <button className="btn btn-default" onClick={props.data?.onEditButtonClick}>
                    Edit
                </button>
            </Modal.Footer>
        </Modal>
    );
}
