import { ILookupModel, IMultiSelectOptions } from '../interfaces/ILookup';

import { IExpertModel } from '../interfaces/IExpert';
import { IPagedQueryModel } from '../interfaces/IQuery';
import { IUserModel } from '../interfaces/IUser';
import _ from 'lodash';

export default class UIHelper {
    static getUserDisplayName = (user?: IUserModel) => {
        if (user && user.profile && user.profile.firstName && user.profile.lastName) {
            return user.profile.firstName.concat(' ', user.profile.lastName);
        }
        return '';
    };

    static buildPageNumberDetailsString = (
        totalCount: number,
        pageSize: number,
        queryModel?: IPagedQueryModel
    ) => {
        if (totalCount === 0 || !queryModel) return '';

        const currentPageNumber = queryModel.pageNumber + 1; // + 1 because current page number starts at 0
        const startCount = queryModel!.pageNumber * pageSize + 1;
        let endCount = currentPageNumber * pageSize;
        if (endCount > totalCount) endCount = totalCount;

        return ' | ' + startCount.toString() + ' - ' + endCount + ' of ' + totalCount.toString();
    };


    static buildPagingDetailsString = (
        totalCount: number,
        pageSize: number,
        pageNumber: number
    ) => {
        if (totalCount === 0) return '';

        const currentPageNumber = pageNumber + 1; // + 1 because current page number starts at 0
        const startCount = pageNumber * pageSize + 1;
        let endCount = currentPageNumber * pageSize;
        if (endCount > totalCount) endCount = totalCount;

        return ' | ' + startCount.toString() + ' - ' + endCount + ' of ' + totalCount.toString();
    };

    static difference(object: any, base: any) {
        function changes(object: any, base: any) {
            if (_.isUndefined(object) || _.isUndefined(base)) {
                return {};
            }
    
            return _.transform(object, function (result: any, value: any, key: string) {
                if (_.isUndefined(base[key])) {
                    result[key] = value;
                } else if (!_.isEqual(value, base[key])) {
                    result[key] =
                        _.isObject(value) && _.isObject(base[key])
                            ? changes(value, base[key])
                            : value;
                }
            });
        }
    
        // Return the result of the changes function
        return changes(object, base);
    }

    static getDisplayName(lookupData: ILookupModel[], item: number) {
        const match = lookupData.find((x) => x.id === item);
        if (match) return match.alternateDisplayName || match.displayName || match.name || '';

        return '';
    }

    static getName(lookupData: ILookupModel[], item: number) {
        const match = lookupData.find((x) => x.id === item);
        return match?.name || '';
    }

    static lookupModelToMultiSelectOptionMapper = (lookup: ILookupModel): IMultiSelectOptions => ({
        label: lookup.displayName ?? lookup.name ?? '',
        value: lookup.id.toString(),
        id: lookup.id,
    });

    static multiSelectOptionTolookupModelMapper = (
        multiSelectOption: IMultiSelectOptions
    ): ILookupModel => ({
        displayName: multiSelectOption.label,
        guid: multiSelectOption.value,
        id: multiSelectOption.id,
        name: multiSelectOption.label,
    });

    static formatExpertName = (expert?: IExpertModel) => {
        if (!expert) {
            return '';
        }

        const { firstName, lastName } = expert;

        // Check if credentials exist and concatenate them with a comma if they do
        const result = `${lastName || ''}${firstName ? ', ' : ''}${firstName || ''}`;

        return result;
    };
}
