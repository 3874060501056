import { CheckmateDialog } from '../../../../components/shared/dialog';
import { ICollabModalProps } from './CollabModalContext';

export function ExistingLeaseModal(props: ICollabModalProps<{ dialogText: string }, unknown>) {
    if (!props.open) return null;

    return (
        <CheckmateDialog
            isShowingModal={props.open}
            title={'Report is Read-only'}
            body={props.data?.dialogText}
            handleClose={props.onClose}
            cancelText="Ok"
            closeButtonClassName="btn btn-default float-end"
            size="sm"
            showBackdrop
        />
    );
}
