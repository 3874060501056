import { MyDocumentsTypesEnum, ReportStatusTypesEnum } from '../../../utilities/Constants';
import React, { useState } from 'react';

import { CollabModalProvider } from '../collab-report/modals/CollabModalContext';
import CollabReportController from '../collab-report/CollabReportController';
import { ILookupModel } from '../../../interfaces/ILookup';
import { IReportParametersModel } from '../../../interfaces/Report/IReport';
import { IValidation } from '../../../interfaces/IError';
import { KpiReportCommentsModal } from './KpiReportCommentsModal';
import { KpiSettingsModal } from './KpiSettingsModal';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';

export interface IKpiReportInputModel extends IReportParametersModel {
    comments_AvgSettlementTrends?: string;
    comments_DataSourceFootnote?: string;
    comments_DefenseSpend?: string;
    comments_OpenActiveCases?: string;
    comments_SettlementSpend?: string;
    endDate?: string | undefined;
}

export interface IKpiReportModel {
    parameters?: IKpiReportInputModel;
    renderReport: boolean;
    refreshToken?: string;
}

const getDefaultReportInputModel = (reportGuid: string | undefined) => {
    const inputModel: IKpiReportInputModel = {
        reportType: { id: MyDocumentsTypesEnum.KpiReport.Value },
        reportTypeId: MyDocumentsTypesEnum.KpiReport.Value,
        amount: undefined,
        entityGuid: reportGuid,
    };

    return inputModel;
};

export function KpiReport() {
    const { guid: reportGuid } = useParams();

    const [inputModel, setInputModel] = React.useState<IKpiReportInputModel>(
        getDefaultReportInputModel(reportGuid)
    );

    const [data, setData] = useState<IKpiReportModel>({
        renderReport: !!reportGuid,
        parameters: {
            amount: undefined,
            entityGuid: reportGuid,
            reportType: { id: MyDocumentsTypesEnum.KpiReport.Value },
        },
        refreshToken: Math.random().toString(),
    });
    const [showSettingsModal, setShowSettingsModal] = React.useState<boolean>(false);
    const [commentsModal, setCommentsModal] = React.useState<{
        open: boolean;
        section?: number;
    }>({ open: false });
    const [reportStatusId, setReportStatusId] = React.useState<number | undefined>();

    const [validation, setValidation] = React.useState<IValidation>({});
    const [shouldRunReport, setShouldRunReport] = React.useState<boolean>(false);

    const handleInputModelChange = (newInputModel: any) => {
        setInputModel(newInputModel as IKpiReportInputModel);
    };

    const handleShowSettingsModal = (show: boolean) => {
        setShowSettingsModal(show);
    };

    const handleShowCommentsModal = (show: boolean, section: number) => {
        setCommentsModal({ open: show, section });
    };

    const handleSettingModalSubmit = (model: IKpiReportInputModel) => {
        setInputModel({ ...inputModel, ...model });
        setShouldRunReport(true);
    };

    const handleReportStatusChange = (status: ILookupModel) => {
        setReportStatusId(status.id);
    };

    const handleCommentsDialogSaveClick = (
        updatedInputModel: IKpiReportInputModel,
        refreshOnClose: boolean
    ) => {
        setInputModel({ ...inputModel, ...updatedInputModel });
        if (refreshOnClose) {
            setShouldRunReport(true);
        }
        setCommentsModal({ open: false });
    };

    React.useEffect(() => {
        if (!reportGuid) {
            setShowSettingsModal(true);
        }
    }, [reportGuid]);

    React.useEffect(() => {
        const token = Math.random().toString();

        if (shouldRunReport) {
            const inputModelCopy = cloneDeep(inputModel);

            setData((prev) => ({
                ...prev,
                refreshToken: token,
                parameters: inputModelCopy,
                renderReport: true,
            }));
            setShouldRunReport(false);
            setShowSettingsModal(false);
        }
    }, [shouldRunReport]);

    const exportParameters = {
        reportType: {
            id: MyDocumentsTypesEnum.KpiReport.Value,
        },
        date: {
            endDate: inputModel.endDate,
        },
        amount: inputModel.amount,
        reportSectionDetails: inputModel.reportSectionDetails,
        entityGuid: inputModel.entityGuid,
        saveReport: inputModel.saveReport,
    };

    const isDraftStatus = reportStatusId === ReportStatusTypesEnum.Draft;

    return (
        <>
            <CollabModalProvider>
                <CollabReportController<IKpiReportModel, IKpiReportInputModel>
                    collabReportUrl={LocalRoutes.KpiReportCollab}
                    data={data}
                    exportSettings={{ parameters: exportParameters }}
                    inputModel={inputModel}
                    onInputModelChange={handleInputModelChange}
                    onReportStatusChange={handleReportStatusChange}
                    onShowCommentsModal={handleShowCommentsModal}
                    onShowSettingsModal={handleShowSettingsModal}
                    pageTitle="KPI Report"
                    reportTypeId={MyDocumentsTypesEnum.KpiReport.Value}
                />
            </CollabModalProvider>
            <KpiSettingsModal
                disabled={!!reportGuid}
                initialInputModel={inputModel}
                readonly={false}
                open={showSettingsModal}
                onHide={() => setShowSettingsModal(false)}
                onSubmit={handleSettingModalSubmit}
                reportGuid={reportGuid}
                setValidation={setValidation}
                validation={validation}
            />
            <KpiReportCommentsModal
                onHide={() => setCommentsModal({ open: false })}
                open={commentsModal.open}
                onSubmit={handleCommentsDialogSaveClick}
                reportInputModel={inputModel}
                disabled={!!reportGuid && !isDraftStatus}
            />
        </>
    );
}
