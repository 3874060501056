import {
    AssertionDescriptorValueDisplayTypes,
    AssertionDescriptorValueSourceTypeEnum,
    CaseFileStaticItems,
    ConditionSelectorEnums,
} from '../../../utilities/Constants';

import Common from '../../../stores/Common';
import { IAssertionDescriptorModel } from '../../../interfaces/IAssertionDescriptor';
import { IQueryFilterCriteria } from '../../../interfaces/IQuery';
import { ITriggerRuleSettingsModel } from '../../../interfaces/ITriggerRule';
import { IValidation } from '../../../interfaces/IError';

export const validateTrigger = (
    trigger: ITriggerRuleSettingsModel,
    assertionDescriptors: IAssertionDescriptorModel[],
    setValidation: (validation: IValidation) => void
) => {
    let hasError = false;
    let validation: IValidation = {};

    if ((trigger.ruleName?.length ?? 0) === 0) {
        hasError = true;
        validation.ruleName = ['Required'];
    }

    if (trigger.queryType === undefined) {
        hasError = true;
        validation.queryType = ['Required'];
    }

    if ((trigger.filters?.length ?? 0) > 0) {
        const criteriaValidation = validateCriteria(trigger.filters, assertionDescriptors);
        if (criteriaValidation.hasError) {
            hasError = true;
            validation = { ...validation, ...criteriaValidation.validation };
        }
    }

    if ((trigger.triggerFields?.length ?? 0) === 0) {
        hasError = true;
        validation.model = ['At least one Trigger Field is required'];
    } else {
        const triggerFieldValidation = validateTriggerFields(
            trigger.triggerFields ?? [],
            assertionDescriptors
        );

        if (triggerFieldValidation.hasError) {
            hasError = true;
            validation = { ...validation, ...triggerFieldValidation.validation };
        }
    }

    if (hasError) {
        setValidation(validation);
    }

    return !hasError;
};

const validateCriteria = (
    criterias: IQueryFilterCriteria[],
    assertionDescriptors: IAssertionDescriptorModel[]
): { validation: IValidation; hasError: boolean } => {
    const validation: IValidation = {};

    criterias.forEach((field) => processCriteria(field, assertionDescriptors));

    const hasError = criterias.some((field) => !!field.validationError);
    return { validation, hasError };
};

const validateTriggerFields = (
    triggerFields: IQueryFilterCriteria[],
    assertionDescriptors: IAssertionDescriptorModel[]
): { validation: IValidation; hasError: boolean } => {
    const validation: IValidation = {};

    triggerFields.forEach((field) => processTriggerField(field, assertionDescriptors));

    const hasError = triggerFields.some((field) => !!field.validationError);
    return { validation, hasError };
};

export const processCriteria = (
    field: IQueryFilterCriteria,
    assertionDescriptors: IAssertionDescriptorModel[]
) => {
    if (field.isAssertionDescriptor) {
        validateAssertionDescriptor(field, assertionDescriptors, criteriaRequiresValue);
    } else {
        validateStaticItem(field, criteriaRequiresValue);
    }

    if (field.condition === null) {
        field.validationError = 'Condition required';
    }
};

export const processTriggerField = (
    field: IQueryFilterCriteria,
    assertionDescriptors: IAssertionDescriptorModel[]
) => {
    if (field.isAssertionDescriptor) {
        validateAssertionDescriptor(field, assertionDescriptors, triggerFieldRequiresValue);
    } else {
        validateStaticItem(field, triggerFieldRequiresValue);
    }

    if (field.condition === null) {
        field.validationError = 'Condition required';
    }
};

const validateStaticItem = (
    field: IQueryFilterCriteria,
    isRequiredFn: (field: IQueryFilterCriteria) => boolean
) => {
    switch (field.staticItemId) {
        case CaseFileStaticItems.CaseNumber.Id:
        case CaseFileStaticItems.UniqueCaseID.Id: {
            validateText(
                field,
                AssertionDescriptorValueDisplayTypes.ShortText.Value,
                triggerFieldRequiresValue
            );
            break;
        }
        case CaseFileStaticItems.CaseStatus.Id:
        case CaseFileStaticItems.CaseManager.Id:
        case CaseFileStaticItems.AlternateCaseManager.Id:
        case CaseFileStaticItems.LocalCounsel.Id:
        case CaseFileStaticItems.OtherDefenseCounsels.Id:
        case CaseFileStaticItems.State.Id: {
            validateSelection(field, isRequiredFn);
            break;
        }
        case CaseFileStaticItems.CasePriority.Id: {
            validateBool(field, isRequiredFn);
            break;
        }
        case CaseFileStaticItems.CaseStatusAsOfDate.Id:
        case CaseFileStaticItems.ResolutionDate.Id: {
            validateDateRange(field, '', isRequiredFn);

            const startDateRequiredMsg = 'Start date is required';
            if (!field.startDate) {
                field.validationError = startDateRequiredMsg;
            }
            break;
        }
        case CaseFileStaticItems.NotesSection.Id:
        case CaseFileStaticItems.DepositionSection.Id:
        case CaseFileStaticItems.PackageSettlement.Id:
        default:
            break;
    }
};

const validateAssertionDescriptor = (
    field: IQueryFilterCriteria,
    assertionDescriptors: IAssertionDescriptorModel[],
    isRequiredFn: (field: IQueryFilterCriteria) => boolean
) => {
    const assertionDescriptor = assertionDescriptors?.find(
        (ad) => ad.guid == field.assertionDescriptorGuid
    );

    if (!assertionDescriptor) {
        field.validationError = 'Could not validate field';
        return;
    }

    switch (assertionDescriptor.valueSourceType!.id) {
        case AssertionDescriptorValueSourceTypeEnum.Boolean.Value: {
            validateBool(field, isRequiredFn);
            break;
        }
        case AssertionDescriptorValueSourceTypeEnum.Selection.Value: {
            validateSelection(field, isRequiredFn);
            break;
        }
        case AssertionDescriptorValueSourceTypeEnum.Text.Value: {
            validateText(field, assertionDescriptor.valueDisplayType?.id ?? 0, isRequiredFn);
            break;
        }
        default:
    }
};

const validateSelection = (
    field: IQueryFilterCriteria,
    isRequiredFn: (field: IQueryFilterCriteria) => boolean
) => {
    if (isRequiredFn(field) && (!field.selectedValues || field.selectedValues.length === 0)) {
        field.validationError = 'Select at least one value';
    } else {
        field.validationError = undefined;
    }
};

const validateBool = (
    field: IQueryFilterCriteria,
    isRequiredFn: (field: IQueryFilterCriteria) => boolean
) => {
    if (isRequiredFn(field) && field.booleanValue === undefined) {
        field.validationError = 'Select Yes / No';
    } else {
        field.validationError = undefined;
    }
};

const validateText = (
    field: IQueryFilterCriteria,
    valueDisplayTypeId: number,
    isRequiredFn: (field: IQueryFilterCriteria) => boolean
) => {
    switch (valueDisplayTypeId) {
        case AssertionDescriptorValueDisplayTypes.DateTime.Value:
            if (field.condition === ConditionSelectorEnums.Range) {
                if (!field.dateRangeInterval) {
                    field.validationError = 'Select a range interval';
                }
            } else {
                validateDateRange(field, 'Enter at least one date', isRequiredFn);
            }
            break;
        case AssertionDescriptorValueDisplayTypes.Currency.Value:
        case AssertionDescriptorValueDisplayTypes.Percentage.Value:
        case AssertionDescriptorValueDisplayTypes.Number.Value:
        case AssertionDescriptorValueDisplayTypes.Decimal.Value:
            if (isRequiredFn(field) && !field.numericValueFrom && !field.numericValueTo) {
                field.validationError = 'Enter at least one value';
            } else {
                field.validationError = undefined;
            }
            break;
        default: {
            if (
                isRequiredFn(field) &&
                (field.text === null || (field.text?.trim().length ?? 0) < 3)
            ) {
                field.validationError = 'Enter at least 3 characters';
            } else {
                field.validationError = undefined;
            }
            break;
        }
    }
};

const validateDateRange = (
    item: IQueryFilterCriteria,
    errorMessage: string,
    isRequiredFn: (field: IQueryFilterCriteria) => boolean
) => {
    if (isRequiredFn(item)) {
        if (!item.startDate && !item.endDate) {
            item.validationError = errorMessage || 'Enter at least one date';
        } else if (item.startDate && !Common.isValidDate(item.startDate)) {
            item.validationError = 'Invalid Date';
        } else if (item.endDate && !Common.isValidDate(item.endDate)) {
            item.validationError = 'Invalid Date';
        } else if (item.startDate && item.endDate && item.startDate > item.endDate) {
            item.validationError = 'From Date must be before To Date';
        } else {
            item.validationError = undefined;
        }
    }
};

export const criteriaRequiresValue = (field: IQueryFilterCriteria) => {
    return (
        field.condition === undefined ||
        field.condition === ConditionSelectorEnums.EqualTo ||
        field.condition === ConditionSelectorEnums.NotEqualTo ||
        field.condition === ConditionSelectorEnums.Range
    );
};

export const triggerFieldRequiresValue = (field: IQueryFilterCriteria) => {
    return (
        field.condition === ConditionSelectorEnums.EqualTo ||
        field.condition === ConditionSelectorEnums.Range
    );
};
