import { ExportFileButton } from '../../../components/shared/ExportFileButton';
import { IExportSettings } from '../../../interfaces/Report/IReport';
import { ReportTypeToExportUrlMap } from '../../../utilities/Constants';
import { Stack } from 'react-bootstrap';

interface ICollabReportCreateActionsProps {
    exportSettings: IExportSettings;
    hideSaveAsButton: boolean;
    onCommentsClick: () => void;
    onExportError?: () => void;
    onSaveAsClick: () => void;
    onSettingsClick: () => void;
    reportTypeId: number;
}

export function CollabReportCreateActions(props: ICollabReportCreateActionsProps) {
    return (
        <Stack direction="horizontal" style={{ flexWrap: 'wrap' }}>
            <button className="btn btn-no-bg text-gray" onClick={props.onSettingsClick}>
                <i className="fal fa-user-cog" />
                &nbsp;Settings
            </button>
            <button className="btn btn-no-bg text-gray" onClick={props.onCommentsClick}>
                <i className="fal fa-comment-lines" />
                &nbsp;Comments
            </button>
            <ExportFileButton
                url={'/' + ReportTypeToExportUrlMap[props.reportTypeId]}
                reportType={{
                    id: props.reportTypeId,
                    availableReportOptions: { customOptions: props.exportSettings.options },
                }}
                reportParameters={props.exportSettings.parameters}
                onError={props.onExportError}
                icon="fa-file-pdf"
            />
            {!props.hideSaveAsButton && (
                <button className="btn btn-no-bg text-gray" onClick={props.onSaveAsClick}>
                    <i className="fal fa-pen" />
                    &nbsp;Save As
                </button>
            )}
        </Stack>
    );
}
