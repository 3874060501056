import { AlreadyEditingModal, IAlreadyEditingModalData } from './AlreadyEditingModal';
import {
    FailedToObtainLeaseModal,
    IFailedToObtainLeaseModalData,
} from './FailedToObtainLeaseModal';
import { HistoryModal, IHistoryModalData } from './HistoryModal';
import { IChatModalData, ReportChatModal } from './ReportChatModal';
import { ISaveReportModalData, SaveReportModal } from './SaveReportModal';
import React, { ReactNode, createContext, useContext, useState } from 'react';

import { CheckmateDialog } from '../../../../components/shared/dialog';
import { ClearSettingsConfirmModal } from './ClearSettingsConfirmModal';
import { CollabReportUnsavedChangesReason } from '../enums/CollabReportUnsavedChangesReason';
import { ExistingLeaseModal } from './ExistingLeaseModal';
import { UnsavedChangesDetectedModal } from './UnsavedChangesDetectedModal';

// Define a generic type for dialog context
interface CollabModalContextType {
    openModal<T, C>(modalType: CollabModalTypes, data?: T, onConfirm?: (data: C) => void): void;
    closeModal(modalType: CollabModalTypes): void;
    getModalState(modalType: CollabModalTypes): boolean;
    getModalConfirmCallback<C>(modalType: CollabModalTypes): ((data: C) => void) | undefined;
    getModalData<T>(modalType: CollabModalTypes): T | undefined;
}

const CollabModalContext = createContext<CollabModalContextType | undefined>(undefined);

export enum CollabModalTypes {
    AlreadyEditing,
    Chat,
    ClearSettingConfirm,
    ExistingLease,
    FailedToObtainLease,
    History,
    RemoveSection,
    Save,
    UnsavedChangesDetected,
}

export interface ICollabModalProps<T, C> {
    open: boolean;
    onClose: () => void;
    onConfirm?: (data?: C) => void;
    data?: T;
}

export const CollabModalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [dialogStates, setModalStates] = useState<Record<string, boolean>>({});
    const [confirmCallbacks, setConfirmCallbacks] = useState<Record<string, any>>({});
    const [dialogData, setModalData] = useState<Record<string, any>>({});

    const openModal = <T, C>(
        modalType: CollabModalTypes,
        data: T,
        onConfirm?: (data: C) => void
    ) => {
        setModalStates((prev) => ({ ...prev, [modalType]: true }));
        setConfirmCallbacks((prev) => ({ ...prev, [modalType]: onConfirm }));
        setModalData((prev) => ({ ...prev, [modalType]: data }));
    };

    const closeModal = (modalType: CollabModalTypes) => {
        setModalStates((prev) => ({ ...prev, [modalType]: false }));
        setConfirmCallbacks((prev) => ({ ...prev, [modalType]: undefined }));
        setModalData((prev) => ({ ...prev, [modalType]: undefined }));
    };

    const getModalState = (modalType: CollabModalTypes) => dialogStates[modalType] || false;
    const getModalConfirmCallback = <C,>(modalType: CollabModalTypes) =>
        confirmCallbacks[modalType] as ((data?: C) => void) | undefined;
    const getModalData = <T,>(modalType: CollabModalTypes) => dialogData[modalType] as T;

    return (
        <CollabModalContext.Provider
            value={{
                openModal,
                closeModal,
                getModalState,
                getModalConfirmCallback,
                getModalData,
            }}
        >
            {children}
            {/* <ModalA
                open={getModalState('ModalA')}
                onClose={() => closeModal('ModalA')}
                onConfirm={getModalConfirmCallback('ModalA')}
                data={getModalData<string[]>('ModalA')}
            /> */}
            <HistoryModal
                data={getModalData<IHistoryModalData>(CollabModalTypes.History)}
                open={getModalState(CollabModalTypes.History)}
                onClose={() => closeModal(CollabModalTypes.History)}
            />
            <ReportChatModal
                data={getModalData<IChatModalData>(CollabModalTypes.Chat)}
                open={getModalState(CollabModalTypes.Chat)}
                onClose={() => closeModal(CollabModalTypes.Chat)}
            />
            <SaveReportModal
                data={getModalData<ISaveReportModalData>(CollabModalTypes.Save)}
                open={getModalState(CollabModalTypes.Save)}
                onClose={() => closeModal(CollabModalTypes.Save)}
            />
            <UnsavedChangesDetectedModal
                data={getModalData<{ reason: CollabReportUnsavedChangesReason }>(
                    CollabModalTypes.UnsavedChangesDetected
                )}
                open={getModalState(CollabModalTypes.UnsavedChangesDetected)}
                onConfirm={getModalConfirmCallback(CollabModalTypes.UnsavedChangesDetected)}
                onClose={() => closeModal(CollabModalTypes.UnsavedChangesDetected)}
            />
            <ExistingLeaseModal
                data={getModalData<{ dialogText: string }>(CollabModalTypes.ExistingLease)}
                open={getModalState(CollabModalTypes.ExistingLease)}
                onClose={() => closeModal(CollabModalTypes.ExistingLease)}
            />
            <AlreadyEditingModal
                data={getModalData<IAlreadyEditingModalData>(CollabModalTypes.AlreadyEditing)}
                open={getModalState(CollabModalTypes.AlreadyEditing)}
                onClose={() => closeModal(CollabModalTypes.AlreadyEditing)}
            />
            <ClearSettingsConfirmModal
                open={getModalState(CollabModalTypes.ClearSettingConfirm)}
                onConfirm={getModalConfirmCallback(CollabModalTypes.ClearSettingConfirm)}
                onClose={() => closeModal(CollabModalTypes.ClearSettingConfirm)}
            />
            <FailedToObtainLeaseModal
                data={getModalData<IFailedToObtainLeaseModalData>(
                    CollabModalTypes.FailedToObtainLease
                )}
                open={getModalState(CollabModalTypes.FailedToObtainLease)}
                onClose={() => closeModal(CollabModalTypes.FailedToObtainLease)}
            />
            <CheckmateDialog
                isShowingModal={getModalState(CollabModalTypes.RemoveSection)}
                body="The section you are trying to remove contains comments. Removing the section will delete the comment for the section. Are you sure you want to remove this section? "
                handleClose={() => closeModal(CollabModalTypes.RemoveSection)}
                handleConfirm={getModalConfirmCallback(CollabModalTypes.RemoveSection)}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end"
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />
        </CollabModalContext.Provider>
    );
};

export const useCollabReportModal = () => {
    const context = useContext(CollabModalContext);
    if (context === undefined) {
        throw new Error('useCollabReportModal must be used within a ModalProvider');
    }
    return context;
};
