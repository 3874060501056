import * as React from 'react';

import { CloseButton, Modal, Stack } from 'react-bootstrap';
import { CollabModalTypes, useCollabReportModal } from '../collab-report/modals/CollabModalContext';

import { ApplicationHelpType } from '../../../utilities/Constants';
import { Help } from '../../../components/shared/Help';
import { IKpiReportInputModel } from './KpiReport';
import { IValidation } from '../../../interfaces/IError';

export interface IKpiSettingsModalProps {
    disabled?: boolean;
    initialInputModel: IKpiReportInputModel;
    onHide: () => void;
    onSubmit: (inputModel: IKpiReportInputModel) => void;
    open: boolean;
    readonly: boolean;
    reportGuid?: string;
    setValidation: React.Dispatch<React.SetStateAction<IValidation>>;
    validation?: IValidation;
}

export function KpiSettingsModal(props: IKpiSettingsModalProps) {
    if (!open) return null;

    const modal = useCollabReportModal();

    const [localInputModel, setLocalInputModel] = React.useState<IKpiReportInputModel>(
        props.initialInputModel ?? { asOfDate: null, defenseSpendYTD: 0 }
    );
    const [validation, setValidation] = React.useState<IValidation>({});

    const validate = (model: IKpiReportInputModel): boolean => {
        const validation: IValidation = {};

        if (!model.endDate) {
            validation.model = ['Enter an As of Date'];
            setValidation(validation);
            return false;
        }

        if ((model.amount ?? 0) < 0) {
            validation.model = ['YTD Defense Spend cannot be negative'];
            setValidation(validation);
            return false;
        }

        setValidation(validation);
        return true;
    };

    const handleRunReportClick = () => {
        const isValid = validate(localInputModel);
        if (isValid) {
            props.onSubmit(localInputModel);
        }
    };

    const handleClearSettings = () => {
        props.setValidation({});
        setLocalInputModel((prev) => ({ ...prev, endDate: undefined, amount: undefined }));
    };

    const handleClearClick = () => {
        modal.openModal(CollabModalTypes.ClearSettingConfirm, null, () => {
            handleClearSettings();
            modal.closeModal(CollabModalTypes.ClearSettingConfirm);
        });
    };

    const handleAsOfDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalInputModel((prev) => ({ ...prev, endDate: e.target.value }));
    };

    const handleDefenseSpendChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        const valAsNumber = parseInt(value);

        if (!isNaN(valAsNumber) || !valAsNumber) {
            setLocalInputModel((prev) => ({ ...prev, amount: valAsNumber }));
        }
    };

    return (
        <Modal show={props.open} centered onHide={props.onHide} backdrop={false}>
            <Modal.Header>
                <Stack
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', width: '100%' }}
                >
                    <Modal.Title>Report Settings</Modal.Title>
                    <CloseButton onClick={props.onHide} />
                </Stack>
            </Modal.Header>
            <Modal.Body>
                <Stack gap={2}>
                    {validation.model && <div className="text-danger">{validation.model}</div>}
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-sm-5">
                            <label className="control-label">As of Date *</label>
                        </div>
                        <div className="col-sm-7">
                            <input
                                className="form-control horizontal-margin"
                                disabled={props.disabled}
                                onChange={handleAsOfDateChange}
                                type="date"
                                value={localInputModel.endDate ?? ''}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col-sm-5">
                            <label className="control-label">YTD Defense Spend</label>
                            <Help
                                type={ApplicationHelpType.Info}
                                title="YTD Defense Spend"
                                helpText="If you set the “as of” date for the report to be 12/31/XX of any prior year, then the report will display the defense spend information that you provided for the lookup table for that year and 3 previous years; if you set the “as of” date for any date other than 12/31 of a prior year OR for YTD of the current year, then you need to input the YTD defense spend value for that partial period to be displayed along with the values from the lookup table for the previous 3 years."
                            />
                        </div>
                        <div className="col-sm-7">
                            <input
                                className="form-control horizontal-margin"
                                disabled={props.disabled}
                                min={0}
                                onChange={handleDefenseSpendChange}
                                style={{ width: 160 }}
                                type="number"
                                value={localInputModel.amount ?? ''}
                            />
                        </div>
                    </div>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                {props.reportGuid ? (
                    <>
                        {props.disabled || props.readonly ? (
                            <button className="btn btn-default" onClick={props.onHide}>
                                Close
                            </button>
                        ) : (
                            <>
                                <button
                                    className="btn btn-orange text-gray"
                                    onClick={handleRunReportClick}
                                >
                                    Run Report
                                </button>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <button className="btn btn-default" onClick={handleClearClick}>
                            Clear
                        </button>
                        <button className="btn btn-orange text-gray" onClick={handleRunReportClick}>
                            Run Report
                        </button>
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
}
