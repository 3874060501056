import { MyDocumentsTypesEnum, ReportTypeToExportUrlMap } from '../../../utilities/Constants';

import { ExportFileButton } from '../../../components/shared/ExportFileButton';
import { IExportSettings } from '../../../interfaces/Report/IReport';
import { Stack } from 'react-bootstrap';
import classNames from 'classnames';

interface ICollabReportNonReadOnlyActionsProps {
    exportSettings: IExportSettings;
    hasChatActs?: boolean;
    hasUnsavedChanges: boolean;
    isPublished?: boolean;
    onCancelClick: () => void;
    onChatClick: () => void;
    onCollabClick: () => void;
    onCommentsClick: () => void;
    onExportError?: () => void;
    onHistoryClick: () => void;
    onSaveClick: () => void;
    onSettingsClick: () => void;
    reportTypeId: number;
}

export function CollabReportNonReadOnlyActions(props: ICollabReportNonReadOnlyActionsProps) {
    return (
        <Stack direction="horizontal">
            <Stack direction="horizontal" style={{ flexWrap: 'wrap' }}>
                <button className="btn btn-no-bg text-gray" onClick={props.onCommentsClick}>
                    <i className="fal fa-comment-lines" />
                    &nbsp;Comments
                </button>
                <button className="btn btn-no-bg text-gray" onClick={props.onSettingsClick}>
                    <i className="fal fa-user-cog" />
                    &nbsp;Settings
                </button>
                <button className="btn btn-no-bg text-gray" onClick={props.onCollabClick}>
                    <i className="fal fa-users" />
                    &nbsp;Collab
                </button>
                {!props.isPublished && (
                    <button className="btn btn-no-bg text-gray" onClick={props.onHistoryClick}>
                        <i className="fal fa-history" />
                        &nbsp;History
                    </button>
                )}
                <ExportFileButton
                    url={'/' + ReportTypeToExportUrlMap[props.reportTypeId]}
                    reportType={{
                        id: MyDocumentsTypesEnum.WeeklyRecap.Value,
                        availableReportOptions: {
                            customOptions: props.exportSettings.options,
                        },
                    }}
                    reportParameters={props.exportSettings.parameters}
                    onError={props.onExportError}
                    icon="fa-file-pdf"
                />
                <button
                    className={classNames('btn btn-no-bg', {
                        'font-orange': props.hasChatActs,
                        'text-gray': !props.hasChatActs,
                    })}
                    onClick={props.onChatClick}
                >
                    <i className="fal fa-messages" />
                    &nbsp;Chat
                </button>
            </Stack>
            <Stack direction="horizontal">
                <button
                    className="btn btn-orange text-gray"
                    onClick={props.onSaveClick}
                    disabled={!props.hasUnsavedChanges}
                >
                    Save
                </button>
                <button className="btn btn-default" onClick={props.onCancelClick}>
                    Cancel
                </button>
            </Stack>
        </Stack>
    );
}
