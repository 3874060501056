import { AssertionDescriptorSummary } from './AssertionDescriptorSummary';
import { IQueryFilterCriteria } from '../../../../interfaces/IQuery';
import { ITriggerRuleInfoCellProps } from '../TriggerRulesTable';
import { StaticItemSummary } from './StaticItemSummary';

export interface IItemSummaryProps extends ITriggerRuleInfoCellProps {
    trigger: IQueryFilterCriteria;
}

export function TriggerFieldsCell(props: ITriggerRuleInfoCellProps) {
    return (
        <td>
            {props.triggerFields.map((trigger, idx) => {
                if (trigger.isAssertionDescriptor) {
                    return <AssertionDescriptorSummary key={idx} {...props} trigger={trigger} />;
                }

                return <StaticItemSummary key={idx} {...props} trigger={trigger} />;
            })}
        </td>
    );
}
