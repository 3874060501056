import * as React from 'react';

import { IKpiModel, IKpiSummaryCrosstabModel } from '../../interfaces/IKpiView';
import { MyDocumentsTypesEnum, UserRightsEnum } from '../../utilities/Constants';

import { ApiRoutes } from '../../utilities/ApiRoutes';
import Authorization from '../../stores/Authorization';
import { DisplayMessages } from '../../utilities/DisplayMessages';
import { ExportFileButton } from '../../components/shared/ExportFileButton';
import Html5ReportViewer from '../../components/report/Html5ReportViewer';
import { IReportParametersModel } from '../../interfaces/Report/IReport';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { Loader } from '../../components/shared/Loader';
import { Modal } from 'react-bootstrap';
import moment from 'moment';

interface ITrendsProps {
    key: string;
    user: IUserModel;
    refreshIdentifier?: string;
    reportDate: string | undefined;
}

interface ITrendsState {
    pendingResponse: boolean;
    reportViewerHeight?: number;
    validation: IValidation;
    reportData?: IKpiModel;
    summaryData: IKpiSummaryCrosstabModel[];
    summaryYears: string[];
    reportParameters: IReportParametersModel;
    refreshReport: boolean;
    showGeneratedLiveButton: boolean;
    showSettingsModal: boolean;
    renderComplete?: boolean;
}

export class Trends extends React.Component<ITrendsProps, ITrendsState> {
    constructor(props: ITrendsProps) {
        super(props);

        this.state = {
            pendingResponse: false,
            validation: {},
            summaryData: [],
            summaryYears: [],
            reportParameters: {
                reportType: { id: 23 },
                getLiveData: false,
                date: {
                    type: 1,
                    startDate: '',
                    endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                },
            },
            refreshReport: false,
            showGeneratedLiveButton: Authorization.userHasRight(
                UserRightsEnum.KPIViewGenerateLive,
                this.props.user
            ),
            showSettingsModal: false,
            reportData: undefined,
        };
    }

    componentDidUpdate(prevProps: ITrendsProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier !== this.props.refreshIdentifier
        ) {
            this.setState({
                pendingResponse: true,
                validation: {},
                summaryData: [],
                summaryYears: [],
                reportParameters: {
                    reportType: { id: 23 },
                    getLiveData: false,
                    date: {
                        type: 1,
                        startDate: '',
                        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                    },
                },
                refreshReport: false,
                showGeneratedLiveButton: Authorization.userHasRight(
                    UserRightsEnum.KPIViewGenerateLive,
                    this.props.user
                ),
                showSettingsModal: false,
                reportData: undefined,
                renderComplete: false,
            });
        }
    }

    runLive = async () => {
        const params = this.state.reportParameters;
        params.getLiveData = true;
        this.setState({ reportParameters: params, refreshReport: true, showSettingsModal: false });
    };

    getReportParameters = (): any => {
        return {
            getLiveData: this.state.reportParameters && this.state.reportParameters.getLiveData,
        };
    };

    render() {
        if (this.state.pendingResponse) return <Loader />;
        return (
            <div>
                <h1 style={{ marginLeft: '10px' }}>
                    Trends
                    {!this.props.reportDate || this.state.reportParameters.getLiveData ? (
                        <span className="padding-left-lg font-size-xs">As of Now</span>
                    ) : (
                        <span className="padding-left-lg font-size-xs">
                            As of 12:01am Central {this.props.reportDate}
                        </span>
                    )}
                    <ExportFileButton
                        url={ApiRoutes.ExportReport}
                        reportType={{ id: MyDocumentsTypesEnum.Trends.Value }}
                        reportParameters={{
                            reportType: { id: MyDocumentsTypesEnum.Trends.Value },
                            date: {
                                type: 1,
                                startDate: '',
                                endDate: '',
                            },
                            getLiveData: this.state.reportParameters.getLiveData,
                        }}
                        icon="fa-file-pdf"
                    />
                    {this.props.reportDate &&
                    this.state.showGeneratedLiveButton &&
                    !this.state.reportParameters.getLiveData ? (
                        <button
                            className="btn btn-no-bg float-end text-gray"
                            onClick={() => this.setState({ showSettingsModal: true })}
                        >
                            <i className="fal fa-user-cog" />
                            &nbsp;Settings
                        </button>
                    ) : null}
                </h1>

                <Html5ReportViewer
                    reportName="Trends.trdp"
                    reportParameters={this.getReportParameters()}
                    refreshReport={this.state.refreshReport}
                    scale={1.3}
                />

                {this.state.showSettingsModal && (
                    <Modal
                        centered
                        show={this.state.showSettingsModal}
                        onHide={() => this.setState({ showSettingsModal: false })}
                        backdrop={false}
                    >
                        <Modal.Header>
                            <Modal.Title> Trends </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                This view is generated at 12:01am Central time each day. You can run
                                this view real time, on an exception basis.
                            </p>
                            <div className="dialog-btn-div margin-top-sm">
                                <button
                                    className="btn btn-default float-end"
                                    onClick={() => this.setState({ showSettingsModal: false })}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-orange float-end" onClick={this.runLive}>
                                    Run
                                </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                )}
            </div>
        );
    }
}
