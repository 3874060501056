import 'bootstrap';
import './css/site.scss';

import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import { CheckmateRoutes } from './components/layouts/CheckmateRoutes';
import { CollabModalProvider } from './pages/report/collab-report/modals/CollabModalContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { SignalRProvider } from './contexts/SignalRContext';
import { useEffect } from 'react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            notifyOnChangeProps: 'tracked',
            retry: false,
        },
    },
});

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export default function App() {
    return <RouterProvider router={router} />;
}

function Root() {
    useEffect(() => {
        preventDuplicateTab();
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <LoadingProvider>
                <SignalRProvider>
                    <AuthProvider>
                        <CollabModalProvider>
                            <CheckmateRoutes />
                        </CollabModalProvider>
                    </AuthProvider>
                </SignalRProvider>
            </LoadingProvider>
        </QueryClientProvider>
    );
}

function preventDuplicateTab() {
    if (sessionStorage.tabCreateTimestamp) {
        if (!window.name) {
            window.name = 'tab';
            sessionStorage.tabCreateTimestamp = Date.now();
            window.location.reload();
        }
    } else {
        sessionStorage.tabCreateTimestamp = Date.now();
    }
}
