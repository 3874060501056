import { CheckmateDialog } from '../../../../components/shared/dialog';
import { CollabReportUnsavedChangesReason } from '../enums/CollabReportUnsavedChangesReason';
import { ICollabModalProps } from './CollabModalContext';

export function UnsavedChangesDetectedModal(
    props: ICollabModalProps<
        { reason: CollabReportUnsavedChangesReason },
        CollabReportUnsavedChangesReason
    >
) {
    if (!props.open) return null;

    return (
        <CheckmateDialog
            isShowingModal={props.open}
            title={'Unsaved Changes Detected'}
            body={'You have unsaved changes. Are you sure you want to proceed?'}
            handleClose={props.onClose}
            handleConfirm={() => {
                if (props.onConfirm) {
                    props.onConfirm(props.data?.reason);
                }
                props.onClose();
            }}
            cancelText="Cancel"
            confirmText="Continue"
            closeButtonClassName="btn btn-default float-end"
            confirmButtonClassName="btn btn-black float-end"
            size="sm"
        />
    );
}
