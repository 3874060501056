import Common from '../../../stores/Common';
import { IReportBaseModel } from '../../../interfaces/Report/IReport';
import { ITimelineReportInputModel } from '../../../interfaces/Report/ITimelineReport';
import { Stack } from 'react-bootstrap';

interface ICollabReportPageHeaderProps {
    actionsComponent: JSX.Element;
    inputModel: ITimelineReportInputModel;
    isCollabReport: boolean;
    isInADraftStatus: boolean;
    report: IReportBaseModel | undefined;
    title: string;
}

export function CollabReportPageHeader(props: ICollabReportPageHeaderProps) {
    const getReportMeta = () => {
        /*
        Report Title
            For Live View from the Report Tool (Report hasn't been saved yet) - Report Name | Date Range 
            Draft/Final Draft - report name | status | date range | Last Edited User | DateTime
            Published - Report Name | Date Range | Date Published (DateTime)
        */
        const dateString =
            props.inputModel && props.inputModel.startDate && props.inputModel.endDate
                ? Common.dateFormat(props.inputModel.startDate.toString()) +
                  ' to ' +
                  Common.dateFormat(props.inputModel.endDate.toString())
                : '';

        let parts: (string | undefined)[];

        if (!props.isCollabReport) {
            return <>{dateString && <span className="font-size-xs">{dateString}</span>}</>;
        } else if (props.isInADraftStatus) {
            parts = [
                props.report?.status?.displayName,
                dateString,
                `Last Updated by ${props.report?.modifiedBy?.profile?.firstName} ${props.report?.modifiedBy?.profile?.lastName}`,
                Common.dateTimeFormatToLocal(props.report?.modifiedDate),
            ];

            return <span className="font-size-xs">{parts.filter(Boolean).join(' | ')}</span>;
        } else {
            parts = [dateString, Common.dateTimeFormatToLocal(props.report?.modifiedDate)];
        }

        return <span className="font-size-xs">{parts.filter(Boolean).join(' | ')}</span>;
    };

    return (
        <Stack
            direction="horizontal"
            style={{
                alignItems: 'flex-start',
                flexWrap: 'wrap',
                paddingRight: '35px',
            }}
        >
            <div style={{ width: '100%' }}>
                <Stack direction="horizontal" style={{ justifyContent: 'space-between' }}>
                    <h1 style={{ whiteSpace: 'nowrap' }}>{props.title}</h1>
                    {props.actionsComponent}
                </Stack>
                <div>{getReportMeta()}</div>
            </div>
        </Stack>
    );
}
