import {
    ApplicationHelpType,
    AssertionDescriptorUsageTypesEnum,
    AssertionDescriptors,
    CaseFileStaticItems,
    ConditionSelectorEnums,
    QueryToolModeEnum,
    QueryTypes,
    TutorialTopics,
    UserRightsEnum,
} from '../../utilities/Constants';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    processCriteria,
    processTriggerField,
    validateTrigger,
} from '../../components/triggers/trigger-details/validation';

import Authorization from '../../stores/Authorization';
import CaseHelper from '../../utilities/CaseHelper';
import { DocumentTitle } from '../../components/shared/DocumentTitle';
import { Help } from '../../components/shared/Help';
import { IAssertionDescriptorModel } from '../../interfaces/IAssertionDescriptor';
import { IQueryFilterCriteria } from '../../interfaces/IQuery';
import { IStaticItemLookupModel } from '../../interfaces/ILookup';
import { ITriggerRuleSettingsModel } from '../../interfaces/ITriggerRule';
import { IUserModel } from '../../interfaces/IUser';
import { IValidation } from '../../interfaces/IError';
import { LocalRoutes } from '../../utilities/LocalRoutes';
import { PageHeaderLayout } from '../../components/shared/PageHeaderLayout';
import { QueryTypeSelector } from '../query2/QueryTypeSelector';
import Sort from '../../stores/Sort';
import { TriggerFieldValueControls } from '../../components/triggers/trigger-details/trigger-fields/TriggerFieldValueControls';
import { TriggerFieldsSection } from '../../components/triggers/trigger-details/trigger-fields/TriggerFieldsSection';
import { TriggerFilterCriteriaSection } from '../../components/triggers/trigger-details/filter-criteria/TriggerFilterCriteriaSection';
import { TriggerFilterCriteriaValueControls } from '../../components/triggers/trigger-details/filter-criteria/TriggerFilterCriteriaValueControls';
import UIHelper from '../../utilities/UIHelper';
import { cloneDeep } from 'lodash';
import { useAssertionDescriptorsLookup } from '../../shared/react-query-hooks/useAssertionDescriptorsLookup';
import { useCreateTriggerMutation } from '../../components/triggers/useCreateTriggerMutation';
import { useGetStaticItems } from '../../shared/react-query-hooks/useGetStaticItems';
import { useGetTriggerRule } from '../../components/triggers/trigger-details/useGetTriggerRule';
import { useLoading } from '../../contexts/LoadingContext';
import { useState } from 'react';
import { useUpdateTriggerMutation } from '../../components/triggers/useUpdateTriggerMutation';

export interface ITriggerDetailsPageProps {
    isCreate?: boolean;
    user: IUserModel;
}

export function TriggerDetailsPage(props: ITriggerDetailsPageProps) {
    const { triggerGuid } = useParams();
    const navigate = useNavigate();
    const loading = useLoading();

    const [validation, setValidation] = useState<IValidation>({});

    const createMutation = useCreateTriggerMutation((errorMessage: string) =>
        setValidation((prev) => ({ ...prev, create: [errorMessage] }))
    );

    const updateMutation = useUpdateTriggerMutation((errorMessage: string) =>
        setValidation((prev) => ({ ...prev, update: [errorMessage] }))
    );

    const {
        data,
        isError,
        error,
        isLoading,
        status: apiStatus,
    } = useGetTriggerRule(triggerGuid, true);
    const assertionDescriptors = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Case
    );
    const staticItems = useGetStaticItems();
    const queryTypes = CaseHelper.getAuthorizedQueryTypes(props.user);
    const [queryTypeId, setQueryTypeId] = useState<number>(QueryTypes.CaseDetails.Value);

    const [localTrigger, setLocalTrigger] = useState<ITriggerRuleSettingsModel | undefined>(data);

    const [suppressDateRangeConditionCondition, setsuppressDateRangeCondition] =
        useState<boolean>(true);

    const processCaseStatusRangeRule = (criteria: IQueryFilterCriteria[]) => {
        const foundCaseStatus = criteria.find(
            (crit) => crit.staticItemId === CaseFileStaticItems.CaseStatus.Id
        );

        const handleHideDateRangeCondition = () => {
            const localTriggerCopy = cloneDeep(localTrigger);
            localTriggerCopy?.filters
                ?.filter((criteria) => criteria.condition === ConditionSelectorEnums.Range)
                .forEach((criteria) => {
                    criteria.condition = ConditionSelectorEnums.EqualTo;
                    criteria.dateRangeInterval = undefined;
                });

            localTriggerCopy?.triggerFields
                ?.filter((rule) => rule.condition === ConditionSelectorEnums.Range)
                .forEach((rule) => {
                    rule.condition = ConditionSelectorEnums.EqualTo;
                    rule.dateRangeInterval = undefined;
                });

            setLocalTrigger(localTriggerCopy);
            setsuppressDateRangeCondition(true);
        };

        let hide = true;

        if (foundCaseStatus) {
            const equalTo = foundCaseStatus.condition === ConditionSelectorEnums.EqualTo;
            const notEqualTo = foundCaseStatus.condition === ConditionSelectorEnums.NotEqualTo;
            const selectedIds = foundCaseStatus.selectedValues?.map((obj) => obj.id) ?? [];

            if (equalTo) {
                const ongoingStatuses = [1, 2, 6, 8];
                const allFromOngoing = selectedIds
                    .filter((id) => !!id)
                    .every((id) => ongoingStatuses.includes(id!));

                hide = !(selectedIds.length > 0 && allFromOngoing);
            } else if (notEqualTo) {
                const closedStatuses = [3, 4, 5, 7, 9, 10, 11];
                const allClosedPresent = closedStatuses.every((status) =>
                    selectedIds.includes(status)
                );

                const noExtraValues = selectedIds.length === closedStatuses.length;

                hide = !(allClosedPresent && noExtraValues);
            }
        }

        if (hide) {
            handleHideDateRangeCondition();
        }

        setsuppressDateRangeCondition(hide);
    };

    const handleSaveClick = () => {
        const queryTypeLU = queryTypes.find((qt) => qt.id === queryTypeId);
        const localTriggerCopy = cloneDeep({
            ...localTrigger,
            queryType: queryTypeLU,
        }) as ITriggerRuleSettingsModel;

        const validation = validateTrigger(
            localTriggerCopy,
            assertionDescriptors.data ?? [],
            (validation: IValidation) => setValidation(validation)
        );

        if (!validation) {
            setLocalTrigger(localTriggerCopy);
            return false;
        }

        loading.showLoading();

        if (props.isCreate) {
            createMutation
                .mutateAsync(localTriggerCopy)
                .then((newTrigger) => {
                    if (newTrigger) {
                        navigate(LocalRoutes.Triggers);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    loading.hideLoading();
                });
        } else {
            updateMutation
                .mutateAsync(localTriggerCopy)
                .then((updatedTrigger) => {
                    if (updatedTrigger) {
                        navigate(LocalRoutes.Triggers);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    loading.hideLoading();
                });
        }
    };

    const handleCancelClick = () => {
        navigate(LocalRoutes.Triggers);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLocalTrigger(
            (prev) => ({ ...prev, ruleName: e.target.value } as ITriggerRuleSettingsModel)
        );
    };

    const handleDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setLocalTrigger(
            (prev) => ({ ...prev, ruleDescription: e.target.value } as ITriggerRuleSettingsModel)
        );
    };

    const handleQueryTypeChange = (selected: any) => {
        setQueryTypeId(selected?.id);
    };

    const handleSelectedQueryFilterCriteriaChange = (selectedCriteria: IQueryFilterCriteria[]) => {
        setLocalTrigger((prev) => {
            if (prev) {
                return { ...prev, filters: selectedCriteria };
            } else {
                return { filters: selectedCriteria } as ITriggerRuleSettingsModel;
            }
        });
    };

    const handleCriteriaConditionChange = (item: IQueryFilterCriteria, itemIndex: number) => {
        if (!localTrigger) {
            return;
        }
        const tirggerCopy = cloneDeep(localTrigger);

        item.validationError = '';
        tirggerCopy.filters[itemIndex] = item;

        setLocalTrigger(tirggerCopy);
    };

    const handleSelectedTriggerFieldsChange = (fields: IQueryFilterCriteria[]) => {
        const triggerFields = fields.map((field) => {
            if (!field.condition) {
                field.condition = 'Equal To'
            }
            return field;
        });
        setLocalTrigger((prev) => {
            if (prev) {
                return { ...prev, triggerFields };
            } else {
                return { triggerFields: fields } as ITriggerRuleSettingsModel;
            }
        });
    };

    const findCriteriaItem = (
        localTrigger: ITriggerRuleSettingsModel | undefined,
        item: IQueryFilterCriteria
    ) => {
        return localTrigger?.filters.find((criteria) => {
            if (criteria.isAssertionDescriptor) {
                return criteria.assertionDescriptorGuid === item.assertionDescriptorGuid;
            } else {
                return criteria.staticItemId === item.staticItemId;
            }
        });
    };

    const handleCriteriaSelectorChange = (item: IQueryFilterCriteria, options: any) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findCriteriaItem(localTriggerCopy, item);

        if (found) {
            found.selectedValues = options;
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleCriteriaTextChange = (item: IQueryFilterCriteria, value: string) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findCriteriaItem(localTriggerCopy, item);

        if (found) {
            found.text = value;
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleCriteriaYesNoChange = (item: IQueryFilterCriteria, optionalBool: any) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findCriteriaItem(localTriggerCopy, item);

        if (found) {
            found.booleanValue = optionalBool;
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleCriteriaDateRangeChange = (
        item: IQueryFilterCriteria,
        field: string,
        value: string
    ) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findCriteriaItem(localTriggerCopy, item);

        if (found) {
            if (field === 'startDate') {
                found.startDate = value;
            } else if (field === 'endDate') {
                found.endDate = value;
            }
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleCriteriaRelativeDateChange = (item: IQueryFilterCriteria, value: number) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findCriteriaItem(localTriggerCopy, item);

        if (found) {
            found.dateRangeInterval = value;
            found.startDate = undefined;
            found.endDate = undefined;
            found.validationError = '';
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleCriteriaNumericRangeChange = (
        item: IQueryFilterCriteria,
        field: string,
        value: number | undefined
    ) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findCriteriaItem(localTriggerCopy, item);

        if (found) {
            if (field === 'from') {
                found.numericValueFrom = value;
            } else if (field === 'to') {
                found.numericValueTo = value;
            }
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const findTriggerFieldItem = (
        localTrigger: ITriggerRuleSettingsModel | undefined,
        item: IQueryFilterCriteria
    ) => {
        return localTrigger?.triggerFields.find((field) => {
            if (field.isAssertionDescriptor) {
                return field.assertionDescriptorGuid === item.assertionDescriptorGuid;
            } else {
                return field.staticItemId === item.staticItemId;
            }
        });
    };

    const handleTriggerFieldConditionChange = (item: IQueryFilterCriteria, itemIndex: number) => {
        if (!localTrigger) {
            return;
        }
        const tirggerCopy = cloneDeep(localTrigger);

        item.validationError = '';
        tirggerCopy.triggerFields[itemIndex] = item;

        setLocalTrigger(tirggerCopy);
    };

    const handleTriggerFieldSelectorChange = (item: IQueryFilterCriteria, options: any) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findTriggerFieldItem(localTriggerCopy, item);

        if (found) {
            found.selectedValues = options;
            processTriggerField(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleTriggerFieldTextChange = (item: IQueryFilterCriteria, value: string) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findTriggerFieldItem(localTriggerCopy, item);

        if (found) {
            found.text = value;
            processTriggerField(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleTriggerFieldYesNoChange = (item: IQueryFilterCriteria, optionalBool: any) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findTriggerFieldItem(localTriggerCopy, item);

        if (found) {
            found.booleanValue = optionalBool;
            processTriggerField(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleTriggerFieldDateRangeChange = (
        item: IQueryFilterCriteria,
        field: string,
        value: string
    ) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findTriggerFieldItem(localTriggerCopy, item);

        if (found) {
            if (field === 'startDate') {
                found.startDate = value;
            } else if (field === 'endDate') {
                found.endDate = value;
            }
            processTriggerField(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleTriggerFieldRelativeDateChange = (
        item: IQueryFilterCriteria,
        value: number | undefined
    ) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findTriggerFieldItem(localTriggerCopy, item);

        if (found) {
            found.dateRangeInterval = value;
            found.startDate = undefined;
            found.endDate = undefined;
            found.validationError = '';
            processCriteria(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const handleTriggerFieldNumericRangeChange = (
        item: IQueryFilterCriteria,
        field: string,
        value: number | undefined
    ) => {
        const localTriggerCopy = cloneDeep(localTrigger);
        const found = findTriggerFieldItem(localTriggerCopy, item);

        if (found) {
            if (field === 'from') {
                found.numericValueFrom = value;
            } else if (field === 'to') {
                found.numericValueTo = value;
            }
            processTriggerField(found, assertionDescriptors.data ?? []);
            setLocalTrigger(localTriggerCopy);
        }
    };

    const availableFilterCriterias = getAvailableFilterCriterias(
        assertionDescriptors.data ?? [],
        staticItems.data ?? [],
        props.user
    );

    const availableTriggerFieldCriterias = [...availableFilterCriterias];

    const filteredFilterCriteria = availableFilterCriterias.filter(
        (crit) => !localTrigger?.triggerFields?.some((tr) => tr.value === crit.value)
    );
    const filteredTriggerFieldCriteria = availableTriggerFieldCriterias.filter(
        (crit) => !localTrigger?.filters?.some((f) => f.value === crit.value)
    );

    const showFilterCriteria = useMemo(
        () =>
            [
                QueryTypes.CaseDetails.Value,
                QueryTypes.CaseExperts.Value,
                QueryTypes.CaseNotes.Value,
                QueryTypes.Deposition.Value,
            ].includes(queryTypeId),
        [queryTypeId]
    );

    const showTriggerFields = useMemo(() => true, [queryTypeId]);
    const pageTitle = props.isCreate ? 'New Trigger Rule' : 'Trigger Rule';
    const saveButtonText = props.isCreate ? 'Create' : 'Save';

    useEffect(() => {
        if (apiStatus === 'success') {
            setLocalTrigger(data);
        }
    }, [apiStatus, data]);

    useEffect(() => {
        if (isLoading) {
            loading.showLoading();
        } else {
            loading.hideLoading();
        }
    }, [isLoading]);

    useEffect(() => {
        processCaseStatusRangeRule(localTrigger?.filters ?? []);
    }, [JSON.stringify(localTrigger?.filters), JSON.stringify(localTrigger?.triggerFields)]);

    if (!isLoading && isError) {
        return (
            <div className="row">
                <div className="text-danger">Failed to load trigger rule.</div>
            </div>
        );
    }

    if (isLoading) {
        return null;
    }

    return (
        <>
            <DocumentTitle title={localTrigger?.ruleName || 'Unnamed Trigger Rule'} />
            <PageHeaderLayout
                title={pageTitle}
                actions={
                    <>
                        <Link
                            target="_blank"
                            to={LocalRoutes.ViewSpecificPDF.replace(
                                ':id',
                                TutorialTopics.Triggers.toString()
                            )}
                        >
                            <span className="btn btn-no-bg text-gray">
                                <i className={'fal fa-graduation-cap'} />
                                &nbsp;Tutorials
                            </span>
                        </Link>
                        <>
                            <button className="btn btn-default" onClick={handleCancelClick}>
                                Cancel
                            </button>
                            <button className="btn btn-orange" onClick={handleSaveClick}>
                                {saveButtonText}
                            </button>
                        </>
                    </>
                }
                disableBottomPadding
            />
            <div className="row mb-2">
                <div className="col-sm-2"></div>
                <div className="col-sm-10">
                    {isError && <div className="text-danger">{error as string}</div>}
                    <div className="text-danger">{validation.model}</div>
                    <div className="text-danger">{validation.create}</div>
                    <div className="text-danger">{validation.update}</div>
                </div>
            </div>
            <QueryTypeSelector
                helpComponent={
                    <Help
                        type={ApplicationHelpType.Info}
                        title="Type"
                        helpText="Type is a fixed field for Trigger Rules and cannot be modified"
                    />
                }
                mode={QueryToolModeEnum.Standalone}
                onChange={handleQueryTypeChange}
                readonly
                selectorLabel="Type"
                selectedQueryType={queryTypeId}
                user={props.user}
                validation={validation}
            />
            <div className="row mb-2">
                <div className="col-sm-2">
                    <label className="text-gray text-lg">Name*</label>
                </div>
                <div className="col-sm-3">
                    <input
                        type="text"
                        className="form-control"
                        value={localTrigger?.ruleName ?? ''}
                        onChange={handleNameChange}
                        style={{ width: '100%' }}
                    />
                    <span className="text-danger">{validation.ruleName}</span>
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-sm-2">
                    <label className="text-gray text-lg">Description</label>
                </div>
                <div className="col-sm-10">
                    <textarea
                        className="form-control"
                        value={localTrigger?.ruleDescription}
                        onChange={handleDescriptionChange}
                        rows={3}
                    />
                </div>
                <span className="text-danger">{validation.ruleDescription}</span>
            </div>
            {showFilterCriteria && (
                <TriggerFilterCriteriaSection
                    availableFilterCriterias={filteredFilterCriteria}
                    buildValueControls={(item) => (
                        <TriggerFilterCriteriaValueControls
                            item={item}
                            onDateRangeChange={handleCriteriaDateRangeChange}
                            onRelativeDateChange={handleCriteriaRelativeDateChange}
                            onNumericRangeChange={handleCriteriaNumericRangeChange}
                            onSelectorChange={handleCriteriaSelectorChange}
                            onTextChange={handleCriteriaTextChange}
                            onYesNoChange={handleCriteriaYesNoChange}
                        />
                    )}
                    onSelectedQueryFilterCriteriaChange={handleSelectedQueryFilterCriteriaChange}
                    onConditionOptionChange={handleCriteriaConditionChange}
                    queryType={queryTypeId}
                    selectedQueryFilterCriteria={localTrigger?.filters ?? []}
                    suppressDateRangeCondition={suppressDateRangeConditionCondition}
                    user={props.user}
                    validation={validation}
                />
            )}
            {showTriggerFields && (
                <TriggerFieldsSection
                    availableFilterCriterias={filteredTriggerFieldCriteria}
                    buildValueControls={(item) => (
                        <TriggerFieldValueControls
                            item={item}
                            onDateRangeChange={handleTriggerFieldDateRangeChange}
                            onRelativeDateChange={handleTriggerFieldRelativeDateChange}
                            onNumericRangeChange={handleTriggerFieldNumericRangeChange}
                            onSelectorChange={handleTriggerFieldSelectorChange}
                            onTextChange={handleTriggerFieldTextChange}
                            onYesNoChange={handleTriggerFieldYesNoChange}
                        />
                    )}
                    onConditionOptionChange={handleTriggerFieldConditionChange}
                    onSelectedTriggerFieldsChange={handleSelectedTriggerFieldsChange}
                    queryType={queryTypeId}
                    selectedTriggerFields={localTrigger?.triggerFields ?? []}
                    suppressDateRangeCondition={suppressDateRangeConditionCondition}
                    user={props.user}
                    validation={validation}
                />
            )}
        </>
    );
}

const getAvailableFilterCriterias = (
    assertionDescriptors: IAssertionDescriptorModel[],
    staticItems: IStaticItemLookupModel[],
    user: IUserModel
): IQueryFilterCriteria[] => {
    const packageDealAuthFilter = (staticItem: IStaticItemLookupModel) => {
        const isPackageDeal = staticItem.guid === CaseFileStaticItems.PackageSettlement.Guid;
        return (
            !isPackageDeal ||
            (isPackageDeal &&
                Authorization.userHasRight(UserRightsEnum.ViewPackageSettlement, user))
        );
    };

    const staticSectionFilter = (staticItem: IStaticItemLookupModel) =>
        ![
            CaseFileStaticItems.ExpertsSection.Id,
            CaseFileStaticItems.DefenseCounselSection.Id,
            CaseFileStaticItems.DepositionSection.Id,
            CaseFileStaticItems.NotesSection.Id,
            CaseFileStaticItems.PackageSettlement.Id,
            CaseFileStaticItems.CaseStatusAsOfDate.Id,
        ].includes(staticItem.id);

    const staticItemToQueryFilterCriteriaMapper = (staticItem: IStaticItemLookupModel) =>
        ({
            label: CaseHelper.getCaseFileStaticItemDisplayName(staticItems, staticItem.id),
            value: UIHelper.getName(staticItems, staticItem.id),
            isAssertionDescriptor: false,
            staticItemId: staticItem.id,
        } as IQueryFilterCriteria);

    const caseAlternateManagerFilter = (item: IStaticItemLookupModel) =>
        item.id !== CaseFileStaticItems.AlternateCaseManager.Id;

    const caseManagerMapper = (item: IQueryFilterCriteria) => {
        if (item.staticItemId === CaseFileStaticItems.CaseManager.Id) {
            return { ...item, label: 'A1/A2' };
        }
        return item;
    };

    const staticItemOptions = staticItems
        .filter(packageDealAuthFilter)
        .filter(staticSectionFilter)
        .filter(caseAlternateManagerFilter)
        .map(staticItemToQueryFilterCriteriaMapper)
        .map(caseManagerMapper);

    const nationalPlaintiffsFirmAdFilter = (item: IAssertionDescriptorModel) =>
        item.guid !== AssertionDescriptors.PlainfirmFirmSecondary.Guid.toLowerCase();

    const otherOccupationAdFilter = (item: IAssertionDescriptorModel) =>
        item.guid !== AssertionDescriptors.OtherOccupation.Guid.toLowerCase();

    const adOptions = assertionDescriptors
        .filter((x) => x.usageType && x.usageType.id === AssertionDescriptorUsageTypesEnum.Case)
        .filter(nationalPlaintiffsFirmAdFilter)
        .filter(otherOccupationAdFilter)
        .map(
            (item: IAssertionDescriptorModel) =>
                ({
                    label: item.alternateDisplayName || item.displayName!,
                    value: item.name!,
                    isAssertionDescriptor: true,
                    assertionDescriptorGuid: item.guid,
                } as IQueryFilterCriteria)
        );

    return staticItemOptions.concat(adOptions).sort(Sort.compareValues('label'));
};
