import { Button, Modal } from 'react-bootstrap';

import Common from '../../../../stores/Common';
import { ICollabModalProps } from '../modals/CollabModalContext';
import { useGetReportHistory } from '../../../../shared/react-query-hooks/useGetReportHistory';

export interface IHistoryModalData {
    reportGuid: string;
}

export const HistoryModal: React.FC<ICollabModalProps<IHistoryModalData, () => void>> = (props) => {
    if (!props.open || !props.data?.reportGuid) return null;

    const { data, isError, isLoading } = useGetReportHistory(props.data?.reportGuid);

    const hasErrors = !isLoading && isError;
    const hasRecords = data?.length ?? 0 > 0;

    return (
        <Modal centered size="lg" show={props.open} backdrop={false}>
            <Modal.Header>
                <Modal.Title>Report History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading && <>Loading history...</>}

                {!isLoading && hasErrors && (
                    <div className="row">
                        <div className="text-danger">Failed to load Report History.</div>
                    </div>
                )}

                {!isLoading && hasRecords && (
                    <table className="table mb-4">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>User</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((item) => (
                                <tr key={item.user.guid + item.dateUTC}>
                                    <td>{Common.dateTimeFormatToLocal(item.dateUTC)}</td>
                                    <td>
                                        {item.user!.profile!.firstName +
                                            ' ' +
                                            item.user!.profile?.lastName}
                                    </td>
                                    <td>{item.details}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                {!isLoading && !hasRecords && <div>No history exists for this report.</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={props.onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
