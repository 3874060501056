/* Enum for organization Types. The Values should match the "Id" value in the database */

import { ILookupModel, IMultiSelectOptions } from '../interfaces/ILookup';

import { ApiRoutes } from './ApiRoutes';
import { ICustomReportOption } from '../interfaces/Report/IReport';
import { IQueryColumnDefinitionModel } from '../interfaces/IQuery';

export enum DatabaseAppSettingsEnum {
    QueryToolMaxRecordCount = 'QueryToolMaxRecordCount',
    QueryToolMaxRecordCountForWeb = 'QueryToolMaxRecordCountForWeb',
    CollabReportLeaseDuration = 'CollabReportLeaseDuration',
    GoogleTagManagerId = 'GoogleTagManagerId',
    DisableCollabReportFeature ='DisableCollabReportFeature',
}

export enum Operation {
    View = 1,
    Create = 2,
    Edit = 3,
    Delete = 4,
}

export enum UserHomeOrganizationTypesEnum {
    NationalCounsel = 1,
    LocalCounsel = 2,
    Company = 3,
    Insurer = 4,
    Portfolio = 5,
}
export enum ConditionSelectorEnums {
    AllChanges = 'All Changes',
    Blank = 'Blank',
    EqualTo = 'Equal To',
    NotBlank = 'Not Blank',
    NotEqualTo = 'Not Equal To',
    Range = 'Range',
}
export enum ExpertReportStatusTypesEnum {
    Yes = 1,
    No = 2,
    Approved = 3,
    Disclosed = 4,
}
export enum LookupDataEnums {
    AcknowledgmentStatusTypes = 'AcknowledgmentStatusTypes',
    AddressTypes = 'AddressTypes',
    AlertOriginTypes = 'AlertOriginTypes',
    AlertStatusTypes = 'AlertStatusTypes',
    ApprovalStatusTypes = 'ApprovalStatusTypes',
    AssertionDescriptorCategories = 'AssertionDescriptorCategories',
    AssertionDescriptorDisplayCategories = 'AssertionDescriptorDisplayCategories',
    AssertionDescriptorDispositionTypes = 'AssertionDescriptorDispositionTypes',
    AssertionDescriptorUsageTypes = 'AssertionDescriptorUsageTypes',
    AssertionDescriptorValueDisplayTypes = 'AssertionDescriptorValueDisplayTypes',
    AssertionDescriptorValueSourceTypes = 'AssertionDescriptorValueSourceTypes',
    CaseFileStaticItems = 'casefilestaticitems',
    CaseNoteCategories = 'CaseNoteCategories',
    CasePlanTemplates = 'CasePlanTemplates',
    CaseStatusTypes = 'CaseStatusTypes',
    DataScopes = 'DataScopes',
    DepositionCategories = 'DepositionCategories',
    DepositionStatusTypes = 'DepositionStatusTypes',
    Diagnosis = 'Diagnosis',
    DocumentCategoryTypes = 'DocumentCategoryTypes',
    DocumentSubTypes = 'DocumentSubTypes',
    DocumentTemplates = 'DocumentTemplates',
    DocumentTypes = 'DocumentTypes',
    ExpertDisciplineTypes = 'ExpertDisciplineTypes',
    ExpertReportStatusTypes = 'ExpertReportStatusTypes',
    ExpertRetainedByTypes = 'ExpertRetainedByTypes',
    ExpertTypes = 'ExpertTypes',
    Jurisdictions = 'Jurisdictions',
    LevelOfEffort = 'LevelOfEffort',
    NoteCategories = 'NoteCategories',
    NotePurposeTypes = 'NotePurposeTypes',
    NoteStatusTypes = 'NoteStatusTypes',
    NoteTypes = 'NoteTypes',
    Occupations = 'Occupations',
    OperatingMechanisms = 'OperatingMechanisms',
    OrganizationDependencyTypes = 'OrganizationDependencyTypes',
    OrganizationTypes = 'OrganizationTypes',
    PackageSettlementNoteCategories = 'PackageSettlementNoteCategories',
    PackageSettlementStatusTypes = 'PackageSettlementStatusTypes',
    PhoneTypes = 'PhoneTypes',
    PlaintiffsFirms = 'PlaintiffsFirms',
    ProjectTypes = 'ProjectTypes',
    QScore = 'QScore',
    ReportStatusTypes = 'ReportStatusTypes',
    ReportTypes = 'ReportTypes',
    ReportUserTypes = 'ReportUserTypes',
    Roles = 'Roles',
    RoleToRight = 'RoleToRight',
    SignificantChangesCategories = 'SignificantChangesCategories',
    SkillLevels = 'SkillLevels',
    Skills = 'Skills',
    StrategyNoteCategories = 'StrategyNoteCategories',
    TaskCategories = 'TaskCategories',
    TaskStatusTypes = 'TaskStatusTypes',
    ThreatAssessmentLevels = 'ThreatAssessmentLevels',
    ThreatReportOptions = 'ThreatReportOptions',
    Users = 'Users',
    UserZoneProfileRoles = 'UserZoneProfileRoles',
    USStates = 'USStates',
    ZoneNoteCategories = 'ZoneNoteCategories',
}

export const OrganizationTypesEnum = {
    Company: {
        Value: 1,
        DisplayName: 'Company',
    },
    Portfolio: {
        Value: 2,
        DisplayName: 'Portfolio',
    },
    Firm: {
        Value: 3,
        DisplayName: 'Firm',
    },
    Insurer: {
        Value: 4,
        DisplayName: 'Insurer',
    },
};

export const DataScopesEnum = {
    Admin: {
        Value: 1,
        DisplayName: 'Admin',
    },
    National: {
        Value: 2,
        DisplayName: 'National',
    },
    LocalBasic: {
        Value: 3,
        DisplayName: 'Local Basic',
    },
    Company: {
        Value: 4,
        DisplayName: 'Company',
    },
    Insurer: {
        Value: 4,
        DisplayName: 'Insurer',
    },
};
export enum ExpertRetainedByTypesEnum {
    Plaintiff = 1,
    Company = 2,
    Codefendant = 3,
    JointCompany = 4,
    JointCodefendants = 5,
}
export enum OrganizationSubTypesEnum {
    Firm_NationaCounsel = 1,
    Firm_LocalCounsel = 2,
}

/* Enum for organization Types. The Values should match the "Id" value in the database */
export const OrganizationDependencyTypesEnum = {
    Master: {
        Value: 1,
        DisplayName: 'Master',
    },
    Associated: {
        Value: 2,
        DisplayName: 'Associated',
    },
    None: {
        Value: 3,
        DisplayName: 'None',
    },
};

// Ids need to match Ids in dbo.AspNetRoles
export const RolesEnum = {
    SystemAdmin: 1,
    OrganizationAdmin: 2,
    User: 3,
    MasterOrganizationAdmin: 4,
    ReportUser: 5,
    External: 6,
    Board: 7,
};

export const RolesTypesEnum = {
    OrganizationAdmin: {
        Value: 2,
        DisplayName: 'organization Admin',
    },
    User: {
        Value: 3,
        DisplayName: 'User',
    },
    MasterOrganizationAdmin: {
        Value: 4,
        DisplayName: 'Master ORganization Admin',
    },
};

export const AssertionDescriptorValueSourceTypeEnum = {
    Selection: {
        Value: 1,
        Name: 'Selection',
    },
    Text: {
        Value: 2,
        Name: 'Text',
    },
    Implied: {
        Value: 3,
        Name: 'Implied',
    },
    Boolean: {
        Value: 4,
        Name: 'Boolean',
    },
};

export const CaseAssertionLevelEnum = {
    L1: {
        Value: 1,
        Name: 'L1',
        DisplayName: 'Level 1 (L1)assessment',
    },
    L2: {
        Value: 2,
        Name: 'L2',
        DisplayName: 'Level 2 (L2)assessment',
    },
    L3: {
        Value: 3,
        Name: 'L3',
        DisplayName: 'Level 3 (L3)assessment',
    },
    L4: {
        Value: 4,
        Name: 'L4',
        DisplayName: 'Level 4 (L4)assessment',
    },
    L5: {
        Value: 5,
        Name: 'L5',
        DisplayName: 'Level 5 (L5)assessment',
    },
};

export const AssertionDescriptorDisplayValueTypeEnum = {
    DateTime: { Value: 1, Name: 'DateTime' },
    Currency: { Value: 2, Name: 'Currency' },
    Percentage: { Value: 3, Name: 'Percentage' },
    Custom: { Value: 4, Name: 'Custom' },
    ShortText: { Value: 5, Name: 'ShortText' },
    Narrative: { Value: 6, Name: 'Narrative' },
    Number: { Value: 7, Name: 'Number' },
    Decimal: { Value: 8, Name: 'Decimal' },
    SSN: { Value: 9, Name: 'SSN' },
    MultiSelect: { Value: 10, Name: 'MultiSelect' },
};

export const CaseFilterType = {
    Diagnosis: {
        Value: 1,
        Name: 'Diagnosis',
    },
    Jurisdiction: {
        Value: 2,
        Name: 'Jurisdiction',
    },
    PlaintiffsFirm: {
        Value: 3,
        Name: 'PlaintiffsFirm',
    },
    CaseManager: {
        Value: 4,
        Name: 'CaseManager',
    },
    State: {
        Value: 5,
        Name: 'State',
    },
    QScore: {
        Value: 6,
        Name: 'QScore',
    },
    LocalCounsel: {
        Value: 7,
        Name: 'LocalCounsel',
    },
};

export const CaseStatusTypesEnum = {
    Open: { Value: 1, Name: 'Open', DisplayName: 'Open' },
    Inactive: { Value: 2, Name: 'Inactive', DisplayName: 'Inactive' },
    Resolved: { Value: 3, Name: 'Resolved', DisplayName: 'Resolved' },
    Alt: { Value: 4, Name: 'Alt', DisplayName: 'Alt' },
    Tendered: { Value: 5, Name: 'Tendered', DisplayName: 'Tendered' },
    NamedNotServed: {
        Value: 6,
        Name: 'NamedNotServed',
        DisplayName: 'Named not served',
    },
};

export const CaseSubStatusTypesEnum = {
    Active: { Value: 1, Name: 'Active', DisplayName: 'Active' },
    Inactive: { Value: 2, Name: 'Inactive', DisplayName: 'Inactive' },
    Settled: { Value: 3, Name: 'Settled', DisplayName: 'Settled' },
    DWOP: { Value: 4, Name: 'DWOP', DisplayName: 'DWOP' },
    InvoluntaryDismissal: {
        Value: 5,
        Name: 'InvoluntaryDismissal',
        DisplayName: 'Involuntary Dismissal',
    },
    Monitored: { Value: 6, Name: 'Monitored', DisplayName: 'Monitored' },
    Closed: { Value: 7, Name: 'Closed', DisplayName: 'Closed' },
    Pending: { Value: 8, Name: 'Pending', DisplayName: 'Pending' },
    Accepted: { Value: 9, Name: 'Accepted', DisplayName: 'Accepted' },
};

export const AssertionDescriptorDisplayCategoryEnum = {
    CaseProfile: { Value: 1, Name: 'Case Profile' },
    CaseData: { Value: 2, Name: 'Case Data' },
    CaseStrategy: { Value: 3, Name: 'Case Strategy' },
    Deadlines: { Value: 4, Name: 'Deadlines' },
    OfficialCaseDocuments: { Value: 5, Name: 'Official Case Documents' },
    WorkProduct: { Value: 6, Name: 'Work Product' },
    CaseNotes: { Value: 7, Name: 'Case Notes' },
    DefenseCounsel: { Value: 8, Name: 'Defense Counsel' },
    GeneralInformation: { Value: 9, Name: 'General Information' },
    Claimant: { Value: 10, Name: 'Claimant' },
    Exposure: { Value: 11, Name: 'Exposure' },
    Medical: { Value: 12, Name: 'Medical' },
    CaseDynamics: { Value: 14, Name: 'Case Dynamics' },
    Discovery: { Value: 15, Name: 'Discovery' },
    Deposition: { Value: 16, Name: 'Deposition' },
    Administration: { Value: 17, Name: 'Administration' },
    Resolution: { Value: 21, Name: 'Resolution' },
    MotionPractice: { Value: 22, Name: 'Motion Practice' },
    AssessmentPlanning: { Value: 23, Name: 'AssessmentPlanning' },
    GeneralExposure: { Value: 24, Name: 'General Exposure' },
    ClientSpecificExposure: { Value: 25, Name: 'Client Specific Exposure' },
    CaseExperts: { Value: 33, Name: 'Experts' },
};

export const CaseDisplayCategoryEnum = {
    CaseProfile: { Value: 1 },
    CaseData: { Value: 2 },
    CaseStrategy: { Value: 3 },
    Deadlines: { Value: 4 },
    OfficialCaseDocuments: { Value: 5 },
    WorkProduct: { Value: 6 },
    CaseNotes: { Value: 7 },
    DefenseCounsel: { Value: 8 },
    GeneralInformation: { Value: 9 },
    Claimant: { Value: 10 },
    Exposure: { Value: 11 },
    Medical: { Value: 12 },
    Damages: { Value: 13 },
    CaseDynamics: { Value: 14 },
    Discovery: { Value: 15 },
    Deposition: { Value: 16 },
    Administration: { Value: 17 },
    Assessment: { Value: 18 },
    BusinessConcerns: { Value: 19 },
    Planning: { Value: 20 },
    Resolution: { Value: 21 },
    MotionPractice: { Value: 22 },
    AssessmentPlanning: { Value: 23 },
    GeneralExposure: { Value: 24 },
    ClientSpecificExposure: { Value: 25 },
    MedicalPlaintiff: { Value: 26 },
    MedicalDefense: { Value: 27 },
    CaseDevelopment: { Value: 28 },
    KeyWitnessDates: { Value: 30 },
    KeyWitnessDatesPlaintiff: { Value: 31 },
    KeyWitnessDatesDefense: { Value: 32 },
    CaseExpert: { Value: 33 },
};

export const TaskCategoryEnum = {
    Simple: {
        Value: 1,
        Name: 'Simple',
    },
    Complex: {
        Value: 2,
        Name: 'Complex',
    },
};

export const DocumentTypesEnum = {
    WorkProduct: {
        Value: 1,
        Name: 'Work Product',
    },
    OfficialCaseDocument: {
        Value: 2,
        Name: 'Official Case Document',
    },
};

export const TaskStatusTypesEnum = {
    Initial: { Value: 1, Name: 'Initial' },
    Assigned: { Value: 2, Name: 'Assigned' },
    Ready: { Value: 3, Name: 'Ready' },
    InProcess: { Value: 4, Name: 'InProcess' },
    Rework: { Value: 5, Name: 'Rework' },
    PastDue: { Value: 6, Name: 'PastDue' },
    Complete: { Value: 7, Name: 'Complete' },
    Pending: { Value: 8, Name: 'Pending' },
};

export const UserSettingTypesEnum = {
    TaskBar: {
        Value: 1,
        Name: 'TaskBar',
    },
    Board: {
        Value: 2,
        Name: 'Board',
    },
};

export const BoardViewDataTypesEnum = {
    State: { Value: 1, Name: 'State', DisplayName: 'State' },
    Jurisdiction: { Value: 2, Name: 'Jurisdiction', DisplayName: 'Jurisdiction' },
    PlaintiffsFirm: {
        Value: 3,
        Name: 'PlaintiffsFirm',
        DisplayName: 'Plaintiffs Firm',
    },
    Diagnosis: { Value: 4, Name: 'Diagnosis', DisplayName: 'Disease' },
    Timeline: { Value: 5, Name: 'Timeline', DisplayName: 'Timeline' },
    CaseDetails: { Value: 6, Name: 'CaseDetails', DisplayName: 'Case Details' },
};

// Even though we say "All", for the purpose of the pie chart, top 5 are their own line item, all others are grouped as 6th line item
// Raw means every single Jurisdiction and Plaintiffs Firm is its own line item
export const BoardViewsEnum = {
    StateAll: { Value: 1, Name: 'State - All' },
    StateTop10: { Value: 2, Name: 'State - Top 10' },
    JurisdictionAll: { Value: 3, Name: 'Jurisdiction - All' },
    JurisdictionTop10: { Value: 4, Name: 'Jurisdiction - Top 10' },
    PlaintiffsFirmAll: { Value: 5, Name: 'Plaintiffs Firm - All' },
    PlaintiffsFirmTop10: { Value: 6, Name: 'Plaintiffs Firm - Top 10' },
    DiagnosisAll: { Value: 7, Name: 'Diagnosis' },
    Timeline: { Value: 8, Name: 'Timeline' },
    JurisdictionAllRaw: { Value: 9, Name: 'Jurisdiction - All - Raw' },
    PlaintiffsFirmAllRaw: { Value: 10, Name: 'Plaintiffs Firm - All - Raw' },
};

export const BoardCriteriaEnum = {
    CaseVolume: { Value: 1, Name: 'Case Volume' },
    ProjectedSettlement: { Value: 2, Name: 'Projected Settlement' },
    ActualSettlement: { Value: 3, Name: 'Final Settlement' },
};

export const BoardFilterDateTypesEnum = {
    AsOfDate: { Value: 1, Name: 'AsOfDate', DisplayName: 'As Of Date' },
    ServiceDate: { Value: 2, Name: 'ServiceDate', DisplayName: 'Service Date' },
    ResolutionDate: {
        Value: 3,
        Name: 'ResolutionDate',
        DisplayName: 'Resolution Date',
    },
    TrialDate: { Value: 4, Name: 'TrialDate', DisplayName: 'Trial Date' },
    ProjectedSettlementDate: {
        Value: 5,
        Name: 'ProjectedSettlementDate',
        DisplayName: 'Projected Settlement Date',
    },
};

export const DateTypesEnum = {
    StartDate: 1,
    EndDate: 2,
};

export const BoardCaseDetailsExportTypes = {
    PDF: { Value: 1, Name: 'PDF', DisplayName: 'PDF' },
    Excel: { Value: 2, Name: 'Excel', DisplayName: 'Excel' },
    Both: { Value: 3, Name: 'Both', DisplayName: 'Both' },
};

export const MyDocumentsTypesEnum = {
    ThreatReport: { Value: 1, Name: 'Threat Report' },
    Board: { Value: 2, Name: 'Board', TemplateName: 'Board.trdp' },
    Settlements: { Value: 3, Name: 'Settlements' },
    CaseVolume: { Value: 4, Name: 'Case Volume' },
    QueryResults: { Value: 5, Name: 'Query Results' },
    WeeklyRecap: { Value: 6, Name: 'Weekly Recap' },
    MonthlyRecap: { Value: 7, Name: 'Monthly Recap' },
    PackageSettlementDetail: { Value: 8, Name: 'PackageSettlementDetail' },
    PackageSettlements: { Value: 10, Name: 'PackageSettlements' },
    ResolutionProjectionReport: { Value: 11, Name: 'ResolutionProjectionReport' },
    StrategyReport: { Value: 12, Name: 'Strategy Report' },
    StrategySignificantChanges: { Value: 13, Name: 'StrategySignificantChanges' },
    StrategicPlanExport: { Value: 14, Name: 'StrategicPlanExport' },
    ExecutionPlanExport: { Value: 15, Name: 'Execution Plan' },
    JurisdictionStrategyDetailExport: {
        Value: 16,
        Name: 'Jurisdiction Strategy',
    },
    PlaintiffsFirmStrategyDetailExport: {
        Value: 17,
        Name: 'PlaintiffsFirmStrategyDetailExport',
    },
    IndemnityProjectionReport: { Value: 18, Name: 'IndemnityProjectionReport' },
    ProjectDetailExport: { Value: 19, Name: 'ProjectDetailExport' },
    LocalCounselStrategyDetailExport: {
        Value: 20,
        Name: 'LocalCounselStrategyDetailExport',
    },
    LocalCounselMeetingAgendaReport: {
        Value: 21,
        Name: 'LocalCounselMeetingAgendaReport',
    },
    CaseAuditExport: { Value: 22, Name: 'CaseAuditExport' },
    Trends: { Value: 23, Name: 'Trends' },
    Snapshot: { Value: 24, Name: 'SnapshotView' },
    KpiReport: { Value: 25, Name: 'KpiReport' },
    ExpertStrategyDetailExport: { Value: 26, Name: 'ExpertStrategyDetailExport' },
};

export const ReportTypeToExportUrlMap = {
    [MyDocumentsTypesEnum.WeeklyRecap.Value]: ApiRoutes.TimelineRecapExport,
    [MyDocumentsTypesEnum.MonthlyRecap.Value]: ApiRoutes.TimelineRecapExport,
    [MyDocumentsTypesEnum.KpiReport.Value]: ApiRoutes.ExportReport,
};

export const reportTypeOptions: IMultiSelectOptions[] = Object.values(MyDocumentsTypesEnum)
    .map((reportType) => ({
        value: reportType.Name.toString(),
        label: reportType.Name,
        id: reportType.Value,
    }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

export const QueryFilterFieldTypes = {
    Database: { Value: 1 },
    assertiondescriptor: { Value: 2 },
};

export const CaseFileStaticItems = {
    CaseStatus: { Id: 1, Guid: '73ddfb8e-ba04-46e8-9984-d4c72a7c2bee' },
    CaseNumber: { Id: 2, Guid: '87d67393-aa33-4280-8da8-e815f2ff20a9' },
    LocalCounsel: { Id: 3, Guid: '96f30f95-e851-4122-8687-070a5200cf86' },
    OtherDefenseCounsels: { Id: 4, Guid: 'ffa1f6fb-32d0-4d1f-a6cc-fd58fd09a06b' },
    UniqueCaseID: { Id: 5, Guid: 'aae203a4-362c-4022-910d-1e971727361b' },
    CaseManager: { Id: 6, Guid: 'c5475a8c-22b4-49ec-9ea6-b2391921f450' },
    AlternateCaseManager: { Id: 7, Guid: 'd0dbd49f-05f4-42fd-a727-fffcf0514e00' },
    PackageSettlement: { Id: 8, Guid: 'dc5d0925-3299-4fe9-82bc-c2ca7177d6be' },
    CasePriority: { Id: 9, Guid: '5b19f8b2-4637-4e07-92cf-48e81de4d552' },
    State: { Id: 10, Guid: '5875eac6-7459-4e5b-88e3-66c49b7f8f11' },
    DefenseCounselSection: { Id: 11, Guid: 'e3519aab-5336-4c9b-b212-5a094b91036f' },
    NotesSection: { Id: 12, Guid: 'b37ceed6-5bee-467d-9602-df8bff4ac93f' },
    DepositionSection: { Id: 13, Guid: 'ef7bdc85-3802-4dd8-9493-cab0495abc75' },
    ExpertsSection: { Id: 14, Guid: 'f5bda66f-d2fc-4e20-8889-e83265a79ad4' },
    CaseStatusAsOfDate: { Id: 15, Guid: '5a7a74bc-4852-432a-8e46-8687c168c911' },
    ResolutionDate: { Id: 16, Guid: '1dde761b-6fd2-442a-b255-c26d17dc15b2' },
};

export const QueryFilterDatabaseFieldsHelpText = [
    {
        id: CaseFileStaticItems.CaseManager.Id,
        name: 'CaseManager',
        helpText: 'Attorney designated as the primary point of contact for a case.',
    },
    {
        id: CaseFileStaticItems.AlternateCaseManager.Id,
        name: 'AlternateCaseManager',
        helpText:
            'Attorney designated as the alternate/secondary/backup/supervising point of contact for a case.',
    },
];

export const QueryFilterCriteriaDataTypes = {
    List: 1,
    Text: 2,
    Numeric: 3,
    DateTime: 4,
};

// // SHOULD ALWAYS BE IN SYNC WITH THE StaticData.cs file - QueryToolDefaultColumnList
export const QueryToolDefaultColumnList: IQueryColumnDefinitionModel[] = [
    { staticItemId: CaseFileStaticItems.CaseStatus.Id },
    {
        assertionDescriptorGuid: '72C60F8C-93BF-4CEB-BDC5-FE666BFC32B4', // Disease
    },
    {
        assertionDescriptorGuid: 'B93F4046-DE24-44E8-89A8-310C19C8D0D2', // Jurisdiction
    },
    {
        assertionDescriptorGuid: 'CCFF8542-0470-4F03-BA27-66A03B9E6E18', // LivingDeceased
    },
    {
        assertionDescriptorGuid: 'AAB1086A-2E5B-4DB0-AE45-407362E1456E', // Occupation
    },
    {
        assertionDescriptorGuid: 'D8C65132-F8D7-4FBA-A1CC-005DD9DEF8C2', // Plaintiffs firm
    },
    {
        assertionDescriptorGuid: '84DAE998-B5C7-4292-A1B4-951B03D7AEF7', // SQ Score
    },
];

export const AssertionDescriptorValueDisplayTypes = {
    DateTime: { Value: 1, Name: 'DateTime' },
    Currency: { Value: 2, Name: 'Currency' },
    Percentage: { Value: 3, Name: 'Percentage' },
    Custom: { Value: 4, Name: 'Custom' },
    ShortText: { Value: 5, Name: 'ShortText' },
    Narrative: { Value: 6, Name: 'Narrative' },
    Number: { Value: 7, Name: 'Number' },
    Decimal: { Value: 8, Name: 'Decimal' },
    SSN: { Value: 9, Name: 'SSN' },
    MultiSelect: { Value: 10, Name: 'MultiSelect' },
};

export const NoteStatusTypes = {
    Open: 1,
    Closed: 2,
    Deleted: 3,
};

export const NoteTypes = {
    Personal: 1,
    CaseNote: 2,
    ZoneNote: 3,
    StrategySignificantChange: 4,
    StrategyNote: 5,
    PackageSettlementNote: 6,
};

export const CaseDetailOperation = {
    CaseFile: { Value: 1, Name: 'CaseFile' },
    EasyUpdate: { Value: 2, Name: 'EasyUpdate' },
};

export const QueryTypes = {
    CaseDetails: { Value: 1, Name: 'CaseDetails', DisplayName: 'Case Details' },
    CaseNotes: { Value: 2, Name: 'CaseNotes', DisplayName: 'Case Notes' },
    ZoneNotes: { Value: 3, Name: 'Zone Notes', DisplayName: 'Zone Notes' },
    StrategyNote: { Value: 4, Name: 'Strategy Note', DisplayName: 'Strategy Note' },
    Deposition: { Value: 5, Name: 'Deposition', DisplayName: 'Deposition' },
    CaseExperts: { Value: 6, Name: 'CaseExperts', DisplayName: 'Case Experts' },
    PackageSettlementNotes: {
        Value: 7,
        Name: 'PackageSettlementNotes',
        DisplayName: 'Deal Notes',
    },
};

export enum UserRightsEnum {
    ViewCaseList = 1,
    ViewThreatScore = 2,
    AddNewCase = 4,
    ViewCaseFileBasic = 5,
    ViewCaseFile = 6,
    EditCaseFile = 7,
    AddCaseNote = 8,
    ViewCaseNotes = 9,
    EditCaseNote = 10,
    Reports = 11,
    Board = 12,
    ReadOnlyPlanningAndExecution = 13,
    FullPlanningAndExecution = 14,
    FirmLevelPlanningAndExecution = 15,
    ViewZoneNotes = 16,
    AddZoneNote = 17,
    EditZoneNote = 18,
    DeleteCase = 19,
    ViewPackageSettlement = 20,
    AddEditDeletePackageSettlement = 21,
    ViewStrategicPlan = 22,
    ViewExecutionPlan = 23,
    ViewPlaintiffsFirmStrategy = 24,
    ViewJurisdictionStrategy = 25,
    ViewProjects = 26,
    EditStrategicPlan = 27,
    EditExecutionPlan = 28,
    AddEditDeletePlaintiffsFirmStrategy = 29,
    AddEditDeleteJurisdictionStrategy = 30,
    AddEditDeleteProject = 31,
    ViewLocalCounselStrategy = 32,
    AddEditDeleteLocalCounselStrategy = 33,
    EasyUpdate = 34,
    ViewDeposition = 35,
    AddDeposition = 36,
    DeleteCaseNote = 37,
    DeleteZoneNote = 38,
    NoteSecurity = 39,
    Snapshot = 41,
    KPIView = 42,
    KPIViewGenerateLive = 43,
    SnapshotViewGenerateLive = 44,
    EditNoteApprovalStatus = 45,
    LockUnlockPackageDeal = 46,
    Alerts = 47,
    AddZoneFavorites = 48,
    ViewExpert = 49,
    AddExpert = 50,
    UpdateExpert = 51,
    DeleteExpert = 52,
    ViewCaseExpert = 53,
    AddCaseExpert = 54,
    UpdateCaseExpert = 55,
    DeleteCaseExpert = 56,
    AddPackageDealNote = 57,
    DeletePackageDealNote = 58,
    UpdatePackageDealNote = 59,
    ViewPackageDealNote = 60,
    AddJurisdictionStrategyNote = 61,
    DeleteJurisdictionStrategyNote = 62,
    UpdateJurisdictionStrategyNote = 63,
    ViewJurisdictionStrategyNote = 64,
    AddPlaintiffsFirmStrategyNote = 65,
    DeletePlaintiffsFirmStrategyNote = 66,
    UpdatePlaintiffsFirmStrategyNote = 67,
    ViewPlaintiffsFirmStrategyNote = 68,
    AddProjectsStrategyNote = 69,
    DeleteProjectsStrategyNote = 70,
    UpdateProjectsStrategyNote = 71,
    ViewProjectsStrategyNote = 72,
    AddLocalCounselStrategyNote = 73,
    DeleteLocalCounselStrategyNote = 74,
    UpdateLocalCounselStrategyNote = 75,
    ViewLocalCounselStrategyNote = 76,
    AddExpertStrategyNote = 77,
    DeleteExpertStrategyNote = 78,
    UpdateExpertStrategyNote = 79,
    ViewExpertStrategyNote = 80,
    EditDeposition = 81,
    DeleteDeposition = 82,
    RestrictExpertReport = 83,
    CaseTriggerRules = 88,
}

export const NoteCategories = {
    CaseNoteOther: 10,
    ZoneNoteOther: 11,
    StrategySignificantChanges: 21,
};

export const AssertionDescriptors = {
    StatusDocumented: {
        Name: 'StatusDocumented',
        Guid: 'CBB311AB-6156-4A11-B1C6-F410C95A76E7',
    },
    Diagnosis: {
        Name: 'Diagnosis',
        Guid: '72C60F8C-93BF-4CEB-BDC5-FE666BFC32B4',
    },
    Jurisdiction: {
        Name: 'Jurisdiction',
        Guid: 'B93F4046-DE24-44E8-89A8-310C19C8D0D2',
    },
    PlainfirmFirmLocal: {
        Name: 'Plaintiffs firm - local',
        DisplayName: 'Plaintiffs Firm',
        Guid: 'D8C65132-F8D7-4FBA-A1CC-005DD9DEF8C2',
    },
    PlainfirmFirmSecondary: {
        Name: 'Plaintiffs firm - Secondary',
        Guid: '640BBBBD-D12F-4C8F-9A2D-9D79C2BFF954',
    },
    Occupation: {
        Name: 'Occupation',
        Guid: 'AAB1086A-2E5B-4DB0-AE45-407362E1456E',
    },
    OtherOccupation: {
        Name: 'OtherOccupation',
        Guid: '443D176A-FDDF-4670-8ED6-03C7A621CBBC',
    },
    TrialDate: {
        Name: 'Trial Date',
        Guid: '333A857A-9151-429E-9EBD-D17756E7F15F',
    },
    CalculatedAgeRange: {
        Name: 'Injured Party Age',
        Guid: 'A61DC182-9553-4E25-A09D-B268CF8C1CBA',
    },
    CalculatedExactAge: {
        Name: 'Injured Party Exact Age',
        Guid: '7E83064B-711B-468B-B12F-A8E97754E49B',
    },
    EstimatedAge: {
        Name: 'EstimatedAge',
        Guid: '63766DFD-FC03-48F8-8154-6EB085BF5014',
    },
    LivingDeceased: {
        Name: 'Plaintiff living/deceased',
        Guid: 'CCFF8542-0470-4F03-BA27-66A03B9E6E18',
        LivingADVGuid: 'CC5AA980-C8BB-49D2-AD8B-64695E82BCB3',
        DeceasedADVGuid: 'E21F7B72-68F7-433C-9614-6CFD71D6F56F',
    },
    SettlementAmount: {
        Name: 'Actual Settlement',
        Guid: '44C0ACEC-ADCB-4EB4-ABC4-10F6EF4B370B',
    },
    ResolutionAgreementDate: {
        Name: 'Resolution Agreement Date',
        Guid: '3DA5A274-0E2E-44E9-A03D-84E853579F5D',
    },
    DismissalDate: {
        Name: 'Date of Dismissal',
        Guid: '0B7B8131-406D-4A0D-AE23-69A610D20C1A',
    },
    TenderedAcceptedDate: {
        Name: 'TenderedAcceptedDate',
        Guid: 'B0978B6E-F9AF-43AE-888F-1BFC74CC0F87',
    },
    InjuredPartyFirstName: {
        Name: 'Injured Party First Name',
        Guid: '21D2A957-6BB6-462F-BA2E-0F7B5EB24BFB',
    },
    InjuredPartyLastName: {
        Name: 'Injured Party Last Name',
        Guid: 'CD327DA6-9798-463A-A8A3-8A2CB330ABA0',
    },
    ExpectedOutcome: {
        Name: 'Expected Outcome',
        Guid: '8A406D6E-478F-497E-BC7F-D089C19930A2',
    },
    ProjectedSettlementAmount: {
        Name: 'Projected Settlement',
        Guid: 'C239421C-6C8E-4297-9014-6CAEC32D8544',
    },
    DOB: { Name: 'DOB', Guid: 'A41048E1-ABC4-4C40-8222-5BD83F95A459' },
    DateOfDeath: {
        Name: 'Date Of Death',
        Guid: 'A175671C-CAFF-49D1-9B93-4B3C9D991A8F',
    },
    NumberOfNonPartyShares: {
        Name: 'Number Of Non-Party Shares',
        Guid: 'A00312F5-1754-4D2B-AC14-0740AD31DF49',
    },
    NonPartySharesExact: {
        Name: 'NonPartySharesExact',
        Guid: 'A9FEC9AE-CFC5-4DBC-886C-0026A1F5AEEF',
    },
    SmokingHistoryPacksPerDay: {
        Name: 'SmokingHistoryPPD',
        Guid: '52897E77-6C77-4FC0-B32F-BEB45D68F515',
    },
    SmokingHistoryPackYear: {
        Name: 'Smoking History PackYear',
        Guid: 'C381C142-B80C-479F-B671-759497C12FD7',
    },
    SmokingStartDate: {
        Name: 'Smoking Start Date',
        Guid: '38AEA8D8-672C-49C4-B874-C422506631D3',
    },
    SmokingEndDate: {
        Name: 'Smoking End Date',
        Guid: '373692A4-707B-45AD-A5FD-02D6CDDB5F1B',
    },
    IsSmoker: {
        Name: 'IsSmoker',
        Guid: '03CC3366-104B-46E9-B6CF-0B8F695EAB07',
        YesADVGuid: '30D89C0A-16B0-448B-9E45-F26471E942B0',
    },
    DefenseBRead: {
        Name: 'DefenseBRead',
        Guid: '56DCB3BE-2F7D-4158-8F8F-5E0EF6FBD23C',
    },
    PlaintiffBRead: {
        Name: 'PlaintiffBRead',
        Guid: '255CFDDA-B545-4A45-A0E4-82081E91B018',
    },
    MSJStatus: {
        Name: 'MSJStatus',
        Guid: 'ADBA8869-8695-43A6-A57F-6F13B6BE396A',
        NoRulingGuid: '60F84E4E-AB69-4FEF-ADC9-E540A1318B8F',
    },
    PJStatus: {
        Name: 'PJ Status',
        Guid: 'D5899B7A-8C3F-4051-98F0-4E1878946908',
        NoRulingGuid: '973E2EFD-8269-452F-9A87-55CC1E86B8B5',
    },
    MSJFiled: {
        Name: 'MSJFiled',
        Guid: 'D9F084E4-5DB0-4F56-BCF6-3363D76D156F',
        AvailableAndFiledGuid: '07C2AF4E-880A-4744-8898-A21FAD39344E',
    },
    MSJGrantedDate: {
        Name: 'MSJGrantedDate',
        Guid: 'F8CC2DAD-F460-4ACD-86FC-65ED954C4B80',
    },
    PJFiled: {
        Name: 'PJFiled',
        Guid: 'A7F79471-528D-4245-AA98-64CF486DC740',
        AvailableAndFiledGuid: '268BB144-4B02-4806-977F-76E2F86C7EEE',
    },
    PJGrantedDate: {
        Name: 'PJGrantedDate',
        Guid: 'BA243A69-42DE-4C47-AF27-6075CA7DD72D',
    },
    ValueAdd: { Name: 'Value Add', Guid: 'D5058795-6916-4AAF-B9B4-69DA9EFD2A81' },
    ValueAddNotes: {
        Name: 'ValueAddNotes',
        Guid: 'C6A6F6D1-C368-45B5-8F9A-E875CA004B44',
    },
    DemandForCorporateRepDepo: {
        Name: 'Demand For Corporate Rep Depo',
        Guid: '610F48F0-E736-4F1A-B760-E42D28DF2A51',
    },
    CorporateRepDemandReceivedDate: {
        Name: 'Corporate Rep Demand Received',
        Guid: 'AC64FD94-1006-488E-99BA-636BA9D71725',
    },
    AlternateCaseIDNumber: {
        Name: 'Alternate Case ID Number',
        Guid: '2427EBAA-FCE4-4202-B157-FEF23AF9B18A',
    },
    AlternateCaseIDNumber3: {
        Name: 'Alternate Case ID Number (3)',
        Guid: '2436394B-4C55-4AB2-81A9-CFE2459A12BB',
    },
    AlternateCaseIDNumber4: {
        Name: 'Alternate Case ID Number (4)',
        Guid: 'F3AF66BB-2B49-4C8C-89B6-0753A1E24439',
    },
    ExpertDiscipline: {
        Name: 'ExpertDiscipline',
        Guid: 'beba23eb-d0db-44b5-a8f9-752301b20c4d',
    },
    ContactInformation: {
        Name: 'ContactInformation',
        Guid: '3880100d-f2bd-4780-8733-ddc0f93a5be6',
    },
    GeneralOpinions: {
        Name: 'GeneralOpinions',
        Guid: 'd25bcffc-e902-4293-a8fd-8a7fe2466776',
    },
    PurposeOfRetention: {
        Name: 'PurposeOfRetention',
        Guid: '0a813e26-f455-45c2-b857-e1c62ead4909',
    },
    StrategyTopic: {
        Name: 'StrategyTopic',
        Guid: '47a49243-656d-4ccb-804b-a1e390759daf',
    },
    StrategyGuidance: {
        Name: 'StrategyGuidance',
        Guid: 'f53836af-4be3-490b-9c7e-a8e677ebcd81',
    },
    TypeOfClaim: {
        Guid: 'b5f7a206-e0bd-4d63-81b1-8dadebc627b5',
    },
    ClientExposureStartDate: {
        Guid: '092a9ccd-b4ac-4660-8f23-603940c88bf5',
    },
    AuthorityRequested: {
        Guid: '7101b17b-6d26-447a-ae66-57eb070f23a5',
    },
    AuthorityRequestedDate: {
        Guid: 'd101638a-5d37-4ddc-8713-42edba2ebdb5',
    },
    InitialDemand: {
        Guid: '76db796b-02cb-45d8-8809-7ce14db13d75'
    },
    ServiceDate: {
        Guid: 'f57c6393-57bc-429b-9169-5b83b1d433ea'
    }
};

// At this time, not enough use cases to configure/ automate which Assertion Descriptors are ReadOnly. If we need more R/O ADs, then we will look into adding fields to the DB.
export const CustomLogicAssertionDescriptors = [
    {
        Name: 'EstimatedAge',
        Guid: '63766DFD-FC03-48F8-8154-6EB085BF5014',
        AutoCalculated: false,
        ReadOnly: false,
    },
    {
        Name: 'Plaintiff living/deceased',
        Guid: 'CCFF8542-0470-4F03-BA27-66A03B9E6E18',
        AutoCalculated: true,
        ReadOnly: false,
    },
    {
        Name: 'Injured Party Calculated Age',
        Guid: 'A61DC182-9553-4E25-A09D-B268CF8C1CBA',
        AutoCalculated: true,
        ReadOnly: true,
    },
    {
        Name: 'Injured Party Exact Age',
        Guid: '7E83064B-711B-468B-B12F-A8E97754E49B',
        AutoCalculated: true,
        ReadOnly: true,
    },
    {
        Name: 'SmokingHistoryPackYear',
        Guid: 'C381C142-B80C-479F-B671-759497C12FD7',
        AutoCalculated: true,
        ReadOnly: false,
    },
    {
        Name: 'IsSmoker',
        Guid: '03CC3366-104B-46E9-B6CF-0B8F695EAB07',
        AutoCalculated: true,
        ReadOnly: false,
    },
    {
        Name: 'MSJStatus',
        Guid: 'ADBA8869-8695-43A6-A57F-6F13B6BE396A',
        AutoCalculated: true,
        ReadOnly: false,
    },
    {
        Name: 'PJ Status',
        Guid: 'D5899B7A-8C3F-4051-98F0-4E1878946908',
        AutoCalculated: true,
        ReadOnly: false,
    },
    {
        Name: 'Demand For Corporate Rep Depo',
        Guid: '610F48F0-E736-4F1A-B760-E42D28DF2A51',
        AutoCalculated: true,
        ReadOnly: false,
    },
];

export const CalculatedAgeAssertionDescriptorValues = [
    {
        Name: 'Over90',
        Guid: 'CEDB0B72-65C0-4922-A159-1A8EFCDAB6CF',
        From: 91,
        To: 1000,
    },
    {
        Name: '80-90',
        Guid: '069D13AF-1AD1-4411-8533-9E1F72DB13A1',
        From: 80,
        To: 90,
    },
    {
        Name: '70-79',
        Guid: '1E69C21E-914B-4DA4-8C8F-865F3EC09FBF',
        From: 70,
        To: 79,
    },
    {
        Name: '60-69',
        Guid: '35E44682-236C-477C-A069-23A114D7C3A4',
        From: 60,
        To: 69,
    },
    {
        Name: '50-59',
        Guid: '67157268-4011-402A-83E0-D2B8705C9197',
        From: 50,
        To: 59,
    },
    {
        Name: '40-49',
        Guid: '8350E4E1-5A27-48CC-984C-96F483FE9DF3',
        From: 40,
        To: 49,
    },
    {
        Name: '30-39',
        Guid: 'D7428E1E-5624-404A-9EF8-E79E68630AB4',
        From: 30,
        To: 39,
    },
    {
        Name: 'Under30',
        Guid: '2F65929B-1693-40FA-A3D6-199D0F373792',
        From: 1,
        To: 29,
    },
];

export enum EntityTypes {
    Case = 1,
    Note = 2,
    Deposition = 3,
    Organization = 4,
    OperatingMechanism = 5,
    AssertionDescriptorValue = 6,
    PackageSettlement = 7,
    Project = 8,
    AssertionDescriptor = 9,
    AssertionDescriptorValueStrategy = 10,
    LocalCounselStrategy = 11,
    Request = 12,
    Report = 13,
    Expert = 14,
    CaseExpert = 15,
}

export enum TimelineReportTypeEnum {
    Weekly = 1,
    Monthly = 2,
}

export enum TimelineReportStatusEnum {
    New = 1,
    Dismissed = 2,
    Settled = 3,
}

export const ReportNoteCategories = {
    SignificantActivity: { Name: 'SignificantActivity' },
    RiskIssues: { Name: 'RiskIssues' },
    InitialCaseEval: { Name: 'InitialCaseEval' },
    ZoneNotes: { Name: 'ZoneNotes' },
    case: { Name: 'case' },
};

export const HelpKeys = {
    SignificantActivity: {
        Name: 'HelpText_SignificantActivity',
        DisplayName: 'Significant Activity',
    },
    RiskIssues: { Name: 'HelpText_RiskIssues', DisplayName: 'Risk Issues' },
    InitialCaseEval: {
        Name: 'HelpText_InitialCaseEval',
        DisplayName: 'Initial Evaluation',
    },
    ZoneNotes: { Name: 'HelpText_ZoneNotes', DisplayName: 'Zone Notes' },
    NumberOfPlaintiffs: {
        Name: 'HelpText_NumberOfPlaintiffs',
        DisplayName: 'Number Of Plaintiffs',
    },
    CaseNotes: { Name: 'HelpText_CaseNotes', DisplayName: '' },
};

export enum SortColumns {
    CaseNumber = 1,
    CaseName = 2,
    CaseStatus = 3,
    State = 4,
    QScore = 5,
    TrialDate = 6,
    ThreatScore = 7,
    Priority = 8,
    PackageSettlement = 9,
    Diagnosis = 10,
    NotePrivate = 11,
    NoteAllowLocalEdit = 12,
    NoteDecision = 13,
    ActionTarget = 14,
    WatchUser = 15,
    NoteWriter = 16,
    NoteDate = 17,
    NotePurpose = 18,
    NoteStatus = 19,
}

export enum SortDirections {
    Asc = 1,
    Desc = 2,
}

export enum PackageSettlementStatusTypesEnum {
    InProcess = 1,
    Complete = 2,
    Cancelled = 3,
}

export const OrganizationSettingNames = {
    QueryFavorites: { Name: 'QueryFavorites' },
    EasyUpdateFavorites: { Name: 'EasyUpdateFavorites' },
};

export const AssertionDescriptorCategories = {
    Strategy_Situation: { Value: 15 },
    Strategy_Goals: { Value: 16 },
    Strategy_Principles: { Value: 17 },
};

export enum OperatingMechanisms {
    ClientService = 1,
    PortfolioManagement = 2,
    CaseDevelopment = 3,
    COE = 4,
    LocalCounsel = 5,
}

export const CommunicationProtocolAssertionDescriptors = {
    Daily: { Guid: '6B454832-3A09-4A95-AEBD-3FDD735F8130' },
    WeeklyMeetings: { Guid: '6F251401-590B-4093-8E37-A76AF0CF6330' },
    MonthlyMeetings: { Guid: '7B839136-F86B-4C0C-86C2-1508A3D358F8' },
    QuarterlyMeetings: { Guid: '98CE3E3F-34FA-4913-9089-CA99E980F057' },
    AnnualMeetings: { Guid: '9EF1CFB3-83D0-4B3E-B746-2AC0E65DDB2F' },
    WeeklyReports: { Guid: 'C7C4CF63-5048-4906-9AC0-740EBC3BC96F' },
    MonthlyReports: { Guid: 'E0CB3E18-11AD-4750-9F9D-CCA7CF3CFD26' },
    QuarterlyReports: { Guid: 'E75893D1-5746-42FB-981A-18779F6A630C' },
    AnnualReports: { Guid: 'F5B3592F-DD36-42DC-9907-CC8E5943A3C8' },
};

export const StrategySignificantChangesAssertionDescriptors = {
    Strategy_SignificantChanges_Date: 'FDEEEAF3-CF1A-40EC-BCCB-767D73B9DD22',
    Strategy_SignificantChanges_Change: '774FCDE6-3C76-4797-B7EA-CA8CCA43D59B',
};

export enum TaskTypesEnum {
    CasePlan = 1,
    StrategyTactics = 2,
}

export enum TaskActivityTypesEnum {
    AnalysisStrategy = 1,
    Drafting = 2,
    Collection = 3,
    Investigation = 4,
    Witnesses = 5,
    Court = 6,
    Communications = 7,
}

// The IDs should match Checkmate.Web.Utilities.StrategyTypes in Enums.cs
export const StrategyTypes = {
    StrategicPlan: {
        Value: 1,
        Name: 'StrategicPlan',
        DisplayName: 'Strategic Plan',
    },
    ExecutionPlan: {
        Value: 2,
        Name: 'ExecutionPlan',
        DisplayName: 'Execution Plan',
    },
    PlaintiffsFirm: {
        Value: 3,
        Name: 'PlaintiffsFirm',
        DisplayName: 'Plaintiffs Firm',
    },
    Jurisdiction: {
        Value: 4,
        Name: 'Jurisidiction',
        DisplayName: 'Jurisidiction',
    },
    PackageSettlement: { Value: 7, Name: 'PackageSettlement', DisplayName: 'Package Deal' },
    Projects: { Value: 8, Name: 'Projects', DisplayName: 'Projects' },
    LocalCounsel: {
        Value: 11,
        Name: 'Local Counsel',
        DisplayName: 'Local Counsel',
    },
    Experts: { Value: 14, Name: 'Experts', DisplayName: 'Experts' },
};

export enum ApplicationHelpType {
    Info = 1,
    Check = 2,
}

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';
export const BoardDataOtherGuid = 'E103370C-B4E6-4E46-BB96-6720E98EB3F3';

export const ReportSettings_CaseInventoryByStateDisplayMode = 'CaseInventoryByStateDisplayMode';
export const ReportSettings_PlaintiffsFirmDisplayMode = 'PlaintiffsFirmDisplayMode';
export const ReportSettings_ReportCaseDetailsLastColumn = 'ReportCaseDetailsLastColumn';
export const ReportSettings_ReportCaseDetailsLastColumnDisplayName =
    'ReportCaseDetailsLastColumnDisplayName';

export const RestrictedDocumentCategory_CaseExpertReport = 1;

export const ClientRequestTypes = {
    Authority: {
        Name: 'Authority',
        Guid: '08C4F51D-CD03-455E-B0F3-B4648F398572',
    },
    ExpertRetention: {
        Name: 'Expert Retention',
        Guid: '08E4840E-573F-4D9F-82BC-5B7087E8A35E',
    },
    DiscoveryApproval: {
        Name: 'Discovery Approval',
        Guid: '3D72809E-084C-4FEA-8E09-E039CF41BE20',
    },
    TrialCounsel: {
        Name: 'Trial Counsel',
        Guid: '4EB46A0C-5DFF-4610-9294-B14359679F4C',
    },
    Other: { Name: 'Other', Guid: '5748B2C3-1DEE-4CB0-AD19-6C348E0004BA' },
};

export enum TimelineReportSections {
    CaseInventory = 1,
    CaseInfoNew = 2,
    CaseInfoDismissed = 3,
    CaseInfoSettled = 4,
    RequestForAuth = 5,
    RiskIssues = 9,
    SignificantActivity = 10,
    ZoneNotes = 14,
    InitialCaseEval = 15,
    TenderedCases = 16,
    RiskProfile = 17,
    SettlementRecap = 18,
    OpenActiveCasesStateMap = 19,
    OpenActiveCasesByDisease = 20,
    UpcomingTrialDates = 21,
    OpenClaims = 23,
    SettlementSpend = 24,
    DefenseSpend = 25,
    AvgSettlementTrends = 26,
    DatasourceFootnote = 27,
    CaseInventoryByState = 28,
    Depositions = 29,

    /*     
OtherClientRequests = 6,
  SignificantNegotiations = 7,
  NewDemands = 8,
  Projects = 11,
  JurisdictionStrategyUpdates = 12,
  PlaintiffsFirmStrategyUpdates = 13,
  CustomCaseInfo = 22,
  */
}

export enum QueryToolModeEnum {
    Standalone = 1,
    Redirect = 2,
}

export enum QueryToolRedirectTypeEnum {
    PackageDeal = 1,
}

export enum PackageDealSummarySectionsEnum {
    HistoricAverages = 1,
    CaseList = 2,
}

export const PackageDealCommentReportParameterNames = [
    {
        id: 1,
        name: 'Comments_HistoricAverages',
        displayName: 'Historic Settlement Averages',
        reportSection: PackageDealSummarySectionsEnum.HistoricAverages,
    },
    {
        id: 2,
        name: 'Comments_CaseList',
        displayName: 'Case List',
        reportSection: PackageDealSummarySectionsEnum.CaseList,
    },
];

export const ExpectedOutcomeTexts = {
    Dismissal: { Text: 'DISMISSAL' },
    Settlement: { Text: 'SETTLEMENT' },
    Alt: { Text: 'ALT STATUS' },
};

export enum NotePurposeTypesEnum {
    Info = 1,
    Approval = 2,
    Acknowledgment = 3,
}

export enum ApprovalStatusTypesEnum {
    Approved = 1,
    NotApproved = 2,
    NeedMoreInfo = 3,
}

export enum SnapshotViewSortableSectionsEnum {
    PriorityCases = 1,
    DiscoveryServedCases = 2,
    Depositions = 3,
    CorporateRepRequests = 4,
    ClientRequests = 5,
    TrialDates = 6,
}

export const KpiReportCommentReportParameterNames = [
    {
        comment: '',
        sectionName: 'Comments_DataSourceFootnote',
        displayName: 'Data Source Footnote',
        section: TimelineReportSections.DatasourceFootnote,
        maxLines: 1,
        maxLength: 140,
    },
    {
        comment: '',
        sectionName: 'Comments_OpenActiveCases',
        displayName: 'Open-Active Cases',
        section: TimelineReportSections.OpenClaims,
        maxLines: 8,
        maxLength: 260,
    },
    {
        comment: '',
        sectionName: 'Comments_SettlementSpend',
        displayName: 'Settlement Spend',
        section: TimelineReportSections.SettlementSpend,
        maxLines: 8,
        maxLength: 260,
    },
    {
        comment: '',
        sectionName: 'Comments_DefenseSpend',
        displayName: 'Defense Spend',
        section: TimelineReportSections.DefenseSpend,
        maxLines: 8,
        maxLength: 260,
    },
    {
        comment: '',
        sectionName: 'Comments_AvgSettlementTrends',
        displayName: 'Average Settlement Trends',
        section: TimelineReportSections.AvgSettlementTrends,
        maxLines: 12,
        maxLength: 390,
    },
];

export const AlertTypes = {
    Act: { Name: 'Act', Value: 1 },
    Watch: { Name: 'Watch', Value: 2 },
    CaseTrigger: { Name: 'CaseTrigger', Value: 3 },
    Report: { Name: 'Report', Value: 4 },
};

export const AlertSubTypes = {
    Collab: { Name: 'Collab', Value: 1 },
    Chat: { Name: 'Chat', Value: 2 },
    Review: { Name: 'Review', Value: 3 },
};

export enum NoteApprovalStatusTypesEnum {
    Approved = 1,
    NotApproved = 2,
    NeedMoreInfo = 3,
}

export enum AlertOriginTypesEnum {
    CaseNote = 1,
    ZoneNote = 2,
    StrategyNote = 3,
    PackageSettlementNote = 4,
    Report = 5,
    CaseTrigger = 6,
}

export enum NoteAcknowledgementStatusTypesEnum {
    Acknowledge = 1,
    Decline = 2,
    NeedMoreInfo = 3,
}

export enum AlertTypesEnum {
    Act = 1,
    Watch = 2,
    CaseTrigger = 3,
    Report = 4,
}

export enum AlertSubTypesEnum {
    Collab = 1,
    Chat = 2,
    Review = 3,
}

export const MyAlertListSearchStatusOptions: IMultiSelectOptions[] = [
    { value: 'OpenRead', label: 'Pending - Read', id: 1 },
    { value: 'OpenUnread', label: 'Pending - Unread', id: 2 },
    { value: 'ResolvedRead', label: 'Closed - Read', id: 3 },
    { value: 'ResolvedUnread', label: 'Closed - Unread', id: 4 },
];

export enum AlertListSearchStatusOptionsEnum {
    OpenRead = 1,
    OpenUnread = 2,
    ResolvedRead = 3,
    ResolvedUnread = 4,
}

export enum FavoriteTypesEnum {
    MyAlertList = 1,
    ZoneAlertList = 2,
    CaseList = 3,
    Query = 4,
    EasyUpdate = 5,
}

export enum AlertListTypeEnum {
    MyAlertList = 1,
    ZoneAlertList = 2,
}

export enum FavoriteScopeEnum {
    User = 1,
    Zone = 2,
}

export enum TutorialTopics {
    CheckmateIntro = 1,
    EasyUpdate = 2,
    QueryToolCaseDetails = 3,
    QueryToolCaseNotes = 4,
    Alerts = 5,
    Triggers = 6,
}

export interface ITutorialDetail {
    id: number;
    name: string;
    displayName: string;
    fileName: string;
    icon: string;
}

export const TutorialDetails: ITutorialDetail[] = [
    {
        id: 1,
        name: 'CheckmateIntro',
        displayName: 'Checkmate Intro',
        fileName: 'CheckmateIntro.pdf',
        icon: 'fa-list',
    },
    {
        id: 2,
        name: 'EasyUpdate',
        displayName: 'Easy Update',
        fileName: 'EasyUpdate.pdf',
        icon: 'fa-edit',
    },
    {
        id: 3,
        name: 'QueryToolCaseDetails',
        displayName: 'Query Tool - Case Details',
        fileName: 'QueryToolCaseDetails.pdf',
        icon: 'fa-database',
    },
    {
        id: 4,
        name: 'QueryToolCaseNotes',
        displayName: 'Query Tool - Case Notes',
        fileName: 'QueryToolCaseNotes.pdf',
        icon: 'fa-database',
    },
    {
        id: 5,
        name: 'Alerts',
        displayName: 'Case Notes and Alerts',
        fileName: 'CaseNotesAndAlerts.pdf',
        icon: 'fa-memo',
    },
];

export enum ListMode {
    UserDefault,
    SystemDefault,
    FavoriteSelected, // User Selects a Favorite from the list
    RunSearch, // User explicitly selecting criteria and clicking on "Search" or "Filter"
}

export const MonthlyUpdateReportSectionList: ILookupModel[] = [
    { id: 1, name: 'CaseInventory', displayName: 'Case Inventory' },
    {
        id: 19,
        name: 'OpenActiveCasesStateMap',
        displayName: 'Open - Active Cases by State',
    },
    {
        id: 28,
        name: 'CaseInventoryByState',
        displayName: 'Case Inventory by State',
    },
    {
        id: 20,
        name: 'OpenActiveCasesByDisease',
        displayName: 'Open - Active Cases by Disease',
    },
    { id: 17, name: 'RiskProfile', displayName: 'Risk Profile' },
    { id: 18, name: 'SettlementRecap', displayName: 'Settlement Recap YTD' },
    { id: 16, name: 'TenderedCases', displayName: 'Tendered Cases' },
    { id: 9, name: 'RiskIssues', displayName: 'Risk Issues' },
    {
        id: 10,
        name: 'SignificantActivity',
        displayName: 'Significant Activity',
    },
    { id: 21, name: 'UpcomingTrialDates', displayName: 'Upcoming Trial Dates' },
    { id: 14, name: 'ZoneNotes', displayName: 'Industry News' },
];

export const WeeklyRecapReportSectionList: ILookupModel[] = [
    { id: 1, name: 'CaseInventory', displayName: 'Weekly Change in Case Volume' },
    {
        id: 2,
        name: 'CaseInfoNew',
        displayName: 'New Cases Recap',
    },
    {
        id: 3,
        name: 'CaseInfoDismissed',
        displayName: 'Dismissed Cases Recap',
    },
    {
        id: 4,
        name: 'CaseInfoSettled',
        displayName: 'Settled Cases Recap',
    },
    {
        id: 15,
        name: 'InitialCaseEval',
        displayName: 'Initial Case Evaluations',
    },
    { id: 16, name: 'TenderedCases', displayName: 'Tendered Cases' },
    {
        id: 5,
        name: 'RequestForAuth',
        displayName: 'Outstanding Requests for Authority',
    },
    { id: 9, name: 'RiskIssues', displayName: 'Risk Issues' },
    {
        id: 10,
        name: 'SignificantActivity',
        displayName: 'Significant Activity',
    },
    { id: 29, name: 'Depositions', displayName: 'Upcoming Depositions' },
    { id: 14, name: 'ZoneNotes', displayName: 'Industry News' },
];

export enum CaseInventoryByStateDisplayMode {
    Top5 = 1,
    Top10 = 2,
    All = 3,
}

export enum PlaintiffsFirmDisplayMode {
    Local = 1,
    National = 2,
    Both = 3,
    Hierarchy = 4,
}

export enum ReportCaseDetailsLastColumn {
    ProductPremises = 1,
    Occupation = 2,
    UniqueCaseID = 3,
    AlternateCaseIDNumber = 4,
    AlternateCaseIDNumber3 = 5,
    AlternateCaseIDNumber4 = 6,
}

export enum AssertionDescriptorUsageTypesEnum {
    Admin = 0,
    Case = 1,
    Shared = 2,
    Strategy = 3,
    PackageDeal = 4,
    Note = 5,
    Expert = 6,
}

export enum DisplayItemTypeEnum {
    SingleField = 1,
    Section = 2,
    ReadOnlySingleField = 3,
}

export const CaseExpertActivityType = {
    disclosure: {
        id: 1,
        label: 'Yes',
    },
    report: {
        id: 2,
        label: 'Report',
    },
    tender: {
        id: 3,
        label: 'Tender',
    },
    deposition: {
        id: 4,
        label: 'Deposition',
    },
};

export enum DocumentTypeEnum {
    WorkProduct = 1,
    OfficialCaseDocument = 2,
    Report = 3,
    DataExport = 4,
    ScreenPDF = 5,
}

export const DocumentTypeOptions: IMultiSelectOptions[] = [
    { value: 'DataExport', label: 'Data Export', id: 4 },
    { value: 'OfficialCaseDocument', label: 'Official Case Document', id: 2 },
    { value: 'Report', label: 'Report', id: 3 },
    { value: 'ScreenPDF', label: 'Screen PDF', id: 5 },
    { value: 'WorkProduct', label: 'Work Product', id: 1 },
];

export const CaseExpertActivityStatusType = {
    yes: {
        id: 1,
        label: 'Yes',
    },
    no: {
        id: 2,
        label: 'No',
    },
    na: {
        id: 3,
        label: 'N/A',
    },
};

export enum AlertStatusTypeEnum {
    Pending = 1,
    Closed = 2,
}

export const MyAlertListSearchStatusMapping = [
    {
        value: 'OpenRead',
        label: 'Pending - Read',
        id: 1,
        AlertStatusType: AlertStatusTypeEnum.Pending,
        Read: true,
    },
    {
        value: 'OpenUnread',
        label: 'Pending - Unread',
        id: 2,
        AlertStatusType: AlertStatusTypeEnum.Pending,
        Read: false,
    },
    {
        value: 'ResolvedRead',
        label: 'Closed - Read',
        id: 3,
        AlertStatusType: AlertStatusTypeEnum.Closed,
        Read: true,
    },
    {
        value: 'ResolvedUnread',
        label: 'Closed - Unread',
        id: 4,
        AlertStatusType: AlertStatusTypeEnum.Closed,
        Read: false,
    },
];

export const ReportStatusOptionsList: ILookupModel[] = [
    { id: 1, name: 'Draft', displayName: 'Draft' },
    { id: 2, name: 'Final Draft', displayName: 'Final Draft' },
    { id: 3, name: 'Published', displayName: 'Published' },
];

export enum ReportStatusTypesEnum {
    Draft = 1,
    FinalDraft = 2,
    Published = 3,
}

export enum ReportUserTypesEnum {
    Collaborator = 1,
    Reader = 2,
    Owner = 3,
}

export const MyAlertListSearchStatusOptionsMapping = [
    { id: 1, status: AlertStatusTypeEnum.Pending, read: true },
    { id: 2, status: AlertStatusTypeEnum.Pending, read: false },
    { id: 3, status: AlertStatusTypeEnum.Closed, read: true },
    { id: 4, status: AlertStatusTypeEnum.Closed, read: false },
];

export interface IQueryExportCountModel {
    orgId: string;
    count: number;
}

export class ExportOptions {
    static IncludeTactics: ICustomReportOption = {
        key: 'includeTasks',
        label: 'Include Tactics',
    };

    static IncludeSignificantChanges: ICustomReportOption = {
        key: 'includeNotes',
        label: 'Include Significant Changes',
    };

    static IncludeAttachments: ICustomReportOption = {
        key: 'includeAttachments',
        label: 'Include Attachments',
    };

    static IncludeHistoricAverages: ICustomReportOption = {
        key: 'includeHistoricAverages',
        label: 'Include Historic Averages',
    };

    static IncludeStrategyNotes: ICustomReportOption = {
        key: 'includeStrategyNotes',
        label: 'Include Strategy Notes',
    };

    static StrategySignificantChange = {
        typeId: NoteTypes.StrategySignificantChange,
        key: NoteCategories.StrategySignificantChanges.toString(),
    };
}
