import {
    ApplicationHelpType,
    DataScopesEnum,
    EntityTypes,
    NoteTypes,
    TimelineReportSections,
    TimelineReportStatusEnum,
    UserRightsEnum,
} from '../../../../utilities/Constants';
import {
    IReportCaseDetailModel,
    IReportNoteBaseModel,
} from '../../../../interfaces/Report/IReportCaseDetailModel';
import {
    IReportSectionDetailModel,
    ITimelineReportInputModel,
    IWeeklyRecapModel,
} from '../../../../interfaces/Report/ITimelineReport';

import Authorization from '../../../../stores/Authorization';
import Common from '../../../../stores/Common';
import { EasyUpdateButton } from '../../EasyUpdateButton';
import { Help } from '../../../../components/shared/Help';
import { ICollabReportViewProps } from '../../collab-report/CollabReportController';
import { IKeyValuePairModel } from '../../../../interfaces/ILookup';
import { INoteModel } from '../../../../interfaces/INote';
import { IndustryNewsSection } from './sections/IndustryNewsSection';
import { Link } from 'react-router-dom';
import { LocalRoutes } from '../../../../utilities/LocalRoutes';
import { SectionFooter } from '../SectionFooter';
import { SectionHeader } from '../../collab-report/SectionHeader';
import { TextWithLineBreaks } from '../../../../components/shared/TextWithLineBreaks';
import { UpcomingDepositionsSection } from './sections/UpcomingDepositionsSection';
import { cloneDeep } from 'lodash';
import { useAuthContext } from '../../../../contexts/AuthContext';

export function WeeklyRecapReportRenderer<
    T extends IWeeklyRecapModel,
    I extends ITimelineReportInputModel
>(props: ICollabReportViewProps<T, I>) {
    const { user } = useAuthContext();

    const getParentCaseGuidByNoteGuid = (
        noteGuid: string,
        section: TimelineReportSections
    ): string => {
        if (props.data) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    for (let i = 0; i < props.data.initialCaseEval.length; i++) {
                        if (
                            props.data.initialCaseEval[i].notes.filter((x) => x.guid === noteGuid)
                                .length > 0
                        )
                            return props.data.initialCaseEval[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    for (let i = 0; i < props.data.tenderedCases.length; i++) {
                        if (
                            props.data.tenderedCases[i].notes.filter((x) => x.guid === noteGuid)
                                .length > 0
                        )
                            return props.data.tenderedCases[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (let i = 0; i < props.data.riskIssues.length; i++) {
                        if (
                            props.data.riskIssues[i].notes.filter((x) => x.guid === noteGuid)
                                .length > 0
                        )
                            return props.data.riskIssues[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (let i = 0; i < props.data.significantActivities.length; i++) {
                        if (
                            props.data.significantActivities[i].notes.filter(
                                (x) => x.guid === noteGuid
                            ).length > 0
                        )
                            return props.data.significantActivities[i].caseGuid;
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    for (let i = 0; i < props.data.clientRequests.length; i++) {
                        if (
                            props.data.clientRequests[i].notes.filter((x) => x.guid === noteGuid)
                                .length > 0
                        )
                            return props.data.significantActivities[i].caseGuid;
                    }
                    break;
                default:
                    return '';
            }
        }

        return '';
    };

    const getNoteGuidsByCaseAndSection = (
        caseGuid: string,
        section: TimelineReportSections
    ): string[] => {
        const returnValue: string[] = [];

        if (props.data) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    {
                        const initialCaseEvalMatch = props.data.initialCaseEval.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (initialCaseEvalMatch && initialCaseEvalMatch.notes)
                            initialCaseEvalMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    {
                        const tenderedcaseMatch = props.data.tenderedCases.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (tenderedcaseMatch && tenderedcaseMatch.notes)
                            tenderedcaseMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    {
                        const riskIssuesMatch = props.data.riskIssues.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (riskIssuesMatch && riskIssuesMatch.notes)
                            riskIssuesMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    {
                        const sigActMatch = props.data.significantActivities.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (sigActMatch && sigActMatch.notes)
                            sigActMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    {
                        const reqForAuthMatch = props.data.clientRequests.find(
                            (x) => x.caseGuid === caseGuid
                        );
                        if (reqForAuthMatch && reqForAuthMatch.notes)
                            reqForAuthMatch.notes.map((item: IReportNoteBaseModel) => {
                                return returnValue.push(item.guid);
                            });
                    }
                    break;
                default:
            }
        }
        return returnValue;
    };

    const getAllGuidsBySectionForExport = (section: TimelineReportSections) => {
        const result: IReportSectionDetailModel = {
            section: section,
            sectionName: section.toString(),
            caseGuids: [],
            noteGuids: [],
        };
        if (props.data) {
            switch (section) {
                case TimelineReportSections.InitialCaseEval:
                    for (let i = 0; i < props.data.initialCaseEval.length; i++) {
                        if (result.caseGuids) {
                            result.caseGuids.push(props.data.initialCaseEval[i].caseGuid);
                            if (props.data.initialCaseEval[i].notes) {
                                for (
                                    let j = 0;
                                    j < props.data.initialCaseEval[i].notes.length;
                                    j++
                                ) {
                                    if (result.noteGuids)
                                        result.noteGuids.push(
                                            props.data.initialCaseEval[i].notes[j].guid
                                        );
                                }
                            }
                        }
                    }
                    break;
                case TimelineReportSections.TenderedCases:
                    for (let i = 0; i < props.data.tenderedCases.length; i++) {
                        result.caseGuids?.push(props.data.tenderedCases[i].caseGuid);
                        if (props.data.tenderedCases[i].notes) {
                            for (let j = 0; j < props.data.tenderedCases[i].notes.length; j++) {
                                result.noteGuids?.push(props.data.tenderedCases[i].notes[j].guid);
                            }
                        }
                    }
                    break;
                case TimelineReportSections.RiskIssues:
                    for (let i = 0; i < props.data.riskIssues.length; i++) {
                        result.caseGuids?.push(props.data.riskIssues[i].caseGuid);
                        if (props.data.riskIssues[i].notes) {
                            for (let j = 0; j < props.data.riskIssues[i].notes.length; j++) {
                                result.noteGuids?.push(props.data.riskIssues[i].notes[j].guid);
                            }
                        }
                    }
                    break;
                case TimelineReportSections.SignificantActivity:
                    for (let i = 0; i < props.data.significantActivities.length; i++) {
                        result.caseGuids?.push(props.data.significantActivities[i].caseGuid);
                        if (props.data.significantActivities[i].notes) {
                            for (
                                let j = 0;
                                j < props.data.significantActivities[i].notes.length;
                                j++
                            ) {
                                result.noteGuids?.push(
                                    props.data.significantActivities[i].notes[j].guid
                                );
                            }
                        }
                    }
                    break;
                case TimelineReportSections.RequestForAuth:
                    for (let i = 0; i < props.data.clientRequests.length; i++) {
                        result.caseGuids?.push(props.data.clientRequests[i].caseGuid);
                        if (props.data.clientRequests[i].notes) {
                            for (let j = 0; j < props.data.clientRequests[i].notes.length; j++) {
                                result.noteGuids?.push(props.data.clientRequests[i].notes[j].guid);
                            }
                        }
                    }
                    break;
                case TimelineReportSections.ZoneNotes:
                    for (let i = 0; i < props.data.zoneNotes.length; i++) {
                        result.noteGuids?.push(props.data.zoneNotes[i].noteGuid);
                    }
                    break;
                default:
            }
        }
        return result;
    };

    const previewFilter = (guid: string, section: number, entityType: EntityTypes) => {
        if (props.isPreviewMode) {
            return getCheckedValue(section, entityType, guid);
        }

        return true;
    };

    const buildReportSection = (item: IReportCaseDetailModel, section: TimelineReportSections) => {
        const rows: any[] = [];
        const hasNotes = item.notes.length > 0;
        const bottomBorder = hasNotes ? 'border-0 ' : '';

        rows.push(
            <tr key={'section_' + section.toString()}>
                {!props.isPreviewMode && (
                    <td width={25} className={bottomBorder}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={item.caseGuid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(e, section, EntityTypes.Case);
                            }}
                            checked={getCheckedValue(section, EntityTypes.Case, item.caseGuid)}
                            disabled={props.isReportReadonly}
                        />
                    </td>
                )}
                <td className={bottomBorder + 'col-sm-2'}>
                    <Link target="_blank" to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}>
                        {item.caseName}
                    </Link>
                    {item.priorServed && <>*</>}
                </td>
                {section === TimelineReportSections.InitialCaseEval && (
                    <td className={bottomBorder + 'col-sm-1'}>
                        {Common.dateFormat(item.serviceDate)}
                    </td>
                )}
                {section === TimelineReportSections.TenderedCases && (
                    <td className={bottomBorder + 'col-sm-1'}>
                        {item.status!.displayName ? item.status!.displayName : item.status!.name!}
                    </td>
                )}
                <td
                    className={
                        bottomBorder +
                        (section === TimelineReportSections.InitialCaseEval ||
                        section === TimelineReportSections.TenderedCases
                            ? 'col-sm-2'
                            : 'col-sm-3')
                    }
                >
                    {item.jurisdiction}
                </td>
                <td className={bottomBorder + 'col-sm-3'}>{item.plaintiffsFirmDisplayName}</td>
                <td
                    className={
                        bottomBorder +
                        (section === TimelineReportSections.InitialCaseEval
                            ? 'col-sm-1'
                            : 'col-sm-2')
                    }
                >
                    {item.diagnosis}
                </td>
                <td className={item.notes.length > 0 ? 'border-0' : ''}>
                    {item.additionalCaseIdentifier}
                </td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, user) && (
                    <td width={45} className={bottomBorder + 'center'}>
                        {!props.isReportReadonly && !props.isPreviewMode && (
                            <button
                                className="btn-no-bg"
                                onClick={() => {
                                    handleEasyUpdate(item.caseGuid);
                                }}
                            >
                                <i
                                    className={`fal fa-edit ${
                                        props.collabReportState.easyUpdateAttemptedCaseList.filter(
                                            (x) => x === item.caseGuid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        )}
                    </td>
                )}
            </tr>
        );

        let colSpan = 5;
        switch (section) {
            case TimelineReportSections.InitialCaseEval:
            case TimelineReportSections.TenderedCases:
                colSpan = 6;
                break;
            default:
        }

        if (item.notes)
            buildNotesRows(item, section, colSpan).forEach((row: any) => {
                rows.push(row);
            });
        return rows;
    };

    const buildNotesRows = (
        caseItem: IReportCaseDetailModel,
        section: TimelineReportSections,
        colSpan: number
    ) => {
        const rows = caseItem.notes.map((note, index) => {
            const showNotesRow = getCheckedValue(section, EntityTypes.Note, note.guid);
            if (!showNotesRow) return null;

            const currentNote: INoteModel = {
                guid: note.guid,
                type: { id: NoteTypes.CaseNote },
                status: { id: 0 },
                purpose: { id: 0 },
                caseGuid: caseItem.caseGuid,
                caseName: caseItem.caseName,
                qScore: caseItem.qScore,
                qProjection: caseItem.qProjection,
            };

            return (
                <tr key={index}>
                    {!props.isPreviewMode && (
                        <td className={caseItem.notes.length - 1 === index ? '' : 'border-0'}>
                            <input
                                type="checkbox"
                                className="form-check-input"
                                name={note.guid}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleCheckedChange(e, section, EntityTypes.Note);
                                }}
                                checked={getCheckedValue(section, EntityTypes.Note, note.guid)}
                                disabled={props.isReportReadonly}
                            />
                        </td>
                    )}
                    <td
                        className={caseItem.notes.length - 1 === index ? '' : 'border-0'}
                        colSpan={colSpan}
                    >
                        {note.content && <TextWithLineBreaks text={note.content} />}
                    </td>
                    {Authorization.userHasRight(UserRightsEnum.EditCaseNote, user) &&
                        (Authorization.getUserDefaultOrganizationDataScope(user) !==
                            DataScopesEnum.LocalBasic.Value ||
                            note.allowLocalEdit) && (
                            <td
                                width={45}
                                className={
                                    (caseItem.notes.length - 1 === index ? '' : 'border-0 ') +
                                    'center'
                                }
                            >
                                {!props.isReportReadonly && (
                                    <button
                                        className="btn btn-no-bg"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            handleNoteEdit(e, currentNote);
                                        }}
                                    >
                                        <i
                                            className={`fal fa-pen ${
                                                props.collabReportState.noteEditAttemptedGuidList.filter(
                                                    (x) => x === currentNote.guid
                                                ).length > 0
                                                    ? 'color-gray'
                                                    : 'color-black'
                                            } `}
                                        />
                                    </button>
                                )}
                            </td>
                        )}
                </tr>
            );
        });

        return rows;
    };

    const buildRequestForAuthRows = (item: IReportCaseDetailModel) => {
        const rows: any[] = [];
        rows.push(
            <tr key={item.caseGuid}>
                {!props.isPreviewMode && (
                    <td width={15}>
                        <input
                            type="checkbox"
                            className="form-check-input"
                            name={item.caseGuid}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleCheckedChange(
                                    e,
                                    TimelineReportSections.RequestForAuth,
                                    EntityTypes.Case
                                );
                            }}
                            checked={getCheckedValue(
                                TimelineReportSections.RequestForAuth,
                                EntityTypes.Case,
                                item.caseGuid
                            )}
                            disabled={props.isReportReadonly}
                        />
                    </td>
                )}
                <td>
                    <Link
                        target="_blank"
                        to={LocalRoutes.CaseFile.replace(':guid', item.caseGuid)}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {item.caseName}
                    </Link>
                </td>
                <td className="col-sm-1">
                    {item.authorityRequested
                        ? Common.formatCurrency(item.authorityRequested.toString())
                        : ''}
                </td>
                <td className="col-sm-1">{Common.dateFormat(item.authorityRequestedDate)}</td>
                <td className="col-sm-2">{item.jurisdiction}</td>
                <td className="col-sm-2">{item.plaintiffsFirmDisplayName}</td>
                <td>{item.diagnosis}</td>
                <td className="col-sm-1">{Common.dateFormat(item.trialDate)}</td>
                <td className="col-sm-1">
                    {item.demand ? Common.formatCurrency(item.demand.toString()) : ''}
                </td>
                <td className="col-sm-1">{Common.dateFormat(item.clientExposureStartDate)}</td>
                {Authorization.isAuthorizedToRoute(LocalRoutes.CaseEasyUpdate, user) && (
                    <td
                        width={45}
                        className={(item.notes.length > 0 ? 'border-0 ' : '') + 'center'}
                    >
                        {' '}
                        {!props.isReportReadonly && (
                            <button
                                className="btn-no-bg"
                                onClick={() => {
                                    handleEasyUpdate(item.caseGuid);
                                }}
                            >
                                <i
                                    className={`fal fa-edit ${
                                        props.collabReportState.easyUpdateAttemptedCaseList.filter(
                                            (x) => x === item.caseGuid
                                        ).length > 0
                                            ? 'color-gray'
                                            : 'color-black'
                                    } `}
                                />
                            </button>
                        )}
                    </td>
                )}
            </tr>
        );

        if (item.notes)
            buildNotesRows(item, TimelineReportSections.RequestForAuth, 8).map((row: any) => {
                rows.push(row);
            });
        return rows;
    };

    const getCommentText = (reportSection: number) => {
        if (props.inputModel?.reportSectionDetails) {
            const sectionMatch = props.inputModel.reportSectionDetails.find(
                (x) => x.section === reportSection
            );
            if (sectionMatch) return sectionMatch.comment;
        }

        return '';
    };

    const displaySection = (sectionNumber: number) =>
        (props.isPreviewMode &&
            props.inputModel?.reportSectionDetails?.some((x) => x.section === sectionNumber)) ||
        !props.isPreviewMode;

    const isSectionRowsSelectAllChecked = (section: TimelineReportSections) => {
        return props.collabReportState.selectAllSections.some((x) => x === section);
    };

    const handleNoteEdit = (e: React.FormEvent<HTMLButtonElement>, noteItem: INoteModel) => {
        if (!noteItem.guid) return;

        const noteEditAttemptedGuidList = [...props.collabReportState.noteEditAttemptedGuidList];
        if (noteEditAttemptedGuidList.filter((x) => x === noteItem.guid).length === 0) {
            noteEditAttemptedGuidList.push(noteItem.guid);
        }

        props.setCollabState((prev) => ({
            ...prev,
            openNoteEditor: true,
            currentNote: noteItem,
        }));
    };

    const handleEasyUpdate = (caseGuid: string, noteGuid?: string) => {
        const easyUpdateAttemptedCaseList = cloneDeep(
            props.collabReportState.easyUpdateAttemptedCaseList
        );

        const easyUpdateAttemptedNoteGuidList = cloneDeep(
            props.collabReportState.noteEditAttemptedGuidList
        );

        if (!easyUpdateAttemptedCaseList.some((x) => x === caseGuid)) {
            easyUpdateAttemptedCaseList.push(caseGuid);
        }

        if (noteGuid) {
            easyUpdateAttemptedNoteGuidList.push(noteGuid);
        }

        props.setCollabState((prev) => ({
            ...prev,
            easyUpdateMode: true,
            easyUpdateCaseGuid: caseGuid,
            easyUpdateAttemptedCaseList: easyUpdateAttemptedCaseList,
            noteEditAttemptedGuidList: easyUpdateAttemptedNoteGuidList,
        }));
    };

    const handleCommentsButtonClick = (section: number) => {
        props.onEventTriggered('commentsModalStateChange', { open: true, section });
    };

    const handleSelectAllCheckedChanged = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections
    ) => {
        const selectAllSectionsCopy = cloneDeep(props.collabReportState.selectAllSections);
        const inputModelCopy = cloneDeep(props.inputModel);

        if (!inputModelCopy.reportSectionDetails) inputModelCopy.reportSectionDetails = [];

        let sectionMatch = inputModelCopy.reportSectionDetails.find((x) => x.section === section);
        if (!sectionMatch) {
            sectionMatch = { section: section, sectionName: section.toString() };
            inputModelCopy.reportSectionDetails.push(sectionMatch);
        }

        const isChecked = event.target.checked;

        if (isChecked) {
            if (!selectAllSectionsCopy.some((x) => x === section)) {
                selectAllSectionsCopy.push(section);
            }
            const allGuids = getAllGuidsBySectionForExport(section);
            sectionMatch.caseGuids = allGuids.caseGuids;
            sectionMatch.noteGuids = allGuids.noteGuids;
        } else {
            if (selectAllSectionsCopy.some((x) => x === section))
                selectAllSectionsCopy.splice(
                    selectAllSectionsCopy.findIndex((x) => x === section),
                    1
                );
            sectionMatch.caseGuids = [];
            sectionMatch.noteGuids = [];
        }

        props.setCollabState((prev) => ({
            ...prev,
            selectAllSections: selectAllSectionsCopy,
        }));

        props.onEventTriggered('inputModelChange', { inputModel: inputModelCopy });
    };

    const updateSelectAllCheckboxState = (
        section: number,
        selectedRowCount: number,
        rowCount: number
    ) => {
        let selectAllSectionsCopy = cloneDeep(props.collabReportState.selectAllSections);

        if (selectedRowCount !== 0 && rowCount !== 0 && selectedRowCount === rowCount) {
            selectAllSectionsCopy.push(section);
        } else {
            selectAllSectionsCopy = selectAllSectionsCopy.filter((x) => x !== section);
        }

        return selectAllSectionsCopy;
    };

    const handleCheckedChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        section: TimelineReportSections,
        entityType: EntityTypes
    ) => {
        const inputModelCopy = cloneDeep(props.inputModel);

        if (event.target.checked) {
            if (!inputModelCopy.reportSectionDetails) {
                inputModelCopy.reportSectionDetails = [];
            }

            if (inputModelCopy.reportSectionDetails.some((x) => x.section === section)) {
                inputModelCopy.reportSectionDetails.push({
                    section: section,
                    sectionName: section.toString(),
                });
            }

            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (!sectionMatch) return;
            if (!sectionMatch.caseGuids) sectionMatch.caseGuids = [];
            if (!sectionMatch.noteGuids) sectionMatch.noteGuids = [];

            switch (entityType) {
                case EntityTypes.Case:
                    {
                        const guid = event.target.name;
                        if (!sectionMatch.caseGuids?.some((x) => x === guid)) {
                            sectionMatch.caseGuids?.push(guid);
                        }

                        const noteGuidsToAddToSelectedList = getNoteGuidsByCaseAndSection(
                            event.target.name,
                            section
                        );

                        if (noteGuidsToAddToSelectedList.length > 0) {
                            for (let i = 0; i < noteGuidsToAddToSelectedList.length; i++) {
                                if (
                                    sectionMatch.noteGuids?.some(
                                        (x) => x === noteGuidsToAddToSelectedList[i]
                                    )
                                )
                                    sectionMatch.noteGuids?.push(noteGuidsToAddToSelectedList[i]);
                            }
                        }
                    }
                    break;
                case EntityTypes.Note:
                    if (!sectionMatch.noteGuids?.some((x) => x === event.target.name)) {
                        sectionMatch.noteGuids?.push(event.target.name);

                        // Find the parent;  if it is not checked, check it ( for all sections except other client requests)
                        const parentCaseGuid = getParentCaseGuidByNoteGuid(
                            event.target.name,
                            section
                        );
                        if (
                            parentCaseGuid &&
                            sectionMatch.caseGuids?.findIndex((x) => x === parentCaseGuid) === -1
                        )
                            sectionMatch.caseGuids?.push(parentCaseGuid);
                    }
                    break;
                default:
            }
        } else {
            if (inputModelCopy.reportSectionDetails && inputModelCopy.reportSectionDetails) {
                const sectionMatch = inputModelCopy.reportSectionDetails.find(
                    (x) => x.section === section
                );
                if (sectionMatch) {
                    switch (entityType) {
                        case EntityTypes.Case:
                            if (
                                sectionMatch.caseGuids &&
                                sectionMatch.caseGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            ) {
                                sectionMatch.caseGuids.splice(
                                    sectionMatch.caseGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                                if (sectionMatch.noteGuids) {
                                    const noteGuidsToRemoveFromSelectedList =
                                        getNoteGuidsByCaseAndSection(event.target.name, section);
                                    if (noteGuidsToRemoveFromSelectedList.length > 0) {
                                        for (
                                            let i = 0;
                                            i < noteGuidsToRemoveFromSelectedList.length;
                                            i++
                                        ) {
                                            if (
                                                sectionMatch.noteGuids?.findIndex(
                                                    (x) =>
                                                        x === noteGuidsToRemoveFromSelectedList[i]
                                                ) > -1
                                            )
                                                sectionMatch.noteGuids?.splice(
                                                    sectionMatch.noteGuids?.findIndex(
                                                        (x) =>
                                                            x ===
                                                            noteGuidsToRemoveFromSelectedList[i]
                                                    ),
                                                    1
                                                );
                                        }
                                    }
                                }
                            }
                            break;
                        case EntityTypes.Note:
                            if (
                                sectionMatch.noteGuids &&
                                sectionMatch.noteGuids.findIndex((x) => x === event.target.name) >
                                    -1
                            )
                                sectionMatch.noteGuids.splice(
                                    sectionMatch.noteGuids.findIndex(
                                        (x) => x === event.target.name
                                    ),
                                    1
                                );
                            break;
                        default:
                    }
                }
            }
        }

        const selectAllSections = updateSelectAllCheckboxState(
            section,
            inputModelCopy.reportSectionDetails?.find((det) => det.section === section)?.caseGuids
                ?.length ?? 0,
            props.data.initialCaseEval.length ?? 0
        );

        props.setCollabState((prev) => ({
            ...prev,
            selectAllSections,
        }));

        props.onEventTriggered('inputModelChange', { inputModel: inputModelCopy });
    };

    const getCheckedValue = (
        section: TimelineReportSections,
        entityType: EntityTypes,
        guid: string
    ) => {
        const inputModelCopy = cloneDeep(props.inputModel);
        if (inputModelCopy.reportSectionDetails) {
            const sectionMatch = inputModelCopy.reportSectionDetails.find(
                (x) => x.section === section
            );
            if (sectionMatch) {
                switch (entityType) {
                    case EntityTypes.Case:
                        if (sectionMatch.caseGuids) {
                            return sectionMatch.caseGuids.some((x) => x === guid);
                        }
                        break;
                    case EntityTypes.Note:
                        if (sectionMatch.noteGuids) {
                            return sectionMatch.noteGuids.some((x) => x === guid);
                        }
                        break;
                    default:
                }
            }
        }
        return false;
    };

    if (!props.data) {
        return null;
    }

    return (
        <div style={{ overflow: 'auto', paddingRight: '12px' }}>
            {displaySection(TimelineReportSections.CaseInventory) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.CaseInventory
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.CaseInventory}
                        title="Weekly Change in Case Volume"
                    />

                    <table className="table thick-outer-border">
                        <thead>
                            <tr>
                                {props.data.inventory.map((item: IKeyValuePairModel) => (
                                    <th className="align-top text-center" key={item.key}>
                                        {item.key!.split('<br/>').map((x, key) => {
                                            return (
                                                <span key={key}>
                                                    {x}
                                                    <br />
                                                </span>
                                            );
                                        })}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="align-top text-center">
                            <tr>
                                {props.data.inventory.map((item: IKeyValuePairModel) => {
                                    return <td key={item.key}>{item.value}</td>;
                                })}
                            </tr>
                        </tbody>
                    </table>

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.CaseInventory)}
                        rowCount={props.data.inventory.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.CaseInfoNew) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.CaseInfoNew
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.CaseInfoNew}
                        title="New Cases Recap"
                    />

                    {props.data.caseInfo.some(
                        (x) => x.status.id === TimelineReportStatusEnum.New
                    ) && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border-0" />
                                    <th className="col-sm-2">Case Name</th>
                                    <th className="col-sm-2">Jurisdiction</th>
                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                    <th className="col-sm-2">Disease</th>
                                    <th>
                                        {props.inputModel.additionalSettings
                                            ? props.inputModel.additionalSettings
                                                  .reportCaseDetailsLastColumnDisplayName
                                            : 'Last Column Header Name'}
                                    </th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.caseInfo
                                    .filter((x) => x.status.id === TimelineReportStatusEnum.New)
                                    .map((item: IReportCaseDetailModel) => {
                                        return (
                                            <tr key={item.caseGuid}>
                                                <td width={25} className="border-0" />
                                                <td className="col-sm-2">
                                                    <Link
                                                        target="_blank"
                                                        to={LocalRoutes.CaseFile.replace(
                                                            ':guid',
                                                            item.caseGuid
                                                        )}
                                                    >
                                                        {item.caseName}
                                                    </Link>
                                                </td>
                                                <td className="col-sm-2">{item.jurisdiction}</td>
                                                <td className="col-sm-3">
                                                    {item.plaintiffsFirmDisplayName}
                                                </td>
                                                <td className="col-sm-2">{item.diagnosis}</td>
                                                <td>{item.additionalCaseIdentifier}</td>
                                                {Authorization.isAuthorizedToRoute(
                                                    LocalRoutes.CaseEasyUpdate,
                                                    user
                                                ) && (
                                                    <td
                                                        width={45}
                                                        className={
                                                            (item.notes.length > 0
                                                                ? 'border-0 '
                                                                : '') + 'center'
                                                        }
                                                    >
                                                        {!props.isReportReadonly && (
                                                            <EasyUpdateButton
                                                                caseGuid={item.caseGuid}
                                                                onClick={(_, caseGuid) => {
                                                                    handleEasyUpdate(caseGuid);
                                                                }}
                                                                visited={props.collabReportState.easyUpdateAttemptedCaseList.some(
                                                                    (x) => x === item.caseGuid
                                                                )}
                                                            />
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.CaseInfoNew)}
                        rowCount={
                            props.data.caseInfo.filter(
                                (x) => x.status.id === TimelineReportStatusEnum.New
                            ).length
                        }
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.CaseInfoDismissed) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.CaseInfoDismissed
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.CaseInfoDismissed}
                        title="Dismissed Cases Recap"
                    />

                    {props.data.caseInfo.some(
                        (x) => x.status.id === TimelineReportStatusEnum.Dismissed
                    ) && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border-0" />
                                    <th className="col-sm-2">Case Name</th>
                                    <th className="col-sm-2">Jurisdiction</th>
                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                    <th className="col-sm-2">Disease</th>
                                    <th>
                                        {props.inputModel.additionalSettings
                                            ?.reportCaseDetailsLastColumnDisplayName ??
                                            'Last Column Header Name'}
                                    </th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.caseInfo
                                    .filter(
                                        (x) => x.status.id === TimelineReportStatusEnum.Dismissed
                                    )
                                    .map((item: IReportCaseDetailModel) => {
                                        return (
                                            <tr key={item.caseGuid}>
                                                <td width={25} className="border-0" />
                                                <td className="col-sm-2">
                                                    <Link
                                                        target="_blank"
                                                        to={LocalRoutes.CaseFile.replace(
                                                            ':guid',
                                                            item.caseGuid
                                                        )}
                                                    >
                                                        {item.caseName}
                                                    </Link>
                                                </td>
                                                <td className="col-sm-2">{item.jurisdiction}</td>
                                                <td className="col-sm-3">
                                                    {item.plaintiffsFirmDisplayName}
                                                </td>
                                                <td className="col-sm-2">{item.diagnosis}</td>
                                                <td>{item.additionalCaseIdentifier}</td>
                                                {Authorization.isAuthorizedToRoute(
                                                    LocalRoutes.CaseEasyUpdate,
                                                    user
                                                ) && (
                                                    <td
                                                        width={45}
                                                        className={
                                                            (item.notes.length > 0
                                                                ? 'border-0 '
                                                                : '') + 'center'
                                                        }
                                                    >
                                                        {!props.isReportReadonly && (
                                                            <EasyUpdateButton
                                                                caseGuid={item.caseGuid}
                                                                onClick={(_, caseGuid) => {
                                                                    handleEasyUpdate(caseGuid);
                                                                }}
                                                                visited={props.collabReportState.easyUpdateAttemptedCaseList.some(
                                                                    (x) => x === item.caseGuid
                                                                )}
                                                            />
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.CaseInfoDismissed)}
                        rowCount={
                            props.data.caseInfo.filter(
                                (x) => x.status.id === TimelineReportStatusEnum.Dismissed
                            ).length
                        }
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.CaseInfoSettled) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.CaseInfoSettled
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.CaseInfoSettled}
                        title="Settled Cases Recap"
                    />

                    {props.data.caseInfo.some(
                        (x) => x.status.id === TimelineReportStatusEnum.Settled
                    ) && (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border-0" />
                                    <th className="col-sm-2">Case Name</th>
                                    <th className="col-sm-2">Jurisdiction</th>
                                    <th className="col-sm-3">Plaintiffs Firm</th>
                                    <th className="col-sm-2">Disease</th>
                                    <th className="col-sm-1">Amount</th>
                                    <th>Date</th>
                                    {Authorization.isAuthorizedToRoute(
                                        LocalRoutes.CaseEasyUpdate,
                                        user
                                    ) && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {props.data.caseInfo
                                    .filter((x) => x.status.id === TimelineReportStatusEnum.Settled)
                                    .map((item: IReportCaseDetailModel) => {
                                        return (
                                            <tr key={item.caseGuid}>
                                                <td width={25} className="border-0" />
                                                <td className="col-sm-2">
                                                    <Link
                                                        target="_blank"
                                                        to={LocalRoutes.CaseFile.replace(
                                                            ':guid',
                                                            item.caseGuid
                                                        )}
                                                    >
                                                        {item.caseName}
                                                    </Link>
                                                </td>
                                                <td className="col-sm-2">{item.jurisdiction}</td>
                                                <td className="col-sm-3">
                                                    {item.plaintiffsFirmDisplayName}
                                                </td>
                                                <td className="col-sm-2">{item.diagnosis}</td>
                                                <td className="col-sm-1">
                                                    {item.settlementAmount
                                                        ? Common.formatCurrency(
                                                              item.settlementAmount.toString()
                                                          )
                                                        : ''}
                                                </td>
                                                <td>{Common.dateFormat(item.resolutionDate)}</td>
                                                {Authorization.isAuthorizedToRoute(
                                                    LocalRoutes.CaseEasyUpdate,
                                                    user
                                                ) && (
                                                    <td
                                                        width={45}
                                                        className={
                                                            (item.notes.length > 0
                                                                ? 'border-0 '
                                                                : '') + 'center'
                                                        }
                                                    >
                                                        {!props.isReportReadonly && (
                                                            <EasyUpdateButton
                                                                caseGuid={item.caseGuid}
                                                                onClick={(_, caseGuid) => {
                                                                    handleEasyUpdate(caseGuid);
                                                                }}
                                                                visited={props.collabReportState.easyUpdateAttemptedCaseList.some(
                                                                    (x) => x === item.caseGuid
                                                                )}
                                                            />
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.CaseInfoSettled)}
                        rowCount={
                            props.data.caseInfo.filter(
                                (x) => x.status.id === TimelineReportStatusEnum.Settled
                            ).length
                        }
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.InitialCaseEval) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.InitialCaseEval
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.InitialCaseEval}
                        title="Initial Case Evaluations"
                    />

                    {props.data.initialCaseEval.length > 0 && (
                        <div className="margin-left-20">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {!props.isPreviewMode && (
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    checked={isSectionRowsSelectAllChecked(
                                                        TimelineReportSections.InitialCaseEval
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleSelectAllCheckedChanged(
                                                            e,
                                                            TimelineReportSections.InitialCaseEval
                                                        );
                                                    }}
                                                    disabled={props.isReportReadonly}
                                                />
                                            </th>
                                        )}
                                        <th className="col-sm-2">Case Name</th>
                                        <th className="col-sm-1">Date Served</th>
                                        <th className="col-sm-3">Jurisdiction</th>
                                        <th className="col-sm-3">Plaintiffs Firm</th>
                                        <th className="col-sm-1">Disease</th>
                                        <th>
                                            {props.inputModel.additionalSettings
                                                ?.reportCaseDetailsLastColumnDisplayName ??
                                                'Last Column Header Name'}
                                        </th>
                                        {Authorization.isAuthorizedToRoute(
                                            LocalRoutes.CaseEasyUpdate,
                                            user
                                        ) && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.initialCaseEval
                                        .filter((item) =>
                                            previewFilter(
                                                item.caseGuid,
                                                TimelineReportSections.InitialCaseEval,
                                                EntityTypes.Case
                                            )
                                        )
                                        .map((item: IReportCaseDetailModel) =>
                                            buildReportSection(
                                                item,
                                                TimelineReportSections.InitialCaseEval
                                            )
                                        )}
                                </tbody>
                            </table>
                            {props.data.initialCaseEval.some((x) => x.priorServed) && (
                                <span className="mb-2">
                                    *Case received from client during this reporting period but date
                                    served was prior to this reporting period
                                </span>
                            )}
                        </div>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.InitialCaseEval)}
                        rowCount={props.data.initialCaseEval.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.TenderedCases) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.TenderedCases
                        )}
                        helpComponent={
                            <Help
                                type={ApplicationHelpType.Info}
                                additionalStyleClassNames="font-size-sm"
                                title="Tendered Cases"
                                helpText="Cases that are Tendered-Pending as of the last date of the reporting period will be displayed along with the most recent case note, Category = Case.  \n\nCases with a Tendered-Accepted date during the reporting period will be displayed along with the most recent case note, Category = Case."
                            />
                        }
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.TenderedCases}
                        title="Tendered Cases"
                    />

                    {props.data.tenderedCases.length > 0 && (
                        <div className="margin-left-20">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {!props.isPreviewMode && (
                                            <th style={{ width: 25 }}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    checked={isSectionRowsSelectAllChecked(
                                                        TimelineReportSections.TenderedCases
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleSelectAllCheckedChanged(
                                                            e,
                                                            TimelineReportSections.TenderedCases
                                                        );
                                                    }}
                                                    disabled={props.isReportReadonly}
                                                />
                                            </th>
                                        )}
                                        <th className="col-sm-2">Case Name</th>
                                        <th>Status</th>
                                        <th className="col-sm-2">Jurisdiction</th>
                                        <th className="col-sm-3">Plaintiffs Firm</th>
                                        <th className="col-sm-2">Disease</th>
                                        <th className="col-sm-2">
                                            {props.inputModel.additionalSettings
                                                ?.reportCaseDetailsLastColumnDisplayName ??
                                                'Last Column Header Name'}
                                        </th>
                                        {Authorization.isAuthorizedToRoute(
                                            LocalRoutes.CaseEasyUpdate,
                                            user
                                        ) && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.tenderedCases
                                        .filter((item) =>
                                            previewFilter(
                                                item.caseGuid,
                                                TimelineReportSections.TenderedCases,
                                                EntityTypes.Case
                                            )
                                        )
                                        .map((item: IReportCaseDetailModel) =>
                                            buildReportSection(
                                                item,
                                                TimelineReportSections.TenderedCases
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.TenderedCases)}
                        rowCount={props.data.tenderedCases.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.RequestForAuth) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.RequestForAuth
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.RequestForAuth}
                        title="Outstanding Requests for Authority"
                    />

                    {props.data.clientRequests.length > 0 && (
                        <div className="margin-left-20">
                            <table className="table">
                                <thead>
                                    <tr
                                        style={{
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        {!props.isPreviewMode && (
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    checked={isSectionRowsSelectAllChecked(
                                                        TimelineReportSections.RequestForAuth
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleSelectAllCheckedChanged(
                                                            e,
                                                            TimelineReportSections.RequestForAuth
                                                        );
                                                    }}
                                                    disabled={props.isReportReadonly}
                                                />
                                            </th>
                                        )}
                                        <th>Case</th>
                                        <th className="col-sm-1">Requested Authority</th>
                                        <th className="col-sm-1">Date Requested</th>
                                        <th className="col-sm-2">Jurisdiction</th>
                                        <th className="col-sm-3">Plaintiffs Firm</th>
                                        <th className="col-sm-1">Disease</th>
                                        <th className="col-sm-1">Trial Date</th>
                                        <th className="col-sm-1">Demand</th>
                                        <th className="col-sm-1">Client Exposure Start Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.clientRequests
                                        .filter((item) =>
                                            previewFilter(
                                                item.caseGuid,
                                                TimelineReportSections.RequestForAuth,
                                                EntityTypes.Case
                                            )
                                        )
                                        .map(buildRequestForAuthRows)}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.RequestForAuth)}
                        rowCount={props.data.clientRequests.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.RiskIssues) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.RiskIssues
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.RiskIssues}
                        title="Risk Issues"
                    />

                    {props.data.riskIssues.length > 0 && (
                        <div className="margin-left-20">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {!props.isPreviewMode && (
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    checked={isSectionRowsSelectAllChecked(
                                                        TimelineReportSections.RiskIssues
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleSelectAllCheckedChanged(
                                                            e,
                                                            TimelineReportSections.RiskIssues
                                                        );
                                                    }}
                                                    disabled={props.isReportReadonly}
                                                />
                                            </th>
                                        )}
                                        <th className="col-sm-2">Case Name</th>
                                        <th className="col-sm-2">Jurisdiction</th>
                                        <th className="col-sm-3">Plaintiffs Firm</th>
                                        <th className="col-sm-2">Disease</th>
                                        <th>
                                            {props.inputModel.additionalSettings
                                                ?.reportCaseDetailsLastColumnDisplayName ??
                                                'Last Column Header Name'}
                                        </th>
                                        {Authorization.isAuthorizedToRoute(
                                            LocalRoutes.CaseEasyUpdate,
                                            user
                                        ) && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.riskIssues
                                        .filter((item) =>
                                            previewFilter(
                                                item.caseGuid,
                                                TimelineReportSections.RiskIssues,
                                                EntityTypes.Case
                                            )
                                        )
                                        .map((item: IReportCaseDetailModel) =>
                                            buildReportSection(
                                                item,
                                                TimelineReportSections.RiskIssues
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.RiskIssues)}
                        rowCount={props.data.riskIssues.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.SignificantActivity) && (
                <div className="pb-2">
                    <SectionHeader
                        checked={props.inputModel?.reportSectionDetails?.some(
                            (x) => x.section === TimelineReportSections.SignificantActivity
                        )}
                        isPreviewMode={props.isPreviewMode}
                        onCommentsButtonClick={handleCommentsButtonClick}
                        onSectionCheckedChange={props.onSectionCheckedChange}
                        readonly={props.isReportReadonly}
                        section={TimelineReportSections.SignificantActivity}
                        title="Significant Activity"
                    />

                    {props.data.significantActivities.length > 0 && (
                        <div className="margin-left-20">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {!props.isPreviewMode && (
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    name="selectAll"
                                                    checked={isSectionRowsSelectAllChecked(
                                                        TimelineReportSections.SignificantActivity
                                                    )}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        handleSelectAllCheckedChanged(
                                                            e,
                                                            TimelineReportSections.SignificantActivity
                                                        );
                                                    }}
                                                    disabled={props.isReportReadonly}
                                                />
                                            </th>
                                        )}
                                        <th className="col-sm-2">Case Name</th>
                                        <th className="col-sm-2">Jurisdiction</th>
                                        <th className="col-sm-3">Plaintiffs Firm</th>
                                        <th className="col-sm-2">Disease</th>
                                        <th>
                                            {props.inputModel.additionalSettings
                                                ?.reportCaseDetailsLastColumnDisplayName ??
                                                'Last Column Header Name'}
                                        </th>
                                        {Authorization.isAuthorizedToRoute(
                                            LocalRoutes.CaseEasyUpdate,
                                            user
                                        ) && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.data.significantActivities
                                        .filter((item) =>
                                            previewFilter(
                                                item.caseGuid,
                                                TimelineReportSections.SignificantActivity,
                                                EntityTypes.Case
                                            )
                                        )
                                        .map((item: IReportCaseDetailModel) =>
                                            buildReportSection(
                                                item,
                                                TimelineReportSections.SignificantActivity
                                            )
                                        )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    <SectionFooter
                        text={getCommentText(TimelineReportSections.SignificantActivity)}
                        rowCount={props.data.significantActivities.length}
                    />
                </div>
            )}

            {displaySection(TimelineReportSections.Depositions) && (
                <UpcomingDepositionsSection
                    data={props.data.depositions}
                    checked={props.inputModel?.reportSectionDetails?.some(
                        (x) => x.section === TimelineReportSections.Depositions
                    )}
                    isPreviewMode={props.isPreviewMode}
                    getCheckedValue={getCheckedValue}
                    onCommentsButtonClick={handleCommentsButtonClick}
                    onCheckedChange={handleCheckedChange}
                    onEasyUpdate={handleEasyUpdate}
                    onSectionCheckedChange={props.onSectionCheckedChange}
                    onSelectAllCheckedChanged={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleSelectAllCheckedChanged(e, TimelineReportSections.Depositions);
                    }}
                    previouslyViewedGuids={props.collabReportState.easyUpdateAttemptedCaseList}
                    readonly={props.isReportReadonly}
                    sectionComment={getCommentText(TimelineReportSections.Depositions)}
                    user={user}
                />
            )}

            {displaySection(TimelineReportSections.ZoneNotes) && (
                <IndustryNewsSection
                    data={props.data.zoneNotes.filter((item) =>
                        previewFilter(
                            item.noteGuid,
                            TimelineReportSections.ZoneNotes,
                            EntityTypes.Note
                        )
                    )}
                    checked={props.inputModel?.reportSectionDetails?.some(
                        (x) => x.section === TimelineReportSections.ZoneNotes
                    )}
                    getCheckedValue={getCheckedValue}
                    isPreviewMode={props.isPreviewMode}
                    onCommentsButtonClick={handleCommentsButtonClick}
                    onCheckedChange={handleCheckedChange}
                    onNoteEdit={handleNoteEdit}
                    onSectionCheckedChange={props.onSectionCheckedChange}
                    onSelectAllCheckedChanged={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleSelectAllCheckedChanged(e, TimelineReportSections.ZoneNotes);
                    }}
                    previouslyViewedGuids={props.collabReportState.noteEditAttemptedGuidList}
                    readonly={props.isReportReadonly}
                    sectionComment={getCommentText(TimelineReportSections.ZoneNotes)}
                    selectAllChecked={isSectionRowsSelectAllChecked(
                        TimelineReportSections.ZoneNotes
                    )}
                    user={user}
                />
            )}
        </div>
    );
}
