import { IIconButtonprops, IconButton } from './IconButton';

export interface ILoadingButtonProps extends IIconButtonprops {
    icon?: string;
    loading?: boolean;
    loadingText?: string;
}

export function LoadingButton(props: ILoadingButtonProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { icon, loading, loadingText, ...rest } = props;

    return (
        <IconButton
            {...rest}
            text={props.loading ? props.loadingText : props.text}
            icon={props.loading ? 'fal fa-spinner fa-solid fa-spin' : props.icon}
        />
    );
}
