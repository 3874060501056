import { AssertionDescriptorUsageTypesEnum } from '../../../utilities/Constants';
import { CheckmateDialog } from '../../shared/dialog';
import { IAssertionDescriptorModel } from '../../../interfaces/IAssertionDescriptor';
import { ICaseStatusLookupModel } from '../../../interfaces/ICase';
import { IQueryFilterCriteria } from '../../../interfaces/IQuery';
import { IStaticItemLookupModel } from '../../../interfaces/ILookup';
import { ITriggerRuleSettingsModel } from '../../../interfaces/ITriggerRule';
import { IUserModel } from '../../../interfaces/IUser';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import { SortableHeader } from '../../shared/SortableHeader';
import { TriggerRulesTableRow } from './TriggerRulesTableRow';
import { useAssertionDescriptorsLookup } from '../../../shared/react-query-hooks/useAssertionDescriptorsLookup';
import { useDeleteTriggerMutation } from '../useDeleteTriggerMutation';
import { useGetStaticItems } from '../../../shared/react-query-hooks/useGetStaticItems';
import { useLoading } from '../../../contexts/LoadingContext';
import { useNavigate } from 'react-router';
import { useSort } from '../../../hooks/useSort';
import { useState } from 'react';

export interface ITriggerRulesTableProps {
    allowDisable?: boolean;
    onDeleteError: (errorMessage: string) => void;
    onDeleteSuccess: () => void;
    user: IUserModel;
    settings: ITriggerRuleSettingsModel[];
}

export interface ITriggerRuleInfoCellProps {
    assertionDescriptors: IAssertionDescriptorModel[];
    caseStatuses: ICaseStatusLookupModel[];
    staticItems: IStaticItemLookupModel[];
    triggerFields: IQueryFilterCriteria[];
}

export function TriggerRulesTable(props: ITriggerRulesTableProps) {
    const navigate = useNavigate();
    const loading = useLoading();

    const deleteMutation = useDeleteTriggerMutation((errorMessage: string) =>
        props.onDeleteError(errorMessage)
    );

    const { data: assertionDescriptors } = useAssertionDescriptorsLookup(
        AssertionDescriptorUsageTypesEnum.Case
    );
    const { data: staticItems } = useGetStaticItems();
    const { handleSort, sortedData } = useSort<ITriggerRuleSettingsModel>(props.settings);

    const [triggerToDelete, setTriggerToDelete] = useState<string | null>(null);

    const handleDeleteConfirm = () => {
        if (!triggerToDelete) return;
        loading.showLoading();

        deleteMutation
            .mutateAsync(triggerToDelete)
            .then(props.onDeleteSuccess)
            .catch(props.onDeleteError)
            .finally(() => {
                setTriggerToDelete(null);
                loading.hideLoading();
            });
    };

    const handleTriggerRowEditClick = (triggerGuid: string) => {
        navigate(LocalRoutes.TriggerDetails.replace(':triggerGuid', triggerGuid));
    };

    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                        <SortableHeader headerText="Name" sortKey="ruleName" onSort={handleSort} />
                        <th className="col-sm-3">Description</th>
                        <th className="col-sm-3">Case Criteria</th>
                        <th className="col-sm-3">Trigger Fields</th>
                        <th></th>
                        {!!props.allowDisable && <th style={{ textAlign: 'right' }}>Enabled</th>}
                    </tr>
                </thead>
                <tbody>
                    {sortedData.map((setting) => (
                        <TriggerRulesTableRow
                            showDisableToggle={props.allowDisable}
                            assertionDescriptors={assertionDescriptors ?? []}
                            key={setting.guid}
                            onDeleteClick={setTriggerToDelete}
                            onEditClick={handleTriggerRowEditClick}
                            setting={setting}
                            staticItems={staticItems ?? []}
                            user={props.user}
                        />
                    ))}
                </tbody>
            </table>
            <CheckmateDialog
                isShowingModal={!!triggerToDelete}
                body="Are you sure you want to delete the selected trigger?"
                handleClose={() => setTriggerToDelete(null)}
                handleConfirm={handleDeleteConfirm}
                confirmText="Yes"
                cancelText="No"
                confirmButtonClassName="btn btn-black float-end"
                dialogClassName="confirm-document-delete-dialog"
                closeButtonClassName="btn btn-default float-end"
            />
        </>
    );
}
