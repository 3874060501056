export interface IEasyUpdateButtonProps {
    caseGuid: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>, caseGuid: string) => void;
    visited?: boolean;
}

export function EasyUpdateButton(props: IEasyUpdateButtonProps) {
    return (
        <button
            className="btn-no-bg"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                props.onClick(event, props.caseGuid)
            }
        >
            <i className={`fal fa-edit ${props.visited ? 'color-gray' : 'color-black'} `} />
        </button>
    );
}
