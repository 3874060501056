import * as React from 'react';

import { IKpiReportInputModel, IKpiReportModel } from './KpiReport';

import Html5ReportViewer from '../../../components/report/Html5ReportViewer';
import { ICollabReportViewProps } from '../collab-report/CollabReportController';

export function KpiReportRenderer<T extends IKpiReportModel, I extends IKpiReportInputModel>(
    props: ICollabReportViewProps<T, I>
) {
    const [refreshReport, setRefreshReport] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.data.refreshToken) {
            setRefreshReport(true);
        }
    }, [props.data.refreshToken]);

    if (!props.data.renderReport) {
        return null;
    }

    return (
        <Html5ReportViewer
            reportName="KpiReport.trdp"
            reportParameters={props.data.parameters}
            refreshReport={refreshReport}
            scale={1.3}
            renderComplete={() => setRefreshReport(false)}
        />
    );
}
