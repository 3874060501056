import * as React from 'react';

import {
    ApplicationHelpType,
    LookupDataEnums,
    MyDocumentsTypesEnum,
    UserRightsEnum,
} from '../../../utilities/Constants';
import { ILookupModel, IMultiSelectOptions } from '../../../interfaces/ILookup';
import {
    IPackageSettlementBaseModel,
    IPackageSettlementFirmModel,
} from '../../../interfaces/ICase';

import ApiClient from '../../../services/apiClient';
import { ApiRoutes } from '../../../utilities/ApiRoutes';
import Authorization from '../../../stores/Authorization';
import { CheckmateDialog } from '../../../components/shared/dialog';
import CheckmateNSelect from '../../../components/shared/CheckmateNSelect';
import Common from '../../../stores/Common';
import { DisplayMessages } from '../../../utilities/DisplayMessages';
import { DocumentTitle } from '../../../components/shared/DocumentTitle';
import { Help } from '../../../components/shared/Help';
import { IReportDateFilterModel } from '../../../interfaces/Report/IReport';
import { IUserModel } from '../../../interfaces/IUser';
import { IValidation } from '../../../interfaces/IError';
import { Link } from 'react-router-dom';
import { Loader } from '../../../components/shared/Loader';
import { LocalRoutes } from '../../../utilities/LocalRoutes';
import Sort from '../../../stores/Sort';
import { SortableHeader } from '../../../components/shared/SortableHeader';
import { Stack } from 'react-bootstrap';
import ValidateUtils from '../../../shared/validations';
import { useGetLookupData } from '../../../shared/react-query-hooks/useGetLookupData';
import { useGetPlaintiffsFirms } from '../../../shared/react-query-hooks/useGetPlaintiffsFirms';

interface IPackageSettlementProps {
    user: IUserModel;
    refreshIdentifier?: string;
    statuses?: IMultiSelectOptions[] | undefined;
    plaintiffsFirms?: IMultiSelectOptions[] | undefined;
}

interface IPackageSettlementListState {
    pendingResponse: boolean;
    validation: IValidation;
    packageSettlementList: IPackageSettlementBaseModel[];
    startDateFilter?: string;
    endDateFilter?: string;
    availableStatuses: IMultiSelectOptions[];
    selectedStatuses: IMultiSelectOptions[];
    selectedPlaintiffsFirms: IMultiSelectOptions[];
    selectedPackageSettlementGuidToDelete?: string;
    showConfirmDeleteDialog?: boolean;
}

const _apiClient = new ApiClient();

function lookupItemToMultiSelectOptionMapper(lookupItem: ILookupModel) {
    const value =
        lookupItem.guid === '00000000-0000-0000-0000-000000000000' || !lookupItem.guid
            ? lookupItem.id
            : lookupItem.guid;

    return {
        id: lookupItem.id,
        label: lookupItem.displayName,
        value,
    } as IMultiSelectOptions;
}

export function PackageSettlementListWrapper(props: IPackageSettlementProps) {
    const packageDealStatuses = useGetLookupData(LookupDataEnums.PackageSettlementStatusTypes);
    const plaintiffsFirms = useGetPlaintiffsFirms();

    if (packageDealStatuses.isLoading || plaintiffsFirms.isLoading) {
        return <Loader />;
    }

    return (
        <PackageSettlementList
            {...props}
            statuses={packageDealStatuses.data?.map(lookupItemToMultiSelectOptionMapper) ?? []}
            plaintiffsFirms={plaintiffsFirms.data?.map(lookupItemToMultiSelectOptionMapper) ?? []}
        />
    );
}

export class PackageSettlementList extends React.Component<
    IPackageSettlementProps,
    IPackageSettlementListState
> {
    constructor(props: IPackageSettlementProps) {
        super(props);

        this.state = {
            availableStatuses: props.statuses ?? [],
            pendingResponse: false,
            packageSettlementList: [],
            selectedStatuses: [],
            selectedPlaintiffsFirms: [],
            validation: {},
        };
    }

    componentDidMount() {
        if (!Authorization.isAuthorizedToRoute(LocalRoutes.PackageSettlements, this.props.user)) {
            window.location.assign(LocalRoutes.AccessDenied);
        }
        this.loadPackageSettlementList();
    }

    componentDidUpdate(prevProps: IPackageSettlementProps) {
        if (
            this.props.refreshIdentifier &&
            prevProps.refreshIdentifier != this.props.refreshIdentifier
        ) {
            this.setState(
                { startDateFilter: undefined, endDateFilter: undefined, selectedStatuses: [] },
                this.loadPackageSettlementList
            );
        }
    }

    loadPackageSettlementList = async () => {
        this.setState({ pendingResponse: true });

        const selectedPlaintiffsFirms = this.state.selectedPlaintiffsFirms.map(
            (filter) => filter.value
        );

        const res = await _apiClient.getPackageSettlements({
            startDate: this.state.startDateFilter,
            endDate: this.state.endDateFilter,
            statusIds: this.state.selectedStatuses?.map((filter) => filter.id),
            plaintiffsFirmIds: selectedPlaintiffsFirms,
        });

        if (res.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (res.errorMessage) {
            this.setState({ validation: ValidateUtils.parseErrors(res.errors, res.errorMessage) });
            return;
        }
        if (res.payload) {
            this.setState({ packageSettlementList: res.payload, pendingResponse: false });
        }
    };

    sortData = (key: string, order: string, subKey?: string, subGrandKey?: string) => {
        let packageSettlementList = this.state.packageSettlementList;
        if (packageSettlementList) {
            if (key.includes('date')) {
                packageSettlementList = packageSettlementList.sort(
                    Sort.compareDate(key, subKey, order)
                );
            } else {
                packageSettlementList = packageSettlementList.sort(
                    Sort.compareValues(key, subKey, order, subGrandKey)
                );
            }
        }

        this.setState({ packageSettlementList: packageSettlementList });
    };

    handleClearSearch = async () => {
        this.setState(
            { startDateFilter: undefined, endDateFilter: undefined, selectedStatuses: [] },
            this.loadPackageSettlementList
        );
    };

    generateAndDownloadReport = async () => {
        this.setState({ pendingResponse: true, validation: {} });

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/' + ApiRoutes.ExportReport, true);
        xhr.responseType = 'blob';
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                this.setState({ pendingResponse: false, validation: {} });
                let fileName = 'PackageSettlementOverview.pdf';
                const blob = xhr.response;

                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                if (contentDisposition) {
                    const contentDispositionItems = contentDisposition.split(';');
                    if (contentDispositionItems) {
                        for (let i = 0; i < contentDispositionItems.length; i++) {
                            const currentItem = contentDispositionItems[i];
                            if (currentItem.includes('filename=')) {
                                const n = currentItem.indexOf('filename=') + 9;
                                fileName = contentDispositionItems[i].substring(
                                    n + 1,
                                    contentDispositionItems[i].length - 1
                                );
                                break;
                            }
                        }
                    }
                }
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = fileName;
                a.dispatchEvent(new MouseEvent('click'));
            }

            if (xhr.readyState === XMLHttpRequest.DONE && xhr.status >= 400) {
                const validation = this.state.validation;
                validation.model = [DisplayMessages.ReportGenerateError];
                this.setState({ pendingResponse: false, validation: validation });
            }
        };

        const dateFilter: IReportDateFilterModel = {};
        if (this.state.startDateFilter) dateFilter.startDate = this.state.startDateFilter;
        if (this.state.endDateFilter) dateFilter.endDate = this.state.endDateFilter;
        const reportParameters = {
            reportType: { id: MyDocumentsTypesEnum.PackageSettlements.Value },
            date: dateFilter,
        };

        xhr.send(JSON.stringify(reportParameters));
    };

    delete = async () => {
        if (!this.state.selectedPackageSettlementGuidToDelete) return;

        const response = await _apiClient.deletePackageSettlement(
            this.state.selectedPackageSettlementGuidToDelete
        );
        if (response.httpResponse.status == 401) {
            window.location.reload();
            return;
        }
        if (response.errorMessage) {
            this.setState({
                validation: ValidateUtils.parseErrors(response.errors, response.errorMessage),
            });
            return;
        }

        const packageSettlementList = this.state.packageSettlementList;
        packageSettlementList.splice(
            packageSettlementList.findIndex(
                (x) => x.guid === this.state.selectedPackageSettlementGuidToDelete
            ),
            1
        );
        this.setState({
            packageSettlementList: packageSettlementList,
            showConfirmDeleteDialog: false,
        });
    };

    handleStatusFilterChange = (statuses: IMultiSelectOptions[]) => {
        this.setState({ selectedStatuses: statuses });
    };

    handlePlaintiffsFirmFilterChange = (firms: IMultiSelectOptions[]) => {
        console.log({ firms });
        this.setState({ selectedPlaintiffsFirms: firms });
    };

    render() {
        const selectedStatuses =
            this.props.statuses?.filter((opt) =>
                this.state.selectedStatuses?.some((filter) => filter.id === opt.id)
            ) ?? [];

        if (this.state.pendingResponse) return <Loader />;

        return (
            <div>
                <DocumentTitle title="Package Deals"></DocumentTitle>
                <Stack
                    direction="horizontal"
                    className="pb-3"
                    style={{ justifyContent: 'space-between' }}
                >
                    <div>
                        <span style={{ fontSize: '24px' }}>Package Deals</span>
                    </div>
                    {Authorization.userHasRight(
                        UserRightsEnum.AddEditDeletePackageSettlement,
                        this.props.user
                    ) && (
                        <Link
                            to={LocalRoutes.PackageSettlementDetails.replace(':guid', '')}
                            style={{ textDecoration: 'none' }}
                        >
                            <span className="btn-green btn float-end btn-icon">
                                <i className="fal fa-lg fa-plus color-white" />
                            </span>
                        </Link>
                    )}
                </Stack>
                <Stack direction="horizontal" className="mb-3">
                    <div className="text-gray text-lg" style={{ width: 75 }}>
                        Filter
                    </div>
                    <Stack direction="horizontal" gap={3}>
                        <div style={{ width: 250 }}>
                            <CheckmateNSelect
                                options={this.state.availableStatuses}
                                value={selectedStatuses}
                                onChange={this.handleStatusFilterChange}
                                placeholder="-- Status --"
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <CheckmateNSelect
                                options={this.props.plaintiffsFirms ?? []}
                                value={this.state.selectedPlaintiffsFirms}
                                onChange={this.handlePlaintiffsFirmFilterChange}
                                placeholder="-- Plaintiffs Firm --"
                            />
                        </div>
                        <Stack direction="horizontal" gap={1}>
                            <input
                                type="date"
                                name="startDate"
                                value={this.state.startDateFilter}
                                onChange={(e) => this.setState({ startDateFilter: e.target.value })}
                                className={
                                    'form-control d-inline-block' +
                                    (this.state.startDateFilter &&
                                    Common.isValidDate(this.state.startDateFilter)
                                        ? ''
                                        : ' unselectClass')
                                }
                            />
                            <span>to</span>
                            <input
                                type="date"
                                name="endDate"
                                value={this.state.endDateFilter}
                                onChange={(e) => this.setState({ endDateFilter: e.target.value })}
                                className={
                                    'form-control d-inline-block' +
                                    (this.state.endDateFilter &&
                                    Common.isValidDate(this.state.endDateFilter)
                                        ? ''
                                        : ' unselectClass')
                                }
                            />
                            <Help
                                type={ApplicationHelpType.Info}
                                title="Date Range"
                                helpText="The date range filter/criteria looks at the Final Date if the deal status is Complete; for all other statuses, the filter/criteria looks at Initial Date."
                            />
                        </Stack>
                        <div>
                            <button
                                type="button"
                                className="btn btn-black btn-icon"
                                onClick={() => this.loadPackageSettlementList()}
                            >
                                <i className="fal fa-filter color-white" />
                            </button>
                            <button
                                type="button"
                                className="btn btn-gray btn-icon"
                                onClick={this.handleClearSearch}
                            >
                                <i className="fal fa-times color-white" />
                            </button>
                        </div>
                    </Stack>
                </Stack>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            <SortableHeader
                                headerText="Name"
                                sortKey="name"
                                onSort={this.sortData}
                                thClassName="col-sm-2"
                            />
                            <SortableHeader
                                headerText="Status"
                                sortKey="status"
                                subKey="displayName"
                                onSort={this.sortData}
                            />
                            <SortableHeader
                                headerText="Initial Date"
                                sortKey="initialDate"
                                onSort={this.sortData}
                                noWrap
                            />
                            <SortableHeader
                                headerText="Final Date"
                                sortKey="finalDate"
                                onSort={this.sortData}
                                noWrap
                            />
                            <SortableHeader
                                headerText="Plaintiffs Firms"
                                sortKey="firmSortText"
                                onSort={this.sortData}
                                thClassName="col-sm-2"
                                noWrap
                            />
                            <th className="center">Total Cases</th>
                            <th className="center">Dismissed</th>
                            <th className="center">Settled</th>
                            <th className="center">Alt-Monitored</th>
                            <th className="center">Total Settlement</th>
                            {Authorization.userHasRight(
                                UserRightsEnum.AddEditDeletePackageSettlement,
                                this.props.user
                            ) && <th />}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.packageSettlementList.map(
                            (deal: IPackageSettlementBaseModel) => {
                                return (
                                    <tr key={deal.guid}>
                                        <td>
                                            {' '}
                                            <Link
                                                target="_blank"
                                                to={LocalRoutes.PackageSettlementDetails.replace(
                                                    ':guid',
                                                    deal!.guid!
                                                )}
                                            >
                                                {deal.name}
                                            </Link>
                                        </td>
                                        <td>{deal.status!.displayName}</td>
                                        <td>{Common.dateFormat(deal.initialDate)}</td>
                                        <td>{Common.dateFormat(deal.finalDate)}</td>
                                        <td>
                                            {deal.firms ? (
                                                <ul className="m-0 list-unstyled">
                                                    {deal.firms.map(
                                                        (firm: IPackageSettlementFirmModel) => {
                                                            return (
                                                                <li key={firm.guid}>{firm.name}</li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            ) : null}
                                        </td>
                                        <td className="center">
                                            {!deal.caseStats ? '' : deal.caseStats.total}
                                        </td>
                                        <td className="center">
                                            {!deal.caseStats ? '' : deal.caseStats.dismissed}
                                        </td>
                                        <td className="center">
                                            {!deal.caseStats ? '' : deal.caseStats.settled}
                                        </td>
                                        <td className="center">
                                            {!deal.caseStats ? '' : deal.caseStats.alt}
                                        </td>
                                        <td className="center">
                                            {!deal.caseStats
                                                ? ''
                                                : deal.caseStats.settlement
                                                ? Common.formatCurrency(
                                                      deal.caseStats.settlement.toString()
                                                  )
                                                : ''}
                                        </td>
                                        {Authorization.userHasRight(
                                            UserRightsEnum.AddEditDeletePackageSettlement,
                                            this.props.user
                                        ) && (
                                            <td>
                                                <button
                                                    className="btn-no-bg float-end"
                                                    onClick={() => {
                                                        this.setState({
                                                            showConfirmDeleteDialog: true,
                                                            selectedPackageSettlementGuidToDelete:
                                                                deal.guid!,
                                                        });
                                                    }}
                                                >
                                                    <i className="fal fa-lg fa-trash-alt"></i>
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>

                <CheckmateDialog
                    isShowingModal={this.state.showConfirmDeleteDialog || false}
                    body="Are you sure you want to delete this item? This operation is permanent and cannot be reverted back."
                    handleClose={() => this.setState({ showConfirmDeleteDialog: false })}
                    handleConfirm={this.delete}
                    confirmText="Yes"
                    cancelText="No"
                    confirmButtonClassName="btn btn-black float-end"
                    dialogClassName="confirm-document-delete-dialog"
                    closeButtonClassName="btn btn-default float-end"
                />
            </div>
        );
    }
}
