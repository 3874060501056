export const LocalRoutes = {
    Root: '/',
    Home: '/Home',
    AcceptInvitation: '/user/acceptinvitation/:guid',
    RegisterWithInvitation: '/invitation/register/:guid',

    OrganizationDetail: '/organization/:guid',

    Login: '/account/login',
    LoginAdvanced: '/account/login/:guid',
    Logout: '/account/logout',
    ForgotPassword: '/account/forgotpassword',
    ResetPassword: '/account/resetpassword',

    UserTasks: '/tasks',
    UserReportList: '/userreports',
    UserZoneProfile: '/userzoneprofile',
    TaskBarSetting: '/taskbarsettings',

    AssertionDescriptors: '/admin/assertiondescriptors',
    AssertionDescriptorNew: '/admin/assertiondescriptor',
    AssertionDescriptorDetail: '/admin/assertiondescriptor/:guid',
    CreateInvitation: '/admin/invitation',
    Users: '/admin/users',
    UserDetail: '/admin/user/:guid',
    Zones: '/admin/zones',
    ZoneDetail: '/admin/zone/:guid',
    ZoneNew: '/admin/zone',

    PackageSettlementDetailExportID: '/packageSettlementDetailExport/',
    PackageSettlementDetailExport: '/packageSettlementDetailExport/:guid',

    Cases: '/cases',
    CaseFileNew: '/case/new',
    CaseFile: '/case/:guid',
    CaseEasyUpdate: '/easyupdate',
    CasePlan: '/caseplan/:guid',
    CasePlanTaskDetail: '/caseplantask/:caseguid/:caseplanguid/:taskguid',
    WorkProductDetail: '/workproduct/:entityguid/:entitytypeid/:documentguid?',

    PackageSettlements: '/packagesettlements',
    PackageSettlementDetails: '/packagesettlement/:guid',
    PackageSettlementNew: '/packagesettlement/',

    ReportTypeList: '/reporttypes',
    ThreatReport: '/threatreport',
    ReportDetail: '/reports/:id',
    AutoExportReport: '/autoexportreport/:id',
    Query: '/query',
    Query2: '/query2',
    QueryLegacy: '/query-legacy',
    ZoneNotes: '/zonenotes',
    MyDocuments: '/mydocuments',

    // Zone Specific Local Routes. This will match dbo.OrganizationReportSetting.Url or dbo.ReportType.Url
    WeeklyRecap: '/weeklyrecap',
    WeeklyRecapCollab: '/weeklyrecap/:guid',
    WeeklyRecapLegacy: '/weeklyrecaplegacy',
    
    MonthlyRecap: '/monthlyrecap',
    MonthlyRecapCollab: '/monthlyrecap/:guid',
    MonthlyRecapLegacy: '/monthlyrecaplegacy',
    
    KpiReport: '/kpireport',
    KpiReportCollab: '/kpireport/:guid',
    KpiReportLegacy: '/kpilegacy',
    
    Trends: '/trends',

    Board: '/board',
    BoardCaseDetail: '/boardcasedetail',
    BoardViewDetail: '/boardviewdetail',

    Experts: '/experts',
    ExpertCreate: '/experts/new',
    ExpertDetail: '/experts/:id',
    ExpertsDetails: '/experts/:guid',

    CaseExpertDetail: '/caseexpert/:id',

    AccessDenied: '/accessdenied',
    StrategicPlan: '/strategicplan',
    StrategyExecutionPlan: '/strategyexecutionplan',
    Strategy: '/strategy/:type',

    PlaintiffFirmStrategyDetail: '/plaintifffirmstrategydetail/:guid',
    JurisdictionStrategyDetail: '/jurisdictionstrategydetail/:guid',
    LocalCounselStrategyDetail: '/localcounselstrategydetail/:guid',

    PlaintiffFirmStrategyNew: '/plaintifffirmstrategydetail/',
    JurisdictionStrategyNew: '/jurisdictionstrategydetail/',
    LocalCounselStrategyNew: '/localcounselstrategydetail/',

    Projects: '/projects',
    ProjectDetail: '/project/:guid',
    ProjectNew: '/project/',
    MeetingAgenda: '/meetingagenda/:guid',

    Snapshot: '/snapshot',
    Alerts: '/alerts/:type',
    ViewPDF: '/viewpdf',
    ViewSpecificPDF: '/viewpdf/:id',
    Tutorials: '/tutorials',

    Triggers: '/triggers',
    TriggerNew: '/triggers/new',
    TriggerDetails: '/trigger/:triggerGuid',
};
