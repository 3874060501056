import { CheckmateDialog } from '../../../../components/shared/dialog';
import { ICollabModalProps } from './CollabModalContext';

export function ClearSettingsConfirmModal(props: ICollabModalProps<unknown, unknown>) {
    if (!props.open) return null;

    return (
        <CheckmateDialog
            isShowingModal={props.open}
            body="Are you sure you want to clear all settings and set to default?"
            handleClose={() => props.onClose()}
            handleConfirm={() => {
                if (props.onConfirm) {
                    props.onConfirm();
                }
            }}
            confirmText="Yes"
            cancelText="No"
            confirmButtonClassName="btn btn-black float-end"
            dialogClassName="confirm-document-delete-dialog"
            closeButtonClassName="btn btn-default float-end"
            title="Clear Settings"
        />
    );
}
